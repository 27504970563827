<mat-card-header class="card-header">
  <div class="card-avatar">
    <img
      mat-card-avatar
      [src]="content.image ? content.image : picturePlaceholder"
      (click)="goToDetails()" />
    <span class="status" [ngClass]="{ active: isOnline }"> </span>
  </div>

  <div class="card-head-details">
    <card-status-badge
      *ngIf="content.isActive"
      [oldStyle]="true"
      [cardId]="parentCardId"
      [instanceType]="'driver'"
      [item]="content">
    </card-status-badge>

    <div
      class="m-b-sm card-status pending"
      [id]="isFirst ? 'driver-status' : ''"
      *ngIf="!content.isActive">
      <span> {{ 'CARD-ASSIGNMENT.PENDING-INVITATION' | translate }}</span>
    </div>

    <mat-card-title (click)="goToDetails()">
      {{ driverName }}
    </mat-card-title>
    <mat-card-subtitle (click)="goToDetails()">
      {{ 'COMMON.IDENTIFICATION' | translate }} :
      {{ content['document-number'] ? content['document-number'] : '' }}
    </mat-card-subtitle>
  </div>
</mat-card-header>
