import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import {
  DriversService,
  HelpersService,
  FirebaseService,
  DriverEventsService,
} from '../../../services';
import { forEach, isNull } from 'lodash';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'card-header-driver',
  templateUrl: './card-header-driver.component.html',
  styleUrls: ['./card-header-driver.component.sass'],
})
export class CardHeaderDriverComponent implements OnInit {
  @Input() content: any = {};
  @Input() assigned: boolean = false;
  @Input() specialStatus: boolean = false;
  @Input() inProgress: boolean = false;
  @Input() setback: boolean = false;
  @Input() isFirst: boolean = false;
  @Input() parentCardId: string = '';

  isOnline: boolean = false;
  picturePlaceholder: string = './assets/img/profile-placeholder.jpg';
  driverName: string = '';

  private interval: any;
  private subscriptions: any = {};

  constructor(
    private router: Router,
    private driverService: DriversService,
    private helper: HelpersService,
    private firebase: FirebaseService,
    private statusService: DriverEventsService
  ) {}

  ngOnInit() {
    this.getName();
    this.checkOnline();

    if (!this.content) return;

    this.statusSubscription(this.content._id);
  }

  // clear subscriptions on destroy
  ngOnDestroy() {
    forEach(this.subscriptions, value => value.unsubscribe());
    clearInterval(this.interval);
  }

  /**
   * Go to details page
   */
  goToDetails() {
    this.router.navigate(['drivers/details', this.content._id]);
  }

  /**
   * Check if driver it's online
   *
   * @param itemId
   */
  checkOnline(itemId?: string) {
    if (this.subscriptions.online) this.subscriptions.online.unsubscribe();

    if (!this.content) return;

    this.subscriptions.online = this.driverService
      .checkIfOnline(this.content._id)
      .subscribe(isOnline => {
        this.isOnline = isOnline;
        let status = {
          cardId: this.parentCardId,
          isOnline: isOnline,
        };
        this.statusService.driverEvent.next(status);
      });
  }

  /**
   * get driver name from content
   */
  private getName() {
    if (!this.content) return;

    let firstName = this.content.firstName ? this.content.firstName : null;
    let lastName = this.content.lastName ? this.content.lastName : null;
    let mailName = this.content.email.split('@')[0];

    this.driverName =
      !firstName && !lastName ? mailName : `${firstName} ${lastName}`;
  }

  /**
   * listen event
   *
   * @param driverId
   */
  private statusSubscription(driverId: string) {
    this.subscriptions['driverEvents'] = this.firebase
      .subscribeToNode(`users/${driverId}`, null, null)
      .pipe(debounceTime(500))
      .subscribe(status => {
        status = isNull(status) ? {} : status;
        status.cardId = this.parentCardId;
        //this.statusService.driverEvent.next(status);
      });
  }
}
