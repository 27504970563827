<div class="table-list-container">
  <div class="table-list-header">
    <ul class="items" [ngClass]="{ 'routes-table': type === 'route' }">
      <li
        class="item"
        *ngFor="let col of displayedColumns[type]"
        [ngClass]="col.class">
        {{ col.label | translate }}
      </li>
    </ul>
  </div>

  <div class="table-list-rows-container" *ngIf="type !== 'route'">
    <card-assignment-row
      *ngFor="let item of dataSource; let first = first"
      (onEdition)="edition(item)"
      (onDelete)="deleted(item)"
      (onResend)="resend(item)"
      [vehiclesCount]="vehiclesAvailable"
      [routesCount]="routesAvailable"
      [driversCount]="driversAvailable"
      [queryDate]="dateQuery"
      [type]="type"
      [isFirst]="first"
      [content]="item">
    </card-assignment-row>
  </div>

  <div class="table-list-rows-container" *ngIf="type === 'route'">
    <div *ngFor="let route of dataSource; let first = first">
      <p class="date-title capitalize">{{ getDateText(route[0].date) }}</p>
      <div class="card">
        <card-assignment-row
          *ngFor="let item of route"
          (onEdition)="edition(item)"
          (onDelete)="deleted(item)"
          (onCancel)="cancelled(item)"
          [vehiclesCount]="vehiclesAvailable[item.dateGroup]"
          [driversCount]="driversAvailable[item.dateGroup]"
          [queryDate]="item.dateValue"
          [type]="type"
          [content]="item">
        </card-assignment-row>
      </div>
    </div>
  </div>
</div>
