import { Pipe, PipeTransform } from '@angular/core';

/**
 * Receive an array and return the string join representation
 *
 * @param array Array to join
 * @param separator separator used between each element. Default ''
 * @returns string join representation
 */
@Pipe({
  name: 'joinArryas',
})
export class joinArryasPipe implements PipeTransform {
  transform(array: string[], separator?: string): any {
    if (!array) return null;
    array = array.filter(e => e);

    separator = separator ? separator : '';

    return array.join(separator);
  }
}
