import { Directive, Input, Output, EventEmitter } from '@angular/core';
import { GoogleMapsAPIWrapper, MapsAPILoader } from '@amin-karimi/agm-core';
import { forEach, has } from 'lodash';
import { CustomMarker } from '../classes/Markers/Markers';

declare let google: any;

@Directive({
  selector: 'circular-markers',
})
export class CircularMarkersDirective {
  @Input() routes: Array<any> = [];
  @Output() markerWasClicked: EventEmitter<any> = new EventEmitter<any>();

  private map: any;
  //Custom marker definition
  private customMarker: any;
  private markers: Array<any> = [];
  bounds: any = {};

  constructor(
    private gmapsApi: GoogleMapsAPIWrapper,
    private mapsAPILoader: MapsAPILoader
  ) {}

  ngOnInit() {
    this.gmapsApi.getNativeMap().then(map => (this.map = map));
  }

  update() {
    this.removeAllMarkers();

    if (this.routes.length) this.addAllMarkers();
    else {
      let currentZoom = this.map.getZoom();

      if (currentZoom !== 17) this.map.setZoom(17);
    }
  }

  removeAllMarkers() {
    forEach(this.markers, function (value) {
      value.setMap(null);
    });
    this.markers = [];
  }

  calculateCurrentPoint(route) {
    let n = 0;
    for (let i = 0; i < route.index.length; i++) {
      if (route.deliverPoints['' + route.index[i].id].isDelivered) n += 1;
      else break;
    }
    if (n == 0) return 0;
    else return n - 1;
  }

  addAllMarkers() {
    let bounds = new google.maps.LatLngBounds();
    forEach(this.routes, (value, i) => {
      let current = this.calculateCurrentPoint(value);

      let lat =
        value.deliverPoints[value.index[current.toString()].id].deliveryPoint
          .latitude;

      let lng =
        value.deliverPoints[value.index[current.toString()].id].deliveryPoint
          .longitude;

      let coords = new google.maps.LatLng({
        lat: lat,
        lng: lng,
      });

      let markerOptions = this.generateMarkerOptions(value, i);

      let marker = new CustomMarker(coords, this.map, markerOptions);
      this.markers.push(marker);
      bounds.extend(coords);
    });

    this.map.fitBounds(bounds);
  }

  updateMarker(route) {
    let location = this.locateMarker(route);
    if (location == -1) return false;
    else {
      let current = this.calculateCurrentPoint(route);
      this.markers[location].setMap(null);

      let lat =
        route.deliverPoints[route.index[current.toString()].id].deliveryPoint
          .latitude;

      let lng =
        route.deliverPoints[route.index[current.toString()].id].deliveryPoint
          .longitude;

      let coords = new google.maps.LatLng({
        lat: lat,
        lng: lng,
      });

      let markerOptions = this.generateMarkerOptions(route, 0);

      let marker = new CustomMarker(coords, this.map, markerOptions);
      this.markers[location] = marker;

      return true;
    }
  }

  locateMarker(route) {
    for (let i = 0; i < this.markers.length; i++) {
      if (this.markers[i].data._id == route._id) return i;
    }
    return -1;
  }

  /**
   * Adjust map each time a marker it's added
   */
  fitBounds() {
    if (this.markers.length) {
      let bounds = new google.maps.LatLngBounds();
      this.markers.forEach(marker => {
        bounds.extend(marker.getPosition());
      });

      this.map.fitBounds(bounds);
    }
  }

  markerClicked(index) {
    this.markerWasClicked.emit(this.routes[index]);
  }

  /**
   * Generate custom marker content
   *
   * @param route route item
   * @param index route item position
   */
  private generateMarkerOptions(route: any, index: number) {
    let image = has(route, 'driver') ? route.driver.image : null;
    let lastEvent = has(route, 'lastEvent') ? route.lastEvent.name : '';
    let background = '#4f656f';

    if (route.status === 'in-progress' && lastEvent === 'setback')
      background = '#d32f2f';
    else if (route.status === 'in-progress' && lastEvent !== 'setback')
      background = '#4caf50';
    else if (route.status === 'completed') background = '#81c784';

    return {
      id: route.id,
      index: index,
      imagePlaceholder: image,
      data: route,
      clickEvent: this.markerWasClicked,
      style: {
        'background-color': '#ebebeb',
        'border-color': background,
      },
    };
  }
}
