<div
  class="modal fade"
  tabindex="2"
  bsModal
  #activationSuccess="bs-modal"
  role="dialog"
  aria-hidden="true"
  [config]="config">
  <div
    class="modal-dialog activation-modal-success modal-lg m-t-xxl"
    style="pointer-events: visible">
    <div class="title-holder">
      <h1 class="title">
        {{ 'SIGNUP-ACTIVATION.VERIFY-COMPLETED' | translate }}
      </h1>
    </div>

    <div class="verified-icon-holder">
      <img
        src="./assets/img/shield-success.png"
        srcset="
          ./assets/img/shield-success@2x.png 2x,
          ./assets/img/shield-success@3x.png 3x
        " />
    </div>

    <div class="divider"></div>

    <div class="description-holder">
      <p class="text">
        {{ 'SIGNUP-ACTIVATION.COMPLETE-PROFILE' | translate }}
      </p>

      <div class="activation-btn">
        <button
          mat-button
          color="primary"
          class="code-resend"
          type="button"
          (click)="continueWithProfile()">
          {{ 'COMMON.CONTINUE' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
