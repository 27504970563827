import { Directive, ViewContainerRef, Inject, Host } from '@angular/core';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { HeaderService } from '../components/header/header.service';
import { forEach } from 'lodash';
import { fromEvent } from 'rxjs';
import { debounceTime, delay } from 'rxjs/operators';

@Directive({
  selector: '[scrollDirective]',
})
export class CustomScrollDirective {
  scrollDown: any;
  scrollUp: any;
  subscriptions: any = {};

  constructor(
    private viewContainerRef: ViewContainerRef,
    @Inject(HeaderService) private hs: HeaderService,
    @Host() ps: PerfectScrollbarComponent
  ) {
    this.scrollDown = ps.psScrollDown;
    this.scrollUp = ps.psScrollUp;
  }

  ngOnInit() {
    if (window.innerWidth <= 768) {
      this.scrollDownSubscribe();
      this.scrollUpSubscribe();
    }

    this.subscriptions.resizedWindow = fromEvent(window, 'resize')
      .pipe(debounceTime(300))
      .subscribe((e: any) => {
        let width = e.target.innerWidth;
        if (width <= 768) {
          this.scrollDownSubscribe();
          this.scrollUpSubscribe();
        }
      });
  }

  scrollDownSubscribe() {
    this.subscriptions.scrollDown = this.scrollDown
      .pipe(delay(200))
      .subscribe(e => {
        this.hs.onScroll.next('down');
      });
  }

  scrollUpSubscribe() {
    this.subscriptions.scrollUp = this.scrollUp
      .pipe(delay(200))
      .subscribe(e => {
        this.hs.onScroll.next('up');
      });
  }

  ngOnDestroy() {
    forEach(this.subscriptions, value => value.unsubscribe());
  }
}
