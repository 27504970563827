<div class="map-view-types-container">
  <button
    mat-mdc-raised-button
    mat-icon-button
    *ngFor="let type of viewTypes"
    (click)="selectType(type)"
    [ngClass]="{ active: type.active }"
    matTooltip="{{ type.name | translate }}"
    [matTooltipPosition]="'right'">
    <mat-icon>
      {{ type.icon }}
    </mat-icon>
  </button>
</div>
