import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CustomLoadingService } from '../services/loading.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private totalRequests = 0;

  constructor(private loadingService: CustomLoadingService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    console.log('caught');
    if (request.headers.has('showLoading')) {
      request.headers.delete('showLoading');
      return next.handle(request);
    }
    this.totalRequests++;
    this.loadingService.show();
    return next.handle(request).pipe(
      finalize(() => {
        setTimeout(() => {
          this.totalRequests--;
          if (this.totalRequests == 0) {
            this.loadingService.hide();
          }
        }, 250);
      })
    );
  }
}
