<div class="mx-auto mx-5 text-center">
  <div>
    <img
      src="/assets/img/onboarding/image0.svg"
      width="400px,"
      height="300px" />
  </div>
  <div class="text-center">
    <div class="title font-weight-bold my-2">
      ¡Hola {{ user?.firstName || 'Usuario' }}, bienvenido a Fleetflex!
    </div>

    <div class="font-weight-bold my-2">
      Aquí podras monitorear todas las entregas
    </div>

    <div class="subtitle font-weight-bold my-2">
      En sus marcas, listos, ¡fuera!
    </div>
  </div>

  <div class="mx-auto mt-4">
    <button mat-button class="btn-fleetflex font-weight-bold" (click)="close()">
      Continuar
    </button>
  </div>
</div>
