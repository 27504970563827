import { RouteDeliveryPointModel } from './routeDeliveryPoint.model';
import { Driver } from './driver.model';
import * as moment from 'moment';
import * as _ from 'lodash';
import { DeliveryPointModel } from './deliveryPoint.model';

export type RouteStatus =
  | 'pending'
  | 'in-progress'
  | 'completed'
  | 'cancelled'
  | 'cancelled-inactivity'
  | 'starting-soon'
  | 'late'
  | 'paused-inactivity';

export interface IRoute {
  _id: string;
  companyId: string;
  userCreated: string;
  index: [IRouteIndex];
  status: RouteStatus;
  date: Date;
  driver: Driver;
  label: string;
  notes: string;
  createdAt: Date;
  updatedAt: Date;
  startedAt: Date;
  endedAt: Date;
  type?: 'fast' | null;

  deliverPoints: RouteDeliveryPointModel[];
}
export interface IRouteIndex {
  id: string;
  index: number;
  eta: {
    text: string;
    timeIn: number;
    timeInText: string;
    timeOut: number;
    timeOutText: string;
    value: number;
  };
}

export class RouteModel implements IRoute {
  _id: string;
  companyId: string;
  createdAt: Date;
  date: Date;
  deliverPoints: RouteDeliveryPointModel[];
  index: [IRouteIndex];
  label: string;
  name: string;
  notes: string;
  driver: Driver;
  status: RouteStatus;
  updatedAt: Date;
  userCreated: string;
  startedAt: Date;
  endedAt: Date;
  finishedAt: Date;
  type?: 'fast' | null;

  // UI variables
  activeCard: boolean;
  selected: boolean;
  closeTemp: boolean;
  checked: boolean;

  get statusLabel() {
    let status = {
      pending: 'Pendiente',
      'in-progress': 'En progreso',
      completed: 'Completada',
      cancelled: 'Cancelada',
      'cancelled-inactivity': 'Cancelada por inactividad',
    };

    return status[this.status] || '';
  }

  constructor(
    id?: string,
    companyId?: string,
    createdAt?: Date,
    date?: Date,
    startedAt?: Date,
    endedAt?: Date,
    finishedAt?: Date,
    driver?: Driver,
    deliverPoints?: Array<RouteDeliveryPointModel>,
    index?: [IRouteIndex],
    label?: string,
    notes?: string,
    status?:
      | 'pending'
      | 'in-progress'
      | 'completed'
      | 'cancelled'
      | 'cancelled-inactivity',
    updatedAt?: Date,
    userCreated?: string,
    type?: 'fast' | null
  ) {
    this._id = id;
    this.driver = Driver.fromMap(driver);
    this.companyId = companyId;
    this.createdAt = createdAt;
    this.date = date;
    this.deliverPoints = deliverPoints;
    this.index = index;
    this.label = label;
    this.notes = notes;
    this.status = status;
    this.updatedAt = updatedAt;
    this.userCreated = userCreated;
    this.type = type;
    this.finishedAt = finishedAt;
    this.endedAt = endedAt;
    this.startedAt = startedAt;
  }
  static fromMap(map: any): RouteModel {
    const route = Object.assign(new RouteModel(), map);
    route.deliverPoints = RouteDeliveryPointModel.fromListMap(
      route['deliverPoints']
    );
    route.driver = Driver.fromMap(route['driver']);
    route.sortRouteByIndex();
    return route;
  }
  static fromListMap(list: Array<any>): RouteModel[] {
    return list.map(x => this.fromMap(x));
  }
  static fromRouteTemplateMap(map: any): RouteModel {
    const routeDeliveryPoints = map['deliverPoints'].map(deliveryPoint => {
      const rdp = new RouteDeliveryPointModel();
      rdp.deliveryPoint = DeliveryPointModel.fromMap(deliveryPoint);
      return rdp;
    });
    const route = new RouteModel();
    route.label = map['name'];
    route.updatedAt = map['updatedAt'];
    route.index = map['index'];
    route.template_id = map['_id'];
    route.deliverPoints = routeDeliveryPoints;
    route.sortRouteByIndex();
    return route;
  }

  get isFastRoute() {
    return this.type && this.type === 'fast';
  }
  get template_id() {
    try {
      return JSON.parse(this.notes).template_id || '';
    } catch (e) {
      return '';
    }
  }

  set template_id(id) {
    this.notes = JSON.stringify({ template_id: id, notes: this.notes || '' });
  }

  getWindowsTime(): Date[] | null {
    if (this.status === 'pending') return null;
    if (this.status === 'in-progress') {
      return [
        moment.utc(this.startedAt).toDate(),
        moment.utc().add(1, 'months').toDate(),
      ];
    }
    if (
      ['completed', 'cancelled', 'cancelled-inactivity'].includes(
        this.status
      ) &&
      this.endedAt &&
      this.startedAt
    ) {
      return [moment(this.startedAt).toDate(), moment(this.endedAt).toDate()];
    }
    if (
      ['completed', 'cancelled', 'cancelled-inactivity'].includes(this.status)
    ) {
      const points = [...this.deliverPoints];
      points.sort((a, b) => {
        return moment(a.documentedAt || a.createdAt).diff(
          moment(b.documentedAt || b.createdAt)
        );
      });
      return [
        moment(points[0].documentedAt).toDate(),
        moment(_.last(points).documentedAt).toDate(),
      ];
    }
  }
  isCompleted() {
    return this.status === 'completed';
  }
  isCancelled() {
    return ['cancelled', 'cancelled-inactivity'].includes(this.status);
  }
  isFinished() {
    return ![
      'pending',
      'in-progress',
      'paused-inactivity',
      'late',
      'starting-soon',
    ].includes(this.status);
  }

  /**
   * In place reorder deliveryPoint array. First push documented routeDeliveryPoints sorted by documentedAt,
   *  and then push undocumented routeDeliveryPoints sorted by index property
   */
  reorderPoints() {
    const unDocumented = this.deliverPoints.filter(e => !e.documentedAt);
    const documented = this.deliverPoints
      .filter(e => e.documentedAt)
      .sort((a, b) => moment(a.documentedAt).diff(b.documentedAt));

    const unDocumentedSorted = _.filter(
      _.sortBy(this.index, ['index']).map((index, position) => {
        index = index as IRouteIndex;

        let point = _.find(unDocumented, e => {
          const id = e.deliveryPoint._id;
          return id === index.id;
        });

        point = _.cloneDeep(point);

        if (!point) return null;
        return point;
      }),
      null
    );

    this.deliverPoints = [...documented, ...unDocumentedSorted];
  }

  /**
   * Return time spent in route from in-progress until its finish or cancellation
   *
   * @returns time in route (HH:mm)
   */
  getTimeInRoute(): String {
    const route = this;

    /**
     * Return checked points
     *
     * @param deliverPoints array of delivery points
     * @returns array of cehcked delivery points
     */
    function getPointsChecked(deliverPoints) {
      return deliverPoints.filter(point => point.documentedAt || point.status);
    }

    let startTime;
    let endTime;
    if (route.status === 'in-progress') {
      startTime = moment(route.startedAt || route.updatedAt);
      endTime = moment();
    } else {
      if (route.startedAt && route.endedAt) {
        startTime = moment(route.startedAt);
        endTime = moment(route.endedAt);
      } else {
        const pointsChecked = getPointsChecked(route.deliverPoints);
        if (pointsChecked.length) {
          startTime = moment(
            pointsChecked[0].documentedAt || pointsChecked[0].updatedAt
          );
          endTime = moment(
            pointsChecked[pointsChecked.length - 1].documentedAt ||
              pointsChecked[pointsChecked.length - 1].updatedAt
          );
        } else {
          return '0:0';
        }
      }
    }
    const timeDiff = moment.duration(endTime.diff(startTime));
    return `${timeDiff.hours()}:${timeDiff.minutes()}`;
  }

  /**
   * Return internationalization string based on route status
   *
   * @param customLabels Record<RouteStatus,String>
   * @returns String | Internationalization text
   */
  getStatusLabelFixture(customLabels?: Partial<Record<RouteStatus, String>>) {
    let statusToMap: Partial<Record<RouteStatus, String>> = {
      pending: 'CARD-ASSIGNMENT.ASSIGNED',
      'in-progress': 'CARD-ASSIGNMENT.IN-PROGRESS',
      'paused-inactivity': 'CARD-ASSIGNMENT.PAUSED-INACTIVITY',
      late: 'CARD-ASSIGNMENT.LATE',
      'starting-soon': 'CARD-ASSIGNMENT.STARTING-SOON',
      completed: 'CARD-ASSIGNMENT.COMPLETED',
      'cancelled-inactivity': 'CARD-ASSIGNMENT.CANCELLED-INACTIVITY',
      cancelled: 'CARD-ASSIGNMENT.CANCELLED',
    };
    if (customLabels) statusToMap = Object.assign(statusToMap, customLabels);

    return statusToMap[this.status];
  }

  getStatusIconFixture(customIcons?: Partial<Record<RouteStatus, String>>) {
    let iconsToMap: Partial<Record<RouteStatus, String>> = {
      completed: 'check_circle',
      'cancelled-inactivity': 'error',
      cancelled: 'block',
    };
    if (customIcons) iconsToMap = Object.assign(iconsToMap, customIcons);

    return iconsToMap[this.status];
  }

  /**
   * Sort route's routeDeliveryPoint array based on index position in index property
   */
  sortRouteByIndex() {
    this.deliverPoints.sort((rdp1, rdp2) => {
      const index1 = this.index.find(ind => ind.id === rdp1.deliveryPoint._id);
      const index2 = this.index.find(ind => ind.id === rdp2.deliveryPoint._id);
      return (index1?.index || -1) - (index2?.index || -1);
    });
  }

  /**
   * Create the plain object to be uploaded to firebase
   *
   * @returns Firebase route representation
   */
  getFirebaseDataObject() {
    const indexObj = {};
    const deliverPointsObj = {};
    this.deliverPoints.forEach(routeDeliveryPoint => {
      deliverPointsObj[routeDeliveryPoint.deliveryPoint._id] =
        routeDeliveryPoint.toMap();
    });
    this.index.forEach((ind, index) => {
      indexObj[index] = ind;
    });

    return _.chain({
      _id: this._id,
      backToStart: false,
      companyId: this.companyId,
      date: this.date,
      draft: false,
      driver: this.driver.toMap(),
      endedAt: this.endedAt,
      finishedAt: this.finishedAt,
      startedAt: this.startedAt,
      label: this.label,
      notes: this.notes,
      status: this.status,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      userCreated: this.userCreated,
      index: indexObj,
      deliverPoints: deliverPointsObj,
      /**
       * @deprecated The property startingPoint will be removed soon
       */
      startingPoint: this.deliverPoints[0].deliveryPoint.toMap(),
      /**
       * @deprecated The property warehouse will be removed soon
       */
      warehouse: this.deliverPoints[0].deliveryPoint.toMap(),
    })
      .omitBy(_.isNil)
      .value();
  }
}
