import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import * as _ from 'lodash';
import {
  AnalyticsService,
  FirebaseService,
  HelpersService,
} from '../../../../services';
import { MatDialog } from '@angular/material/dialog';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { ReportExcelService } from '../../../../services/report-excel.service';
import * as moment from 'moment';
import { ReportShareModalComponent } from '../../../report-share-modal/report-share-modal.component';
import { NgxGalleryComponent } from '@rybos/ngx-gallery';
import { ApiRequestsService } from '../../../../../api';
import { NgxTippyService } from 'ngx-tippy-wrapper';
import { TagSelectorModalComponent } from '../../../tag-selector-modal/tag-selector-modal.component';
import {
  LocationMapComponent,
  AppLocationMap,
} from '../../../../../reports/location-map/location-map.component';
import { ReportV2Service } from '../../../../services/report-v2.service';
import { RouteModel } from '../../../../../models/route.model';
import { Driver } from '../../../../../models/driver.model';
import { RouteDeliveryPointModel } from '../../../../../models/routeDeliveryPoint.model';

@Component({
  selector: 'app-routes-report-table-row',
  templateUrl: './routes-report-table-row.component.html',
  styleUrls: ['./routes-report-table-row.component.sass'],
})
export class RoutesReportTableRowComponent implements OnInit {
  constructor(
    private analytics: AnalyticsService,
    public dialog: MatDialog,
    public reportService: ReportV2Service,
    public reportExcelService: ReportExcelService,
    private api: ApiRequestsService,
    private tagsPopOver: NgxTippyService,
    public helpers: HelpersService,
    private firebase: FirebaseService
  ) {}

  @Input() route: RouteModel;
  @Input() driver: Driver;
  @ViewChild('emailSwal') private emailSwal: SwalComponent;
  @ViewChild('galeriaNg') ngxGalleryComponent: NgxGalleryComponent;
  @Output() previewOpenEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() previewChangeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() previewCloseEvent: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Input() statuses: Array<any> = [];

  galleryOptions: Array<any> = [];

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  emails: Array<any> = [];
  show = false;
  tagLoading = true;
  tagListShow = false;
  tagList = [];
  splitRegex: RegExp = /[\n,]/;

  getAllTags() {
    this.tagLoading = true;
    this.tagList = [];
    this.api.tags.get().subscribe(response => {
      this.tagList = response.data;
      if (this.tagList.length === 0) {
        console.log('No hay tags');
      } else {
        console.log('Si hay tags');
        this.tagLoading = false;
        this.tagListShow = true;
      }
    });
  }
  sendEvent() {
    this.analytics.sendAnalyticsEvent(
      'Tag submenu icon clicked',
      null,
      'sends event when the tag list pops up'
    );
  }

  ngOnInit() {
    this.tagsPopOver.setDefaultProps({
      trigger: 'click',
      placement: 'bottom',
      appendTo: () => document.body,
      hideOnClick: true,
      interactive: true,
    });
    this.galleryOptions = [
      {
        image: false,
        height: '120px',
        arrowPrevIcon: 'fa fa-chevron-left',
        arrowNextIcon: 'fa fa-chevron-right',
        closeIcon: 'fa fa-times',
        imageArrowsAutoHide: true,
        thumbnailsArrowsAutoHide: true,
      },
      {
        breakpoint: 500,
        width: '100%',
        thumbnailsColumns: 2,
      },
    ];
  }
  getComments(task) {
    try {
      const parsed = JSON.parse(task.comments);
      return parsed.comments || '';
    } catch (e) {
      return task.comments || '';
    }
  }

  hideTagsPopOver() {
    this.tagsPopOver.hideAll();
  }

  checkValueTag(event, deliverPoint, tag, route) {
    const add = event.checked;
    if (!deliverPoint) {
      return;
    }
    if (add) {
      deliverPoint.tags = _.union(deliverPoint.tags, [tag]);
    } else {
      deliverPoint.tags = _.filter(
        deliverPoint.tags,
        function (currentDP: any) {
          return currentDP._id !== tag._id;
        }
      );
    }
    let mappedTags;

    if (deliverPoint.tags) {
      mappedTags = deliverPoint.tags.map(x => x._id);
    }
    const payload = {
      id: deliverPoint._id,
      referralGuides: deliverPoint.referralGuides,
      tags: mappedTags || [],
    };
    this.api.routes
      .updateAddress(payload)
      .toPromise()
      .then(response => {
        const updatedPoints = route.deliverPoints.map(delivery =>
          delivery._id === deliverPoint._id
            ? { ...delivery, tags: deliverPoint.tags }
            : delivery
        );
        const updateFb = this.generateFirebaseDeliverPoints(
          route,
          updatedPoints
        );
        return this.firebase.update(`routes/${route._id}`, {
          deliverPoints: updateFb,
        });
      })
      .then(fbResponse => {
        console.log('yes');
      })
      .catch(error => {
        this.helpers.handleErrorMessages(error);
      });
  }

  generateFirebaseDeliverPoints(route, deliverPoints) {
    const _omits = [
      '_valid',
      'name',
      'control',
      '__v',
      'updatedAt',
      'savedInDirectory',
      'createdAt',
      'cursor',
      'eta',
      'id',
      'index',
      'instanceId',
      'opened',
    ];
    const fbDeliverPoints = {};
    for (let i = 0; i < deliverPoints.length; i++) {
      const deliverPoint = deliverPoints[i];
      const deliveryPoint = deliverPoint.deliveryPoint;
      const _deliveryPoint = _.omit(deliverPoint.deliveryPoint, _omits);
      _deliveryPoint['type'] = 'delivery';
      // _deliveryPoint['details'] = JSON.stringify(_deliveryPoint['details']);
      _deliveryPoint['companyId'] = route.companyId;
      fbDeliverPoints[deliverPoint.deliveryPoint._id] = {
        route: route._id,
        referralGuides: deliverPoint.referralGuides || [],
        tags: deliverPoint.tags,
        deliveryPoint: _deliveryPoint,
        type: _deliveryPoint['type'] || 'delivery',
        _id: _.isPlainObject(deliverPoint) ? deliverPoint._id : deliverPoint,
      };
      if (route.status === 'in-progress') {
        const items = _.omit(deliverPoint, _omits);
        fbDeliverPoints[deliveryPoint._id] = Object.assign(
          {},
          fbDeliverPoints[deliveryPoint._id],
          items
        );
      }
    }
    return fbDeliverPoints;
  }

  confirmCheckedValues(tag, item) {
    if (item.tags) {
      return item.tags.some(t => t._id === tag._id);
    }
    return false;
  }

  getOldStatus(task) {
    try {
      const parsed = JSON.parse(task.comments);
      if (parsed.deliveryStatus == 'completed') {
        return 'completed';
      }
      if (parsed.deliveryStatus == 'incompleted') {
        return 'incompleted';
      }
      if (parsed.deliveryStatus == 'rejected') {
        return 'rejected';
      }
      return 'rejected';
    } catch (e) {
      return '';
    }
  }

  getFiles(points) {
    const images = [];
    const files =
      points.length && typeof points[0] !== 'string'
        ? points[0] || []
        : points || [];

    _.forEach(files, val => {
      images.push({
        small: val,
        medium: val,
        big: val,
      });
    });
    return images;
  }

  sendAnalytics() {
    this.analytics.sendAnalyticsEvent('View more details (REPORT)', null, '');
  }

  sendAnalyticsPhoto($event, item, driver) {
    this.analytics.sendAnalyticsEvent('Open Photo in report', null, '');
    item.driver = driver;
    item.index = this.ngxGalleryComponent.selectedIndex;
    item.img =
      this.ngxGalleryComponent.images[
        this.ngxGalleryComponent.selectedIndex
      ].medium.toString();
    this.previewOpenEvent.emit(item);
  }

  previewClose($event) {
    this.previewCloseEvent.emit(false);
  }

  previewChange2($event, item, driver) {
    item.driver = driver;
    item.index = $event.index;
    item.img = $event.image.medium.toString();
    this.previewChangeEvent.emit(item);
  }

  showExportModal(message: string, item: any, route: any) {
    const dialogRef = this.dialog.open(ReportShareModalComponent, {
      data: {
        message: message,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const method = {
          method: 'download',
        };
        const reportContent = {
          item: item,
          route: route,
        };
        //item.route = route;

        switch (result) {
          case 'PDF':
            method.method = 'download';

            this.reportService.getReport({
              type: 'delivery',
              data: item,
              method: 'download',
              filters: { route },
            });
            break;
          case 'EXCEL':
            method.method = 'download-excel';
            this.reportExcelService.generateReport({
              data: item,
              driver: item.driver,
              initDate: moment(item.documentedAt).valueOf(),
              endDate: moment(item.documentedAt).valueOf(),
              type: 'delivery',
              route: route,
              status: null,
            });
            break;
          case 'EMAIL':
            this.emailSwal.fire().then(_result => {
              if (_result.isConfirmed) {
                let validMails = this.emails.filter(x => x.isValid == true);
                if (validMails.length > 0) {
                  validMails = validMails.map(x => x.email);
                  const method = {
                    method: 'sendEmail',
                    contacts: validMails,
                  };
                  this.reportService.getReport({
                    type: 'delivery',
                    data: item,
                    method: 'sendEmail',
                    emails: validMails,
                    filters: { route },
                  });
                } else {
                  this.helpers.showErrorMessage(
                    'No existen destinatarios válidos para enviar reporte'
                  );
                }
              }
              if (_result.isDismissed) {
                this.emails = [];
              }
            });
            break;
        }
      }
    });
  }

  downloadReport(item: any, route: any) {
    const companyName = item.deliveryPoint.deliveryName;
    const message = `Desea generar reporte sobre la entrega en "${companyName}"`;
    this.showExportModal(message, item, route);
  }

  hasStatus(item) {
    if (this.route.status.includes('cancelled')) {
      return true;
    }
    if (item.status) {
      return true;
    }
    return false;
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (value.trim() != '') {
      if (!this.emails.find(x => x.email === value.trim())) {
        const mail = {
          email: value.trim(),
          bgColor: '#b7b7b7',
          isValid: true,
        };
        const toSend = this.reportService.checkMail(mail);
        if (toSend) {
          this.emails.push(toSend);
        }
      } else {
        this.showRepeatedEmail();
      }
    }

    if (input) {
      input.value = '';
    }
  }

  remove(email: string): void {
    const index = this.emails.indexOf(email);
    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }
  addEmailsOnPaste(event, separator) {
    let stringList = event.clipboardData.getData('text/plain');
    stringList = stringList.trim();
    const emailList = stringList.split(separator);
    for (let i = 0; i < emailList.length; i++) {
      const value = emailList[i];
      if (value.trim() != '') {
        if (!this.emails.find(x => x.email === value.trim())) {
          const mail = {
            email: value.trim(),
            bgColor: '#b7b7b7',
            isValid: true,
          };
          const toSend = this.reportService.checkMail(mail);
          if (toSend) {
            this.emails.push(toSend);
          }
        }
      }
    }
    event.target.setValue('');
  }

  showRepeatedEmail() {
    this.helpers.showWarningMessage('Dirección de correo ingresada repetida');
  }
  getMapUrl(latitude, longitude) {
    if (!latitude || !longitude) {
      return null;
    }
    return `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
  }

  toFixedCoord(coord) {
    if (coord) {
      return coord.toFixed(5);
    }
    return '';
  }
  openTagsSelectorModal(routeDeliveryPoint) {
    this.dialog
      .open(TagSelectorModalComponent, {
        data: { selectedTags: routeDeliveryPoint.tags },
        width: '500px',
      })
      .afterClosed()
      .subscribe(response => {
        routeDeliveryPoint.tags = response;
        const payload = {
          id: routeDeliveryPoint._id,
          referralGuides: routeDeliveryPoint.referralGuides,
          tags: response.map(x => x._id) || [],
        };
        this.api.routes
          .updateAddress(payload)
          .toPromise()
          .catch(error => {
            this.helpers.handleErrorMessages(error);
          });
      });
  }

  openMapModal(item: RouteDeliveryPointModel) {
    const data: AppLocationMap = {
      distance: this.helpers.calculateDistance({
        latitudeX: item.deliveryPoint.latitude,
        longitudeX: item.deliveryPoint.longitude,
        latitudeY: item.latitude,
        longitudeY: item.longitude,
      }),
      originalPoint: {
        latitude: item.deliveryPoint.latitude,
        longitude: item.deliveryPoint.longitude,
        name: item.deliveryPoint.deliveryName,
      },
      realPointPoint: {
        latitude: item.latitude,
        longitude: item.longitude,
        name: 'Coordenada de gestión',
      },
    };
    this.dialog.open(LocationMapComponent, {
      data: data,
    });
  }
}
