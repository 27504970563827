<div class="activation-container">
  <div class="code-section">
    <div class="code-holder">
      <div class="code-box">
        {{ activationCode }}
      </div>
    </div>
  </div>

  <hr />

  <div class="button-row pull-right">
    <button
      class="m-r-sm uppercase-text"
      mat-button
      color="primary"
      (click)="cancel()">
      {{ 'SIDE-MENU.CODES.GO-BACK' | translate }}
    </button>
  </div>
</div>
