<div class="card-item">
  <div class="card-item-details">
    <p class="card-item-title" (click)="goToRouteDetails()">
      {{ content?.label }}
    </p>

    <div class="form-item" (click)="goToRouteDetails()">
      <span class="control-label">
        {{ 'CARD-ASSIGNMENT.ROUTE-START' | translate }}: &nbsp;</span
      >
      <span class="control-label"> {{ getDateText(content?.date) }} </span>
    </div>

    <div class="form-item">
      <div
        class="routes-items-container"
        [ngClass]="{
          'corousel-enabled':
            content?.deliverPoints?.length >= (viewType === 'list' ? 5 : 8)
        }"
        *ngIf="content?.deliverPoints.length">
        <div
          class="arrow-container"
          *ngIf="
            content.deliverPoints?.length >= (viewType === 'list' ? 5 : 8)
          ">
          <i class="arrow-left material-icons" (click)="scroll(0)">
            arrow_drop_down
          </i>
        </div>

        <div
          [id]="content._id"
          class="step-container"
          [ngClass]="{ 'w-full': viewType !== 'list' }">
          <div
            class="route-item"
            *ngFor="
              let point of content?.index;
              let index = index;
              let last = last
            "
            [ngClass]="point?.className">
            <span *ngIf="!point.icon"> {{ index + 1 }} </span>
            <mat-icon class="step-icon" *ngIf="point.icon">
              {{ point.icon }}
            </mat-icon>
          </div>
        </div>

        <div
          class="arrow-container"
          *ngIf="
            content?.deliverPoints?.length >= (viewType === 'list' ? 5 : 8)
          ">
          <i class="arrow-right material-icons" (click)="scroll(1)">
            arrow_drop_down
          </i>
        </div>
      </div>
    </div>
  </div>
</div>
