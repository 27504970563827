import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'card-header-vehicle',
  templateUrl: './card-header-vehicle.component.html',
  styleUrls: ['./card-header-vehicle.component.sass'],
})
export class CardHeaderVehicleComponent implements OnInit {
  @Input() content: any = {};
  @Input() assigned: boolean = false;
  @Input() specialStatus: boolean = false;
  @Input() inProgress: boolean = false;
  @Input() setback: boolean = false;
  @Input() parentCardId: string = '';

  picturePlaceholder: string = './assets/img/vehicle-pic-placeholder.png';

  constructor(private router: Router) {}

  ngOnInit() {}

  /**
   * Go to details page
   */
  goToDetails() {
    this.router.navigate(['vehicles/details', this.content._id]);
  }
}
