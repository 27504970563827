import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from '../services';
import * as _ from 'lodash';

@Directive({
  selector: '[appRole]',
})
export class AppRoleDirective {
  private currentUser;
  private permissions = [];

  @Input()
  set appRole(val: Array<string>) {
    this.viewContainer.createEmbeddedView(this.templateRef);
    this.permissions = val;
    this.updateView();
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userService: UserService
  ) {
    this.currentUser = this.userService.user;
  }

  updateView() {
    this.viewContainer.clear();
    if (this.checkRole()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
  checkRole() {
    let hasPermission = false;
    if (this.currentUser && this.currentUser.role) {
      this.permissions.forEach(permission => {
        let a =
          permission.toLowerCase() === 'manager_writer' &&
          this.currentUser.role.toLowerCase() === 'manager' &&
          _.has(this.currentUser, 'read_only') &&
          !this.currentUser.read_only;
        if (permission.toLowerCase() === this.currentUser.role.toLowerCase()) {
          hasPermission = true;
        } else if (
          permission.toLowerCase() === 'manager_writer' &&
          this.currentUser.role.toLowerCase() === 'manager' &&
          _.has(this.currentUser, 'read_only') &&
          !this.currentUser.read_only
        ) {
          hasPermission = true;
        }
      });
    }
    return hasPermission;
  }
}
