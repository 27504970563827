<div class="profile-container" *ngIf="!displayChild" [@flyOutIn]>
  <div class="profile-header">
    <div class="wrapper">
      <button mat-icon-button (click)="close()" class="profile-close">
        <i class="material-icons">clear</i>
      </button>
      <button mat-button color="primary" class="sign-out" (click)="logout()">
        {{ 'COMMON.SIGN-OUT' | translate }}
      </button>
    </div>
  </div>

  <div class="profile-content" (click)="goToProfile()">
    <div class="content-head">
      <img
        class="profile-picture"
        [src]="currentUser.image ? currentUser.image : picturePlaceholder"
        alt="currentUser?.email" />

      <div class="content-details">
        <h4 class="username" *ngIf="currentUser.firstName">
          {{ currentUser.firstName }} {{ currentUser.lastName }}
        </h4>
        <h4 class="company-name" *ngIf="companyData.name">
          {{ companyData?.name }}
        </h4>
        <h5 class="user-email" *ngIf="currentUser.email">
          {{ currentUser?.email }}
        </h5>
      </div>
    </div>
    <button mat-icon-button class="icon-button">
      <i class="material-icons"> chevron_right </i>
    </button>
  </div>
  <!-- Configuration -->
  <div class="sub-titles">
    <i class="material-icons">build</i>
    <h4 class="pl-3 title-style">
      {{ 'SIDE-MENU.CONFIGURATION' | translate }}
    </h4>
  </div>
  <div *ngFor="let item of menuItems">
    <div class="item-row" *ngIf="item.display" (click)="item?.action(item)">
      <button mat-icon-button class="item-button">
        {{ item?.title | translate }}
        <i class="material-icons item-icon" *ngIf="item.icon" id="tagIconMove">
          {{ item.icon }}
        </i>
      </button>

      <button mat-icon-button class="icon-button">
        <i class="material-icons"> chevron_right </i>
      </button>
    </div>
  </div>
  <!-- Resources -->
  <div class="sub-titles">
    <i class="material-icons">settings</i>
    <h4 class="pl-3 title-style">{{ 'SIDE-MENU.RESOURCES' | translate }}</h4>
  </div>
  <div *ngFor="let item of menuItems2">
    <div class="item-row" *ngIf="item.display" (click)="item?.action(item)">
      <button mat-icon-button class="item-button">
        {{ item?.title | translate }}
        <i class="material-icons item-icon" *ngIf="item.icon" id="tagIconMove">
          {{ item.icon }}
        </i>
      </button>

      <button mat-icon-button class="icon-button">
        <i class="material-icons"> chevron_right </i>
      </button>
    </div>
  </div>
</div>

<app-side-child-view
  *ngIf="displayChild"
  [@flyOutIn]
  [item]="selectedChild"
  (close)="onChildMenuClose()">
</app-side-child-view>
