import { Component, Input } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { HelpersService } from '../..';

@Component({
  selector: 'app-popover-clipboard',
  templateUrl: './popover-clipboard.component.html',
  styleUrls: ['./popover-clipboard.component.sass'],
})
export class PopoverClipboardComponent {
  @Input() guides: Array<String>;

  constructor(private clipboard: Clipboard, private helper: HelpersService) {}

  /**
   * The text of the input is copied to the clipboard
   *
   * @param guide referral guide
   */
  copyToClipboard(guide) {
    this.clipboard.copy(guide);
    this.helper.showSuccessMessage(
      'Texto copiado al portapapeles',
      'Successful'
    );
  }
}
