import { Component, OnInit } from '@angular/core';
import { CardHeaderRouteComponent } from '../card-assignment/card-header-route/card-header-route.component';

@Component({
  selector: 'card-route-head',
  templateUrl: './card-route-head.component.html',
  styleUrls: ['./card-route-head.component.sass'],
})
export class CardRouteHeadComponent
  extends CardHeaderRouteComponent
  implements OnInit
{
  ngOnInit() {
    this.calculateCurrentPoint();
    this.checkDeliveredPoints();
  }
}
