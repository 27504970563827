import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { forEach, isNull } from 'lodash';
import {
  DriversService,
  FirebaseService,
  DriverEventsService,
} from '../../../services';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'card-item-driver',
  templateUrl: './card-item-driver.component.html',
  styleUrls: ['./card-item-driver.component.sass'],
})
export class CardItemDriverComponent implements OnInit {
  @Input() driverContent: any = {};
  @Input() assigned: boolean = false;
  @Input() type: string = 'list';
  @Input() cardId: string = '';

  isOnline: boolean = false;
  driverNameLabel: string = '';

  picturePlaceholder: string = './assets/img/profile-placeholder.jpg';

  private subscriptions: any = {};
  private interval: any;

  constructor(
    private driverService: DriversService,
    private router: Router,
    private firebase: FirebaseService,
    private statusService: DriverEventsService
  ) {}

  ngOnInit() {
    this.checkOnline();
    this.driverNameLabel = this.driverContent.firstName
      ? this.driverContent.firstName + ' ' + this.driverContent.lastName
      : this.driverContent.email;

    this.statusSubscription(this.driverContent._id);
  }

  ngOnDestroy() {
    forEach(this.subscriptions, value => value.unsubscribe());
    clearInterval(this.interval);
  }

  /**
   * Check if driver it's online
   *
   * @param itemId
   */
  checkOnline(itemId?: string) {
    if (this.subscriptions.online) this.subscriptions.online.unsubscribe();

    if (!this.driverContent) return;

    this.subscriptions.online = this.driverService
      .checkIfOnline(this.driverContent._id)
      .subscribe(isOnline => {
        this.isOnline = isOnline;
      });
  }

  /**
   * Go to details page
   */
  goToDetails() {
    if (this.type !== 'list') return;

    this.router.navigate(['drivers/details', this.driverContent._id]);
  }

  /**
   * listen event
   *
   * @param driverId
   */
  private statusSubscription(driverId: string) {
    this.subscriptions['driverEvents'] = this.firebase
      .subscribeToNode(`users/${driverId}`, null, null)
      .pipe(debounceTime(50))
      .subscribe(status => {
        status = isNull(status) ? {} : status;
        status.cardId = this.cardId;
        this.statusService.driverEvent.next(status);
      });
  }
}
