import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'customDate',
})
export class CustomDatesPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) return null;

    let format = args ? args : 'YYYY-MM-DD';
    let date = moment(value, 'x').local().format(format);

    return date;
  }
}
