import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { GoogleMapsAPIWrapper, MapsAPILoader } from '@amin-karimi/agm-core';
import { CustomMarker } from '../../classes/Markers/Markers';
import { HelpersService, DriverEventsService } from '../../services';
import { forEach, clone, isEmpty, merge, replace } from 'lodash';
import { filter } from 'rxjs/operators';
import { RouteDeliveryPointModel } from '../../../models/routeDeliveryPoint.model';

declare let google: any;

@Component({
  selector: 'location-marker, [location-marker]',
  templateUrl: './location-marker.component.html',
  styleUrls: ['./location-marker.component.sass'],
})
export class LocationMarkerComponent implements OnInit, AfterViewInit {
  @Input() location: any = {};
  @Input() data: any = {};
  @Input() event: any = {};
  @Input() status: string = 'pending';
  @Input() currentIndex: any = 0;
  @Input() index: any = 1;
  @Input() name: string = '';
  @Input() outside: boolean = false;

  @ViewChild('locationMarker') locationMarker: ElementRef;

  displayName: boolean = false;
  point: any = {};
  pointModel: RouteDeliveryPointModel;

  private map: any;
  private marker: any;
  private validDelayedStatus: Array<any> = ['in-progress', 'pending'];

  private subscriptions: any = {};

  constructor(
    private gmapsApi: GoogleMapsAPIWrapper,
    private mapsAPILoader: MapsAPILoader,
    private helpers: HelpersService,
    private driverEvents: DriverEventsService
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    try {
      this.pointModel = RouteDeliveryPointModel.fromMap(this.data);
    } catch (e) {
      console.log(e);
    }
    this.mapsAPILoader
      .load()
      .then(() => this.gmapsApi.getNativeMap())
      .then(map => {
        this.map = map;
        this.point = this.getPointstatus(clone(this.data));
        this.initialize();

        this.subscriptions['driverEvent'] = this.driverEvents
          .subscribeToEventPool(this.data.driverId)
          .pipe(filter(driverEvent => driverEvent.owner === this.data.driverId))
          .subscribe(driverEvent => {
            this.event = driverEvent.lastEvent || {};
            this.point = this.getPointstatus(clone(this.data));
            try {
              this.pointModel = RouteDeliveryPointModel.fromMap(this.data);
            } catch (e) {
              console.log(e);
            }
          });
      });
  }

  ngOnChanges(changes) {
    // this.point = this.getPointstatus(clone(this.data));
  }

  ngOnDestroy() {
    this.marker.setMap(null);
    forEach(this.subscriptions, subscription => subscription.unsubscribe());
  }

  /**
   * trigger name display on hover
   */
  elementHover() {
    this.displayName = !this.displayName;
  }

  /**
   * Create new marker with their properties
   */
  private initialize() {
    let location: any = this.getDefaultCoords();

    if (isEmpty(location) || !location.latitude || !location.longitude) return;

    this.location = location;

    let coords = new google.maps.LatLng({
      lat: this.location.latitude,
      lng: this.location.longitude,
    });

    let markerOptions = this.generateMarkerOptions(this.data);
    this.marker = new CustomMarker(
      coords,
      this.map,
      this.getMarkerLayout(markerOptions),
      markerOptions
    );

    let bounds = new google.maps.LatLngBounds();
    bounds.extend(coords);
  }

  /**
   * Get default coordinates
   */
  private getDefaultCoords() {
    return this.location || {};
  }

  /**
   * Get marker layout from html content
   *
   * @param options
   */
  private getMarkerLayout(options: any) {
    return this.locationMarker.nativeElement || document.createElement('div');
  }

  /**
   * Generate custom marker data
   *
   * @param route route item
   * @param content
   */
  private generateMarkerOptions(content: any) {
    let data = clone(this.data);

    data.location = this.location;

    return {
      id: data._id,
      data: data,
      clickEvent: null,
    };
  }

  /**
   * Get point  colors and icons
   *
   * @param point
   */
  getPointstatus(point: any) {
    let _name = '';
    let _event: any = !isEmpty(this.event) ? this.event : {};
    let _point = point;

    _point.deliveryPoint = _point.deliveryPoint || {};
    _point.eta = _point.eta || {};

    _point = merge(
      _point,
      this.helpers.getDeliveryPointStatus(
        _point,
        this.status,
        _event,
        this.index,
        this.currentIndex
      )
    );

    _name = !isEmpty(_point.deliveryPoint)
      ? _point.deliveryPoint.deliveryName || _point.deliveryPoint.customerName
      : _point.deliveryName || _point.customerName || _point.name;

    _point.name = _name;
    _point.index = this.index + 1;

    if (this.validDelayedStatus.indexOf(this.status) !== -1) {
      if (!_point.onTime && !_point.delivered) _point.className = 'delayed';
    }

    if (!isEmpty(_event) && _event.name !== 'setback' && !_point.isDelivered) {
      _point.icon = replace(_event.icon, /-/g, '_');
      _point.style = _event;
      _point.className = '';
    }
    if (_point.type === 'startingPoint') {
      _point.icon = 'home';
      _point.className =
        _point.status === 'in-progress' || _point.status === 'completed'
          ? 'completed'
          : '';
    }
    return _point;
  }

  get iconClassForState() {
    if (this.point) {
      if (this.outside) {
        return 'outside';
      }
      if (['completed'].includes(this.point.status)) {
        return 'completed';
      } else if (['rejected', 'warning'].includes(this.point.status)) {
        //warning
        return 'warning';
      } else if (['not-completed', 'incomplete'].includes(this.point.status)) {
        //incomplete
        return 'incomplete';
      }
    } else {
      return '';
    }
  }
}
