<mat-card appearance="outlined" class="card-assignment" role="group">
  <div
    class="overlay"
    [ngStyle]="{ display: deactivated ? 'block' : 'none' }"></div>
  <div class="card-assignment-header">
    <!-- Headers -->
    <card-header-driver
      *ngIf="type === 'driver'"
      [assigned]="isAssigned"
      [inProgress]="isOnRoute"
      [setback]="isSetback"
      [isFirst]="isFirst"
      [parentCardId]="cardId"
      [specialStatus]="statusSpecial"
      [content]="dataContent">
    </card-header-driver>

    <!--    <card-header-vehicle -->
    <!--      *ngIf="type === 'vehicle'"-->
    <!--      [assigned]="isAssigned"-->
    <!--      [inProgress]="isOnRoute"-->
    <!--      [setback]="isSetback"-->
    <!--      [parentCardId]="cardId"-->
    <!--      [specialStatus]="statusSpecial"-->
    <!--      [content]="dataContent">-->
    <!--    </card-header-vehicle>-->

    <card-header-route
      *ngIf="type === 'route'"
      [assigned]="isAssigned"
      [inProgress]="isOnRoute"
      [parentCardId]="cardId"
      [setback]="isSetback"
      [specialStatus]="statusSpecial"
      [content]="dataContent">
    </card-header-route>

    <!-- Header actions -->
    <button
      style="z-index: 30"
      mat-icon-button
      [matMenuTriggerFor]="cardMenu"
      class="card-actions-head-toggle">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>

  <!-- Card content -->
  <mat-card-content class="card-assigment-content" *ngIf="displayContent">
    <card-assign-item
      *ngIf="type !== 'route'"
      [available]="routesAvailable"
      [content]="dataContent"
      [parentType]="type"
      [parentCardId]="cardId"
      [queryDate]="queryDate"
      [type]="'route'">
    </card-assign-item>

    <card-assign-item
      *ngIf="type !== 'driver'"
      [available]="driversAvailable"
      [content]="dataContent"
      [parentType]="type"
      [parentCardId]="cardId"
      [queryDate]="queryDate"
      [type]="'driver'">
    </card-assign-item>

    <!--    <card-assign-item *ngIf="type !== 'vehicle'" -->
    <!--      [available]="vehiclesAvailable"-->
    <!--      [content]="dataContent"-->
    <!--      [parentType]="type"-->
    <!--      [parentCardId]="cardId"-->
    <!--      [queryDate]="queryDate"-->
    <!--      [type]="'vehicle'">-->
    <!--    </card-assign-item>-->
  </mat-card-content>
</mat-card>

<!-- Card menu actions -->
<mat-menu style="z-index: 30" #cardMenu="matMenu" [xPosition]="'before'">
  <button mat-menu-item (click)="goToDetails(dataContent)" *ngIf="!deactivated">
    <mat-icon>event_note</mat-icon>
    <span>{{ 'COMMON.DETAILS' | translate }}</span>
  </button>

  <button
    mat-menu-item
    (click)="editComponent()"
    *ngIf="type === 'route' && !routeCompleted && !isCancelled && !deactivated">
    <mat-icon>mode_edit</mat-icon>
    <span>{{ 'COMMON.EDIT' | translate }}</span>
  </button>

  <button
    mat-menu-item
    (click)="openCancelDialog()"
    *ngIf="
      type === 'route' && !routeCompleted && !isCancelled && hasAssignation
    ">
    <mat-icon>not_interested</mat-icon>
    <span>{{ 'CARD-ASSIGNMENT.CANCEL' | translate }}</span>
  </button>

  <button
    mat-menu-item
    (click)="editComponent()"
    *ngIf="
      type !== 'route' &&
      dataContent?.route?.status !== 'in-progress' &&
      !deactivated
    ">
    <mat-icon>mode_edit</mat-icon>
    <span>{{ 'COMMON.EDIT' | translate }}</span>
  </button>

  <button
    mat-menu-item
    (click)="resendInvitation()"
    *ngIf="type === 'driver' && !dataContent?.isActive">
    <mat-icon>forward</mat-icon>
    <span> {{ 'COMMON.RESEND-INVITATION' | translate }} </span>
  </button>

  <button
    mat-menu-item
    (click)="openConfirmationDialog()"
    *ngIf="
      hasAssignation &&
      !routeCompleted &&
      !isOnRoute &&
      !isSetback &&
      !isCancelled
    ">
    <mat-icon>block</mat-icon>
    <span>
      {{ (hasAssignation ? 'COMMON.UNASSIGN' : 'COMMON.DELETE') | translate }}
    </span>
  </button>

  <button
    mat-menu-item
    (click)="deactivateDriver(true, driverId)"
    *ngIf="!deactivated">
    <mat-icon>delete</mat-icon>
    <span>{{ 'Eliminar conductor' | translate }}</span>
  </button>

  <button
    mat-menu-item
    (click)="deactivateDriver(false, driverId)"
    *ngIf="deactivated">
    <mat-icon>restore_from_trash</mat-icon>
    <span>{{ 'Reactivar conductor' | translate }}</span>
  </button>
</mat-menu>
