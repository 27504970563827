import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ApiRequestsService } from '../../../api';
import {
  UserService,
  SidePanelService,
  HelpersService,
  AnalyticsService,
} from '../../services';
import { UntypedFormControl } from '@angular/forms';
import { split, isEmpty, concat, isNull } from 'lodash';
import { debounceTime, startWith, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HeaderService } from './header.service';
import { forEach } from 'lodash';

import * as appUtils from '../../../utils';

const isAdmin = appUtils.isCompanyDomain();

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
})
export class HeaderComponent implements OnInit {
  @Input() loggedIn: boolean;
  @Output() location: EventEmitter<string> = new EventEmitter();
  public userIsAuth: boolean;
  public type: string;
  public userInfo = {};
  public subscriptions: any = {};
  public isToggleMenuIcon = false;
  public hovered = 0;

  itemsCtrl: UntypedFormControl;
  filteredItems: any;

  items: any = [];
  response: any;
  itemsType: string;
  itemsSelected: any = [];
  throttle = 50;
  scrollDistance = 1;
  tracking: Boolean = false;
  profileCompleted: Boolean;
  logo: string = './assets/img/fleetflex_tracklink.png';
  withBackground: Boolean = false;
  defaultImage = './assets/img/profile-placeholder.jpg';
  companyData: any = {};

  userActive: boolean = true;

  query: any = {
    limit: 100,
    page: 1,
    sort: 'updatedAt desc',
  };

  loaded: Subject<any> = new Subject<any>();

  headerClass: string = '';
  private defaultLogo: string = './assets/img/fleetflex_tracklink.png';

  constructor(
    public router: Router,
    private api: ApiRequestsService,
    private userService: UserService,
    private helpers: HelpersService,
    private hs: HeaderService,
    private sidePanel: SidePanelService,
    private analytics: AnalyticsService
  ) {
    this.itemsCtrl = new UntypedFormControl();

    this.subscriptions.scroll = this.hs.onScroll.subscribe(
      val => (this.headerClass = val)
    );
  }

  ngOnDestroy() {
    forEach(this.subscriptions, value => value.unsubscribe());
  }

  ngOnInit() {
    this.router.events.subscribe(value => {
      let route = split(this.router.url, '/', 2);
      this.type = route[1];
      this.location.emit(this.type);
      this.items = [];
      this.itemsSelected = [];
      this.response = {};
      let _logo = localStorage.getItem('companyLogo');
      let _bgColor = localStorage.getItem('withBackground');
      //this.logo = _logo ? _logo : this.defaultLogo;
      this.withBackground =
        _bgColor && _bgColor !== 'transparent' ? true : false;

      if (this.type === 'tracking') {
        this.tracking = true;
      } else {
        this.tracking = false;
      }

      this.hs.onScroll.next('up');

      this.checkProfile();
    });

    this.filteredItems = this.itemsCtrl.valueChanges.pipe(
      startWith(null),
      debounceTime(250),
      tap(value => {
        if (this.type && value) {
          this.items = [];
          this.response = {};
          this.filterItems(value);
        } else {
          this.itemsSelected = this.items.slice();
        }
      })
    );

    this.filteredItems.subscribe();

    this.userInfo = {
      image: this.defaultImage,
    };

    this.userService.onProfileLoad.subscribe(profile => {
      this.userInfo = isEmpty(profile) ? this.userInfo : profile;
      this.getCompany();
    });

    this.userService.isLogged.subscribe(logged => {
      if (!logged) {
        //this.logo = this.defaultLogo;
        this.withBackground = false;
        //localStorage.setItem('companyLogo', this.logo);
        localStorage.setItem('withBackground', 'transparent');
      }
    });
  }

  /**
   * Nottify tosuccess modal on profile completed
   */
  userActivated() {
    this.userActive = true;
    this.userService.activated.next(null);
  }

  checkProfile() {
    if (isEmpty(this.userService.user)) return;

    let data = this.userService.user;
    this.profileCompleted = data.profileCompleted;
    this.userActive = data.isActive;
  }

  onScrollDown(ev) {
    this.filterItems(this.itemsCtrl.value);
  }

  /**
   * Filter search items
   *
   * @param search
   */
  private filterItems(search: any) {
    let page;

    if (!isEmpty(this.response)) {
      if (this.response.paging.page == this.response.paging.totalPages) return;
      else page = this.response.paging.page + 1;
    }

    this.query.page = page || this.query.page;

    if (search) this.query.q = search;

    if (this.type === 'routes') this.getAvailableRoutes(this.query);
    else if (this.type === 'vehicles') this.getAvailableVehicles(this.query);
    else this.getAvailableDrivers(this.query);
  }

  toggleMenuIcon() {
    this.isToggleMenuIcon = this.isToggleMenuIcon ? false : true;
  }

  /**
   * Open user side menu
   *
   * @param type
   */
  openSidePanel(type: string) {
    console.log(this.sidePanel.triggerSidePanel);
    this.sidePanel.triggerSidePanel.next(type);
  }

  goToDrivers() {
    this.router.navigate(['/drivers']);
  }

  hover(element) {
    this.hovered = element;
  }

  hoverleave() {
    this.hovered = 0;
  }

  /**
   * Get available vehicles list
   *
   * @param query
   */
  private getAvailableVehicles(query) {
    this.api.vechiles
      .get(query)
      .toPromise()
      .then(response => {
        this.response = response;
        this.itemsType = this.type;
        this.items = concat(this.items, response.data);
        this.itemsSelected = this.items;
      })
      .catch(error => this.handleErrors(error));
  }

  /**
   * Get available drivers lists
   *
   * @param query
   */
  private getAvailableDrivers(query) {
    this.api.drivers
      .get(query)
      .toPromise()
      .then(response => {
        this.response = response;
        this.itemsType = this.type;
        this.items = concat(this.items, response.data);
        this.itemsSelected = this.items;
      })
      .catch(error => this.handleErrors(error));
  }

  /**
   * Get Route information
   *
   * @param data
   * @param query
   */
  private getAvailableRoutes(query) {
    this.api.routes
      .get(query)
      .toPromise()
      .then(response => {
        this.response = response;
        this.itemsType = this.type;
        this.items = concat(this.items, response.data);
        this.itemsSelected = this.items;
      })
      .catch(error => this.handleErrors(error));
  }

  /**
   * Display error message at error
   *
   * @param error
   */
  private handleErrors(error) {
    this.items = [];
    this.helpers.handleErrorMessages(error);
  }

  /**
   * Get company profile
   */
  private getCompany() {
    this.api.company
      .get()
      .toPromise()
      .then(response => {
        response = response.data;
        let hasLogo =
          !isNull(response.companyLogo) &&
          response.companyLogo &&
          response.companyLogo.length;
        this.logo = response.companyLogo
          ? response.companyLogo
          : this.defaultLogo;
        this.withBackground =
          hasLogo &&
          response.bgLogoColor &&
          response.bgLogoColor !== 'transparent'
            ? true
            : false;

        if (hasLogo) {
          localStorage.setItem('companyLogo', this.logo);
          localStorage.setItem(
            'withBackground',
            response.bgLogoColor || 'transparent'
          );
        }

        this.loaded.next(null);
      })
      .catch(error => this.helpers.handleErrorMessages(error));
  }

  /**
   * Get selected option of autocomplete
   *
   * @param id
   */
  onOptionSelection(id: any) {
    if (this.type === 'routes') this.router.navigate(['routes', id]);
    else if (this.type === 'vehicles')
      this.router.navigate(['/vehicles/details', id]);
    else this.router.navigate(['/drivers/details', id]);
  }

  sendAnalytics(event) {
    switch (event) {
      case 1:
        this.analytics.sendAnalyticsEvent(
          'Visualizar la sección monitoreo',
          null,
          "Driver's location"
        );
        break;
    }
  }
}
