<h2 mat-dialog-title class="head-dialog">
  <button mat-icon-button [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>
</h2>
<mat-dialog-content head-dialog class="content-dialog">
  {{ 'CARD-ASSIGNMENT.CONFIRM-CANCEL-ROUTE' | translate }}
</mat-dialog-content>
<mat-dialog-actions class="actions-container">
  <button mat-button color="warn" [mat-dialog-close]="true">
    {{ 'COMMON.CANCEL_ROUTE' | translate }}
  </button>
  <button mat-button color="primary" [mat-dialog-close]="false">
    {{ 'COMMON.NOT_CANCEL_ROUTE' | translate }}
  </button>
</mat-dialog-actions>
