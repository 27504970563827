import * as moment from 'moment';
import * as _ from 'lodash';

export interface IDriver {
  _id: string;
  email: string;
  companyId: string | null;
  isActive?: boolean;
  role: 'driver';
  status: 'online' | 'offline';
  image: string;
  'document-number'?: string;
  firstName?: string;
  lastName?: string;
  license?: string;
  phone?: string;
  address?: string;
  country?: string;
  city?: string;
  lastLocation?: null | { latitude: number; longitude: number };
}

export class Driver implements IDriver {
  'document-number': string;
  _id: string;
  address?: string;
  city?: string;
  companyId: string | null;
  country?: string;
  email: string;
  firstName?: string;
  image: string;
  isActive = false;
  lastName?: string;
  license?: string;
  phone?: string;
  role: 'driver';
  status: 'online' | 'offline';
  lastLocation?: null | { latitude: number; longitude: number };
  lastStatusAt: Date | string;

  // UI variables
  selected: boolean;
  totalRegistries = 0;
  lastRecordAt;
  color2;
  lastActivityAt;
  emailPart: string;
  route: any;
  updatedAt: Date | string;

  get hasImage() {
    return (
      this.image !==
      'https://storage.googleapis.com/assets.weco.build/profile.png'
    );
  }
  get color() {
    return this.getColor();
  }
  get statusLabel(): string {
    switch (this.status) {
      case 'offline':
        return 'Inactivo';
      case 'online':
        return 'Activo';
    }
  }
  constructor(
    document_number?: string,
    id?: string,
    address?: string,
    city?: string,
    companyId?: string | null,
    country?: string,
    email?: string,
    firstName?: string,
    image?: string,
    isActive?: boolean,
    lastName?: string,
    license?: string,
    phone?: string,
    role?: 'driver',
    status?: 'online' | 'offline',
    lastStatusAt?: Date | string
  ) {
    this['document-number'] = document_number;
    this.lastStatusAt = lastStatusAt;
    this._id = id;
    this.address = address;
    this.city = city;
    this.companyId = companyId;
    this.country = country;
    this.email = email;
    this.firstName = firstName;
    this.image = image;
    this.isActive = isActive;
    this.lastName = lastName;
    this.license = license;
    this.phone = phone;
    this.role = role;
    this.status = status;
  }
  static fromMap(map: any): Driver {
    return Object.assign(new Driver(), map);
  }

  static fromListMap(list: Array<any>): Array<Driver> {
    return list.map(x => this.fromMap(x));
  }

  getFullName() {
    return `${this.firstName || ''} ${this.lastName || ''}`.trim();
  }
  isOnline() {
    return this.status === 'online';
  }

  getFirebaseStatusPath() {
    return `driver_sessions/${this.companyId}/${this._id}`;
  }

  getInitialsName() {
    if (!this.firstName && !this.lastName) return 'N/A';
    return (
      `${this.firstName?.charAt(0)}${this.lastName?.charAt(
        0
      )}`?.toUpperCase() || 'N/A'
    );
  }

  getColor(str = this._id.slice(-4)) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      // eslint-disable-next-line no-bitwise
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
      // eslint-disable-next-line no-bitwise
      const value = (hash >> (i * 8)) & 0xff;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  }

  /**
   * Obtein two(2) first letters to email
   */
  getEmail() {
    return `${this.email.substring(0, 2).toUpperCase()}`;
  }

  /**
   * Obtein moment from now. Example: hace 7 meses
   *
   * @param date
   */
  getMomentFromNow(date: Date | string) {
    return moment(date).fromNow();
  }

  /**
   * Check firebase object last session data
   * Select correct source data from .lastStatusAt and .sessionInfo.lastStatusAt
   *
   * @param data firebase session data
   */
  setLastStatusAtFromFirebase(data) {
    if (!data) return;
    let dateToCheck = data.lastStatusAt;
    let otherDateToCheck;
    if (_.has(data, 'session.lastStatusAt')) {
      otherDateToCheck = data.session.lastStatusAt;
    }
    if (dateToCheck && otherDateToCheck) {
      dateToCheck = moment(dateToCheck).isAfter(moment(otherDateToCheck))
        ? moment(dateToCheck)
        : moment(otherDateToCheck);
    } else {
      dateToCheck = dateToCheck || otherDateToCheck;
    }
    if (dateToCheck) {
      this.lastStatusAt = moment(dateToCheck).toDate();
    }
  }

  /**
   * Return Driver plain object
   *
   * @returns driver plain object
   */
  toMap() {
    return _.chain({
      _id: this._id,
      companyId: this.companyId,
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      address: this.address,
      'document-number': this['document-number'],
    })
      .omitBy(_.isNil)
      .value();
  }
}
