export enum ErrorsComponent {
  E001 = 'E001',
  E002 = 'E002',
  E003 = 'E003',
  E004 = 'E004',
  E005 = 'E005',
  E006 = 'E006',
  E007 = 'E007',
  E008 = 'E008',
  E009 = 'E009',
  E010 = 'E010',
  E011 = 'E011',
  E012 = 'E012',
  E013 = 'E013',
  E014 = 'E014',
  E015 = 'E015',
  E016 = 'E016',
  E017 = 'E017',
  E018 = 'E018',
  E019 = 'E019',
  E020 = 'E020',
  E021 = 'E021',
  E022 = 'E022',
  E023 = 'E023',
  E024 = 'E024',
  E025 = 'E025',
  E026 = 'E026',
  E027 = 'E027',
  E028 = 'E028',
  E029 = 'E029',
  E030 = 'E030',
  E031 = 'E031',
  E032 = 'E032',
  E033 = 'E033',
  E034 = 'E034',
}

export const ERRORS = {
  [ErrorsComponent.E001]: {
    code: ErrorsComponent.E001,
    message: 'Error en login:',
    statusCode: 500,
  },
  [ErrorsComponent.E002]: {
    code: ErrorsComponent.E002,
    message: 'Error en agregar usuario:',
    statusCode: 500,
  },
  [ErrorsComponent.E003]: {
    code: ErrorsComponent.E003,
    message: 'Error en la ruta.',
    statusCode: 501,
  },
  [ErrorsComponent.E004]: {
    code: ErrorsComponent.E004,
    message: 'Error en monitoreo.',
    statusCode: 501,
  },
  [ErrorsComponent.E005]: {
    code: ErrorsComponent.E005,
    message: 'Error en cambio de password.',
    statusCode: 501,
  },
  [ErrorsComponent.E006]: {
    code: ErrorsComponent.E006,
    message: 'Error en destinos:',
    statusCode: 501,
  },
  [ErrorsComponent.E007]: {
    code: ErrorsComponent.E007,
    message: 'Error en filtro de ruta:',
    statusCode: 501,
  },
  [ErrorsComponent.E008]: {
    code: ErrorsComponent.E008,
    message: 'Error en directorio de ruta:',
    statusCode: 501,
  },
  [ErrorsComponent.E009]: {
    code: ErrorsComponent.E009,
    message: 'Error en edición de ruta:',
    statusCode: 501,
  },
  [ErrorsComponent.E010]: {
    code: ErrorsComponent.E010,
    message: 'Error en cabecera de ruta:',
    statusCode: 501,
  },
  [ErrorsComponent.E011]: {
    code: ErrorsComponent.E011,
    message: 'Error en resumen de ruta:',
    statusCode: 501,
  },
  [ErrorsComponent.E012]: {
    code: ErrorsComponent.E012,
    message: 'Error en actualización de ruta:',
    statusCode: 501,
  },
  [ErrorsComponent.E013]: {
    code: ErrorsComponent.E013,
    message: 'Error en registro rápido:',
    statusCode: 501,
  },
  [ErrorsComponent.E014]: {
    code: ErrorsComponent.E014,
    message: 'Error en equipo:',
    statusCode: 501,
  },
  [ErrorsComponent.E015]: {
    code: ErrorsComponent.E015,
    message: 'Error en servicio:',
    statusCode: 501,
    details: 'createFastRoute',
  },
  [ErrorsComponent.E016]: {
    code: ErrorsComponent.E016,
    message: 'Error al actualizar ruta en directorio:',
    statusCode: 501,
  },
  [ErrorsComponent.E017]: {
    code: ErrorsComponent.E017,
    message: 'Existió un error al guardar los cambios:',
    statusCode: 501,
  },
  [ErrorsComponent.E018]: {
    code: ErrorsComponent.E018,
    message: 'Error en actualización de ruta desde el api:',
    statusCode: 501,
    datails: 'this.api.routes.update(response.data._id, routeUpdateData)',
  },
  [ErrorsComponent.E019]: {
    code: ErrorsComponent.E019,
    message: 'Error en asignación de conductor:',
    statusCode: 501,
    details: 'assingDriver',
  },
  [ErrorsComponent.E020]: {
    code: ErrorsComponent.E020,
    message: 'Existió un error creando la plantilla:',
    statusCode: 501,
    details: 'createNewTemplate',
  },
  [ErrorsComponent.E021]: {
    code: ErrorsComponent.E021,
    message: 'Existió un error creando la plantilla:',
    statusCode: 501,
    details: 'calculateETA',
  },
  [ErrorsComponent.E022]: {
    code: ErrorsComponent.E022,
    message: 'Error agregando destinos a la ruta:',
    statusCode: 501,
    details: 'addPointsToRoute',
  },
  [ErrorsComponent.E023]: {
    code: ErrorsComponent.E023,
    message: 'Existió un error eliminando destinos de la ruta:',
    statusCode: 501,
    details: 'removePointsFromRoute',
  },
  [ErrorsComponent.E024]: {
    code: ErrorsComponent.E024,
    message: 'Existió un error actualizando la ruta:',
    statusCode: 501,
    details: 'this.api.routes.update(routeId, routeUpdateData)',
  },
  [ErrorsComponent.E025]: {
    code: ErrorsComponent.E025,
    message: 'Existió un error obteniendo los destinos de ruta:',
    statusCode: 501,
    details: 'getRouteTemplateDeliveryPoints',
  },
  [ErrorsComponent.E026]: {
    code: ErrorsComponent.E026,
    message: 'Existió un error en la actualización y asignación de la ruta:',
    statusCode: 501,
    details: 'updateAndAssingRoute(driver, start_date)',
  },
  [ErrorsComponent.E027]: {
    code: ErrorsComponent.E027,
    message: 'Existió un error en la creación y asignación de la ruta:',
    statusCode: 501,
    details: 'createAndAssingRoute(driver, start_date)',
  },
  [ErrorsComponent.E028]: {
    code: ErrorsComponent.E028,
    message: 'Existió un error en la plantilla de ruta:',
    statusCode: 501,
    details: ' handleTemplate()',
  },
  [ErrorsComponent.E029]: {
    code: ErrorsComponent.E029,
    message: 'Error eliminando la ruta:',
    statusCode: 501,
    details: 'deleteTemplate()',
  },
  [ErrorsComponent.E030]: {
    code: ErrorsComponent.E030,
    message: 'Error cancelando la ruta en la api:',
    statusCode: 501,
    details: 'cancelRoute()',
  },
  [ErrorsComponent.E031]: {
    code: ErrorsComponent.E031,
    message: 'Error cancelando la ruta:',
    statusCode: 501,
    details: 'showCancelChangesModal()',
  },
  [ErrorsComponent.E032]: {
    code: ErrorsComponent.E032,
    message: 'Error eliminando la ruta:',
    statusCode: 501,
    details: 'showDeleteTemplateModal()',
  },
  [ErrorsComponent.E033]: {
    code: ErrorsComponent.E033,
    message: 'Error eliminando destinos de la ruta:',
    statusCode: 501,
    details: 'updateRoute()',
  },
  [ErrorsComponent.E034]: {
    code: ErrorsComponent.E034,
    message: 'Error en destino:',
    statusCode: 501,
    details: 'clonePoint(pointToClone)',
  },
};
