import { EventEmitter, Injectable } from '@angular/core';
import { UserService } from './user.service';
import { MatDialog } from '@angular/material/dialog';
import { OnboardingStartModalComponent } from '../components/onboarding-start-modal/onboarding-start-modal.component';
import { ApiRequestsService } from '../../api';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  tutorialStepsKeysNames = {
    welcomePage: 'welcomePage',
    dashboard: 'welcomePage',
    report_table: 'report_table',
    report_modal: 'report_modal',
    finishPage: 'finishPage',
  };
  tutorialSteps = {
    welcomePage: false,
    dashboard: false,
    report_table: false,
    report_modal: false,
    finishPage: false,
  };
  tutorialActualStepIndex = 0;
  tutorialActualStep: string = null;
  tutorialStepsKeys = [
    'welcomePage',
    'dashboard',
    'report_table',
    'report_modal',
    'finishPage',
  ];
  changeOnBoardingStep: EventEmitter<any> = new EventEmitter<any>();
  isStarted = false;
  isFinished = false;

  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    private api: ApiRequestsService
  ) {}
  resetOnBoarding() {
    for (const key in this.tutorialSteps) {
      this.tutorialSteps[key] = false;
    }
    localStorage.setItem('bdcWalkthrough', JSON.stringify(this.tutorialSteps));
    this.isFinished = false;
  }
  startOnBoarding() {
    this.resetOnBoarding();
    this.isStarted = true;
    this.isFinished = false;
    this.tutorialActualStep = this.tutorialStepsKeys[0];
    this.changeOnBoardingStep.emit(this.tutorialStepsKeys[0]);
  }

  checkShouldShowOnBoarding() {
    const shouldDisplay = this.userService?.user?.displayTutorial;
    return shouldDisplay;
  }
  setTutorialStep(step: string, value: boolean) {
    this.tutorialSteps[step] = value;
  }
  recalculateActiveStep() {
    let counter = 0;
    const storageValues = JSON.parse(
      localStorage.getItem('localStorage') || '{}'
    );
    delete storageValues['version'];
    for (const key in this.tutorialSteps) {
      this.tutorialSteps[key] = storageValues[key] || false;
    }

    for (const key in this.tutorialSteps) {
      if (!this.tutorialSteps[key]) {
        this.tutorialActualStep = key;
        break;
      }
      counter++;
    }
    this.tutorialActualStepIndex = counter;
    if (
      this.tutorialActualStep ===
      this.tutorialStepsKeys[this.tutorialStepsKeys.length - 1]
    ) {
      this.isFinished = true;
    } else if (this.checkShouldShowOnBoarding()) {
      this.isStarted = true;
    }
  }
  backStep({ cb, callingStep }: any = null) {
    if (callingStep && this.tutorialStepsKeys.includes(callingStep)) {
      this.tutorialActualStep = callingStep;
    }
    if (this.tutorialActualStep !== this.tutorialStepsKeys[0]) {
      this.setTutorialStep(this.tutorialActualStep, false);
      //this.tutorialActualStepIndex = this.tutorialActualStepIndex - 1;
      this.tutorialActualStep = this.getPrevStep();
      this.setTutorialStep(this.tutorialActualStep, false);
      this.changeOnBoardingStep.emit(this.tutorialActualStep);
    }
    if (cb) cb();
  }
  nextStep({ cb, callingStep }: any = null) {
    if (callingStep && this.tutorialStepsKeys.includes(callingStep)) {
      this.tutorialActualStep = callingStep;
    }
    if (
      this.tutorialActualStep !==
      this.tutorialStepsKeys[this.tutorialStepsKeys.length - 1]
    ) {
      this.setTutorialStep(this.tutorialActualStep, true);
      this.tutorialActualStep = this.getNextStep();
      //this.tutorialActualStepIndex = this.tutorialActualStepIndex + 1;
      this.setTutorialStep(this.tutorialActualStep, false);
      this.changeOnBoardingStep.emit(this.tutorialActualStep);
    } else {
      this.finishOnBoarding();
    }
    if (cb) cb();
  }
  getNextStep() {
    for (const key in this.tutorialSteps) {
      if (!this.tutorialSteps[key]) {
        return key;
      }
    }
  }
  getPrevStep() {
    let prev = '';
    for (const key in this.tutorialSteps) {
      if (key === this.tutorialActualStep) {
        return prev;
      } else {
        prev = key;
      }
    }
  }
  finishOnBoarding() {
    this.isFinished = true;
    this.isStarted = false;
    this.userService.user.displayTutorial = false;
    this.api.user.updateProfile({ displayTutorial: false }).toPromise();
  }
  showStartModal({ cb }: any = null) {
    const ref = this.dialog.open(OnboardingStartModalComponent);
    ref.afterClosed().subscribe(response => {
      console.log('aqui en el close', this.tutorialStepsKeys[0]);
      this.nextStep({ callingStep: this.tutorialStepsKeys[0], cb: cb });
    });
  }
  showEndModal({ cb }: any = null) {
    const ref = this.dialog.open(OnboardingStartModalComponent);
    ref.afterClosed().subscribe(response => {
      this.nextStep({
        callingStep: this.tutorialStepsKeys[this.tutorialStepsKeys.length - 1],
        cb: cb,
      });
    });
  }
}
