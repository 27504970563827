import { Component, OnInit } from '@angular/core';
import { CardHeaderRouteComponent } from '../card-header-route/card-header-route.component';

@Component({
  selector: 'card-item-route',
  templateUrl: './card-item-route.component.html',
  styleUrls: ['./card-item-route.component.sass'],
})
export class CardItemRouteComponent
  extends CardHeaderRouteComponent
  implements OnInit
{
  ngOnInit() {
    this.calculateCurrentPoint();
    this.checkDeliveredPoints();
  }

  /**
   * Go to details page
   */
  goToRouteDetails() {
    if (this.type !== 'list') return;

    this.router.navigate(['routes', this.content._id]);
  }
}
