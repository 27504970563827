import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OnboardingService } from '../../services/onboarding.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-onbording-card',
  templateUrl: './onbording-card.component.html',
  styleUrls: ['./onbording-card.component.sass'],
})
export class OnbordingCardComponent implements OnInit {
  @Input() message =
    'Look up hereddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd!';
  @Input() messageUnderImage = '';
  @Input() imageUrl = '/assets/img/onboarding/image1.svg';
  @Input() imageBackground = '';
  @Input() buttonsColors = '';
  @Input() hasNext = false;
  @Input() hasBefore = false;
  @Input() navigateToNext;
  @Input() navigateToPrev;
  @Input() callingStep;
  @Output() onPressNext: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPressBefore: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private onBoardingService: OnboardingService,
    private router: Router
  ) {}

  ngOnInit(): void {}
  pressNextButton() {
    this.onBoardingService.nextStep({ callingStep: this.callingStep });
    this.onPressNext.emit(true);
    if (this.navigateToNext) {
      this.router.navigate([this.navigateToNext]);
    }
  }

  pressBeforeButton() {
    this.onBoardingService.backStep({ callingStep: this.callingStep });
    this.onPressBefore.emit(true);
    if (this.navigateToPrev) {
      this.router.navigate([this.navigateToPrev]);
    }
  }
}
