import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pagination-fleetflex',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.sass'],
})
export class PaginationComponent implements OnInit {
  @Input() paginationObject: any;
  @Output() paginationObjectChange: EventEmitter<any> = new EventEmitter();
  @Output() nextEvent: EventEmitter<any> = new EventEmitter();
  @Output() backEvent: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {
    this.paginationObject = null;
  }

  showing() {
    if (this.paginationObject != null) {
      var floor =
        (this.paginationObject.page - 1) * this.paginationObject.limit + 1;
      var ceiling =
        this.paginationObject.page * this.paginationObject.limit >
        this.paginationObject.totalItems
          ? (this.paginationObject.totalItems % this.paginationObject.limit) +
            (floor - 1)
          : this.paginationObject.page * this.paginationObject.limit;
      return (
        floor + ' - ' + ceiling + ' of ' + this.paginationObject.totalItems
      );
    } else {
      return '0 - 0 of 0';
    }
  }

  next() {
    if (this.canGoNext()) this.nextEvent.emit(this.paginationObject.page + 1);
  }

  canGoNext() {
    if (this.paginationObject == null) {
      return false;
    } else if (
      (this.paginationObject.page - 1) * this.paginationObject.limit +
        1 +
        this.paginationObject.limit >
      this.paginationObject.totalItems
    ) {
      return false;
    } else {
      return true;
    }
  }

  previous() {
    if (this.canGoPrevious())
      this.backEvent.emit(this.paginationObject.page - 1);
  }

  canGoPrevious() {
    if (this.paginationObject == null) {
      return false;
    } else if (
      (this.paginationObject.page - 1) * this.paginationObject.limit +
        1 -
        this.paginationObject.limit <
      0
    ) {
      return false;
    } else {
      return true;
    }
  }
}
