import { Component, Input, ViewChild } from '@angular/core';
import { CardAssignmentComponent } from '../card-assignment';
import { forEach, has, last, find, isEmpty } from 'lodash';
import * as moment from 'moment';
import { CardHeaderDriverComponent } from '../card-assignment/card-header-driver/card-header-driver.component';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'card-assignment-row',
  templateUrl: './card-assignment-row.component.html',
  styleUrls: ['./card-assignment-row.component.sass'],
})
export class CardAssignmentRowComponent extends CardAssignmentComponent {
  driverPicturePlaceholder: string = './assets/img/profile-placeholder.jpg';
  vehiclePicturePlaceholder: string =
    './assets/img/vehicle-pic-placeholder.png';
  totalDuration: string = '';
  driverName: string = '';
  invitePending: boolean = false;
  completed: boolean = false;
  cancelled: boolean = false;
  customDriver: any = {};
  private currentPoint = 0;
  deactivated: boolean = false;
  driverId: string = '';

  @Input() isFirst: boolean = false;
  @Input() content: any = {};
  @ViewChild(CardHeaderDriverComponent) driverRef: CardHeaderDriverComponent;

  ngOnInit() {
    this.subscriptions.assignedStatusCheck =
      this.routeAssign.assignedStatusCheck
        .pipe(filter(item => item.cardId === this.cardId))
        .subscribe(item => {
          this.checkIfIsAssigned(item.route);

          if (item.checkCount) this.routeAssign.checkCounts.next(true);

          this.statusService.statusShouldUpdate.next(item);
        });

    this.subscriptions.driverStatusItem = this.statusService.driverEvent
      .pipe(
        filter(item => {
          return item.cardId === this.cardId;
        })
      )
      .subscribe(statusEvent => {
        /*
        let _content = cloneDeep(this.content);
        _content.lastEvent = pick(statusEvent, 'lastEvent') || {};
        _content.events = statusEvent.lastEvent ? [statusEvent.lastEvent] : [];

        let _payload = { ..._content, ...pick(_content, ['driver', 'vehicle', 'events', 'lastEvent']) };

        _payload.cardId = this.cardId;

         */
        this.statusService.statusShouldUpdate.next(statusEvent);
        //this.renderer['click'].apply(this.componentRef.nativeElement);
      });

    if (this.type === 'route') {
      this.completed =
        this.content.status === 'completed' ||
        this.content.status === 'cancelled';
      this.cancelled = this.content.status === 'cancelled';

      this.totalDuration = '---';
      this.getETA();
      this.checkIfIsAssigned(this.content);
      this.calculateCurrentPoint();

      this.checkDeliveredPoints();
      this.createRouteSubscription(this.content);
    }

    if (this.type === 'driver') {
      this.getName();
      this.invitePending = !this.content.isActive;

      let _interval = setInterval(() => {
        if (this.driverRef) {
          this.customDriver = this.driverRef;
          clearInterval(_interval);
        }
      }, 500);
    }
  }

  ngOnDestroy() {
    forEach(this.subscriptions, value => value.unsubscribe());
  }

  generateId(currentId: string) {
    return currentId.substring(currentId.length - 3);
  }

  /**
   * display ETA for route
   */
  getETA() {
    if (!has(this.content, 'deliverPoints') && !has(this.content, 'index'))
      return;

    if (!this.content.index.length) return;

    let durations = this.content.index.map((point, i) => {
      return !isEmpty(point.eta)
        ? moment(point.eta.timeOut, 'x').format('HH:mm:ss')
        : moment().format('HH:mm:ss');
    });

    let _totalDuration = durations
      .slice(1)
      .reduce(
        (prev, cur) => moment.duration(cur).add(prev),
        moment.duration(durations[0])
      );
    this.totalDuration = `${_totalDuration._data.hours}hrs ${_totalDuration._data.minutes}min`;
  }

  /**
   * Get date text
   *
   * @param date
   */
  getDateText(date: any) {
    let _date = moment
      .utc(date, 'YYYY-MM-DD HH:mm:ss')
      .local()
      .locale(sessionStorage.getItem('lang'));
    let at = this.translate.instant('CARD-ASSIGNMENT.LIST.AT');

    if (moment().diff(_date, 'days') >= 1)
      return `${_date.fromNow()}, ${at} ${_date.format('hh:mm A')}`;

    return `${_date.calendar().split(' ')[0]}, ${at} ${_date.format(
      'hh:mm A'
    )}`;
  }

  scroll(who) {
    if (who == 0) document.getElementById(`${this.cardId}`).scrollLeft += -27;
    else if (who == 1)
      document.getElementById(`${this.cardId}`).scrollLeft += 27;
  }

  /**
   * Find a point by index position
   *
   * @param position
   */
  findPoint(position: any) {
    let point = this.content.index[position];

    if (!point) return null;

    let _item = find(this.content.deliverPoints, e => {
      return e.deliveryPoint._id === this.content.index[position].id;
    });

    return !_item ? null : _item;
  }

  /**
   * Check delivery points statuses
   */
  checkDeliveredPoints() {
    if (!this.content.deliverPoints && !this.content.index) return;

    let _event = this.content.events.length ? last(this.content.events) : {};

    this.content.index.forEach((index, position) => {
      let point = find(this.content.deliverPoints, e => {
        return e.deliveryPoint._id === index.id;
      });

      if (!point) return;

      let classIcon = this.helpers.getDeliveryPointStatus(
        point,
        this.content.status,
        _event,
        position,
        this.currentPoint
      );

      index.className = classIcon.className;
      index.icon = classIcon.icon;
    });
  }

  /**
   * Calculate active point
   */
  private calculateCurrentPoint() {
    for (let i = 0; i < this.content.index.length; i++) {
      let _item = this.findPoint(i);

      if (_item && (_item.isDelivered || _item.omittedAt || _item.deliveredAt))
        this.currentPoint += 1;
      else break;
    }

    return this.currentPoint === 0 ? 0 : this.currentPoint - 1;
  }

  /**
   * get driver name from content
   */
  private getName() {
    if (!this.content) return;

    let firstName = this.content.firstName ? this.content.firstName : null;
    let lastName = this.content.lastName ? this.content.lastName : null;
    let mailName = this.content.email.split('@')[0];

    this.driverId = this.content._id;

    this.driverName =
      !firstName && !lastName ? mailName : `${firstName} ${lastName}`;

    if (this.content.deactivated !== null) {
      this.deactivated = this.content.deactivated;
    }
  }
}
