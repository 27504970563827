import { Directive, Host } from '@angular/core';
import { UserService } from '../services/';
import { DeviceDetectorService } from 'ngx-device-detector';
import { forEach, first } from 'lodash';
import * as _ from 'lodash';
import { HeaderComponent } from '../components/header/header.component';
import { NavigationEnd } from '@angular/router';
import { delay, filter } from 'rxjs/operators';

@Directive({
  selector: '[safariHeader]',
})
export class SafariHeaderDirective {
  private subscriptions: any = {};
  private headerComponent: HeaderComponent;
  private customSections: Array<any> = ['dashboard', ''];
  private location: string = '';
  private isLogged: Boolean = false;

  constructor(
    private device: DeviceDetectorService,
    private userService: UserService,
    @Host() headerComponent: HeaderComponent
  ) {
    this.headerComponent = headerComponent;
  }

  ngOnInit() {
    if (
      this.device.browser.toLowerCase().indexOf('safari') === -1 ||
      this.device.userAgent.toLowerCase().indexOf('safari') === -1
    )
      return;

    this.subscriptions.navigationEnd = this.headerComponent.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe((route: NavigationEnd) => {
        this.location = route.url.replace('/', '');
        _.delay(() => this.checkStatus(), 300);
      });

    this.subscriptions.userLogged = this.userService.isLogged
      .pipe(delay(400))
      .subscribe(logged => {
        this.isLogged = logged;
        this.checkStatus();
      });
  }

  ngOnDestroy() {
    forEach(this.subscriptions, value => value.unsubscribe());
  }

  /**
   * Set custom styles for header
   */
  setHeaderStyles() {
    let header = first(document.getElementsByTagName('app-header'));

    if (!header) return;

    // prevent create multiple divs
    if (!document.getElementById('custom-box-shadow')) {
      header.setAttribute('style', 'z-index: auto;');
      header.insertAdjacentHTML(
        'afterend',
        '<div id="custom-box-shadow"></div>'
      );
    }

    let firstChild: any = first(header.childNodes);
    if (firstChild) firstChild.setAttribute('style', 'z-index: 110');

    // Set custom rules for specific sections
    if (this.customSections.indexOf(this.location) !== -1) {
      switch (this.location) {
        case 'dashboard':
          this.setDashboardHeaderStyle(header);
          break;
        case '':
          this.setDashboardHeaderStyle(header);
          break;
      }
    }
  }

  /**
   * Remove header styles for safari on loggout or when user it's activated
   */
  removeHeaderStyles() {
    let header: any = first(document.getElementsByTagName('app-header'));
    let backdrop = document.getElementById('custom-box-shadow');

    if (backdrop) backdrop.remove();

    if (!header) return;

    header.style.removeProperty('z-index');
    let firstChild: any = first(header.childNodes);
    if (firstChild) firstChild.style.removeProperty('z-index');
  }

  /**
   * Handle issues with dashboard header
   *
   * @param header
   */
  private setDashboardHeaderStyle(header) {
    if (!header) return;

    // reset header styles
    this.removeHeaderStyles();

    header.setAttribute('style', 'z-index: auto;');
    let firstChild: any = first(header.childNodes);

    if (firstChild) firstChild.setAttribute('style', 'z-index: auto');

    let widgetsContainer = document.getElementById('widgets-container');

    if (widgetsContainer) widgetsContainer.classList.add('safari-widget');
  }

  /**
   * Check header status
   */
  private checkStatus() {
    if (!this.isLogged) return this.removeHeaderStyles();

    if (this.userService.user.isActive) {
      this.removeHeaderStyles();
      return;
    }

    this.setHeaderStyles();
  }
}
