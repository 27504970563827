import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as async from 'async';

@Injectable()
export class RouteETAService {
  constructor() {}

  timeConversion(millisec) {
    let seconds: any = (millisec / 1000).toFixed(1);
    let minutes: any = (millisec / (1000 * 60)).toFixed(1);
    let hours: any = (millisec / (1000 * 60 * 60)).toFixed(1);
    let days: any = (millisec / (1000 * 60 * 60 * 24)).toFixed(1);

    if (seconds < 60) {
      return seconds + ' Sec';
    } else if (minutes < 60) {
      return minutes + ' Min';
    } else if (hours < 24) {
      return hours + ' Hrs';
    } else {
      return days + ' Days';
    }
  }

  valculeETA(points, cb) {
    let object = points;
    let start = moment(object.time_start, 'YYYY-MM-DD HH:mm:ss');
    let aux = start.valueOf();
    async.each(
      object.points,
      (point, callback) => {
        //console.log('point = ', point);
        let duration =
          parseInt(point.duration.value) * 1000 +
          parseInt(point.buffer || '0') * 60000;
        let timeIn = aux + point.duration.value * 1000;
        let timeInText = moment(timeIn).format('hh:mm');
        let timeOut = aux + duration;
        let timeOutText =
          moment(timeOut).hours() + ':' + moment(timeOut).minutes();
        point.eta = {
          value: duration,
          text: this.timeConversion(duration),
          timeIn: timeIn,
          timeInText: timeInText,
          timeOut: timeOut,
          timeOutText: timeOutText,
        };
        aux = moment(timeOut).valueOf();
        callback();
      },
      err => {
        cb(err, object);
      }
    );
  }
}
