import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { isPlainObject, isEmpty, has, split } from 'lodash';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Angulartics2 } from 'angulartics2';
import { UntypedFormGroup } from '@angular/forms';

// Sentry
import * as Raven from 'raven-js';

@Injectable()
export class HelpersService {
  private minutesSingular: Array<any> = ['minute', 'minuto'];
  private minutesPlurals: Array<any> = ['minutes', 'minutos'];
  private hoursPlurals: Array<any> = ['hours', 'horas'];
  private hoursSingular: Array<any> = ['hour', 'hora'];
  private daysPlurals: Array<any> = ['days', 'dias'];
  private daysSingular: Array<any> = ['day', 'day'];
  private humanazedDates: Array<any> = ['hoy', 'today', 'ayer', 'yesterday'];
  _statuses: any; // Set from formConfig page
  _companyConfiguration: any; // Set from formConfig page

  constructor(
    private toastr: ToastrService,
    private translate: TranslateService,
    private angulartics2: Angulartics2
  ) {}

  setCompanyConfigurations(data) {
    this._companyConfiguration = data;
    this._statuses = data.driver_form.statuses;
    localStorage.setItem('companyStatuses', JSON.stringify(this._statuses));
    localStorage.setItem(
      'companyConfiguration',
      JSON.stringify(this._companyConfiguration)
    );
  }
  get statuses() {
    try {
      return JSON.parse(localStorage.getItem('companyStatuses'));
    } catch (e) {
      return null;
    }
  }
  get companyConfiguration() {
    try {
      return JSON.parse(localStorage.getItem('companyConfiguration'));
    } catch (e) {
      return null;
    }
  }

  mapStatutes({ status, statutesObject = null }) {
    const statutesFinal = statutesObject || this.statuses;

    if (!statutesFinal) {
      return status;
    }
    switch (status) {
      case 'completed':
        return statutesFinal.completed.text;
      case 'incomplete':
      case 'not-completed':
        return statutesFinal.incomplete.text;
      case 'rejected':
        return statutesFinal.rejected.text;
      case 'cancelled':
      case 'cancelled-inactivity':
        return this.translate.instant('DASHBOARD.CARDS.NOT-DONE');
      default:
        return status || this.translate.instant('DASHBOARD.CARDS.PENDING');
    }
  }

  hasSignature(point) {
    if (!point.signatureUrl || point.signatureUrl === '') {
      return null;
    }
    return `Si (adjunta)`;
  }

  hasSignatureFastRegistry(point) {
    if (!point.signature || point.signature === '') {
      return null;
    }
    return `Si (adjunta)`;
  }

  getCustomFields(point, index) {
    if (!point.custom_fields || point.custom_fields.length === 0) {
      return null;
    }
    if (point.custom_fields.length > index) {
      return point.custom_fields[index];
    }
    return null;
  }

  renderCustomField(point, index) {
    if (!point.custom_fields || point.custom_fields.length === 0) {
      return false;
    }
    if (index === index && point.custom_fields.length > index) {
      return true;
    }
    return false;
  }

  getCustomFieldValue(point) {
    if (!point || !point.value) {
      return null;
    }
    return point.value;
  }

  /**
   * Display toastr error
   *
   * @param error
   * @param code
   */
  handleErrorMessages(error: any, code?: any) {
    let defaultCode = {
      code: 'E000',
      message: 'Ha ocurrido un error, por favor intente nuevamente',
    };

    // Capture Exceptions with sentry
    if (error) Raven.captureException(error);
    if (code === undefined) {
      code = defaultCode;
    }

    this.toastr.error(`Cod ${code.code}`, code.message);
  }

  /**
   * Show warning toastr
   *
   * @param message
   * @param title
   */
  showWarningMessage(message: string, title?: string) {
    if (isPlainObject(message)) return;

    return this.toastr.warning(message, title ? title : '');
  }

  /**
   * Show error toastr
   *
   * @param message
   * @param title
   */
  showErrorMessage(message: string, title?: string) {
    if (isPlainObject(message)) return;

    return this.toastr.error(message, title ? title : '');
  }

  /**
   * Show success toast
   *
   * @param message
   * @param title
   * @param timeOut
   */
  showSuccessMessage(message: string, title?: string, timeOut?: number) {
    return this.toastr.success(message, title ? title : '', {
      timeOut: timeOut || 5000,
    });
  }

  /*
   * Generate an unique ID for each instance
   */
  randomItemId(length: number) {
    return Math.round(
      Math.pow(36, length + 1) - Math.random() * Math.pow(36, length)
    )
      .toString(36)
      .slice(1);
  }

  /**
   * Get date time label, "today", "yesterday", "2 days from",....
   *
   * @param date on format YYYY-MM-DD HH:mm:ss or ISO string
   * @returns human readable date
   */
  getDateText(date: any) {
    let _date = moment
      .utc(date, ['YYYY-MM-DD HH:mm:ss', 'x'])
      .local()
      .locale(sessionStorage.getItem('lang'));

    let _diff = moment().diff(_date, 'days');

    if (_diff >= 1 && _diff <= 3) return _date.fromNow();

    let _calendar = _date.calendar().split(' ')[0];

    return this.humanazedDates.indexOf(_calendar.toLowerCase()) !== -1
      ? _calendar
      : _date.format('DD/MM/YYYY');
  }

  /**
   * Capitalize Text
   *
   * @param text to capitalize
   * @returns text capitalized
   */
  capitalizeText(text: string) {
    return text
      .toLowerCase()
      .replace(/(^|\s)([a-z])/g, (m, p1, p2) => p1 + p2.toUpperCase());
  }

  /**
   * Get delivery point icon and state color
   *
   * @param point point information
   * @param status current route status
   * @param events current route events
   * @param currentIndex point position
   * @param currentActiveIndex current active position
   * @returns icon and className
   */
  getDeliveryPointStatus(
    point: any,
    status: string,
    events: any,
    currentIndex: Number,
    currentActiveIndex: Number
  ) {
    if (!point)
      return {
        icon: '',
        className: '',
        delviered: false,
        setback: false,
        status: '',
      };
    let icon = '';
    let className = 'in-progress';
    const delivered = point.deliveredAt || point.isDelivered;

    const setback = !isEmpty(events) && events.name === 'setback';

    const samePoint = currentActiveIndex === currentIndex;

    if (point.status) {
      switch (point.status) {
        case 'completed':
          icon = 'check';
          className = 'completed';
          break;
        case 'incomplete':
        case 'not-completed':
          icon = 'error_outline';
          className = 'incomplete';
          break;
        case 'rejected':
          icon = 'not_interested';
          className = 'warning';
          break;
      }
    }

    return {
      icon: icon,
      className: className,
      delivered: delivered || false,
      status: point.status || '',
      setback: setback && samePoint,
      finished:
        has(point, 'documentedAt') ||
        has(point, 'deliveredAt') ||
        has(point, 'omittedAt') ||
        has(point, 'finishedAt'),
    };
  }

  /**
   * Get current user location from navigator
   */
  getCurrentLocation(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!('geolocation' in navigator))
        return reject(this.translate.instant('LOCATION.NOT-SUPPORTED'));

      let options = {
        enableHighAccuracy: true,
        timeout: 120000,
        maximumAge: 0,
      };

      navigator.geolocation.getCurrentPosition(
        position => resolve(position.coords),
        error => {
          let _message = this.translate.instant('LOCATION.DEFAULT-ERROR');

          switch (error.code) {
            case 1:
              _message = this.translate.instant('LOCATION.CANCELED');
              break;

            case 6:
              _message = this.translate.instant('LOCATION.LOCATION-TIMEOUT');
              break;

            default:
              _message = this.translate.instant('LOCATION.DEFAULT-ERROR');
              break;
          }

          reject(_message);
        },
        options
      );
    });
  }

  /**
   * Send event to analytics dashboards
   *
   * @param action action name
   * @param properties  action payload
   * @param label action label for event
   */
  sendAnalyticsEvent(action: string, properties?: any, label?: string) {
    let payload: any = { action: action };

    if (properties) payload.properties = properties;

    if (label) payload.label = label;

    // send event
    this.angulartics2.eventTrack.next(payload);
  }

  /**
   * Get images or videos files as base64 string
   *
   * @param file
   */
  getMediaAsBase64(file: File): Promise<Base64File> {
    let reader: FileReader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = (e: any) => {
        resolve({ url: e.target.result, data: file });
      };

      reader.onerror = () => {
        return reject(this);
      };

      if (
        file.type.indexOf('image') !== -1 ||
        file.type.indexOf('video') !== -1
      ) {
        reader.readAsDataURL(file);
      } else reject(file);
    });
  }

  /**
   * Get current index position of a route
   *
   * @param indexes
   * @param deliveryPoints
   */
  getCurrentDeliveryPointIndex(indexes: Array<any>, deliveryPoints) {
    if (!indexes.length) return 0;

    let currentPoint = 0;

    for (let i = 0; i < indexes.length; i++) {
      let point = deliveryPoints['' + indexes[i].id];
      if (
        point.isDelivered ||
        point.omittedAt ||
        point.deliveredAt ||
        point.documentedAt
      )
        currentPoint++;
      else break;
    }

    return currentPoint;
  }

  /**
   * Compare dates to check if ETA is on time
   *
   * @param baseDate route date
   * @param shoulBeDelivered time were should be delivered
   * @param timeTo min time from delivery window
   * @param timeFrom max time from delivery window
   */
  verifyOnTime(
    baseDate: any,
    shoulBeDelivered: any,
    timeTo: any,
    timeFrom: any
  ) {
    let _locale = sessionStorage.getItem('lang' || 'es');

    let _deliveredAt = moment(shoulBeDelivered, 'x').local().locale(_locale);

    let _date: any = moment
      .utc(baseDate)
      .local()
      .locale(_locale)
      .startOf('day')
      .format('YYYY-MM-DD');

    let _dateFormat = ['YYYY-MM-DD hh:mm a', 'YYYY-MM-DD HH:mm'];

    let begin = moment(`${_date} ${timeFrom}`, _dateFormat);
    let end = moment(`${_date} ${timeTo}`, _dateFormat);

    let onTime = moment(_deliveredAt).isBetween(begin, end, null, '[]');

    return onTime;
  }

  /**
   * Get label for late time
   *
   * @param start
   * @param end
   * @param onTime
   */
  getDiffTimeLabel(start: any, end: any, onTime: boolean) {
    let _locale = sessionStorage.getItem('lang' || 'es');
    let diff = moment(start).diff(moment(end));
    let duration = moment.duration(diff).asMinutes();
    let early = false;

    if (duration < 0) {
      onTime = false;
      early = true;
    }

    let labelTexts: any = moment
      .duration(Math.ceil(duration), 'minutes')
      .locale(_locale)
      .humanize();
    labelTexts = split(labelTexts, ' ', 2);

    let labelText = labelTexts.length > 1 ? labelTexts[1] : labelTexts[0];
    ``;

    let label =
      this.minutesSingular.indexOf(labelText) !== -1
        ? this.translate.instant('COMMON.MINUTES')
        : this.minutesPlurals.indexOf(labelText) !== -1
        ? this.translate.instant('COMMON.MINUTES-LONG')
        : this.hoursSingular.indexOf(labelText) !== -1
        ? this.translate.instant('COMMON.HOUR')
        : this.hoursPlurals.indexOf(labelText) !== -1
        ? this.translate.instant('COMMON.HOURS')
        : this.daysSingular.indexOf(labelText) !== -1
        ? this.translate.instant('COMMON.DAY')
        : this.daysPlurals.indexOf(labelText) !== -1
        ? this.translate.instant('COMMON.DAYS')
        : '';

    label = label.toLowerCase();

    return {
      onTime: onTime,
      early: early,
      duration: duration,
      label: label
        ? `${labelTexts[0]} ${label}`
        : moment
            .duration(Math.ceil(duration), 'minutes')
            .locale(_locale)
            .humanize(),
    };
  }

  changeTimeStatusRoutes(routes) {
    for (var i = 0; i < routes.length; i++) {
      const route = routes[i];
      this.changeTransitoryStatus(route);
    }
  }

  changeTransitoryStatus(route) {
    const now = moment();
    const routeStart = moment(route.date);
    const timeDiff = routeStart.diff(now, 'minutes');
    if (this.isBeforeStart(route) && timeDiff >= 0 && timeDiff <= 20)
      route.status = 'starting-soon';
    else if (this.isBeforeStart(route) && timeDiff < 0) route.status = 'late';
  }
  isBeforeStart(route) {
    const status = route.status;
    return (
      status === 'pending' || status === 'late' || status === 'starting-soon'
    );
  }

  /**
   * Calculate distance in meters between two coordinates
   *
   * @param latitudeX.latitudeX
   * @param latitudeX
   * @param longitudeX
   * @param latitudeY
   * @param longitudeY
   * @param latitudeX.longitudeX
   * @param latitudeX.latitudeY
   * @param latitudeX.longitudeY
   */
  calculateDistance({ latitudeX, longitudeX, latitudeY, longitudeY }) {
    /**
     *
     * @param degrees
     */
    function degreesToRadians(degrees) {
      return (degrees * Math.PI) / 180;
    }
    const R = 6378137;
    const dLat = degreesToRadians(latitudeX - latitudeY);
    const dLong = degreesToRadians(longitudeX - longitudeY);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(degreesToRadians(latitudeX)) *
        Math.cos(degreesToRadians(latitudeY)) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  }
}

export interface Base64File {
  data: any;
  url: string;
  contentType?: string;
}

/**
 *
 * @param controlName
 * @param matchingControlName
 */
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
