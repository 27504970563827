<div *ngIf="displayCreator" class="tag-container">
  <div class="main-label">
    <div class="title">
      <span>{{ 'SIDE-MENU.TAGS.TITLE' | translate }}</span>
      <i (click)="displayInfoSection()" class="material-icons item-icon">
        info
      </i>
    </div>

    <div class="subtitle">
      <span>{{ 'SIDE-MENU.TAGS.NAME-LABEL' | translate }}</span>
    </div>

    <div *ngIf="displayNameError" class="error-title">
      <span>{{ 'SIDE-MENU.TAGS.REPEATED-NAME' | translate }}</span>
    </div>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{ 'SIDE-MENU.TAGS.NAME-INPUT' | translate }}</mat-label>
      <input
        matInput
        formControlName="name"
        autocomplete="off"
        #tagName
        maxlength="30" />
      <mat-hint matSuffix align="end">{{ tagName.value.length }}/30</mat-hint>
    </mat-form-field>
  </div>

  <div class="color-picker">
    <div class="title">
      <span>{{ 'SIDE-MENU.TAGS.PICK-COLOR' | translate }}</span>
    </div>

    <div
      class="border rounded-lg d-flex justify-content-start flex-wrap align-content-center p-2"
      style="gap: 15px">
      <div
        *ngFor="
          let color of [
            '#FF3B30',
            '#FF9500',
            '#FFCC00',
            '#34C759',
            '#56C0DC',
            '#007AFF',
            '#5856D6',
            '#AF52DE'
          ]
        "
        class="circle text-center align-items-baseline"
        [ngStyle]="{
          cursor: 'pointer',
          'background-color':
            selectedColor.toLowerCase() === color.toLowerCase()
              ? 'white'
              : color,
          'border-color': color,
          'border-style':
            selectedColor.toLowerCase() === color.toLowerCase()
              ? 'solid'
              : 'none'
        }"
        [ngClass]="{
          circle_active: selectedColor.toLowerCase() === color.toLowerCase()
        }"
        (click)="onSelectColor(color)">
        <span>{{
          disabledColors.includes(color.toLowerCase()) ? 'x' : ''
        }}</span>
      </div>
    </div>
    <spam class="moreColorsLabel" (click)="openColorArrayPicker()">{{
      '+ más colores' | translate
    }}</spam>
    <div
      *ngIf="selectedColor"
      class="d-flex justify-content-end align-items-center"
      style="gap: 10px">
      <div class="font-weight-bold">{{ 'Color seleccionado:' }}</div>
      <div
        class="circle"
        [ngStyle]="{
          'background-color': selectedColor
        }"></div>
    </div>
  </div>

  <div class="main-button">
    <button
      mat-raised-button
      [disabled]="
        selectedColor === '' || tagName.value === '' || !userService.canWrite()
      "
      (click)="createTag(tagName.value, selectedColor)">
      {{ 'SIDE-MENU.TAGS.CREATE-BUTTON' | translate }}
    </button>
  </div>

  <mat-divider class="my-2"></mat-divider>
  <div id="searcher" class="text-center">
    <mat-form-field class="search_form w-100 p-0" appearance="outline">
      <mat-label>{{ 'Buscar etiquetas' | translate }}</mat-label>
      <input matInput type="text" [(ngModel)]="searchText" />
      <button
        *ngIf="searchText"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="searchText = ''">
        <mat-icon>close</mat-icon>
      </button>
      <button *ngIf="!searchText" matSuffix mat-icon-button>
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>
  </div>

  <perfect-scrollbar style="max-height: 400px">
    <div class="tag-list">
      <mat-chip-listbox class="mat-chip-list-stacked">
        <div class="tag-row" *ngFor="let tag of getFilterTagList()">
          <mat-chip
            [removable]="true"
            [ngStyle]="{ 'background-color': tag.color }">
            {{ tag.label }}
            <mat-icon
              matChipRemove
              [hidden]="!userService.canWrite()"
              (click)="deleteTag(tag)"
              >close</mat-icon
            >
            <div [hidden]="userService.canWrite()"></div>
          </mat-chip>
          <i
            *appRole="['company', 'manager_writer']"
            (click)="displayEditorSection(tag)"
            class="material-icons edit-icon">
            edit
          </i>
        </div>
      </mat-chip-listbox>
    </div>
  </perfect-scrollbar>

  <div class="deleted-tags" (click)="displayDeletedSection()">
    <span>{{ 'Etiquetas eliminadas' | translate }}</span>
  </div>
</div>

<div *ngIf="displayEditor" class="tag-container">
  <div class="main-label">
    <div class="title">
      <span>{{ 'SIDE-MENU.TAGS.TITLE' | translate }}</span>
      <i (click)="displayInfoSection()" class="material-icons item-icon">
        info
      </i>
    </div>

    <div class="subtitle">
      <span>{{ 'SIDE-MENU.TAGS.EDIT-LABEL' | translate }}</span>
    </div>

    <div *ngIf="displayNameError" class="error-title">
      <span>{{ 'SIDE-MENU.TAGS.REPEATED-NAME' | translate }}</span>
    </div>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{ 'SIDE-MENU.TAGS.NAME-INPUT' | translate }}</mat-label>
      <input
        matInput
        formControlName="name"
        autocomplete="off"
        #tagName
        maxlength="30"
        [defaultValue]="selectedTagForEdit?.label" />
      <mat-hint matSuffix align="end">{{ tagName.value.length }}/30</mat-hint>
    </mat-form-field>
  </div>

  <div class="color-picker">
    <div class="title">
      <span>{{ 'SIDE-MENU.TAGS.PICK-COLOR' | translate }}</span>
    </div>

    <div
      class="border rounded-lg d-flex justify-content-start flex-wrap align-content-center p-2"
      style="gap: 15px">
      <div
        *ngFor="
          let color of [
            '#FF3B30',
            '#FF9500',
            '#FFCC00',
            '#34C759',
            '#56C0DC',
            '#007AFF',
            '#5856D6',
            '#AF52DE'
          ]
        "
        class="circle text-center align-items-baseline"
        [ngStyle]="{
          cursor: 'pointer',
          'background-color':
            selectedTagForEdit?.color?.toLowerCase() === color.toLowerCase()
              ? 'white'
              : color,
          'border-color': color,
          'border-style':
            selectedTagForEdit?.color?.toLowerCase() === color.toLowerCase()
              ? 'solid'
              : 'none'
        }"
        [ngClass]="{
          circle_active:
            selectedTagForEdit?.color?.toLowerCase() === color.toLowerCase()
        }"
        (click)="onSelectColor(color)">
        <span>{{
          disabledColors.includes(color.toLowerCase()) &&
          editInitialColor.toLowerCase() !== color.toLowerCase()
            ? 'x'
            : ''
        }}</span>
      </div>
    </div>
    <span class="moreColorsLabel" (click)="openColorArrayPicker()">{{
      '+ más colores' | translate
    }}</span>
    <div
      *ngIf="selectedTagForEdit"
      class="d-flex justify-content-end align-items-center"
      style="gap: 10px">
      <div class="font-weight-bold">{{ 'Color seleccionado:' }}</div>
      <div
        class="circle"
        [ngStyle]="{
          'background-color': selectedTagForEdit?.color
        }"></div>
    </div>
  </div>

  <div class="main-button">
    <button
      mat-raised-button
      [disabled]="selectedTagForEdit.color === '' || tagName.value === ''"
      (click)="editTag(tagName.value, this.selectedTagForEdit)">
      {{ 'SIDE-MENU.TAGS.SAVE-CHANGES' | translate }}
    </button>
  </div>

  <mat-divider></mat-divider>

  <div class="tag-list">
    <mat-chip-listbox class="mat-chip-list-stacked">
      <div class="tag-row">
        <mat-chip
          [removable]="true"
          [ngStyle]="{ 'background-color': selectedTagForEdit.color }">
          {{ tagName.value }}
          <mat-icon matChipRemove>close</mat-icon>
        </mat-chip>
      </div>
    </mat-chip-listbox>
  </div>

  <div class="prev-tag">
    <span>{{ 'SIDE-MENU.TAGS.TAG-PREV' | translate }}</span>
  </div>
</div>

<div *ngIf="displayInfo" class="tag-container">
  <div class="info-section">
    <span>{{ 'SIDE-MENU.TAGS.INFO-1' | translate }}</span>
    <img class="info-img" src="assets/img/tag-info-1.svg" />
  </div>

  <mat-divider></mat-divider>

  <div class="info-section">
    <span>{{ 'SIDE-MENU.TAGS.INFO-2' | translate }}</span>
    <img class="info-img" src="assets/img/tag-info-2.svg" />
  </div>

  <div class="main-button-start">
    <button mat-raised-button (click)="this.displayCreatorSection(false)">
      {{ 'Empieza a usar etiquetas' | translate }}
      <i class="material-icons item-icon-start"> sell </i>
    </button>
  </div>
</div>

<div *ngIf="displayDeleted" class="tag-container">
  <div class="main-label">
    <div class="title">
      <span>{{ 'Etiquetas eliminadas' | translate }}</span>
    </div>
  </div>

  <div class="tag-list">
    <mat-chip-listbox class="mat-chip-list-stacked">
      <mat-chip
        *ngFor="let tag of deletedList"
        [removable]="true"
        [ngStyle]="{ 'background-color': tag.color }">
        {{ tag.label }}
        <mat-icon
          matChipRemove
          [hidden]="!userService.canWrite()"
          (click)="enableTag(tag._id)"
          >replay</mat-icon
        >
        <div [hidden]="userService.canWrite()"></div>
      </mat-chip>
    </mat-chip-listbox>
  </div>
</div>

<div *ngIf="displaySpinner" class="spinner-container">
  <mat-spinner></mat-spinner>
</div>
