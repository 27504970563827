<mat-card-header class="shared-card-header">
  <div class="content-details">
    <h2
      class="content-title"
      [ngClass]="{
        'profile-title': type === 'profile' || type === 'profile-edit'
      }">
      {{ title }}
    </h2>
    <h4 class="content-action" *ngIf="type !== 'profile'" (click)="back()">
      {{ 'COMMON.CANCEL' | translate }}
    </h4>
    <h4
      class="content-action"
      *ngIf="type === 'profile' && !(isManagerEditView && isManagerDomain)"
      (click)="editProfile()">
      {{ 'COMMON.EDIT' | translate }}
    </h4>
  </div>

  <div class="avatar-picture">
    <file-upload
      #headerPicture
      [category]="pictureCategory"
      [placeholder]="placeholder"
      (change)="changePicture($event)"
      [imageType]="pictureCategory"
      [edit]="type === 'profile' ? false : true">
    </file-upload>
  </div>
</mat-card-header>
