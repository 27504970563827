import { Injectable, Inject } from '@angular/core';
import { NewHttpClient } from '../interceptor.service';
//import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import { INJECTION_TOKEN } from '../../app.config';

@Injectable()
export class GroupRequests {
  constructor(
    @Inject(INJECTION_TOKEN) public config: any,
    private http: NewHttpClient
  ) {}

  /**
   * Get group list
   *
   * @param data
   */
  get(data?: any): Observable<any> {
    let params = '';

    if (data) {
      let keys = Object.keys(data);
      keys.forEach(key => {
        if (!params.length) params = `?${key}=${data[key]}`;
        else params += `&${key}=${data[key]}`;
      });
    }

    let url = `${this.config.vehicles_groups_url}/${params}`;
    return this.http.get(url);
  }

  /**
   * Get group by id
   *
   * @param id
   */
  getOne(id: string): Observable<any> {
    let url = `${this.config.vehicles_groups_url}/${id}`;
    return this.http.get(url);
  }

  /**
   * Create group
   *
   * @param data
   */
  create(data: any): Observable<any> {
    let url = `${this.config.vehicles_groups_url}`;
    return this.http.post(url, data);
  }

  /**
   * Edit a group
   *
   * @param id
   * @param data
   */
  edit(id: string, data: any): Observable<any> {
    let url = `${this.config.vehicles_groups_url}/${id}`;
    return this.http.put(url, data);
  }

  /**
   * Delete one group
   *
   * @param id
   */
  delete(id: string): Observable<any> {
    let url = `${this.config.vehicles_groups_url}/${id}`;
    return this.http.delete(url);
  }
}
