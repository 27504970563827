import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// bootstrap components
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import localeES from '@angular/common/locales/es-US';

defineLocale('es', esLocale);
registerLocaleData(localeES);

//others';
import { TranslateModule } from '@ngx-translate/core';
import { AgmCoreModule } from '@amin-karimi/agm-core';
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from 'ngx-perfect-scrollbar';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxGalleryModule } from '@rybos/ngx-gallery';

// Custom components
import { PopoverClipboardComponent, SharedComponents } from './components';

// Custom Directives
import { SharedDirectives } from './directives';

// services
import { SharedServices } from './services';

// pipes
import { SharedPipes } from './pipes';

// Material UI
import { MaterialUIComponents } from './material-ui-declarations';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { OnboardingTutorialComponent } from './components/onboarding-tutorial/onboarding-tutorial.component';
import { SelectDatePickerComponent } from './components/select-date-picker/select-date-picker.component';
import { ImportModalComponent } from './components/import-modal/import-modal.component';
import { ImportRoutesComponent } from './components/import-routes/import-routes.component';
import { ProfileModalComponent } from './components/profile-modal/profile-modal.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { OptionsModalComponent } from './components/options-modal/options-modal.component';
import { MatRadioModule } from '@angular/material/radio';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { TaskReportRowComponent } from '../reports/tasks-report-table/task-report-row/task-report-row.component';
import { MatChipsModule } from '@angular/material/chips';
import { ReportAccordeonComponent } from './components/reports-shared-components/report-accordeon/report-accordeon.component';
import { RoutesReportTableComponent } from './components/reports-shared-components/routes-report-table/routes-report-table.component';
import { RoutesReportTableRowComponent } from './components/reports-shared-components/routes-report-table/routes-report-table-row/routes-report-table-row.component';
import { DragScrollModule } from 'ngx-drag-scroll';
import { ReportShareModalComponent } from './components/report-share-modal/report-share-modal.component';
import { ReportNoContentComponent } from './components/report-no-content/report-no-content.component';
import { OnbordingCardComponent } from './components/onbording-card/onbording-card.component';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { CustomOverlayComponent } from './components/custom-overlay/custom-overlay.component';
import { OnboardingStartModalComponent } from './components/onboarding-start-modal/onboarding-start-modal.component';
import { OnboardingEndModalComponent } from './components/onboarding-end-modal/onboarding-end-modal.component';
import { TagCreatorComponent } from './components/side-profile/tag-creator/tag-creator.component';
import { InfoTagsComponent } from './components/side-profile/info-tags/info-tags.component';
import { MatDividerModule } from '@angular/material/divider';
import { ColorCircleModule } from 'ngx-color/circle';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { UnitsModalComponent } from './components/units-modal/units-modal.component';
import { MatListModule } from '@angular/material/list';
import { UnitCreationModalComponent } from './components/units-modal/unit-creation-modal/unit-creation-modal.component';
import { DatetimeRangePickerComponent } from './components/datetime-range-picker/datetime-range-picker.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ColorArrayPickerComponent } from './components/color-array-picker/color-array-picker.component';
import { TagSelectorModalComponent } from './components/tag-selector-modal/tag-selector-modal.component';
import { SelectAllCheckControllerComponent } from './components/select-all-check-controller/select-all-check-controller.component';
import { DestinationNoContentComponent } from './components/destination-no-content/destination-no-content.component';

const defaultScrollbarConfig: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  scrollYMarginOffset: 10,
};

@NgModule({
  imports: [
    ColorCircleModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule,
    TranslateModule,
    PerfectScrollbarModule,
    InfiniteScrollModule,
    TooltipModule.forRoot(),
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    TimepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PopoverModule.forRoot(),
    RouterModule,
    TextMaskModule,
    NgxGalleryModule,
    DatepickerModule.forRoot(),
    MaterialUIComponents(),
    MatRadioModule,
    SweetAlert2Module,
    MatChipsModule,
    DragScrollModule,
    PortalModule,
    OverlayModule,
    MatDividerModule,
    NgxTippyModule,
    MatListModule,
    NgxDaterangepickerMd.forRoot(),
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    TabsModule,
    TranslateModule,
    AgmCoreModule,
    BsDropdownModule,
    TimepickerModule,
    BsDatepickerModule,
    TooltipModule,
    PerfectScrollbarModule,
    TextMaskModule,
    NgxGalleryModule,
    InfiniteScrollModule,
    OnboardingTutorialComponent,
    SelectDatePickerComponent,
    ImportModalComponent,
    ImportRoutesComponent,
    ProfileModalComponent,
    DatepickerModule,
    MaterialUIComponents(),
    SharedPipes(),
    SharedComponents(),
    SharedDirectives(),
    TaskReportRowComponent,
    ReportAccordeonComponent,
    RoutesReportTableComponent,
    RoutesReportTableRowComponent,
    OnbordingCardComponent,
    CustomOverlayComponent,
    UnitsModalComponent,
    DatetimeRangePickerComponent,
    SelectAllCheckControllerComponent,
    PopoverClipboardComponent,
  ],
  declarations: [
    OnboardingTutorialComponent,
    SelectDatePickerComponent,
    SharedPipes(),
    SharedComponents(),
    SharedDirectives(),
    ImportModalComponent,
    ImportRoutesComponent,
    ProfileModalComponent,
    ConfirmationModalComponent,
    OptionsModalComponent,
    TaskReportRowComponent,
    ReportAccordeonComponent,
    RoutesReportTableComponent,
    RoutesReportTableRowComponent,
    ReportShareModalComponent,
    ReportNoContentComponent,
    OnbordingCardComponent,
    CustomOverlayComponent,
    OnboardingStartModalComponent,
    OnboardingEndModalComponent,
    TagCreatorComponent,
    InfoTagsComponent,
    UnitsModalComponent,
    UnitCreationModalComponent,
    DatetimeRangePickerComponent,
    ColorArrayPickerComponent,
    TagSelectorModalComponent,
    SelectAllCheckControllerComponent,
    DestinationNoContentComponent,
    PopoverClipboardComponent,
  ],
  providers: [
    SharedServices(),
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: defaultScrollbarConfig,
    },
    { provide: LOCALE_ID, useValue: 'es-US' },
    { provide: MAT_DATE_LOCALE, useValue: 'es-US' },
  ],
})
export class SharedsModule {}
