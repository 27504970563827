import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'sortArray',
})
export class SortArrayPipe implements PipeTransform {
  transform(value: any, sort: String, order: 'asc' | 'desc' = 'desc'): any {
    if (!_.isArray(value)) return value;
    return _.orderBy(value, [sort], order);
  }
}
