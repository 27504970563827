import { Injectable, Inject } from '@angular/core';
import { NewHttpClient } from '../interceptor.service';
//import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import { INJECTION_TOKEN } from '../../app.config';

@Injectable()
export class ReportsRequests {
  constructor(
    @Inject(INJECTION_TOKEN) public config: any,
    private http: NewHttpClient
  ) {}

  /**
   * Get delivery points by state according to status sent from the backend
   *
   * @param data
   */
  getDeliveryPointsListByState(data?: any) {
    let params = '';

    if (data) {
      let keys = Object.keys(data);
      keys.forEach(key => {
        if (!params.length) params = `?${key}=${data[key]}`;
        else params += `&${key}=${data[key]}`;
      });
    }

    let url = `${this.config.reports_url}${params}`;
    return this.http.get(url);
  }

  /**
   * Get delivery points by state according to status
   *
   * @param data
   */
  getDeliveryPointsList(data: any): Observable<any> {
    let url = `${this.config.reports_url}`;
    return this.http.post(url, data);
  }

  /**
   * Get delivery points count by state, ig: all, not completed, incompleted
   *
   * @param data
   */
  getDeliveryPointsListFilter(data?: any): Observable<any> {
    let params = '';

    if (data) {
      let keys = Object.keys(data);
      keys.forEach(key => {
        if (!params.length) params = `?${key}=${data[key]}`;
        else params += `&${key}=${data[key]}`;
      });
    }
    let url = `${this.config.reports_delivery_points}${params}`;
    return this.http.get(url);
  }

  /**
   * Get geolocation history for a driver
   *
   * @param driverId
   * @param data
   * @param options
   * @param showLoading
   */
  getGeolocationHistory(
    driverId: string,
    options?: any,
    showLoading = true
  ): Observable<any> {
    let url = `${this.config.base_report_url}/geolocation/${driverId}`;
    return this.http.post(url, options, null, showLoading);
  }

  /**
   * Fetch data for driver report download
   *
   * @param driverId
   * @param options
   */
  getGeolocationReport(driverId: string, options?: any) {
    let params = '';

    if (options) {
      let keys = Object.keys(options);
      keys.forEach(key => {
        if (!params.length) params = `?${key}=${options[key]}`;
        else params += `&${key}=${options[key]}`;
      });
    }

    let url = `${this.config.base_report_url}/geolocation/${driverId}/export${params}`;
    return url;
  }

  /**
   * Fetch data for tasks report
   *
   * @param driverId
   * @param options
   */
  getTasksReport(options?: any): Observable<any> {
    let params = '';

    if (options) {
      let keys = Object.keys(options);
      keys.forEach(key => {
        if (!params.length) params = `?${key}=${options[key]}`;
        else params += `&${key}=${options[key]}`;
      });
    }

    let url = `${this.config.delivery_points_url}/reports/tasks`;
    return this.http.post(url, options);
  }

  getExcelReport(payload) {
    let url = `${this.config.base_report_url}/excelReport`;
    //let url = `http://localhost:8500/fleetflex/generateExcel`;

    return this.http.post2(url, payload);
  }

  getClientHistory(options?: any): Observable<any> {
    let params = '';

    if (options) {
      let keys = Object.keys(options);
      keys.forEach(key => {
        if (!params.length) {
          if (options[key] != null) params = `?${key}=${options[key]}`;
        } else {
          if (options[key] != null) params += `&${key}=${options[key]}`;
        }
      });
    }

    console.log(params);
    let url = `${this.config.base_report_url}/clientsHistory${params}`;
    //return this.getClientHistoryLocal();
    return this.http.get(url);
  }

  getClientReport({
    limit = 10,
    page = 1,
    initDate,
    endDate,
    q,
    driverId,
    sort,
    status,
    id,
    tagId,
  }: any) {
    const query = {
      limit,
      page,
      initDate,
      endDate,
      q,
      driverId,
      sort,
      status,
      tagId,
    };
    let params = '';
    const keys = Object.keys(query);
    keys.forEach(key => {
      if (query[key]) {
        if (!params.length) params = `?${key}=${query[key]}`;
        else params += `&${key}=${query[key]}`;
      }
    });

    const url = `${this.config.base_report_url}/clientHistory/${id}${params}`;

    return this.http.get(url);
  }

  getClientHistoryLocal() {
    return {
      docs: [
        {
          _id: '60df43d944ee4287cea08453',
          total: 1,
          routeDeliveryPoints: [
            {
              _id: '60df43d944ee42baf8a08454',
              comments: 'Jdjd',
              files: [],
              referralGuides: [
                {
                  comment: '',
                  referralGuide: 'xjxj',
                },
                {
                  comment: '',
                  referralGuide: 'jdkdk',
                },
              ],
              phoneNumber: '',
              contactName: '',
              signature: '',
              documentedAt: '2021-07-02T16:50:26.782250Z',
              status: 'completed',
              receiverName: 'Jxjz',
              receiverId: 'Zjdj',
              isDelivered: true,
              type: 'task',
              deliveryPoint: {
                _id: '60df43d944ee4287cea08453',
                address: 'Av. 8 NO 602, Guayaquil, Ecuador',
                companyId: '5f4fb96bff969b0042603e06',
                latitude: '-2.1276248',
                longitude: '-79.9072064',
                customerName: 'Contacto 1',
                deliveryName: 'A Capitalizar Se Ha Dicho',
                customerMail: 'a@s.com',
                details: '',
                updatedAt: '2021-07-02T16:50:33.786Z',
                createdAt: '2021-07-02T16:50:33.786Z',
              },
              companyId: '5f4fb96bff969b0042603e06',
              userId: '5fbd700ede7d51002d493a4c',
              updatedAt: '2021-07-02T16:50:33.832Z',
              createdAt: '2021-07-02T16:50:33.832Z',
              latitude: -2.1276248,
              longitude: -79.9072064,
              route: [],
            },
          ],
          deliveryPoint: {
            _id: '60df43d944ee4287cea08453',
            address: 'Av. 8 NO 602, Guayaquil, Ecuador',
            companyId: '5f4fb96bff969b0042603e06',
            latitude: '-2.1276248',
            longitude: '-79.9072064',
            customerName: 'Contacto 1',
            deliveryName: 'A Capitalizar Se Ha Dicho',
            customerMail: 'a@s.com',
            details: '',
            updatedAt: '2021-07-02T16:50:33.786Z',
            createdAt: '2021-07-02T16:50:33.786Z',
          },
        },
      ],
      totalDocs: 16,
      limit: 10,
      page: 1,
      totalPages: 2,
      pagingCounter: 1,
      hasPrevPage: false,
      hasNextPage: true,
      prevPage: null,
      nextPage: 2,
    };
  }

  getPdfReport(payload) {
    const url = `${this.config.base_report_url}/uploadReport`;
    return this.http.post2(url, payload);
  }

  getCompanyResume(payload: { startDate: string; endDate: string }) {
    const url = `${this.config.base_report_url}/getCompanyResume?startDate=${payload.startDate}&endDate=${payload.endDate}`;
    return this.http.get(url);
  }

  getDriverLastRecord(payload: { driverId: string }) {
    const url = `${this.config.base_report_url}/getDriverLastRecord?driverId=${payload.driverId}`;
    return this.http.get(url);
  }

  getDriverResume(payload: { driverId: string; startDate; endDate }) {
    const url = `${this.config.base_report_url}/getDriverResume?driverId=${payload.driverId}&startDate=${payload.startDate}&endDate=${payload.endDate}`;
    return this.http.get(url);
  }

  getDriversResume(payload: { startDate: string }) {
    const url = `${this.config.base_report_url}/getDriversResume?startDate=${payload.startDate}`;
    return this.http.get(url, false);
  }

  getActivityDays(payload: { startDate; endDate }) {
    const url = `${this.config.base_report_url}/getActivityDays?startDate=${payload.startDate}&endDate=${payload.endDate}`;
    return this.http.get(url);
  }
}
