import { Component } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';

import { Router } from '@angular/router';
import { ApiRequestsService } from '../../api';
import { CustomLoadingService, HelpersService } from '../../shared';
import { MustMatch } from './must-match-validator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.sass'],
})
export class ResetPasswordComponent {
  resetPwdForm: UntypedFormGroup;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private loading: CustomLoadingService,
    private helpers: HelpersService,
    private api: ApiRequestsService
  ) {}

  ngOnInit() {
    this.initForm();
  }

  /**
   * Submit reset password
   *
   * @param root0
   * @param root0.value
   * @param root0.valid
   */
  resetPwd({ value, valid }: { value: any; valid: boolean }): void {
    if (!valid) return;

    this.api.auth.resetPwd(value.code, value.newPassword).subscribe(
      response => {
        this.router.navigate(['/login']);
      },
      error => {
        this.helpers.handleErrorMessages(error);
      }
    );
  }

  /**
   * Init form fields
   */
  private initForm(): void {
    this.resetPwdForm = this.fb.group(
      {
        code: ['', Validators.required],
        newPassword: ['', Validators.required],
        confirmPassword: ['', Validators.required],
      },
      {
        validator: MustMatch('newPassword', 'confirmPassword'),
      }
    );
  }
}
