import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PopoverDirective } from 'ngx-bootstrap/popover';

@Component({
  selector: 'detail-header',
  templateUrl: './detail-header.component.html',
  styleUrls: ['./detail-header.component.sass'],
})
export class DetailHeaderComponent implements OnInit {
  @Input() name: string = '';
  @Input() pictureCategory: string = 'driver';
  @Input() type: string = 'driver';
  @Input() imgSrc: string = null;
  @Input() state: any = {};
  @Input() hasAssignation: boolean = false;
  @Output() editCall: EventEmitter<any> = new EventEmitter();
  @Output() deleteCall: EventEmitter<any> = new EventEmitter();
  @ViewChild('pop') pop: PopoverDirective;

  placeholder: string = '';

  constructor(private translate: TranslateService) {
    // this.state = translate.instant("COMMON.NOT-ASSIGNED");
  }

  ngOnInit() {
    this.placeholder =
      this.type === 'driver'
        ? './assets/img/profile-placeholder.jpg'
        : './assets/img/truck.png';
  }

  deleteEmit() {
    this.pop.hide();
    this.deleteCall.emit();
  }
}
