import { Component, OnInit } from '@angular/core';
import * as data from '../../assets/sources/FAQ.json';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.sass'],
})
export class FaqComponent implements OnInit {
  faq: any = (data as any).default;
  panelOpenState = false;

  constructor() {}

  ngOnInit(): void {
    console.log(this.faq);
  }
}
