<div class="card-item">
  <div class="card-avatar">
    <img
      mat-card-avatar
      [src]="driverContent.image ? driverContent.image : picturePlaceholder"
      (click)="goToDetails()" />
    <span class="status" [ngClass]="{ active: isOnline }"> </span>
  </div>

  <div class="card-item-details" (click)="goToDetails()">
    <p class="card-item-title">{{ driverNameLabel }}</p>
    <p class="card-item-subtitle">
      {{ 'COMMON.IDENTIFICATION' | translate }}:
      {{
        driverContent['document-number'] ? driverContent['document-number'] : ''
      }}
    </p>
  </div>
</div>
