import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pagination-footer-fleetflex',
  templateUrl: './pagination-footer.component.html',
  styleUrls: ['./pagination-footer.component.sass'],
})
export class PaginationFooterComponent implements OnInit {
  @Input() paginationObject: any;
  @Input() rowsCounts: Array<any> = [20, 50, 100];
  @Output() nextEvent: EventEmitter<any> = new EventEmitter();
  @Output() backEvent: EventEmitter<any> = new EventEmitter();
  @Output() onRowPerPageChanged: EventEmitter<any> = new EventEmitter<any>();

  rowsPerPage: number = this.setRowsPerPage();
  paging: any = {};

  constructor() {}

  ngOnInit() {
    this.paginationObject = null;
    //this.onRowPerPageChanged.next(this.rowsCounts[0]);
  }

  ngOnChanges(changes: any) {
    if (changes.rowsCounts) this.rowsPerPage = this.setRowsPerPage();
  }

  paginationObjectChange(paging) {
    this.paging = paging;
  }

  /**
   * Rows per page default setting
   */
  private setRowsPerPage() {
    return this.rowsCounts[0];
  }
}
