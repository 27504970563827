import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { FileUploadComponent } from '../uploader/uploader.component';
import { Router } from '@angular/router';
import { AnalyticsService, HelpersService } from '../../services';
import { ApiRequestsService } from '../../../api';

import * as appUtils from '../../../utils';

@Component({
  selector: 'shared-cards-form-header',
  templateUrl: './shared-cards-forms-headers.component.html',
  styleUrls: ['./shared-cards-forms-headers.component.sass'],
})
export class SharedCardsHeaderComponent implements OnInit {
  @ViewChild('headerPicture') headerPicture: FileUploadComponent;
  @Input() title: string = '';
  @Input() pictureCategory: string = 'driver';
  @Input() type: string = 'driver';
  @Input() isManagerEditView = false;
  @Input() userId: string;

  @Output() pictureChange: Subject<any> = new Subject<any>();

  @Input() placeholder: string = '';
  isManagerDomain = false;

  constructor(
    private location: Location,
    private router: Router,
    private api: ApiRequestsService,
    private analytics: AnalyticsService,
    private helper: HelpersService
  ) {}

  ngOnInit() {
    if (!this.placeholder) {
      this.placeholder =
        this.type === 'driver'
          ? './assets/img/profile-placeholder.jpg'
          : './assets/img/truck.png';
    }

    this.isManagerDomain = appUtils.isManagerDomain();
  }

  /**
   * Check if profile is completed
   */
  checkProfile() {
    let data = JSON.parse(localStorage.getItem('currentUserInfo'));

    if (data) data = data.data;
    else return false;

    return data.profileCompleted;
  }

  /**
   * Emit change picture action
   *
   * @param event
   */
  changePicture(event: any) {
    this.headerPicture.upload(event);
  }

  /**
   * Loggout user from app
   */
  logout() {
    this.analytics.logout();
    this.api.auth
      .logout()
      .then(response => {
        this.router.navigate(['/login']);
      })
      .catch(err => {
        this.helper.handleErrorMessages(err);
      });
  }

  /**
   * Go to edit profile
   */
  editProfile() {
    if (this.isManagerEditView && this.userId) {
      this.router.navigate(['/managers/edit', this.userId]);
    } else {
      this.router.navigate(['/profile/edit']);
    }
  }

  /**
   * Trigger picture update
   */
  updatePicture() {
    this.headerPicture.updatePicture();
  }

  /**
   * Go to previous page
   */
  back(): void {
    this.location.back();
  }
}
