import { Component, Inject, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomLoadingService } from './shared';
import {
  SidePanelService,
  UserService,
  AnalyticsService,
  TourService,
} from './shared/services';
import { INJECTION_TOKEN } from './app.config';
import { forEach } from 'lodash';
import { ActivationSuccessComponent } from './shared/components/activation-success/activation-success.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { HeaderService } from './shared/components/header/header.service';
import { OnboardingService } from './shared/services/onboarding.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent {
  @ViewChild('modalSuccess') modalSuccess: ActivationSuccessComponent;
  @ViewChild(HeaderComponent) header: HeaderComponent;

  title = 'app';
  public loggedIn: Boolean;

  useMenu: boolean = false;
  useNotify: boolean = false;
  tourBackdrop: boolean = false;
  withoutIndex: boolean = false;
  headerClass: string = '';
  location: string = '';

  post = {
    loading: true,
  };

  private subscriptions: any = {};

  constructor(
    private translate: TranslateService,
    private loading: CustomLoadingService,
    private sidePanel: SidePanelService,
    private userService: UserService,
    private analytics: AnalyticsService,
    private tour: TourService,
    private hs: HeaderService,
    @Inject(INJECTION_TOKEN) private config: any,
    private onBoardingService: OnboardingService
  ) {
    // watch until analytics it's loaded
    // and set up with their respective credentials
    let _interval = setInterval(() => {
      if (window['mixpanel']) {
        this.analytics.mixpanelEnabled = true;
        this.analytics.init();
        clearInterval(_interval);
      }
    });

    // specify app languages
    translate.addLangs(this.config.langs);

    // get browser languages
    let browserLang = this.config.defaultLang;

    // check in session storage for user lang if not exists use browserlang
    let userLang = sessionStorage.getItem('lang') || browserLang;

    // if do not exists on session storage, set browser lang
    if (!sessionStorage.getItem('lang'))
      sessionStorage.setItem('lang', browserLang);

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(this.config.defaultLang);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use(userLang);

    this.subscriptions.scroll = this.hs.onScroll.subscribe(
      val => (this.headerClass = val)
    );
  }

  ngOnInit() {
    // Listen to side panel open to choose which element to be displayed
    this.subscriptions.sidePanelTrigger =
      this.sidePanel.triggerSidePanel.subscribe(panel => {
        this.useMenu = panel === 'menu';
        this.useNotify = panel === 'notifications';
      });

    // Reset elemet displayed
    this.subscriptions.sidePanelClose = this.sidePanel.onClose.subscribe(() => {
      this.useNotify = false;
      this.useMenu = false;
    });

    this.userService.isLogged.subscribe(logged => (this.loggedIn = logged));

    this.subscriptions.successActivated = this.userService.activated.subscribe(
      () => this.displaySuccessModal()
    );

    // tour backdrop subscriptions
    this.subscriptions.tourDisplayStatus =
      this.tour.tourDisplayStatus.subscribe(
        display => (this.tourBackdrop = display)
      );
    this.subscriptions.tourHeaderIndex = this.tour.tourHeaderIndex.subscribe(
      zIndex => (this.withoutIndex = zIndex)
    );
    this.onBoardingService.recalculateActiveStep();
    if (this.onBoardingService.checkShouldShowOnBoarding()) {
      this.onBoardingService.startOnBoarding();
    }
  }

  ngOnDestroy() {
    forEach(this.subscriptions, value => value.unsubscribe());
  }

  getLocation(event) {
    this.location = event;
  }

  /**
   * Display success modal on profile completed
   */
  displaySuccessModal() {
    this.modalSuccess.show();
  }

  /**
   * Get a langs regex to validate browser lang
   */
  private getLangRegex() {
    const langs = this.config.langs
      ? this.config.langs.join('|')
      : this.config.defaultLang;
    const regex = new RegExp(langs);
    return regex;
  }
}
