import { Injectable, Inject } from '@angular/core';
import { NewHttpClient } from '../interceptor.service';
import { Observable } from 'rxjs';
import { INJECTION_TOKEN } from '../../app.config';

@Injectable()
export class TagsRequests {
  constructor(
    @Inject(INJECTION_TOKEN) public config: any,
    private http: NewHttpClient
  ) {}

  /**
   * Create delivery point
   *
   * @param data
   */
  create(data: any): Observable<any> {
    let url = `${this.config.tags_url}`;
    return this.http.post(url, data);
  }

  /**
   * Get delivery points list
   *
   * @param data
   */
  get(data?: any): Observable<any> {
    let url = `${this.config.tags_url}/?limit=50`;
    return this.http.get(url);
  }

  getDeleted(data?: any): Observable<any> {
    let url = `${this.config.tags_url}/?isDeleted=true&limit=100`;
    return this.http.get(url);
  }

  /**
   * Get one delivery point
   *
   * @param id
   * @param addDeleted
   */
  getOne(id: string, addDeleted: boolean = false): Observable<any> {
    let url = `${this.config.delivery_points_url}/${id}?addDeleted=${addDeleted}`;
    return this.http.get(url);
  }

  /**
   * Update point
   *
   * @param id
   * @param data
   */
  update(id: string, data: any): Observable<any> {
    let url = `${this.config.delivery_points_url}/${id}`;
    return this.http.put(url, data);
  }

  enableTag(id: string): Observable<any> {
    const url = `${this.config.tags_url}/reactivate/${id}`;
    return this.http.post(url, {});
  }

  /**
   * Update point
   *
   * @param tag
   */
  updateTag(tag: any): Observable<any> {
    const url = `${this.config.tags_url}/${tag._id}`;
    const editedTag = {
      label: tag.label,
      color: tag.color,
    };
    return this.http.put(url, editedTag);
  }

  /**
   * delete point
   *
   * @param id
   */
  deleteTag(id: string): Observable<any> {
    let url = `${this.config.tags_url}/${id}`;
    return this.http.delete(url);
  }

  /**
   * Tracking
   *
   * @param id
   */
  tracking(id: string): Observable<any> {
    let url = `${this.config.tracking_url}${id}`;
    return this.http.get(url);
  }

  //TODO WRITE DOC
  /**
   * Export destinations
   *
   * @param id
   * @param data
   */
  export(data: any): Observable<any> {
    let url = `${this.config.delivery_points_export}`;
    return this.http.post2(url, data);
  }
}
