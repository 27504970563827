<div class="uploader-container">
  <span class="uploader-label" *ngIf="!imagePreview">{{ labelPick }}</span>
  <img *ngIf="imagePreview" [src]="imagePreview" class="img-responsive" />
  <img
    *ngIf="!imagePreview"
    [src]="placeholder"
    class="img-responsive img-preview" />
  <input
    [disabled]="!edit"
    type="file"
    name="fileUploader"
    class="image-upload"
    #fileInput />
  <label *ngIf="edit" class="btn btn-primary btn-narrow"> </label>
</div>
