<div #locationMarker class="location-marker-container">
  <div
    class="location-marker"
    (mouseenter)="elementHover()"
    (mouseleave)="elementHover()"
    [ngStyle]="point?.style">
    <div class="delivery-icon">
      <div class="pin" [ngClass]="iconClassForState"></div>
      <span class="point-text"> {{ point.index }} </span>
      <img
        *ngIf="pointModel?.isOutOfFence()"
        class="icon-outside"
        src="../../../../assets/img/icons/icon-warning.svg"
        alt="" />
    </div>
  </div>

  <div
    *ngIf="displayName && pointModel?.isOutOfFence()"
    class="info-tootlip"
    style="transform: translateY(-80px) !important">
    <p class="capitalize-text" style="color: black">
      <b> {{ 'Registro hecho lejos del punto' | translate }} </b>
    </p>
    <div></div>
  </div>

  <!--<div class="location-name" [ngClass]="{ active: displayName }">
    {{ point?.name }}
  </div>-->
</div>
