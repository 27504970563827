import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RouteAssignmentService } from '../../services/route-assignment.service';
import {
  DriverState,
  HeaderState,
  VehicleState,
  RouteState,
} from './header-for-lists.types';
import { ApiRequestsService } from '../../../api';
import { CustomLoadingService } from '../../../shared/services/loading.service';
import { HelpersService } from '../../services/helpers.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { has } from 'lodash';

@Component({
  selector: 'header-for-lists',
  templateUrl: './header-for-lists.component.html',
  styleUrls: ['./header-for-lists.component.sass'],
})
export class HeaderForListsComponent implements OnInit {
  @Input() listName: string = '';
  @Input() list: Array<{}>;
  @Output() paginationEvent: EventEmitter<any> = new EventEmitter();
  @Output() displayEvent: EventEmitter<any> = new EventEmitter();
  @Output() filterEvent: EventEmitter<any> = new EventEmitter();
  @Output() addEvent: EventEmitter<any> = new EventEmitter();
  @Output() dateUpdated: EventEmitter<any> = new EventEmitter();
  @Input() types: Array<string>;
  @Input() paging: any = {};

  public sortType: string;
  public listActive: boolean;
  private subscriptions: any = {};
  public state: HeaderState;
  private perPage: Number = 20;
  private page: Number = 1;
  public notOpened: boolean = true;
  public activeRoutes: boolean = true;
  private date: any;
  private selected: Number;

  constructor(
    private api: ApiRequestsService,
    private loading: CustomLoadingService,
    private helpers: HelpersService,
    private assignService: RouteAssignmentService
  ) {}

  private set viewType(type) {
    localStorage.setItem('viewType', type);
  }

  private get viewType() {
    return localStorage.getItem('viewType');
  }

  ngOnInit() {
    this.displayEvent.emit('list');
    this.sortType = 'updatedAt desc';
    this.listActive = true;

    if (this.listName == 'route') {
      this.sortType = 'date asc';
      this.date = { startDate: moment().format('YYYY-MM-DD Z') };
    } else this.date = moment().format('YYYY-MM-DD Z');

    this.listActive = !this.viewType || this.viewType === 'list';
    this.displayEvent.emit(
      !this.viewType || this.viewType === 'list' ? 'list' : 'module'
    );

    if (this.listName == 'driver') {
      this.state = new DriverState(this.api);
    } else if (this.listName == 'vehicle') {
      this.state = new VehicleState(this.api);
    } else if (this.listName == 'route') {
      this.state = new RouteState(this.api);
    }

    this.assignService.checkCounts.subscribe(() =>
      this.state.getAllCount(this.date)
    );

    if (window.innerWidth <= 767) this.moduleView();
  }

  changeFilterstate(newFilter) {
    if (this.state.filterApplied != newFilter) {
      this.page = 1;
      this.paging = { limit: this.perPage, page: this.page };
    }
    this.state.filterApplied = newFilter;
  }

  getAll() {
    this.changeFilterstate(1);

    let query = this.state.getAll(
      this.perPage,
      this.page,
      this.sortType,
      this.date
    );
    this.state.busy = new Subscription();
    this.handleResponseFromFilter(query, this.listName);
  }

  getAssigned() {
    this.changeFilterstate(3);

    let query = this.state.getAssigned(
      this.perPage,
      this.page,
      this.sortType,
      this.date
    );
    this.state.busy = new Subscription();
    this.handleResponseFromFilter(query, this.listName);
  }

  getNotAssigned() {
    this.changeFilterstate(2);

    let query = this.state.getNotAssigned(
      this.perPage,
      this.page,
      this.sortType,
      this.date
    );
    this.state.busy = new Subscription();
    this.handleResponseFromFilter(query, this.listName);
  }

  getOOS() {
    this.changeFilterstate(4);

    let query = this.state.getOOS(
      this.perPage,
      this.page,
      this.sortType,
      this.date
    );
    this.state.busy = new Subscription();
    this.handleResponseFromFilter(query, this.listName);
  }

  getOnRoute() {
    this.changeFilterstate(5);

    let query = this.state.getOnRoute(
      this.perPage,
      this.page,
      this.sortType,
      this.date
    );
    this.state.busy = new Subscription();
    this.handleResponseFromFilter(query, this.listName);
  }

  getSetback() {
    this.changeFilterstate(6);

    let query = this.state.getSetback(
      this.perPage,
      this.page,
      this.sortType,
      this.date
    );
    this.state.busy = new Subscription();
    this.handleResponseFromFilter(query, this.listName);
  }

  getCancelled() {
    this.changeFilterstate(8);

    let query = this.state.getCancelled(
      this.perPage,
      this.page,
      this.sortType,
      this.date
    );
    this.state.busy = new Subscription();
    this.handleResponseFromFilter(query, this.listName);
  }

  getInvited() {
    this.changeFilterstate(7);

    let query = this.state.getInvited(
      this.perPage,
      this.page,
      this.sortType,
      this.date
    );
    this.state.busy = new Subscription();
    this.handleResponseFromFilter(query, this.listName);
  }

  update() {
    this.filter(this.state.filterApplied);
  }

  updatePerPage(perPage) {
    this.perPage = perPage;
    this.paging.limit = perPage;
    this.filter(this.state.filterApplied);
  }

  updatePage(page) {
    this.page = page;
    this.filter(this.state.filterApplied);
  }

  updateSort(sort) {
    this.sortType = sort;
    this.filter(this.state.filterApplied);
  }

  ngOnDestroy() {
    //  this.routeAssignmentService.assignedRouteItem.unsubscribe();
  }

  add() {
    this.addEvent.emit();
  }

  filter(filterNumber: Number) {
    if (this.state.busy) {
      this.state.busy.unsubscribe();
    }
    if (filterNumber == 1) {
      this.getAll();
    } else if (filterNumber == 2) {
      this.getNotAssigned();
    } else if (filterNumber == 3) {
      this.getAssigned();
    } else if (filterNumber == 4) {
      this.getOOS();
    } else if (filterNumber == 5) {
      this.getOnRoute();
    } else if (filterNumber == 6) {
      this.getSetback();
    } else if (filterNumber == 7) {
      this.getInvited();
    } else if (filterNumber == 8) {
      this.getCancelled();
    }
  }

  next(page) {
    this.updatePage(page);
  }

  previous(page) {
    this.updatePage(page);
  }

  listView() {
    this.displayEvent.emit('list');
    this.listActive = true;
    this.viewType = 'list';
  }

  moduleView() {
    this.listActive = false;
    this.displayEvent.emit('module');
    this.viewType = 'card';
  }

  dateChange(event) {
    this.notOpened = false;
    this.date = moment(event.value).format('YYYY-MM-DD Z');
    this.dateUpdated.emit(this.date);
    this.state.getAllCount(this.date);
    this.update();
  }

  dateChangeRoute(event) {
    if (!event.allDates) {
      this.date = moment(event.date).format('YYYY-MM-DD Z');
      this.sortType =
        this.sortType !== 'date asc' ? this.sortType : 'updatedAt desc';
      this.activeRoutes = false;
    } else {
      this.date = {
        startDate: moment(event.date).format('YYYY-MM-DD Z'),
      };
      this.activeRoutes = true;
    }
    this.dateUpdated.emit(moment(event.date).format('YYYY-MM-DD Z'));
    this.state.getAllCount(moment(event.date).format('YYYY-MM-DD Z'));
    this.update();
  }

  /**
   * Handle response for each filter
   *
   * @param query
   * @param type
   */
  private handleResponseFromFilter(query: any, type: string) {
    console.log('handleResponseFromFilter');
    query
      .toPromise()
      .then(response => {
        this.paging = response.paging;

        if (type === 'route' || !has(response, 'routes')) {
          if (type === 'route') {
            console.log(response);
            this.filterEvent.emit({
              response: response,
              date: this.date,
              sort: this.sortType,
            });
          } else {
            console.log('2: ' + response);
            this.filterEvent.emit(response);
          }

          this.state.busy = null;
          return;
        }

        let items = response.data.map(item => {
          let _route = response.routes.find(route => item._id === route[type]);
          return this.assignService.getItemRoute(
            item,
            _route ? _route._id : null,
            type
          );
        });

        Promise.all(items)
          .then(res => {
            this.state.busy = null;
            response.data = res;
            console.log(res);
            this.filterEvent.emit(response);
          })
          .catch(error => {
            this.helpers.handleErrorMessages(error);
          });
      })
      .catch(error => {
        this.helpers.handleErrorMessages(error);
      });
  }

  /**
   * Refresh all trigger
   */
  refreshAll() {
    this.state.getAllCount();
    this.update();
  }
}
