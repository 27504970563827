import { Injectable, Inject } from '@angular/core';
import { NewHttpClient } from '../interceptor.service';
import { Observable } from 'rxjs';
import { INJECTION_TOKEN } from '../../app.config';

@Injectable()
export class RoutesRequests {
  constructor(
    @Inject(INJECTION_TOKEN) public config: any,
    private http: NewHttpClient
  ) {}

  /**
   * Get group list
   *
   * @param data
   */
  get(data?: any): Observable<any> {
    let params = '';

    if (data) {
      const keys = Object.keys(data);
      keys.forEach(key => {
        if (!params.length) {
          params = `?${key}=${data[key]}`;
        } else {
          params += `&${key}=${data[key]}`;
        }
      });
    }

    const url = `${this.config.routes_url}${params}`;
    return this.http.get(url);
  }

  /**
   * Get group by id
   *
   * @param id
   */
  getOne(id: string): Observable<any> {
    const url = `${this.config.routes_url}/${id}`;
    return this.http.get(url);
  }

  /**
   * Get number of available routes
   *
   * @param date
   */
  getNumberActive(date: string): Observable<any> {
    const url = `${this.config.number_routes_active}?date=${date}`;
    return this.http.get(url);
  }

  /**
   * create draft route
   *
   * @param data
   */
  create(data: any) {
    const url = `${this.config.routes_url}`;
    return this.http.post(url, data);
  }

  /**
   * Import delivery points
   *
   * @param formData
   * @param routeId
   */
  import(formData: any, routeId: string) {
    const currentLang = sessionStorage.getItem('lang');
    const lang =
      currentLang === 'es' ? 'es-Es' : currentLang === 'en' ? 'en-En' : 'es-Es';

    const url = `${this.config.routes_import}/${routeId}`;

    return new Promise((resolve, reject) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.response));
          } else {
            reject(JSON.parse(xhr.response));
          }
        }
      };

      xhr.open('POST', url, true);
      xhr.setRequestHeader(
        'Authorization',
        `Bearer ${localStorage.getItem('currentUser')}`
      );
      xhr.setRequestHeader('Accept-Language', `${lang}`);
      xhr.send(formData);
    });
  }

  /**
   * Import delivery points on directory
   *
   * @param formData
   */
  importDeliveryPoints(formData: any) {
    const currentLang = sessionStorage.getItem('lang');
    const lang =
      currentLang === 'es' ? 'es-Es' : currentLang === 'en' ? 'en-En' : 'es-Es';

    const url = `${this.config.points_import}`;

    return new Promise((resolve, reject) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            console.log('HEADERS', xhr.getAllResponseHeaders());
            if (xhr.getResponseHeader('isProblem') == 'true') {
              resolve({
                response: xhr.response,
                problem: true,
                destinationsCreated: xhr.getResponseHeader(
                  'destinationsCreated'
                ),
                destinationsFailed: xhr.getResponseHeader('destinationsFailed'),
              });
            } else {
              resolve({
                response: {},
                problem: false,
                destinationsCreated: xhr.getResponseHeader(
                  'destinationsCreated'
                ),
                destinationsFailed: xhr.getResponseHeader('destinationsFailed'),
              });
            }
          } else {
            reject(JSON.parse(xhr.response));
          }
        }
      };

      xhr.open('POST', url, true);
      xhr.setRequestHeader(
        'Authorization',
        `Bearer ${localStorage.getItem('currentUser')}`
      );
      xhr.setRequestHeader('Accept-Language', `${lang}`);
      xhr.send(formData);
    });
  }

  /**
   * Pre import routes
   *
   * @param formData
   */
  preImport(formData: any) {
    const currentLang = sessionStorage.getItem('lang');
    const lang =
      currentLang === 'es' ? 'es-Es' : currentLang === 'en' ? 'en-En' : 'es-Es';

    const url = `${this.config.pre_import}`;

    return new Promise((resolve, reject) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            if (xhr.getResponseHeader('isProblem') == 'true') {
              resolve({
                response: xhr.response,
                isProblem: true,
                routeName: xhr.getResponseHeader('routeName'),
              });
            } else {
              xhr.response.text().then(text => {
                resolve({
                  response: JSON.parse(text),
                  isProblem: false,
                  routeName: xhr.getResponseHeader('routeName'),
                });
              });
            }
          } else {
            reject(JSON.parse(xhr.response));
          }
        }
      };

      xhr.open('POST', url, true);
      xhr.setRequestHeader(
        'Authorization',
        `Bearer ${localStorage.getItem('currentUser')}`
      );
      xhr.setRequestHeader('Accept-Language', `${lang}`);
      xhr.send(formData);
    });
  }

  /*
   * Delete one route
   */
  delete(id: string): Observable<any> {
    const url = `${this.config.routes_url}/${id}`;
    return this.http.delete(url);
  }

  /**
   * Update given route
   *
   * @param id
   * @param data
   */
  update(id: string, data: any): Observable<any> {
    const url = `${this.config.routes_url}/${id}`;
    return this.http.put(url, data);
  }

  /**
   * Add point to a given id
   *
   * @param data
   */
  addAdress(data: any): Observable<any> {
    const url = `${this.config.routes_url}/addAddress`;
    return this.http.post(url, data);
  }

  /**
   * Add point to a given id
   *
   * @param data
   */
  updateAddress(data: any): Observable<any> {
    const url = `${this.config.routes_url}/updateAddress`;
    return this.http.post(url, data);
  }

  /**
   * Remove address from route
   *
   * @param data
   */
  removeAddress(data: any): Observable<any> {
    const url = `${this.config.routes_url}/deleteAddress`;
    return this.http.post(url, data);
  }

  /**
   * Assign vehicle to route
   *
   * @param data
   */
  assignVehicle(data: any): Observable<any> {
    const url = `${this.config.routes_url}/assign-vehicle`;
    return this.http.post(url, data);
  }

  /**
   * Assign driver to route
   *
   * @param data
   */
  assignDriver(data: any): Observable<any> {
    const url = `${this.config.routes_url}/assign-driver`;
    return this.http.post(url, data);
  }

  /**
   * Get routes count by state, ig: Assigned, not assigned, setback
   *
   * @param data
   */
  getRoutesCountByState(data?: any): Observable<any> {
    let params = '';

    if (data) {
      const keys = Object.keys(data);
      keys.forEach(key => {
        if (!params.length) {
          params = `?${key}=${data[key]}`;
        } else {
          params += `&${key}=${data[key]}`;
        }
      });
    }

    const url = `${this.config.routes_count_by_state}/${params}`;
    return this.http.get(url);
  }

  /**
   * Get route by state according to status sent from the backend, ig: Assigned, not assigned, setback
   *
   * @param q
   * @param data
   * @param method
   */
  getRoutesListByState(q, data?: any, method = 'get'): Observable<any> {
    let params = '';

    if (method === 'get') {
      if (data) {
        const keys = Object.keys(data);
        keys.forEach(key => {
          if (!params.length) {
            params = `?${key}=${data[key]}`;
          } else if (data[key] != null) {
            params += `&${key}=${data[key]}`;
          }
        });
      }

      const url = `${this.config.routes_by_state}/${q}${params}`;
      return this.http.get(url);
    } else if (method === 'post') {
      return this.http.post(`${this.config.routes_by_state}/${q}`, data);
    }
  }

  /**
   * Get routes by entity
   *
   * @param date
   * @param entity
   */
  getAvailableByEntity(date: string, entity: string): Observable<any> {
    const url = `${this.config.routes_url}/available/${date}/${entity}`;
    return this.http.get(url);
  }

  getTeamDriver(data?: any): Observable<any> {
    let params = '';

    if (data) {
      const keys = Object.keys(data);
      keys.forEach(key => {
        if (!params.length) {
          params = `?${key}=${data[key]}`;
        } else if (data[key] != null) {
          params += `&${key}=${data[key]}`;
        }
      });
    }

    const url = `${this.config.routes_url}/team-driver/${params}`;
    console.log(url);
    return this.http.get(url);
  }

  /**
   * Unassing route from entities
   *
   * @param route
   */
  unassignRoute(route: string): Observable<any> {
    const url = `${this.config.routes_url}/unassing`;
    return this.http.post(url, { routeId: route });
  }

  /**
   * Activate route
   *
   * @param route
   */
  activateRoute(route: string): Observable<any> {
    const url = `${this.config.routes_url}/${route}/activate`;
    return this.http.post(url, {});
  }

  /**
   * Clean route
   *
   * @param route
   */
  cleanRoute(route: string): Observable<any> {
    const url = `${this.config.routes_url}/clean-route`;
    return this.http.post(url, { routeId: route });
  }

  /**
   * Create a template from a route
   *
   * @param data
   */
  createTemplate(data: any): Observable<any> {
    const url = `${this.config.routes_templates}`;
    return this.http.post(url, data);
  }

  /**
   * Update template
   *
   * @param data
   */
  updateTemplate(data: any): Observable<any> {
    const url = `${this.config.routes_templates}`;
    return this.http.put(url, data);
  }

  /**
   * Remove a template
   *
   * @param template
   */
  removeTemplate(template: string): Observable<any> {
    const _payload = { _id: template };

    const url = `${this.config.routes_templates}/${template}`;
    return this.http.delete(url);
  }

  /**
   * find templates
   *
   * @param data
   */
  getTemplates(data: any): Observable<any> {
    let params = '';

    if (data) {
      const keys = Object.keys(data);
      keys.forEach(key => {
        if (!params.length) {
          params = `?${key}=${data[key]}`;
        } else if (data[key] != null) {
          params += `&${key}=${data[key]}`;
        }
      });
    }

    const url = `${this.config.routes_templates}/${params}`;
    return this.http.get(url);
  }

  /**
   * find a template
   *
   * @param id
   */
  getTemplate(id): Observable<any> {
    const url = `${this.config.routes_templates}/${id}`;
    return this.http.get(url);
  }

  /**
   * Import a template from directory
   *
   * @param data
   */
  importTemplates(data: any): Observable<any> {
    const url = `${this.config.import_routes_templates}`;
    return this.http.post(url, data);
  }

  // TODO WRITE DOC
  /**
   * Export destinations
   *
   * @param id
   * @param data
   */
  export(data: any): Observable<any> {
    const url = `${this.config.routes_templates_export}`;
    return this.http.post2(url, data);
  }

  /**
   * Create from template
   *
   * @param data data
   * @param data.label label
   * @param data.deliveryPoints deliveryPoints
   * @param data.startDate startDate
   * @param data.index index
   * @param data.templateId templateId
   * @param data.notes notes include templateID
   * @returns route
   */
  createWithDeliveyPoints(data: {
    label: string;
    deliveryPoints: Array<any>;
    startDate: string;
    index: Array<any>;
    templateId: string;
    notes?: string;
  }) {
    const url = `${this.config.create_with_deliveryPoints}`;
    return this.http.post(url, data);
  }
}
