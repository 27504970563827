import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'map-views-types',
  templateUrl: './map-view-type.component.html',
  styleUrls: ['./map-view-type.component.sass'],
})
export class MapViewsTypesComponent implements OnInit {
  viewTypes: Array<any> = [];
  private selection: string = 'roadmap';

  @Input()
  get model() {
    return this.selection;
  }
  @Output() modelChange = new EventEmitter<any>();
  set model(val: any) {
    this.selection = val;
  }

  constructor() {}

  ngOnInit() {
    this.viewTypes = [
      {
        name: 'MAP-VIEWS.ROADMAP',
        type: 'roadmap',
        icon: 'streetview',
        active: true,
      },
      {
        name: 'MAP-VIEWS.SATELLITE',
        type: 'satellite',
        icon: 'satellite',
      },
      {
        name: 'MAP-VIEWS.HYBRID',
        type: 'hybrid',
        icon: 'layers_clear',
      },
    ];
  }

  /**
   * Select and emit view type
   *
   * @param type
   */
  selectType(type: any) {
    this.viewTypes.forEach(type => (type.active = false));
    type.active = true;
    this.model = type.type;
    this.modelChange.emit(type.type);
  }
}
