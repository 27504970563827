<mat-card-header class="card-header">
  <div class="card-avatar">
    <img
      mat-card-avatar
      [src]="content?.vehicleImage ? content?.vehicleImage : picturePlaceholder"
      (click)="goToDetails()" />
  </div>

  <div class="card-head-details">
    <card-status-badge
      [oldStyle]="true"
      [instanceType]="'vehicle'"
      [cardId]="parentCardId"
      [item]="content">
    </card-status-badge>

    <mat-card-title (click)="goToDetails()">
      {{ content?.brand }} {{ content?.model }}
    </mat-card-title>
    <mat-card-subtitle (click)="goToDetails()"
      >{{ 'CARD-ASSIGNMENT.LIST.PLATE' | translate }}:{{ content?.plate }}
    </mat-card-subtitle>
  </div>
</mat-card-header>
