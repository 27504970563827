import { Component, Inject, OnInit } from '@angular/core';
import { ApiRequestsService } from '../../../api';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-tag-selector-modal',
  templateUrl: './tag-selector-modal.component.html',
  styleUrls: ['./tag-selector-modal.component.sass'],
})
export class TagSelectorModalComponent implements OnInit {
  tagLoading = false;
  tagList = [];
  selectedTags = [];
  searchText = '';
  uniqueSelector = false;
  hintText = '';
  showExtraActionButton = false;
  constructor(
    private api: ApiRequestsService,
    private dialogRef: MatDialogRef<TagSelectorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.selectedTags = data['selectedTags'] || [];
      this.uniqueSelector = data['uniqueSelector'] || false;
      this.hintText = data['hintText'] || '';
      this.showExtraActionButton = data['showExtraActionButton'] || false;
    }
  }

  ngOnInit(): void {
    this.getAllActiveTags();
    this.dialogRef.backdropClick().subscribe(r => {
      this.closeModal();
    });
  }
  closeModal() {
    this.dialogRef.close(this.selectedTags);
  }
  onExtraActionButton() {
    this.dialogRef.close(false);
  }
  getAllActiveTags() {
    this.tagLoading = true;
    this.api.tags.get().subscribe(
      response => {
        this.tagList = response.data;
        this.tagLoading = false;
      },
      error => {
        this.tagLoading = false;
      }
    );
  }
  getFilterTagList() {
    return this.tagList
      .filter(tag =>
        tag.label.toLowerCase().includes(this.searchText.toLowerCase())
      )
      .sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      );
  }

  checkIfSelected(tag) {
    return this.selectedTags.find(t => t._id === tag._id);
  }
  checkTag(event, tag) {
    if (this.uniqueSelector) {
      this.selectedTags = [tag];
      return;
    }
    const add = event.checked;
    if (add) {
      this.selectedTags.push(tag);
    } else {
      this.selectedTags = this.selectedTags.filter(t => t._id !== tag._id);
    }
  }

  isCheckedAll(): boolean {
    return this.selectedTags.length === this.tagList.length;
  }

  isIndeterminateAll(): boolean {
    return this.selectedTags.length < this.tagList.length;
  }

  toggleSelectionAll(change: MatCheckboxChange): void {
    if (change.checked) {
      this.selectedTags = [...this.tagList];
    } else {
      this.selectedTags = [];
    }
  }
}
