import { Injectable } from '@angular/core';

@Injectable()
export class CustomLoadingService {
  private _selector: string = 'loading-wrapper';
  private _element: HTMLElement;

  constructor() {
    this._element = document.getElementById(this._selector);
  }

  public show(index?: number): void {
    this._element.style['display'] = 'block';
    if (index) this._element.style['z-index'] = index;
  }

  public hide(delay: number = 300): void {
    setTimeout(() => {
      this._element.style['display'] = 'none';
    }, delay);
  }
}
