import { Injectable, Inject } from '@angular/core';
import { NewHttpClient } from '../interceptor.service';
//import 'rxjs/add/operator/map';
import { INJECTION_TOKEN } from '../../app.config';
import { Observable } from 'rxjs';

@Injectable()
export class UserRequests {
  constructor(
    @Inject(INJECTION_TOKEN) public config: any,
    private http: NewHttpClient
  ) {}

  /**
   * Get current user data
   */
  me(): Observable<any> {
    let url = `${this.config.me_url}`;
    return this.http.get(url);
  }

  allAdmin(data?: any): Observable<any> {
    let params = '';

    if (data) {
      let keys = Object.keys(data);
      keys.forEach(key => {
        if (!params.length) params = `?${key}=${data[key]}`;
        else if (data[key] != null) params += `&${key}=${data[key]}`;
      });
    }

    //let url = `${this.config.routes_url}/team-driver/${params}`;
    let url = `${this.config.users_base}all-admin/${params}`;
    console.log(url);
    return this.http.get(url);
  }

  /**
   * Update user profile
   *
   * @param data
   */
  update(data: any): Observable<any> {
    let url = `${this.config.users_base}company`;
    return this.http.put(url, data);
  }
  /**
   * Update user profile
   *
   * @param data
   */

  updateProfile(data: any): Observable<any> {
    let url = `${this.config.users_base}`;
    return this.http.put(url, data);
  }

  /**
   * Change password
   *
   * @param data
   */
  changePassword(data: any): Observable<any> {
    let url = `${this.config.change_password}`;
    return this.http.post(url, data);
  }

  /**
   * Veriffy if emails it's already in use
   *
   * @param email
   */
  checkEmail(email: string): Observable<any> {
    let url = `${this.config.users_base}exist-mail`;
    return this.http.post(url, { email: email });
  }

  /**
   * Get user firebase token
   */
  getFirebaseToken(): Observable<any> {
    let url = `${this.config.users_base}firebase-token`;
    return this.http.get(url);
  }

  /**
   * Get activation code
   */
  getActivationCode(): Observable<any> {
    let url = `${this.config.activation_code}`;
    return this.http.get(url);
  }

  /**
   * Add firebase token
   */

  addFirebaseToken(data: any) {
    let url = `${this.config.users_base}firebase-token`;
    return this.http.post(url, data).toPromise();
  }

  /**
   * Remove firebase token
   */

  removeFirebaseToken(data: any) {
    let url = `${this.config.users_base}firebase-token/${data.token}`;
    return this.http.delete(url).toPromise();
  }
}
