import { HelpersService } from './helpers.service';
import { CustomLoadingService } from './loading.service';
import { RouteETAService } from './route-eta.service';
import { FirebaseService } from './firebase.service';
import { DriversService } from './drivers.service';
import { RouteAssignmentService } from './route-assignment.service';
import { SidePanelService } from './side-panel.service';
import { SubmenuService } from './submenu.service';
import { LocationService } from './location.service';

// global services
import { AnalyticsService } from './analytics.service';
import { UserService } from './user.service';
import { TourService } from './tour.service';
import { DriverEventsService } from './driver-events.service';

/**
 *
 */
export function SharedServices() {
  return [
    HelpersService,
    CustomLoadingService,
    RouteETAService,
    FirebaseService,
    RouteAssignmentService,
    SidePanelService,
    DriversService,
    SubmenuService,
    LocationService,
  ];
}

/**
 *
 */
export function GlobalServices() {
  return [AnalyticsService, UserService, TourService, DriverEventsService];
}
