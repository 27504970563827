import { Component, OnInit, Input } from '@angular/core';
import {
  RouteAssignmentService,
  DriverEventsService,
  DriversService,
} from '../../services';
import { forEach, cloneDeep, get } from 'lodash';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'card-status-badge',
  templateUrl: './card-status.component.html',
  styleUrls: ['./card-status.component.sass'],
})
export class CardStatusComponent implements OnInit {
  state: any = {};
  style: any = {};
  @Input() item: any = '';
  @Input() type: string = 'card';
  @Input() oldStyle: boolean = false;
  @Input() instanceType: string = 'route';
  @Input() cardId: string = '';

  private subscriptions: any = {};

  constructor(
    private assignmentService: RouteAssignmentService,
    private statusEvent: DriverEventsService,
    private driverService: DriversService
  ) {}

  ngOnInit() {
    this.getStatus();

    this.subscriptions['status'] = this.statusEvent.statusShouldUpdate
      .pipe(
        filter(item => {
          return item.cardId === this.cardId;
        })
      )
      .subscribe(item => {
        let payload = item.route ? item.route : item;

        let _payload = cloneDeep(payload);

        if (this.instanceType === 'vehicle' && _payload.vehicle) {
          payload = _payload.vehicle;
          payload.route = _payload;
          payload.driver = _payload.driver;
          payload.events = get(item, 'events');
        }

        if (this.instanceType === 'driver' && _payload.driver) {
          payload = _payload.driver;
          payload.route = _payload;
          payload.vehicle = _payload.vehicle;
          payload.events = get(item, 'events');
        }

        this.getStatus(payload);
      });
  }

  ngOnDestroy() {
    forEach(this.subscriptions, value => value.unsubscribe());
  }

  /**
   * listen when data source change
   *
   * @param changes
   */
  ngOnChanges(changes: any) {
    this.getStatus();
  }

  /**
   * Get route assigment status
   *
   * @param payload
   */
  getStatus(payload?: any) {
    if (this.instanceType === 'driver') {
      const hasPlainStatus = payload && payload.isOnline;
      this.state = hasPlainStatus
        ? this.driverService.getStatusFixturesByBoolean(payload.isOnline)
        : this.driverService.getStatusFixtures(payload);
    } else {
      this.state = this.assignmentService.checkIfIsAssigned(
        payload ? payload : this.item,
        this.instanceType
      );
      this.style = this.state.style || '';
    }
  }
}
