<div class="notifications-settings-container">
  <span class="title"> {{ title | translate }} </span>

  <div class="notifications-items">
    <div class="check-container" *ngFor="let item of items">
      <ng-container *ngIf="item.display">
        <!-- main checkbox -->
        <mat-checkbox
          (change)="checkboxUpdated($event, item)"
          [checked]="item?.active"
          [labelPosition]="'before'">
          {{ item?.label | translate }}
        </mat-checkbox>
        <!-- Select item -->
        <div class="notification-options" *ngIf="item.type === 'schedule'">
          <mat-form-field [floatLabel]="'never'">
            <mat-select
              placeholder=""
              (selectionChange)="selectionUpdated($event, item)"
              [(value)]="item.selected.value">
              <mat-option
                *ngFor="let option of item.optionsValue; let index = index"
                [value]="option">
                {{ option }} {{ item?.optionsLabel | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-container>
    </div>
  </div>
</div>
