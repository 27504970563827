<div
  class="modal fade"
  bsModal
  #activationModal="bs-modal"
  role="dialog"
  aria-hidden="true"
  [config]="config">
  <div
    class="modal-dialog activation-modal modal-lg m-t-xxl"
    style="pointer-events: visible">
    <button
      id="toast-close"
      mat-icon-button
      class="close-modal"
      (click)="hide()">
      <i class="material-icons">close</i>
    </button>

    <h1 class="title">{{ 'SIGNUP-ACTIVATION.VERIFY-ACCOUNT' | translate }}</h1>
    <p class="subtitle">{{ 'SIGNUP-ACTIVATION.WE-SEND-CODE' | translate }}</p>

    <form
      name="form"
      class="activation-form"
      [formGroup]="activationForm"
      no-validate>
      <div class="input-container">
        <input
          matInput
          formControlName="code"
          OnlyNumber="true"
          placeholder="{{ 'SIGNUP-ACTIVATION.WRITES-CODE' | translate }}" />

        <button
          mat-button
          color="primary"
          class="code-resend"
          type="button"
          (click)="getActivationCode()"
          [ngClass]="{ 'ios-botton': isIOS }">
          {{ 'SIGNUP-ACTIVATION.RE-SEND-CODE' | translate }}
        </button>
      </div>
    </form>

    <div class="activation-btn">
      <button
        [disabled]="activationForm.invalid"
        class="activate"
        (click)="activateAccount(activationForm)"
        mat-raised-button
        color="primary">
        {{ 'SIGNUP-ACTIVATION.ACTIVATE-ACCOUNT' | translate }}
      </button>
    </div>
  </div>
</div>
