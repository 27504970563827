<div
  *ngIf="loading"
  class="d-flex justify-content-center align-items-center vh-100">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="!loading" class="form-main-container">
  <div class="save-panel">
    <span class="title">Configuración de formulario</span>
    <div *appRole="['company', 'manager_writer']" class="actions">
      <button
        mat-raised-button
        [disabled]="!editMode"
        [ngClass]="{
          'cancel-action': editMode,
          'disabled-cancel-action': !editMode
        }"
        (click)="resetForm()">
        Cancelar
      </button>
      <button
        *ngIf="!editMode"
        (click)="editMode = true"
        mat-raised-button
        class="edit-action">
        Editar
      </button>
      <button
        *ngIf="editMode"
        mat-raised-button
        class="save-action"
        (click)="saveForm()">
        Guardar
      </button>
    </div>
  </div>

  <div class="form-container">
    <div class="tips-section">
      <span class="header"><mat-icon>tips_and_updates</mat-icon> Tips </span>
      <p class="tip">
        ¿Qué información quieres capturar?
        <b
          >Elige los datos que quieres ver en tu formulario y cuáles deben
          llenarse obligatoriamente.<br />
          ¿No necesitas un campo? ¡Apágalo!
        </b>
        Usarás esta herramienta todos los días, así que seremos eficientes para
        ti.<br />
        Solo escoge los campos que necesites así ahorremos tiempo para tu
        equipo.
      </p>
    </div>

    <mat-divider></mat-divider>

    <div class="destination-info-section">
      <span class="header"
        ><mat-icon>pin_drop</mat-icon> Información del destino
        <button (click)="showInfoPop('destination')" class="info-button">
          i
        </button>
      </span>
      <div class="fields-container">
        <div class="field-container">
          <div
            class="single-line-space"
            [ngClass]="{ 'disabled-text': !this.driverForm.sector.visible }">
            Sector
            <mat-slide-toggle
              [disabled]="!editMode"
              (change)="deactivateRequired($event.checked, 'sector')"
              [(ngModel)]="this.driverForm.sector.visible"></mat-slide-toggle>
          </div>
          <div class="single-line">
            <mat-checkbox
              class="mr-2"
              [disabled]="!this.driverForm.sector.visible || !editMode"
              [(ngModel)]="this.driverForm.sector.isRequired"></mat-checkbox
            >Campo obligatorio
          </div>
        </div>
        <div class="field-container">
          <div
            class="single-line-space"
            [ngClass]="{
              'disabled-text': !this.driverForm.contact_name.visible
            }">
            Nombre del contacto
            <mat-slide-toggle
              [disabled]="!editMode"
              (change)="deactivateRequired($event.checked, 'contact_name')"
              [(ngModel)]="
                this.driverForm.contact_name.visible
              "></mat-slide-toggle>
          </div>
          <div class="single-line">
            <mat-checkbox
              class="mr-2"
              [disabled]="!this.driverForm.contact_name.visible || !editMode"
              [(ngModel)]="
                this.driverForm.contact_name.isRequired
              "></mat-checkbox
            >Campo obligatorio
          </div>
        </div>
        <div class="field-container">
          <div
            class="single-line-space"
            [ngClass]="{ 'disabled-text': !this.driverForm.email.visible }">
            Correo electrónico
            <mat-slide-toggle
              [disabled]="!editMode"
              (change)="deactivateRequired($event.checked, 'email')"
              [(ngModel)]="this.driverForm.email.visible"></mat-slide-toggle>
          </div>
          <div
            class="single-line"
            [ngClass]="{ 'disabled-text': !this.driverForm.email.visible }">
            <mat-checkbox
              class="mr-2"
              [disabled]="!this.driverForm.email.visible || !editMode"
              [(ngModel)]="this.driverForm.email.isRequired">
            </mat-checkbox
            >Campo obligatorio
          </div>
        </div>
        <div class="field-container">
          <div
            class="single-line-space"
            [ngClass]="{ 'disabled-text': !this.driverForm.phone.visible }">
            Número del contacto
            <mat-slide-toggle
              [disabled]="!editMode"
              (change)="deactivateRequired($event.checked, 'phone')"
              [(ngModel)]="this.driverForm.phone.visible"></mat-slide-toggle>
          </div>
          <div
            class="single-line"
            [ngClass]="{ 'disabled-text': !this.driverForm.phone.visible }">
            <mat-checkbox
              class="mr-2"
              [disabled]="!this.driverForm.phone.visible || !editMode"
              [(ngModel)]="this.driverForm.phone.isRequired"></mat-checkbox
            >Campo obligatorio
          </div>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="gestion-status-section">
      <span class="header"
        ><mat-icon>traffic</mat-icon> Estados de gestión
        <button (click)="showInfoPop('gestion-status')" class="info-button">
          i
        </button>
      </span>
      <div class="fields-container">
        <div class="status-container">
          <div class="status green">
            {{ message1.value || '' }}
          </div>
          <div>
            <mat-form-field appearance="fill">
              <input
                [disabled]="!editMode"
                matInput
                #message1
                maxlength="20"
                [(ngModel)]="this.driverForm.statuses.completed.text" />
              <mat-hint align="end">{{ message1.value.length }} / 20</mat-hint>
            </mat-form-field>
          </div>
        </div>
        <div class="status-container">
          <div class="status yellow">
            {{ message2.value || '' }}
          </div>
          <div>
            <mat-form-field appearance="fill">
              <input
                [disabled]="!editMode"
                matInput
                #message2
                maxlength="20"
                [(ngModel)]="this.driverForm.statuses.incomplete.text" />
              <mat-hint align="end">{{ message2.value.length }} / 20</mat-hint>
            </mat-form-field>
          </div>
        </div>
        <div class="status-container">
          <div class="status red">
            {{ message3.value || '' }}
          </div>
          <div>
            <mat-form-field appearance="fill">
              <input
                [disabled]="!editMode"
                matInput
                #message3
                maxlength="20"
                [(ngModel)]="this.driverForm.statuses.rejected.text" />
              <mat-hint align="end">{{ message3.value.length }} / 20</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="tags-section">
      <div class="d-flex mb-3" style="gap: 5px">
        <span class="header"
          ><mat-icon>sell</mat-icon> Etiquetas
          <button (click)="showInfoPop('tags-section')" class="info-button">
            i
          </button></span
        >
        <button mat-raised-button class="tag-button" (click)="openSidePanel()">
          <mat-icon>redo</mat-icon>
          Ir a etiquetas
        </button>
      </div>
      <span class="label">
        Activa tus etiquetas Fleetflex, te ayudarán a clasificar y agrupar tus
        registros de manera personalizada y conveniente.
      </span>
    </div>

    <mat-divider></mat-divider>

    <div class="gestion-info-section">
      <span class="header"
        ><mat-icon>task</mat-icon> Detalle de gestión
        <button (click)="showInfoPop('gestion')" class="info-button">
          i
        </button></span
      >
      <div class="fields-container">
        <div class="field-container">
          <div
            class="single-line-space"
            [ngClass]="{ 'disabled-text': !this.driverForm.details.visible }">
            Información del pedido
            <mat-slide-toggle
              [disabled]="!editMode"
              (change)="deactivateRequired($event.checked, 'details')"
              [(ngModel)]="this.driverForm.details.visible"></mat-slide-toggle>
          </div>
          <div
            class="single-line"
            [ngClass]="{ 'disabled-text': !this.driverForm.details.visible }">
            <mat-checkbox
              class="mr-2"
              [disabled]="!this.driverForm.details.visible || !editMode"
              [(ngModel)]="this.driverForm.details.isRequired">
            </mat-checkbox
            >Campo obligatorio
          </div>
        </div>
        <div class="field-container">
          <div
            class="single-line-space"
            [ngClass]="{ 'disabled-text': !this.driverForm.referrals.visible }">
            Guías de remisión
            <mat-slide-toggle
              [disabled]="!editMode"
              (change)="deactivateRequired($event.checked, 'referrals')"
              [(ngModel)]="
                this.driverForm.referrals.visible
              "></mat-slide-toggle>
          </div>
          <div
            class="single-line"
            [ngClass]="{ 'disabled-text': !this.driverForm.referrals.visible }">
            <mat-checkbox
              class="mr-2"
              [disabled]="!this.driverForm.referrals.visible || !editMode"
              [(ngModel)]="this.driverForm.referrals.isRequired">
            </mat-checkbox
            >Campo obligatorio
          </div>
        </div>
        <div class="field-container">
          <div
            class="single-line-space"
            [ngClass]="{ 'disabled-text': !this.driverForm.photos.visible }">
            Fotos
            <mat-slide-toggle
              [disabled]="!editMode"
              (change)="deactivateRequired($event.checked, 'photos')"
              [(ngModel)]="this.driverForm.photos.visible"></mat-slide-toggle>
          </div>
          <div
            class="single-line"
            [ngClass]="{ 'disabled-text': !this.driverForm.photos.visible }">
            <mat-checkbox
              class="mr-2"
              [disabled]="!this.driverForm.photos.visible || !editMode"
              [(ngModel)]="this.driverForm.photos.isRequired">
            </mat-checkbox
            >Campo obligatorio
          </div>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="numeric-section">
      <span class="header"
        ><mat-icon>pin</mat-icon> Campos Numéricos
        <button (click)="showInfoPop('numerics')" class="info-button">
          i
        </button></span
      >
      <div class="inputs-controler">
        <div>
          <span class="font-weight-bold">Nombre del campo</span>
          <mat-form-field appearance="outline" class="w-100">
            <input
              matInput
              autocomplete="off"
              placeholder="(ej; viaticos, producto , total de km)"
              [disabled]="!editMode"
              [(ngModel)]="selectedNumericFieldName" />
          </mat-form-field>
        </div>

        <div class="position-relative">
          <span class="font-weight-bold mx-3">Tipo de unidad</span>
          <div class="pb-4">
            <div
              [hidden]="showOptions"
              class="border rounded-lg p-4 d-flex justify-content-between align-items-center"
              (click)="toggleOptions()"
              style="cursor: pointer; width: 350px"
              [ngStyle]="{
                color: !selectedUnit ? 'grey' : 'black',
                opacity: !selectedUnit ? 0.5 : 1
              }">
              <span>{{
                computedNumericSelected() || 'Click para seleccionar'
              }}</span>
              <mat-icon matSuffix>arrow_drop_down</mat-icon>
            </div>
          </div>
          <!--<input matInput autocomplete="off" placeholder="Click para seleccionar" [value]="computedNumericSelected()" disabled>
                           -->

          <div
            *ngIf="showOptions"
            class="position-absolute p-5 pt-2"
            style="
              top: 0%;
              left: 0%;
              background-color: white;
              z-index: 20;
              width: 350px;
            ">
            <app-units-modal
              (selected)="onSelectedUnit($event)"
              (clickedOutEvent)="toggleOptions()"></app-units-modal>
          </div>
        </div>
        <button
          class="h-100 blue-color"
          mat-raised-button
          [disabled]="!(selectedNumericFieldName && selectedUnit)"
          (click)="addNumericField(selectedNumericFieldName, selectedUnit)">
          + Agregar campo
        </button>
      </div>

      <div>
        <div *ngFor="let numeric of driverForm.numerics || []; let i = index">
          <div
            *ngIf="!numeric.isDeleted"
            class="d-flex align-items-center my-3"
            style="gap: 10px">
            <div
              class="d-flex justify-content-around p-2 py-4 border rounded-lg"
              style="width: 400px">
              <span>
                {{ numeric.label }}
              </span>
              <span>
                {{ numeric.metric_unit.label }}
              </span>
            </div>
            <div
              class="single-line"
              [ngClass]="{ 'disabled-text': !numeric.visible }">
              <mat-checkbox
                class="mr-2"
                [disabled]="!numeric.visible || !editMode"
                [(ngModel)]="numeric.isRequired">
              </mat-checkbox
              >Campo obligatorio
            </div>

            <button mat-icon-button (click)="showRemoveNumericModal(i)">
              <mat-icon>delete</mat-icon>
            </button>

            <mat-slide-toggle
              [disabled]="!editMode"
              (change)="deactivateRequiredNumeric($event, i, numeric)"
              [(ngModel)]="numeric.visible"></mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="receiver-section">
      <span class="header"
        ><mat-icon>person</mat-icon> Información del receptor
        <button (click)="showInfoPop('receiver')" class="info-button">
          i
        </button></span
      >
      <div class="fields-container">
        <div class="field-container">
          <div
            class="single-line-space"
            [ngClass]="{
              'disabled-text': !this.driverForm.receiver_name.visible
            }">
            Nombre del receptor
            <mat-slide-toggle
              [disabled]="!editMode"
              (change)="deactivateRequired($event.checked, 'receiver_name')"
              [(ngModel)]="
                this.driverForm.receiver_name.visible
              "></mat-slide-toggle>
          </div>
          <div
            class="single-line"
            [ngClass]="{
              'disabled-text': !this.driverForm.receiver_name.visible
            }">
            <mat-checkbox
              class="mr-2"
              [disabled]="!this.driverForm.receiver_name.visible || !editMode"
              [(ngModel)]="
                this.driverForm.receiver_name.isRequired
              "></mat-checkbox
            >Campo obligatorio
          </div>
        </div>
        <div class="field-container">
          <div
            class="single-line-space"
            [ngClass]="{
              'disabled-text': !this.driverForm.receiver_id.visible
            }">
            Cédula del receptor
            <mat-slide-toggle
              [disabled]="!editMode"
              (change)="deactivateRequired($event.checked, 'receiver_id')"
              [(ngModel)]="
                this.driverForm.receiver_id.visible
              "></mat-slide-toggle>
          </div>
          <div
            class="single-line"
            [ngClass]="{
              'disabled-text': !this.driverForm.receiver_id.visible
            }">
            <mat-checkbox
              class="mr-2"
              [disabled]="!this.driverForm.receiver_id.visible || !editMode"
              [(ngModel)]="this.driverForm.receiver_id.isRequired">
            </mat-checkbox
            >Campo obligatorio
          </div>
        </div>
        <div class="field-container">
          <div
            class="single-line-space"
            [ngClass]="{ 'disabled-text': !this.driverForm.signature.visible }">
            Firma
            <mat-slide-toggle
              [disabled]="!editMode"
              (change)="deactivateRequired($event.checked, 'signature')"
              [(ngModel)]="
                this.driverForm.signature.visible
              "></mat-slide-toggle>
          </div>
          <div
            class="single-line"
            [ngClass]="{ 'disabled-text': !this.driverForm.signature.visible }">
            <mat-checkbox
              class="mr-2"
              [disabled]="!this.driverForm.signature.visible || !editMode"
              [(ngModel)]="this.driverForm.signature.isRequired"></mat-checkbox
            >Campo obligatorio
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
