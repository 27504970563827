import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { INJECTION_TOKEN } from '../../app.config';

@Injectable()
export class UploaderRequests {
  constructor(
    @Inject(INJECTION_TOKEN) public config: any,
    private http: HttpClient
  ) {}

  /**
   * Get signed url for upload
   *
   * @param file
   * @param type
   */
  getSignedUrl(file: any, type?: string): Observable<any> {
    let data = {
      provider: this.config.upload_provider,
      bucket:
        type === 'driver'
          ? this.config.upload_drivers_bucket
          : this.config.upload_vehicles_bucket,
      file: file,
    };
    let url = this.config.upload_url_signed;
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    //console.log(url)
    console.log('Data');
    console.log(data);

    return this.http.post(url, data);
    // .pipe(
    //   map(response => {
    //     console.log("response upload")
    //     console.log(response)
    //     this.extractData
    //   }),
    // catchError(this.handleError))
  }

  /**
   * Upload file
   *
   * @param url
   * @param formData
   */
  upload(url: string, formData: any): Observable<any> {
    return this.http.post(url, formData);
  }

  /**
   * Extract response from each request
   *
   * @param res Endpoint response
   */
  private extractData(res: HttpResponse<any>) {
    let body = res.body || {};
    return body;
  }

  /**
   * Handle error if it's present on request
   *
   * @param error
   */
  private handleError(error: HttpResponse<any> | any) {
    let errMsg;
    if (error instanceof HttpResponse) {
      const body = error.body || {};
      errMsg = body;
    } else {
      errMsg = error;
    }

    return throwError(errMsg);
  }
}
