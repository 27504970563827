import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  OnInit,
  Output,
} from '@angular/core';
import { UploaderRequests } from '../../../api';
import { forEach } from 'lodash';
import { Subject } from 'rxjs';

@Component({
  selector: 'file-upload',
  styleUrls: ['./uploader.component.sass'],
  templateUrl: './uploader.component.html',
})
export class FileUploadComponent implements OnInit {
  @Input() category: string;
  @Input() multiple: boolean = false;
  @Input() labelPick: string;
  @Input() labelChange: string;
  @Input() imageType: string;
  @Input() placeholder: string = '';
  @Input() edit: boolean = true;
  @Output() fileUploaded: Subject<any> = new Subject<any>();
  @ViewChild('fileInput') inputEl: ElementRef;

  urlSigned: any = {};
  imagePreview: string = '';

  constructor(private api: UploaderRequests) {}

  ngOnInit() {
    if (!this.placeholder)
      this.placeholder = './assets/img/license-placeholder.png';

    if (localStorage.getItem(this.imageType + 'Picture'))
      this.imagePreview = localStorage.getItem(this.imageType + 'Picture');
  }

  getUrlSigned(fileName, formData) {
    return this.api.getSignedUrl(fileName).subscribe(data => {
      return (this.urlSigned = data.data);
    });
  }

  upload($event) {
    let fileList: FileList = $event.target.files;

    return new Promise((resolve, reject) => {
      if (fileList.length > 0) {
        this.getImage(fileList[0]).then(file => (this.imagePreview = file.url));

        let file = fileList[0];
        let fileName = $event.target.value.split('\\').pop();

        this.api
          .getSignedUrl(fileName, this.category)
          .toPromise()
          .then(data => {
            this.urlSigned = data.data;
            let formData: FormData = new FormData();
            forEach(this.urlSigned.urlSigned, (value, key) => {
              if (key !== 'url') formData.append(key, value);
            });

            formData.append('file', file);
            // this.imagePreview = this.urlSigned.url+'/'+this.urlSigned.file_name;
            localStorage.setItem(
              this.imageType + 'Picture',
              this.urlSigned.url + '/' + this.urlSigned.file_name
            );
            resolve(this.urlSigned.url + '/' + this.urlSigned.file_name);

            return this.api.upload(this.urlSigned.url, formData).toPromise();
          })
          .then(upload => {
            this.fileUploaded.next(
              this.urlSigned.url + '/' + this.urlSigned.file_name
            );
            resolve(this.urlSigned.url + '/' + this.urlSigned.file_name);
            this.updatePicture();
          })
          .catch(error => console.log('error uploading', error));
      } else resolve([]);
    });
  }

  updatePicture() {
    this.imagePreview = localStorage.getItem(this.imageType + 'Picture');
  }

  /**
   * Get base64 image from an input file
   *
   * @param file
   */
  private getImage(file: File): Promise<any> {
    let reader: FileReader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = (e: any) => {
        resolve({ url: e.target.result, data: file });
      };

      reader.onerror = () => {
        return reject(this);
      };

      if (file.type.indexOf('image') !== -1) reader.readAsDataURL(file);
      else reject(file);
    });
  }
}
