import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';

@Component({
  selector: 'app-report-share-modal',
  templateUrl: './report-share-modal.component.html',
  styleUrls: ['./report-share-modal.component.sass'],
})
export class ReportShareModalComponent implements OnInit {
  message = 'Are you sure?';
  confirmButtonText = 'Yes';
  cancelButtonText = 'Cancel';

  showOnBoarding;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ReportShareModalComponent>
  ) {
    if (data) {
      this.message = data.message || this.message;
    }
  }
  ngOnInit(): void {}

  onConfirmClick(type): void {
    this.dialogRef.close(type);
  }
  close(value) {
    this.dialogRef.close(value);
  }
}
