import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();

  //initialize hotjar on production env only
  initializeHotjar();

  //initialize google tag manager on production env only
  initializeGooleTagManager();

  //initialize tawk chat on production env
  // initializeTawkChat();
}

platformBrowserDynamic().bootstrapModule(AppModule);

/**
 * Initialize Google Tag Manager
 */
function initializeGooleTagManager() {
  (function (w: any, d: any, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', environment['gtm']);

  let _noscript = document.createElement('noscript');
  _noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${environment['gtm']}"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
  document.querySelector('body').appendChild(_noscript);
}

/**
 * Initialize Hotjar
 */
function initializeHotjar() {
  (function (h: any, o: any, t: any, j: any, a, r) {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: 787610, hjsv: 6 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
}

/**
 * Initialize tawk
 */
function initializeTawkChat() {
  var Tawk_API = Tawk_API || {},
    Tawk_LoadStart = new Date();
  (function () {
    var s1 = document.createElement('script'),
      s0 = document.getElementsByTagName('script')[0];
    s1.async = true;
    s1.src = environment['tawk'];
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);
  })();
}
