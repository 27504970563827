import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';

@Component({
  selector: 'activation-success',
  templateUrl: './activation-success.component.html',
  styleUrls: ['./activation-success.component.sass'],
})
export class ActivationSuccessComponent implements OnInit {
  @ViewChild('activationSuccess') activationSuccess: ModalDirective;

  constructor(private router: Router) {}

  config = {
    backdrop: true,
    class: 'success-modal',
    ignoreBackdropClick: true,
  };

  ngOnInit() {}

  /**
   * Show activation Modal
   */
  show() {
    this.activationSuccess.show();
  }

  /**
   * hide activation modal
   */
  hide() {
    this.activationSuccess.hide();
  }

  /**
   * Continue with profile edition
   */
  continueWithProfile() {
    this.hide();
    this.router.navigate(['/profile']);
  }
}
