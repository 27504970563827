<div>
  <div class="d-flex justify-content-between border-bottom pb-2">
    <div style="visibility: hidden"></div>
    <div class="title">{{ 'SELECCIONA EL COLOR' | translate }}</div>
    <i class="material-icons action_icon" (click)="closeModal()"> close </i>
  </div>
  <div>
    <!--
        <div class="border rounded-lg d-flex justify-content-between p-5" style="gap: 10px">
            <color-circle
                    [colors]="colorsT"
                    class="d-flex flex-column"
                    width="10px"
                    style="max-width: 350px;"
                    circleSpacing="16"
            ></color-circle>


        </div>

        -->
    <div
      class="border rounded-lg d-flex justify-content-between align-content-center p-5"
      style="gap: 15px">
      <div
        *ngFor="let colorArray of colors"
        class="d-flex flex-column"
        style="gap: 15px">
        <div
          *ngFor="let color of colorArray"
          class="circle text-center align-items-baseline"
          [ngStyle]="{
            cursor: 'pointer',
            'background-color':
              selectedColor?.toLowerCase() == color.toLowerCase()
                ? 'white'
                : color,
            'border-color': color,
            'border-style':
              selectedColor?.toLowerCase() == color.toLowerCase()
                ? 'solid'
                : 'none'
          }"
          [ngClass]="{
            circle_active: selectedColor?.toLowerCase() == color.toLowerCase()
          }"
          (click)="onSelectColor(color)">
          <span>{{
            disabledColors.includes(color.toLowerCase()) ? 'x' : ''
          }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
