<div class="login-container">
  <mat-card appearance="outlined">
    <h1 class="title">{{ 'LOGIN.TITLE' | translate }}</h1>
    <p class="subtitle">{{ 'LOGIN.SUBTITLE' | translate }}</p>

    <form
      name="form"
      class="login-form"
      [formGroup]="loginForm"
      (ngSubmit)="login(loginForm)">
      <!-- EMAIL -->
      <mat-form-field class="w-20 m-t-xs">
        <input
          matInput
          type="email"
          placeholder="{{ 'COMMON.EMAIL' | translate }}"
          name="email"
          formControlName="username" />
        <mat-error
          *ngIf="
            !loginForm.controls.username.valid &&
            loginForm.controls.username.touched
          ">
          {{ 'LOGIN.MESSAGES.REQUIRED-EMAIL' | translate }}
        </mat-error>
      </mat-form-field>
      <!--PASSWORD -->
      <mat-form-field class="w-20 m-t-xs">
        <input
          matInput
          type="password"
          placeholder="{{ 'COMMON.PASSWORD' | translate }}"
          name="password"
          formControlName="password" />
        <mat-error
          *ngIf="
            !loginForm.controls.password.valid &&
            loginForm.controls.password.touched
          ">
          {{ 'LOGIN.MESSAGES.REQUIRED-PASSWORD' | translate }}
        </mat-error>
      </mat-form-field>
      <!--FORGOT PASSWORD -->
      <div class="text-right">
        <a [routerLink]="['/forgot-password']" class="forgot-link">
          {{ 'LOGIN.FORGOT' | translate }}
        </a>
      </div>
      <div
        class="login-btn"
        [ngClass]="{ 'justify-content-center': isManagerDomain }">
        <button
          *ngIf="!isManagerDomain"
          [routerLink]="['/signup']"
          type="button"
          mat-raised-button
          color="primary">
          {{ 'LOGIN.NO-ACCOUNT' | translate }}
        </button>
        <button
          [disabled]="loginForm.invalid"
          type="submit"
          mat-raised-button
          color="primary">
          {{ 'LOGIN.LOGIN' | translate }}
        </button>
      </div>
    </form>
  </mat-card>
</div>
