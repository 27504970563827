import { Injectable, Inject } from '@angular/core';
import { NewHttpClient } from '../interceptor.service';
import { Observable } from 'rxjs';
import { INJECTION_TOKEN } from '../../app.config';

@Injectable()
export class DeliveryPointsRequests {
  constructor(
    @Inject(INJECTION_TOKEN) public config: any,
    private http: NewHttpClient
  ) {}

  /**
   * Create delivery point
   *
   * @param data delivery point
   * @returns boolean
   */
  create(data: any): Observable<any> {
    let url = `${this.config.delivery_points_url}`;
    return this.http.post(url, data);
  }

  /**
   * Get delivery points list
   *
   * @param data keys
   * @returns delivery points
   */
  get(data?: any): Observable<any> {
    let params = '';

    if (data) {
      let keys = Object.keys(data);
      keys.forEach(key => {
        if (!params.length) params = `?${key}=${data[key]}`;
        else params += `&${key}=${data[key]}`;
      });
    }

    let url = `${this.config.delivery_points_url}/${params}`;
    return this.http.get(url);
  }

  /**
   * Get one delivery point
   *
   * @param id string
   * @param addDeleted boolean
   * @returns delivery point
   */
  getOne(id: string, addDeleted: boolean = false): Observable<any> {
    let url = `${this.config.delivery_points_url}/${id}?addDeleted=${addDeleted}`;
    return this.http.get(url);
  }

  /**
   * Update delivery point
   *
   * @param id string
   * @param data delivery point
   * @returns boolean
   */
  update(id: string, data: any): Observable<any> {
    let url = `${this.config.delivery_points_url}/${id}`;
    return this.http.put(url, data);
  }

  /**
   * Update task
   *
   * @param data task data
   * @returns  boolean
   */
  updateTask(data: any): Observable<any> {
    let url = `${this.config.update_task}`;
    return this.http.post(url, data);
  }

  /**
   * delete point
   *
   * @param id string
   * @returns boolean
   */
  deletePoint(id: string): Observable<any> {
    let url = `${this.config.delivery_points_url}/${id}`;
    return this.http.delete(url);
  }

  /**
   * get traking data
   *
   * @param id  string
   * @returns traking
   */
  tracking(id: string): Observable<any> {
    let url = `${this.config.tracking_url}${id}`;
    return this.http.get(url);
  }

  /**
   * Export destinations
   *
   * @param data  delivery point
   * @returns boolean
   */
  export(data: any): Observable<any> {
    let url = `${this.config.delivery_points_export}`;
    return this.http.post2(url, data);
  }

  /**
   * Calculate duplicated delivery points
   *
   * @returns duplicated
   */
  calculateDuplicated(): Observable<any> {
    let url = `${this.config.delivery_points_url}/calculate-duplicated`;
    return this.http.post(url, {});
  }

  /**
   * get duplicates delivery points
   *
   * @returns delivery points
   */
  getDuplicateds(): Observable<any> {
    let url = `${this.config.delivery_points_url}/duplicateds`;
    return this.http.get(url);
  }

  /**
   * get duplicate delivery point
   *
   * @param id string
   * @returns all duplicateds
   */
  getDuplicatedbyId(id: string): Observable<any> {
    let url = `${this.config.delivery_points_url}/duplicateds?deliveryPointId=${id}&verbose=true`;
    return this.http.get(url);
  }

  /**
   * Merge duplicated
   *
   * @param data object overrided
   * @returns boolean
   */
  mergeDuplicated(data: any): Observable<any> {
    let url = `${this.config.delivery_points_url}/mergeDeliveryPoints`;
    return this.http.post(url, data);
  }
}
