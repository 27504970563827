import {
  Component,
  ElementRef,
  OnInit,
  Input,
  Output,
  EventEmitter,
  forwardRef,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';
import { MatFormField } from '@angular/material/form-field';

@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimepickerComponent),
      multi: true,
    },
  ],
  host: {
    '(document:mousedown)': 'onClick($event)',
  },
})
export class TimepickerComponent implements OnInit {
  @ViewChild('inputField') inputField: MatFormField;

  showTimepicker: boolean = false;
  @Input() time: Date = void 0;
  @Input() placeholder: string = 'hh:mm A';
  @Output() timeChange: EventEmitter<any> = new EventEmitter();
  @Input() formatDate: string = 'hh:mm A';
  @Input() minTime: Date = void 0;
  @Input() preInit: Boolean = false;
  @Input() floatingPlaholder: Boolean = false;
  @Input() furtherMinutes: any = 10;
  @Input() isDisabled: boolean = false;
  @Input() appearance: string = 'standard';

  private _time: Date = void 0;
  propagateChange: any = () => {};
  propageteTouch: any = () => {};
  validateFn: any = () => {};

  @Input()
  get model() {
    return this.time;
  }
  @Output() modelChange = new EventEmitter<any>();
  set model(val: any) {
    this.time = val;
    this.modelChange.emit(this.time);
  }

  constructor(private _elementRef: ElementRef) {}

  ngOnInit() {
    if (this.preInit) {
      this.time = this.time ? this.time : this.roundDate(moment(), 5).toDate();
      this._time = this.time;
      setTimeout(() => {
        this.propagateChange(this.time);
        this.propageteTouch(this.time);
      }, 100);
    } else {
      this._time = this.roundDate(moment(), 5).toDate();
    }
  }

  displayTimePicker() {
    if (this.isDisabled) return;

    this.showTimepicker = !this.showTimepicker;
  }

  onClick(event: any) {
    if (!this._elementRef.nativeElement.contains(event.target))
      this.closeTimepicker();
  }

  writeValue(value) {}

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.propageteTouch = fn;
  }

  closeTimepicker(): void {
    this.showTimepicker = false;
  }

  /**
   * Detect changes
   *
   * @param time
   */
  valueChange(time) {
    this._time = time;
    this.model = time;
    this.propagateChange(time ? time : '');
    this.propageteTouch(time ? time : '');
  }

  /**
   * Close and save date
   */
  done() {
    this.model = this.time;
    this.propagateChange(this.time);
    this.propageteTouch(this.time);
  }

  /**
   * Close picker
   */
  triggerClose() {
    this.done();
    this.closeTimepicker();
  }

  /**
   * round dates
   *
   * @param date
   * @param round
   */
  private roundDate(date: any, round: number) {
    let rounding = 1000 * 60 * round;
    let rounded = new Date(
      Math.round(date.toDate().getTime() / rounding) * rounding
    );

    return moment(rounded).add(this.furtherMinutes, 'minutes');
  }
}
