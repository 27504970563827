<!-- popover content begin -->
<div
  class="popover-content"
  mat-menu-item
  [disableRipple]="true"
  (click)="$event.stopPropagation()">
  <div>
    <p class="popover-guide" *ngFor="let guide of guides">
      {{ guide }}
    </p>
  </div>
  <div
    class="icon-copy-content"
    trigger="click"
    (click)="copyToClipboard(guides)">
    <div class="icon-copy-button">
      <mat-icon
        style="
          padding-top: 3px;
          padding-left: 8px;
          margin-right: 3px;
          color: white;
          font-size: 15px;
        "
        >content_copy</mat-icon
      >
      <p
        style="
          margin-top: 4px;
          margin-bottom: 1px;
          color: white;
          margin-right: 10px;
          font-size: 12px;
        ">
        Copiar
      </p>
    </div>
  </div>
</div>
<!-- popover content end  -->
