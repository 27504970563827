import { Component, OnInit, Output, Input } from '@angular/core';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Subject } from 'rxjs';

const moment = extendMoment(Moment);

declare const $;

@Component({
  selector: 'range-time-picker',
  templateUrl: './range-time-picker.component.html',
  styleUrls: ['./range-time-picker.component.sass'],
})
export class RangeTimePickerComponent implements OnInit {
  @Input() outputFormat: string = '';
  @Output() onRange: Subject<any> = new Subject<any>();

  timeFormat: string = 'hh:mm A';
  sinceValue: any;
  untilValue: any;
  pickerError: boolean = false;
  opened: boolean = false;

  private rangeOptions: Array<any> = [];
  private untilPicker: any;
  private sincePicker: any;

  private timePickerOptions: any = {
    showDuration: false,
    timeFormat: 'g:i A',
    showOn: 'focus',
    className: 'picker-holder',
    step: 30,
  };

  constructor() {}

  ngOnInit() {
    this.initializeDefaultimepickers();
  }

  ngAfterViewInit() {
    this.setValues();
  }

  showPicker(type) {
    type === 'since'
      ? this.sincePicker.timepicker('show')
      : this.untilPicker.timepicker('show');
    this.opened = true;
  }

  /**
   * Initialize array time options
   */
  private initializeRanges() {
    let start = moment().startOf('day');
    let end = moment().endOf('day');
    let range = moment.range(start, end);

    let items: any = Array.from(
      range.by('hour', { excludeEnd: false, step: 1 })
    );

    items = items.map(date => date.format(this.timeFormat));

    return items;
  }

  /**
   * Initialize picker options
   */
  private initializeDefaultimepickers() {
    this.rangeOptions = this.initializeRanges();
    let _endHour = moment().endOf('day').format('HH:mm');

    // initialize pickers
    this.sincePicker = $('#sinceInput').timepicker(this.timePickerOptions);
    this.untilPicker = $('#untilInput').timepicker({
      ...this.timePickerOptions,
      maxTime: _endHour,
    });

    let startTime = this.rangeOptions.find(e => e === '08:00 AM');
    let endTime = this.rangeOptions.find(e => e === '06:00 PM');

    // set min value to until picker
    this.getSelectionValue(startTime, 'since');

    // set change listener
    this.sincePicker.on('changeTime', () =>
      this.getSelectionValue(this.sincePicker.val(), 'since')
    );
    this.untilPicker.on('changeTime', () =>
      this.getSelectionValue(this.untilPicker.val(), 'until')
    );

    // set error listeners
    this.sincePicker.on('timeFormatError', () => this.setPickerError());
    this.sincePicker.on('timeRangeError', () => this.setPickerError());
    this.untilPicker.on('timeFormatError', () => this.setPickerError());
    this.untilPicker.on('timeRangeError', () => this.setPickerError());

    // set close listener
    this.sincePicker.on('hideTimepicker', () => this.closePicker());
    this.untilPicker.on('hideTimepicker', () => this.closePicker());

    // set default times
    this.sincePicker.timepicker('setTime', startTime);
    this.untilPicker.timepicker('setTime', endTime);
  }

  /**
   * Update on selection
   *
   * @param value
   * @param type
   */
  private getSelectionValue(value: any, type: any) {
    this.pickerError = false;

    // Uncoment this to change until picker time at since picker change
    /* if(type === 'since') {
      this.untilPicker.timepicker('option', 'minTime', value);
      this.untilPicker.timepicker('setTime', moment(value, this.timeFormat).add(30, 'minutes').format(this.timeFormat) );
    } */

    this.setValues();
  }

  /**
   * Set picker error
   */
  private setPickerError() {
    this.pickerError = true;
  }

  /**
   * Set picker as closed
   */
  private closePicker() {
    this.opened = false;
  }

  /**
   * Set selection values and emit result
   */
  private setValues() {
    this.sinceValue = this.outputFormat
      ? moment(this.sincePicker.val(), this.timeFormat).format(
          this.outputFormat
        )
      : this.sincePicker.val();

    this.untilValue = this.outputFormat
      ? moment(this.untilPicker.val(), this.timeFormat).format(
          this.outputFormat
        )
      : this.untilPicker.val();

    this.onRange.next({
      since: this.sinceValue,
      until: this.untilValue,
    });
  }
}
