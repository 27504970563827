<div
  class="header-container container-fluid"
  [ngClass]="{ 'header-responsive': headerClass === 'down' }">
  <div class="header-tabs-search" *ngIf="!tracking">
    <div
      class="header"
      [ngClass]="{
        'header-init': !loggedIn || tracking
      }">
      <div
        class="header-logo-container"
        [ngClass]="{
          'with-background': withBackground,
          loaded: loggedIn && !tracking && withBackground
        }">
        <img
          class="logo"
          src="/assets/img/fleetflex_tracklink.png"
          [routerLink]="['/', 'dashboard']"
          style="cursor: pointer"
          *ngIf="loggedIn" />
        <img
          class="logo"
          src="/assets/img/fleetflex_tracklink.png"
          *ngIf="!loggedIn" />
      </div>
      <nav
        mat-tab-nav-bar
        class="custom-tabs"
        disableRipple="true"
        *ngIf="loggedIn && !tracking">
        <a
          mat-tab-link
          [routerLink]="['/', 'dashboard']"
          routerLinkActive="active"
          (mouseenter)="hover(1)"
          (mouseleave)="hoverleave()"
          disableRipple="true"
          (click)="sendAnalytics(1)"
          [active]="
            hovered === 0
              ? router.url === '/' || router.url.startsWith('/dashboard')
              : hovered === 1
          "
          id="dashboard-menu"
          routerLinkActive>
          {{ 'TABS.DASHBOARD' | translate }}
        </a>
        <a
          mat-tab-link
          *appRole="['company', 'manager_writer']"
          [routerLink]="['/', 'directory']"
          routerLinkActive="active"
          (mouseenter)="hover(2)"
          (mouseleave)="hoverleave()"
          disableRipple="true"
          [active]="
            hovered === 0 ? router.url.startsWith('/directory') : hovered === 2
          "
          id="library-menu"
          routerLinkActive>
          {{ 'TABS.LIBRARY' | translate }}
        </a>

        <!-- <a mat-tab-link
          [routerLink]="['/', 'routes']"
          routerLinkActive="active"
          (mouseenter)="hover(3)"
          (mouseleave)="hoverleave()"
          disableRipple="true"
          id="routes-menu"
          [active]="hovered == 0 ? router.url.startsWith('/routes'): hovered == 3"
          routerLinkActive>
         {{ "TABS.ROUTES" | translate }}
      </a> -->
        <a
          mat-tab-link
          [routerLink]="['/', 'team']"
          routerLinkActive="active"
          (mouseenter)="hover(4)"
          (mouseleave)="hoverleave()"
          id="driver-menu"
          disableRipple="true"
          [active]="
            hovered === 0 ? router.url.startsWith('/team') : hovered === 4
          "
          routerLinkActive>
          {{ 'TABS.TEAM' | translate }}
        </a>
        <!-- <a mat-tab-link
        <a [disabled]="!userActive" mat-tab-link
        <a mat-tab-link
           [routerLink]="['/', 'drivers']"
           routerLinkActive="active"
           (mouseenter)="hover(4)"
           (mouseleave)="hoverleave()"
           id="driver-menu"
           disableRipple="true" [active]="hovered == 0 ? router.url.startsWith('/drivers'): hovered == 4" routerLinkActive>
          {{ "TABS.DRIVERS" | translate }}

        </a> -->
        <!--        <a mat-tab-link-->
        <!--           [routerLink]="['/', 'vehicles']"-->
        <!--           routerLinkActive="active"-->
        <!--           (mouseenter)="hover(5)"-->
        <!--           (mouseleave)="hoverleave()"-->
        <!--           disableRipple="true"-->
        <!--           id="vehicles-menu"-->
        <!--           [active]="hovered == 0 ? router.url.startsWith('/vehicles'): hovered == 5"-->
        <!--           routerLinkActive>-->
        <!--          {{ "TABS.VEHICLES" | translate }}-->
        <!--        </a>-->

        <a
          mat-tab-link
          [routerLink]="['/', 'reports']"
          routerLinkActive="active"
          (mouseenter)="hover(6)"
          (mouseleave)="hoverleave()"
          disableRipple="true"
          [active]="
            hovered === 0 ? router.url.startsWith('/reports') : hovered === 6
          "
          routerLinkActive>
          {{ 'TABS.REPORT' | translate }}
        </a>
      </nav>
      <div class="user" *ngIf="loggedIn && !tracking">
        <!-- <div class="search-container-extra">
          <mat-form-field>
            <input matInput>
          </mat-form-field>
          <i class="material-icons aligned-search">search</i>
        </div> -->

        <div class="control-items">
          <div *appRole="['company', 'manager_writer']">
            <inactive-account-check
              style="z-index: 100"
              *ngIf="!userActive"
              id="last-tour-step"
              (activated)="userActivated()"
              [userInfo]="userService.user">
            </inactive-account-check>
          </div>

          <notification-badge
            *appRole="['company', 'manager_writer']"
            (open)="openSidePanel($event)">
          </notification-badge>

          <button
            mat-icon-button
            class="menu-button"
            id="sidepanelopen"
            (click)="openSidePanel('menu')"
            [class.selected]="isToggleMenuIcon">
            <i class="material-icons"> dehaze </i>
          </button>
        </div>
      </div>
    </div>

    <!--
        <div class="card-autocomplete-container search-header" [ngClass]="{
           'inactive-search': !userActive
          }">
          <form class="header-search">
            <mat-form-field class="form-field">
              <input matInput [matAutocomplete]="auto" [formControl]="itemsCtrl">
              <mat-icon matSuffix class="white-icon">search</mat-icon>
              <div class="autocomplete-container">
                <i *ngIf="itemsSelected.length" class="material-icons pointer arrow-up-icon">arrow_drop_up</i>
                <mat-autocomplete #auto="matAutocomplete" class="autocomplete-header" >
                  <div class="autocomplete-panel" [ngClass]="{'search-results-container' : itemsSelected.length > 1}"
                  infiniteScroll
                  [infiniteScrollDistance]="scrollDistance"
                  [infiniteScrollThrottle]="throttle"
                  [scrollWindow]="false"
                  (scrolled)="onScrollDown($event)">
                    <div>
                      <span class="text-title m-l-xs" *ngIf="itemsType !== 'vehicles' && itemsType !== 'routes' ">{{ "TABS.DRIVERS" | translate }}</span>
                      <span class="text-title m-l-xs" *ngIf="itemsType === 'vehicles' ">{{ "TABS.VEHICLES" | translate }}</span>
                      <span class="text-title m-l-xs" *ngIf="itemsType === 'routes' ">{{ "TABS.ROUTES" | translate }}</span>
                      <span class="text-title"> ( {{ itemsSelected.length }} )</span>
                    </div>
                    <mat-option *ngFor="let item of itemsSelected" [value]="item?.firstName || item?.model || item?.label" class="autocomplete-item" (onSelectionChange)="onOptionSelection(item._id)">
                      &lt;!&ndash; Items view &ndash;&gt;
                      <card-item-driver *ngIf="itemsType !== 'vehicles' && itemsType !== 'routes' " [driverContent]="item">
                      </card-item-driver>

                      <card-item-vehicle *ngIf="itemsType === 'vehicles'" [vehicleContent]="item">
                      </card-item-vehicle>

                      <card-item-route *ngIf="itemsType === 'routes'" [isAutocomplete]="true" [content]="item">
                      </card-item-route>

                    </mat-option>
                  </div>
                </mat-autocomplete>
              </div>
            </mat-form-field>
          </form>
        </div>
    -->
  </div>
</div>
