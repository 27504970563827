import {
  Component,
  OnInit,
  ElementRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-custom-datepicker',
  templateUrl: './custom-datepicker.component.html',
  styleUrls: ['./custom-datepicker.component.sass'],
  host: {
    '(document:mousedown)': 'onClick($event)',
  },
})
export class CustomDatepickerComponent implements OnInit {
  @Input() dateSelected: Date = void 0;
  @Output() dateChange: EventEmitter<any> = new EventEmitter();

  date: any = moment();
  selectedDate: any = {};
  format: string = 'DD/MM/YYYY';
  dateOptions: any = {
    startingDay: 1,
  };
  allDates: boolean = true;
  opened: boolean = false;

  constructor(private _elementRef: ElementRef) {}

  ngOnInit() {}

  isToday(): Boolean {
    return moment(this.date).isSame(moment(), 'day');
  }

  OnValueChange(event): void {
    this.selectedDateValue(event, false);
  }

  selectedDateValue(date?: any, all?: boolean): void {
    if (all) {
      this.allDates = true;
      this.selectedDate = moment();
      this.date = moment();
    } else {
      this.allDates = false;
      this.selectedDate = moment(date);
    }
    this.dateChange.emit({
      date: this.selectedDate,
      allDates: this.allDates,
    });
    this.closeDatePicker();
  }

  onClick(event: any) {
    if (!this._elementRef.nativeElement.contains(event.target))
      this.closeDatePicker();
  }

  closeDatePicker(): void {
    this.opened = false;
  }
}
