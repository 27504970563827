import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { LoadingInterceptor } from './shared/interceptors/loading.interceptor';

//import { NgxDropzoneModule } from 'ngx-dropzone';
//import { NgxFileDragDropModule } from 'ngx-file-drag-drop';

// Forms
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
// Translate
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {
  AuthGuard,
  AuthGuardAdmin,
  AnonymousGuard,
  NotAuthGuard,
} from './app.auth.guard';

import {
  AgmCoreModule,
  GoogleMapsAPIWrapper,
  LAZY_MAPS_API_CONFIG,
} from '@amin-karimi/agm-core';

import { LoginComponent } from './login/login.component';

import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './forgot-password/reset-password/reset-password.component';
import { MapsConfig, RavenErrorHandler, GlobalServices } from './shared';
import { HeaderService } from './shared/components/header/header.service';
import { SignupComponent } from './signup/signup.component';
import { SignupActivationComponent } from './signup/signup-activation/signup-activation.component';
import { ActivateByPasswordComponent } from './activate-by-password/activate-by-password.component';
// others
import { ToastrModule } from 'ngx-toastr';

import * as firebase from 'firebase';

// Shared modules
import { SharedsModule } from './shared/shared.module';
import { ToastrComponent } from './shared/components';

// all requests interceptor
import { NewHttpClient, ApiRequestsService, ApiDeclarations } from './api';

// Enviroment config
import { INJECTION_TOKEN } from './app.config';
import { environment } from '../environments/environment';

// Mixpanel and GA
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2Mixpanel } from 'angulartics2/mixpanel';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { SignupSuccessComponent } from './signup/signup-success/signup-success.component';

// Device detector
import { DeviceDetectorModule } from 'ngx-device-detector';
import { FaqComponent } from './faq/faq.component';

// Sweet Alert Modals
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { DragScrollModule } from 'ngx-drag-scroll';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { PasswordUpdatedComponent } from './password-updated/password-updated.component';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { ManagerComponent } from './manager/manager.component';
import { FormConfigComponent } from './form-config/form-config.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PendingChangesGuard } from './shared/guards/pending-changes.guard';

/**
 *
 * @param http
 */
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const toastrOptions: any = {
  timeOut: 5000,
  positionClass: 'toast-top-right',
  preventDuplicates: true,
  closeButton: true,
  autoDismiss: true,
  newestOnTop: true,
  iconClasses: {
    error: 'error-toast',
    info: 'info-toast',
    warning: 'warning-toast',
    success: 'success-toast',
  },
  toastComponent: ToastrComponent,
};
// init firebase
firebase.initializeApp(environment.firebase);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SignupComponent,
    SignupActivationComponent,
    SignupSuccessComponent,
    ToastrComponent,
    FaqComponent,
    ChangePasswordComponent,
    PasswordUpdatedComponent,
    ActivateByPasswordComponent,
    ManagerComponent,
    FormConfigComponent,
  ],
  imports: [
    DragScrollModule,
    BrowserModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    AgmCoreModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot(toastrOptions),
    Angulartics2Module.forRoot(),
    DeviceDetectorModule.forRoot(),
    SweetAlert2Module.forRoot(),
    PortalModule,
    OverlayModule,
    MatDividerModule,
    MatSlideToggleModule,
  ],
  providers: [
    { provide: INJECTION_TOKEN, useValue: environment },
    { provide: LAZY_MAPS_API_CONFIG, useClass: MapsConfig },
    { provide: ErrorHandler, useClass: RavenErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    NewHttpClient,
    ApiRequestsService,
    ApiDeclarations(),
    AuthGuard,
    AuthGuardAdmin,
    AnonymousGuard,
    NotAuthGuard,
    GoogleMapsAPIWrapper,
    GlobalServices(),
    HeaderService,
    Angulartics2GoogleAnalytics,
    Angulartics2Mixpanel,
    PendingChangesGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
