<div class="card-item">
  <div class="card-avatar" (click)="goToDetails()">
    <img
      mat-card-avatar
      [src]="
        vehicleContent?.vehicleImage
          ? vehicleContent?.vehicleImage
          : picturePlaceholder
      " />
  </div>

  <div class="card-item-details" (click)="goToDetails()">
    <p class="card-item-title">
      {{ vehicleContent?.brand }} {{ vehicleContent?.model }}
    </p>
    <p class="card-item-subtitle">
      {{ 'CARD-ASSIGNMENT.LIST.PLATE' | translate }}:
      {{ vehicleContent?.plate }}
    </p>
  </div>
</div>
