<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content> {{ message }} </mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">
    {{ 'COMMON.NO' | translate }}
  </button>
  <button mat-button color="warn" [mat-dialog-close]="true">
    {{ 'COMMON.YES' | translate }}
  </button>
</mat-dialog-actions>
