import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-unit-creation-modal',
  templateUrl: './unit-creation-modal.component.html',
  styleUrls: ['./unit-creation-modal.component.sass'],
})
export class UnitCreationModalComponent implements OnInit {
  unit_label = '';
  unit_unit = '';
  unit_types = [
    { type: 'float', label: 'Cantidad decinal' },
    { type: 'integer', label: 'Cantidad entera' },
  ];

  unit_type_selected = '';
  constructor(private dialogRef: MatDialogRef<UnitCreationModalComponent>) {}

  ngOnInit(): void {}

  allowSave() {
    return this.unit_type_selected && this.unit_label;
  }

  save() {
    this.dialogRef.close({
      label: this.unit_label,
      type: this.unit_type_selected,
      unit: this.unit_unit || '',
    });
  }
  cancel() {
    this.dialogRef.close();
  }
}
