import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard
  implements CanDeactivate<ComponentCanDeactivate>
{
  canDeactivate(
    component: ComponentCanDeactivate
  ): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first
    return component.canDeactivate()
      ? true
      : // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
        // when navigating away from your angular app, the browser will show a generic warning message
        // see http://stackoverflow.com/a/42207299/7307355
        //confirm('WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.');
        this.showCancelChangesModal();
  }

  showCancelChangesModal() {
    return new Observable<boolean>(subscribe => {
      Swal.fire({
        width: 500,
        title:
          '<br><p style="text-align: center;font-size: larger;color: black"><strong>¿Deseas salir de la configuración de formulario?</strong></p>',
        html: '<br><p style="text-align: center;font-size: medium;color: black"><span>Al no guardar los cambios, estos se perderán</span></p>',
        confirmButtonColor: '#1C98F8',
        confirmButtonText:
          '<div style="font-size: 20px; padding: 0 5px">Si</div>',
        denyButtonColor: '#1C98F8',
        denyButtonText: '<div style="font-size: 20px; padding: 0 5px">No</div>',
        showDenyButton: true,
      })
        .then(result => {
          subscribe.next(result.value);
        })
        .catch(e => {
          subscribe.next(true);
        })
        .finally(() => {
          subscribe.complete();
        });
    });
  }
}
