import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';

@Injectable()
export class DriverEventsService {
  driverEvent: ReplaySubject<any> = new ReplaySubject<any>(0);
  statusShouldUpdate: ReplaySubject<any> = new ReplaySubject<any>(1);

  driverFocus: Subject<any> = new Subject<any>();

  private eventsPool: any = {};
  private subscriptions: any = {};

  constructor() {}

  /**
   * Notify to nodes
   *
   * @param driverId
   * @param lastEvent
   */
  setEventToDriver(driverId: string, lastEvent: any) {
    this.eventsPool[driverId] = lastEvent || {};
    if (!this.subscriptions[driverId]) return;
    this.subscriptions[driverId].next({
      owner: driverId,
      lastEvent: this.eventsPool[driverId],
    });
  }

  /**
   * Subscribe to node
   *
   * @param driverId
   */
  subscribeToEventPool(driverId: string): ReplaySubject<any> {
    if (this.subscriptions[driverId]) return this.subscriptions[driverId];

    this.subscriptions[driverId] = new ReplaySubject<any>(0, 100);
    setTimeout(() => {
      this.subscriptions[driverId].next({
        owner: driverId,
        lastEvent: this.eventsPool[driverId],
      });
    }, 10);

    return this.subscriptions[driverId];
  }
}
