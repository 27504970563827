<div class="forgot-container">
  <mat-card appearance="outlined">
    <button [routerLink]="['/login']" type="submit" mat-icon-button>
      <mat-icon aria-label="icon">arrow_back</mat-icon>
    </button>
    <h1 class="title">{{ 'PASSWORD-FORGOT.TITLE' | translate }}</h1>
    <p class="subtitle">{{ 'PASSWORD-FORGOT.SUBTITLE' | translate }}</p>
    <form
      name="forgotPwdForm"
      class="forgot-form"
      [formGroup]="forgotPwdForm"
      (ngSubmit)="requestPwd(forgotPwdForm)"
      no-validate>
      <!-- EMAIL -->
      <mat-form-field class="w-20">
        <input
          matInput
          type="email"
          placeholder="{{ 'COMMON.EMAIL' | translate }}"
          name="email"
          formControlName="email" />
        <mat-error
          *ngIf="
            forgotPwdForm.controls.email.invalid &&
            forgotPwdForm.controls.email.touched
          ">
          {{ 'PASSWORD-FORGOT.MESSAGES.REQUIRED-EMAIL' | translate }}
        </mat-error>
      </mat-form-field>

      <div class="forgot-btn">
        <button mat-button [routerLink]="['/login']" type="button">
          {{ 'COMMON.CANCEL' | translate }}
        </button>
        <button
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="forgotPwdForm.invalid">
          {{ 'COMMON.SEND' | translate }}
        </button>
      </div>
    </form>
  </mat-card>
</div>
