import { Component, OnInit, ElementRef } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { SidePanelService } from '../../services';
import { forEach } from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.sass'],
  host: {
    '(document:click)': 'onClick($event)',
  },
  animations: [
    trigger('flyInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [
        style({ transform: 'translateX(100%)' }),
        animate('350ms linear'),
      ]),
      transition('* => void', [
        animate('350ms linear', style({ transform: 'translateX(100%)' })),
      ]),
    ]),
  ],
})
export class SidePanelComponent implements OnInit {
  showSidePanel = false;
  private subscriptions: any = {};
  safari = false;

  constructor(
    private ref: ElementRef,
    private sidePanel: SidePanelService,
    private device: DeviceDetectorService
  ) {}

  ngOnInit() {
    if (this.device.browser.indexOf('safari') !== -1) {
      this.safari = true;
    }

    // Open side panel
    this.subscriptions.triggerSidePanel =
      this.sidePanel.triggerSidePanel.subscribe(() => this.open());
    // Close side panel
    this.subscriptions.triggerClose = this.sidePanel.triggerClose.subscribe(
      () => this.close()
    );
  }

  ngOnDestroy() {
    forEach(this.subscriptions, value => value.unsubscribe());
  }

  /**
   * Open side menu
   */
  open() {
    this.showSidePanel = true;
  }

  /**
   * Close side bar
   */
  close() {
    this.showSidePanel = false;
    this.sidePanel.onClose.next(null);
  }

  /**
   * Handle click event on outside side bar
   *
   * @param event
   */
  private onClick(event: any) {
    const inContainer = this.ref.nativeElement.contains(event.target);
    const canOpen = false;
    const buttonToggle = event.target.getAttribute('id');

    if (buttonToggle && buttonToggle === 'app-side-backdrop') {
      this.showSidePanel = false;
      this.sidePanel.onClose.next(null);
    }

    if (!inContainer && !canOpen) {
      return;
    }

    return;
  }
}
