import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-destination-no-content',
  templateUrl: './destination-no-content.component.html',
  styleUrls: ['./destination-no-content.component.sass'],
})
export class DestinationNoContentComponent implements OnInit {
  constructor() {}

  @Input() title: string = 'PLACEHOLDERS.DESTINATIONS.TITLE';
  @Input() img: string = '/assets/img/no_content_1.png';
  @Input() messages: string[] = [
    'PLACEHOLDERS.DESTINATIONS.LINE_ONE',
    'PLACEHOLDERS.DESTINATIONS.LINE_TWO',
    'PLACEHOLDERS.DESTINATIONS.LINE_THREE',
  ];

  ngOnInit(): void {}
}
