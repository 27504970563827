<div class="range-picker-holder" [ngClass]="{ 'error-picker': pickerError }">
  <div class="holder">
    <input
      id="sinceInput"
      class="ui-timepicker-input"
      style="cursor: pointer"
      type="text"
      (click)="showPicker('since')" />
    <span class="range-divider"> - </span>
    <input
      id="untilInput"
      class="ui-timepicker-input"
      style="cursor: pointer"
      type="text"
      (click)="showPicker('until')" />
  </div>

  <i class="material-icons input-arrow">
    {{ opened ? 'arrow_drop_up' : 'arrow_drop_down' }}
  </i>
</div>
