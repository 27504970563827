import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { SignupActivationComponent } from './signup/signup-activation/signup-activation.component';
import { SignupSuccessComponent } from './signup/signup-success/signup-success.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './forgot-password/reset-password/reset-password.component';

import {
  AuthGuard,
  AnonymousGuard,
  NotAuthGuard,
  AuthGuardAdmin,
} from './app.auth.guard';
import { FaqComponent } from './faq/faq.component';
import { ActivateByPasswordComponent } from './activate-by-password/activate-by-password.component';
import * as appUtils from './utils';
import { FormConfigComponent } from './form-config/form-config.component';
import { PendingChangesGuard } from './shared/guards/pending-changes.guard';

const isAdmin = appUtils.isCompanyDomain();

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'signup',
    component: SignupComponent,
    canActivate: [NotAuthGuard],
  },
  {
    path: 'signup/activation',
    component: SignupActivationComponent,
  },
  {
    path: 'signup/success',
    component: SignupSuccessComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [NotAuthGuard],
  },
  {
    path: 'forgot-password/change-password',
    component: ResetPasswordComponent,
    canActivate: [NotAuthGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AnonymousGuard],
  },
  {
    path: 'routes',
    loadChildren: () =>
      import('./routes/routes.module').then(f => f.RoutesModule),
    canActivate: [!isAdmin ? AuthGuardAdmin : AuthGuard],
  },
  {
    path: 'directory',
    loadChildren: () =>
      import('./directory/directory.module').then(m => m.DirectoryModule),
    //component: DirectoryComponent,
    canActivate: [!isAdmin ? AuthGuardAdmin : AuthGuard],
  },
  {
    path: 'drivers',
    loadChildren: () =>
      import('./driver/driver.module').then(m => m.DriverModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'managers',
    loadChildren: () =>
      import('./manager/manager.module').then(m => m.ManagerModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'vehicles',
    loadChildren: () =>
      import('./vehicles/vehicles.module').then(m => m.VehiclesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'form-configuration',
    component: FormConfigComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'activateAccount',
    component: ActivateByPasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./reports/reports.module').then(m => m.ReportsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'tracking',
    loadChildren: () =>
      import('./tracking/tracking.module').then(m => m.TrackingModule),
  },
  {
    path: 'faq',
    component: FaqComponent,
  },
  {
    path: 'team',
    loadChildren: () => import('./team/team.module').then(m => m.TeamModule),
    canActivate: [AuthGuard],
  },

  // otherwise redirect to home
  { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
