import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import * as Flow from '@flowjs/flow.js';
import {
  HelpersService,
  SubmenuService,
  CustomLoadingService,
  UserService,
} from '../../../services';
import { ApiRequestsService } from '../../../../api';
import { TranslateService } from '@ngx-translate/core';
import { clone, isNull } from 'lodash';
import { EmailPreviewComponent } from '../email-preview/email-preview.component';

@Component({
  selector: 'customize-company',
  templateUrl: './customize-company.component.html',
  styleUrls: ['./customize-company.component.sass'],
})
export class CustomizeCompanyComponent implements OnInit {
  selectedFile: File;
  flow: Flow = new Flow();
  imageUrl: string = '';
  checked: boolean = false;
  showEdition: boolean = false;
  cropingImage: boolean = false;
  cropperConfig: any = {
    modal: true,
    movable: true,
    viewMode: 0,
    autoCrop: true,
    zoomable: true,
    zoomOnTouch: true,
    zoomOnWheel: false,
    cropBoxResizable: true,
    toggleDragModeOnDblclick: false,
    minContainerWidth: 341,
    minContainerHeight: 177,
    minCanvasWidth: 341,
    minCanvasHeight: 177,
    minCropBoxWidth: 321,
    minCropBoxHeight: 157,
    maxWidth: 341,
    maxHeight: 157,
    guides: false,
    highlight: true,
    background: false,
    scalable: true,
    responsive: true,
    center: true,
    rotable: false,
    autoCropArea: 0.9,
    aspectRatio: 18 / 9,
    dragMode: 'move',
  };

  containerStyle: any = {};

  private mainContainerStyle: {
    'min-height': '177px';
    'min-width': '341';
  };

  private imageCoords: any = {};
  private selectedFileList: any = {};
  private urlRegex: RegExp =
    /((https?|ftp)\:\/\/)?([a-z0-9+!*(),;?&=\$_.-]+(\:[a-z0-9+!*(),;?&=\$_.-]+)?@)?([a-z0-9-.]*)\.([a-z]{2,3})(\:[0-9]{2,5})?(\/([a-z0-9+\$_\-~@\(\)\%]\.?)+)*\/?(\?[a-z+&\$_.-][a-z0-9;:@&#%=+\/\$_.-]*)?(#[a-z_.-][a-z0-9+\$_.-]*)?/i;

  private interval: any;

  //@ViewChild(CropperComponent) cropper: CropperComponent;
  //@ViewChild('dropTarget') public dropTarget: ElementRef;
  @ViewChild('browseButton') public browseButton: ElementRef;
  @ViewChild(EmailPreviewComponent) emailModal: EmailPreviewComponent;

  constructor(
    private helper: HelpersService,
    private api: ApiRequestsService,
    private translate: TranslateService,
    private submenuService: SubmenuService,
    private loading: CustomLoadingService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.getCompany();
  }

  ngOnDestroy() {
    if (this.interval) clearInterval(this.interval);
  }

  /**
   * Cancel customize
   */
  cancel() {
    this.submenuService.softBack.next(true);
  }

  /**
   * Remove selected file
   */
  removeImage() {
    let _imageUrl = clone(this.imageUrl);
    this.imageUrl = '';
    this.cropingImage = false;
    this.containerStyle = {};
    this.showEdition = false;
    this.checked = false;

    if (this.interval) clearInterval(this.interval);

    if (!this.urlRegex.test(_imageUrl)) return;

    this.api.company
      .deleteLogo()
      .toPromise()
      .then(response => {
        let title = this.translate.instant('SIDE-MENU.CUSTOMIZATION.TITLE');
        let message = this.translate.instant(
          'SIDE-MENU.CUSTOMIZATION.MESSAGES.REMOVED'
        );
        localStorage.removeItem('companyLogo');
        localStorage.removeItem('withBackground');
        this.userService.onProfileLoad.next({});
        this.helper.showSuccessMessage(message, title);
      })
      .catch(error => this.helper.handleErrorMessages(error));
  }

  /**
   * Store company logo
   */
  storePicture() {
    this.uploadFile()
      .then((file: any) => {
        let payload = {
          bgLogoColor: this.checked ? '#FFFFFF' : 'transparent',
          companyLogo: file.url || file,
        };

        localStorage.setItem('companyLogo', payload.companyLogo);
        localStorage.setItem('withBackground', payload.bgLogoColor);
        return this.api.company.update(payload).toPromise();
      })
      .then(response => {
        let title = this.translate.instant('SIDE-MENU.CUSTOMIZATION.TITLE');
        let message = this.translate.instant(
          'SIDE-MENU.CUSTOMIZATION.MESSAGES.ADDED'
        );
        this.helper.showSuccessMessage(message, title);
        this.getCompany();
        this.userService.onProfileLoad.next({});
      })
      .catch(error => {
        this.helper.handleErrorMessages(error);
      });
  }

  /**
   * listen when file input change
   *
   * @param event
   */
  loadPicture(event: any) {
    let fileList: FileList = event.target.files;
    let file = fileList[0];

    if (!file) return;

    this.selectedFileList = fileList;
    this.selectedFile = file;
    this.getImage(file);

    this.cropingImage = true;
    // this.interval = setInterval(() => {
    //   if (this.cropper) {
    //     setTimeout(() => {
    //       this.cropper.settings = { width: 341, height: 177 };
    //       this.cropper.cropper.zoomTo(1);
    //       this.containerStyle = {
    //         'min-width': this.cropper.cropper.getContainerData().width + 'px',
    //         'min-height': this.cropper.cropper.getContainerData().height + 'px'
    //       };
    //     }, 200);
    //     clearInterval(this.interval);
    //   }
    // }, 100);
  }

  /**
   * Get container styles
   */
  getContainerStyle() {
    return this.cropingImage ? this.containerStyle : this.mainContainerStyle;
  }

  /**
   * Set zoom
   *
   * @param value
   */
  zoom(value: any) {
    //this.cropper.cropper.zoom(value);
  }

  /**
   * Crop image algo get dimensions and coords
   */
  cropImage() {
    // this.imageCoords = this.cropper.cropper.getData();
    // this.imageUrl = this.cropper.cropper.getCroppedCanvas({
    //   width: 320,
    //   minWidth: 320,
    //   height: 156,
    //   minHeight: 156,
    // }).toDataURL();
    // this.cropingImage = false;
    // this.showEdition = true;
    // this.containerStyle = {};
  }

  /**
   * Show email preview modal
   */
  showEmailPreview() {
    this.emailModal.show(this.imageUrl);
  }

  /**
   * Get uploaded image as base64 to be displayed
   * on image container
   *
   * @param file
   */
  private getImage(file: any) {
    this.helper
      .getMediaAsBase64(file)
      .then(response => (this.imageUrl = response.url))
      .catch(error => this.helper.handleErrorMessages(error));
  }

  /**
   * Get signed url for images to be uploaded and then upload to desired bucket
   */
  private uploadFile() {
    return new Promise((resolve, reject) => {
      if (this.urlRegex.test(this.imageUrl)) return resolve(this.imageUrl);

      let file = this.selectedFile;

      if (!file)
        return reject({
          error: {
            userMessage: this.translate.instant(
              'SIDE-MENU.CUSTOMIZATION.ERROR.NO-IMAGE'
            ),
          },
        });

      let ext = file.name
        .substring(file.name.lastIndexOf('.') + 1)
        .toLowerCase();
      let fileName = this.setFileName(file);

      let tasks = [
        {
          name: 'crop',
          properties: {
            width: this.imageCoords.width,
            height: this.imageCoords.height,
            x: this.imageCoords.x,
            y: this.imageCoords.y,
          },
        },
        {
          name: 'resize',
          properties: {
            width: 321,
            height: 177,
            quality: 100,
          },
        },
      ];

      this.api.company
        .uploadLogo(file, fileName, tasks)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }

  /**
   * Get current company
   */
  private getCompany() {
    this.api.company
      .get()
      .toPromise()
      .then(response => {
        response = response.data;
        let hasLogo =
          !isNull(response.companyLogo) &&
          response.companyLogo &&
          response.companyLogo.length;
        this.imageUrl = response.companyLogo || '';
        this.checked =
          hasLogo &&
          response.bgLogoColor &&
          response.bgLogoColor !== 'transparent'
            ? true
            : false;
      })
      .catch(error => this.helper.handleErrorMessages(error));
  }

  /**
   * Generate filename
   *
   * @param file
   */
  private setFileName(file) {
    let name = new Date().getTime();
    let rand = Math.round(Math.random() * 1000);
    let fileName = file.name;
    let ext = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
    return `${name}-${rand}.${ext}`;
  }
}
