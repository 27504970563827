import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { INJECTION_TOKEN } from '../../app.config';
import { Router } from '@angular/router';
import * as _ from 'lodash';

@Injectable()
export class UserService {
  onProfileLoad: Subject<any> = new Subject<any>();
  onProfileUpdate: Subject<any> = new Subject<any>();
  isLogged: Subject<Boolean> = new Subject<Boolean>();
  activated: Subject<any> = new Subject<any>();
  displayActivationModal: Subject<any> = new Subject<any>();

  private _userLocation: any = this.config.location;
  private _enabledLocations: Array<any> = this.config.enabledLocations;
  private _user: any = {};

  // set user content
  set user(user: any) {
    this._user = user;
    this.isLogged.next(true);
  }

  // return user data
  get user() {
    return this._user;
  }

  // set logged as false on user loggout
  triggeredLogout() {
    this.isLogged.next(false);
  }

  // set user location
  set userLocation(location) {
    this._userLocation = location;
  }

  // get user location
  get userLocation() {
    return this._userLocation;
  }

  // set enabled location
  set enabledLocations(locations) {
    this._enabledLocations = locations;
  }

  // get enabled location
  get enabledLocations() {
    return this._enabledLocations;
  }

  constructor(
    @Inject(INJECTION_TOKEN) private config: any,
    private router: Router
  ) {}

  canWrite() {
    return (
      this.user.role === 'company' ||
      (this.user.role.toLowerCase() === 'manager' &&
        _.has(this.user, 'read_only') &&
        !this.user.read_only)
    );
  }
}
