<div class="containerModal">
  <div class="pop-container" #defaultTemplate>
    <div class="title border-bottom pb-3">
      <i
        *ngIf="showExtraActionButton"
        class="material-icons title-icon icon_action"
        (click)="onExtraActionButton()">
        tune
      </i>
      <div *ngIf="!showExtraActionButton" style="visibility: hidden">x</div>
      <span class="title-text">ETIQUETAS</span>
      <i class="material-icons title-icon icon_action" (click)="closeModal()">
        close
      </i>
    </div>
    <div id="searcher" class="text-center form-style">
      <mat-form-field class="search_form p-0" appearance="outline">
        <mat-label>{{ 'Buscar etiquetas' | translate }}</mat-label>
        <input matInput type="text" [(ngModel)]="searchText" />
        <button
          *ngIf="searchText"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="searchText = ''">
          <mat-icon>close</mat-icon>
        </button>
        <button *ngIf="!searchText" matSuffix mat-icon-button>
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
      <span *ngIf="hintText" class="hintMessage">{{
        hintText | translate
      }}</span>
    </div>
    <div *ngIf="tagLoading" class="spinner-container">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
    <perfect-scrollbar>
      <div class="tag-list" *ngIf="!tagLoading">
        <mat-chip-listbox class="mat-chip-list-stacked">
          <div class="d-flex justify-content-between w-100 px-3">
            <div>{{ 'Todos' | translate }}</div>
            <mat-checkbox
              *ngIf="!uniqueSelector"
              class="mr-3"
              [indeterminate]="isIndeterminateAll()"
              [checked]="isCheckedAll()"
              (change)="toggleSelectionAll($event)"></mat-checkbox>
          </div>

          <div class="tag-row" *ngFor="let tag of getFilterTagList()">
            <mat-chip [ngStyle]="{ 'background-color': tag.color }">
              {{ tag.label }}
            </mat-chip>
            <mat-radio-button
              *ngIf="uniqueSelector"
              [checked]="checkIfSelected(tag)"
              (change)="checkTag($event, tag)"></mat-radio-button>
            <mat-checkbox
              *ngIf="!uniqueSelector"
              [checked]="checkIfSelected(tag)"
              (change)="checkTag($event, tag)">
            </mat-checkbox>
          </div>
        </mat-chip-listbox>
      </div>
    </perfect-scrollbar>
  </div>
</div>
