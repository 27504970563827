import { Injectable, Inject, ErrorHandler } from '@angular/core';
import { INJECTION_TOKEN } from '../../app.config';
import { UserService } from './user.service';

// Sentry
import * as Raven from 'raven-js';

@Injectable()
export class RavenErrorHandler implements ErrorHandler {
  public apiKey: string;

  constructor(
    @Inject(INJECTION_TOKEN) public config: any,
    @Inject(UserService) private userService: any
  ) {
    this.apiKey = config.sentry_key;

    Raven.config(this.apiKey).install();
  }

  handleError(err: any): void {
    Raven.setTagsContext({
      environment: this.config.development
        ? 'development'
        : this.config.qa
        ? 'qa'
        : this.config.production
        ? 'production'
        : 'development',
    });

    Raven.setUserContext({
      email: this.userService.user.email || '',
      userId: this.userService.user._id || '',
      username:
        (this.userService.user.firstName || '') +
        ' ' +
        this.userService.user.lastName,
    });

    Raven.captureException(err);
  }
}
