<div class="timepicker-container">
  <mat-form-field
    class="w-100"
    [appearance]="appearance"
    [floatLabel]="floatingPlaholder ? 'always' : 'never'"
    #inputField>
    <mat-label>{{ placeholder }}</mat-label>
    <input
      matInput
      (click)="displayTimePicker()"
      [placeholder]="!time ? placeholder : placeholder"
      [value]="time | customDate : formatDate"
      readonly />
  </mat-form-field>

  <div class="timepicker" *ngIf="showTimepicker">
    <!-- <i class="fa fa-times close" ></i> -->
    <i class="material-icons close" (click)="triggerClose()">highlight_off</i>
    <timepicker
      class="timepicker-item"
      [(ngModel)]="_time"
      [min]="minTime"
      (ngModelChange)="valueChange($event)">
    </timepicker>
  </div>
</div>
