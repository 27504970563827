import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Input,
  Output,
  Inject,
} from '@angular/core';
import { intersection, forEach } from 'lodash';
import { ActivationModalComponent } from '../activation-modal/activation-modal.component';
import { Subject } from 'rxjs';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'inactive-account-check',
  templateUrl: './inactive-account-check.component.html',
  styleUrls: ['./inactive-account-check.component.sass'],
  host: {
    '(document:click)': 'overClick($event)',
  },
})
export class InactiveAccountCheckComponent implements OnInit {
  @Input() userInfo: any = {};
  @Output() activated: Subject<any> = new Subject<any>();
  @ViewChild('modalActivation') modalActivation: ActivationModalComponent;

  displayToaster: boolean = false;
  displayModal: boolean = false;
  displayBackDrop: boolean = false;
  private subscriptions: any = {};

  constructor(
    private ref: ElementRef,
    @Inject(UserService) private userService: UserService
  ) {}

  ngOnInit() {
    this.subscriptions.displayActivationModal =
      this.userService.displayActivationModal.subscribe(() =>
        this.handleToasterDisplay()
      );
  }

  ngOnDestroy() {
    forEach(this.subscriptions, value => value.unsubscribe());
  }

  /**
   * Handle show or hide of custom toaster
   *
   * @param withBackDrop
   */
  handleToasterDisplay(withBackDrop?: boolean) {
    setTimeout(() => {
      if (this.displayBackDrop) this.displayBackDrop = false;

      this.displayToaster = !this.displayToaster;
      // backdrop for small devices
      let width = window.innerWidth;

      if (withBackDrop) return (this.displayBackDrop = true);

      this.displayBackDrop = this.displayToaster && width <= 864 ? true : false;
    }, 10);
  }

  /**
   * Display activation modal
   */
  displayActivationModal() {
    this.handleToasterDisplay(true);
    this.modalActivation.show();
  }

  /**
   * Triggered when activation modal emit a success value
   */
  userActivated() {
    this.modalActivation.hide();
    this.activated.next(true);
  }

  /**
   * Handle click event on outside of custom toaster
   *
   * @param event
   */
  overClick(event: any) {
    let inContainer = this.ref.nativeElement.contains(event.target);
    let canOpen = false;
    let buttonToggle = event.target.getAttribute('class');
    buttonToggle = buttonToggle ? buttonToggle.split(' ') : buttonToggle;
    let isBackDrop = event.target.getAttribute('id') === 'toaster-backdrop';
    let valids = [
      'toast-close',
      'material-icons',
      'mat-icon-button',
      'activate-text',
      'alert-button',
      'toast-backdrop',
    ];

    // when backdrop was displayed and click on backdrop item
    if (isBackDrop && this.displayBackDrop) {
      this.displayToaster = false;
      this.displayBackDrop = false;
      return;
    }

    if (inContainer || intersection(buttonToggle, valids).length) return;
    else this.displayToaster = false;
    return;
  }
}
