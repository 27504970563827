import { Component, OnInit, Input } from '@angular/core';
import { has, find, last, isPlainObject, isEmpty, forEach } from 'lodash';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { HelpersService, RouteAssignmentService } from '../../../services';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'card-header-route',
  templateUrl: './card-header-route.component.html',
  styleUrls: ['./card-header-route.component.sass'],
})
export class CardHeaderRouteComponent implements OnInit {
  @Input() content: any = {};
  @Input() assigned: boolean = false;
  @Input() specialStatus: boolean = false;
  @Input() inProgress: boolean = false;
  @Input() setback: boolean = false;
  @Input() isAutocomplete: boolean = false;
  @Input() viewType: any = {};
  @Input() type: string = 'list';
  @Input() parentCardId: string = '';

  selected: any = '';
  dateOptions: Array<any> = [];
  descriptionETA: string = '';
  displayPTimePicker: boolean = false;
  totalDuration: string = '';

  startDateLabel: String = '---';
  endDateLabel: String = '---';

  completed: boolean = false;
  cancelled: boolean = false;

  currentPoint = 0;

  private subscriptions: any = {};
  constructor(
    public router: Router,
    private helpers: HelpersService,
    private routeAssign: RouteAssignmentService
  ) {}

  ngOnInit() {
    this.initialize();
    this.getETA();
    this.dateOptions = [
      {
        name: 'Tomorrow',
        value: 1,
      },
    ];

    /**
     * Update route header items when it's trigged by firebase
     */
    this.subscriptions.updateRouteItems = this.routeAssign.updateRouteItems
      .pipe(filter(item => item._id === this.content._id))
      .subscribe(item => {
        this.initialize();
      });
  }

  /**
   * Re init data after component data changes changes
   *
   * @param changes
   */
  ngOnChanges(changes) {
    if (changes.content && changes.content.currentValue) this.initialize();
  }

  ngOnDestroy() {
    forEach(this.subscriptions, value => value.unsubscribe());
  }

  /**
   * Go to details page
   */
  goToDetails() {
    this.router.navigate(['routes', this.content._id]);
  }

  /**
   * display ETA for route
   */
  getETA() {
    if (!has(this.content, 'deliverPoints') && !has(this.content, 'index'))
      return;

    if (!this.content.index.length) return;

    let durations = this.content.index.map((point, i) => {
      return !isEmpty(point.eta)
        ? moment(point.eta.timeOut, 'x').format('HH:mm:ss')
        : moment().format('HH:mm:ss');
    });

    let _totalDuration = durations
      .slice(1)
      .reduce(
        (prev, cur) => moment.duration(cur).add(prev),
        moment.duration(durations[0])
      );
    this.totalDuration = `${_totalDuration._data.hours}hrs ${_totalDuration._data.minutes}min`;
  }

  /**
   * Get date text
   *
   * @param date
   */
  getDateText(date: any) {
    let _date = moment
      .utc(date, 'YYYY-MM-DD HH:mm:ss')
      .local()
      .locale(sessionStorage.getItem('lang'));

    if (moment().diff(_date, 'days') >= 1) return _date.fromNow();

    return _date.calendar().split(' ')[0] !== 'Invalid'
      ? _date.calendar().split(' ')[0]
      : '---';
  }

  scroll(who) {
    if (who == 0)
      document.getElementById(`${this.content._id}`).scrollLeft += -27;
    else if (who == 1)
      document.getElementById(`${this.content._id}`).scrollLeft += 27;
  }

  /**
   * Find a point by index position
   *
   * @param position
   */
  findPoint(position: any) {
    let point = this.content.index[position];

    // console.log(position)
    if (!point || this.isAutocomplete) return {};

    let _item = find(this.content.deliverPoints, e => {
      let id = isPlainObject(e.deliveryPoint) ? e.deliveryPoint._id : e;
      return id === this.content.index[position].id;
    });

    return !_item ? {} : _item;
  }

  /**
   * Check delivery points statuses
   */
  checkDeliveredPoints() {
    if (!this.content.deliverPoints && !this.content.index) return;

    let _event =
      this.content.events && this.content.events.length
        ? last(this.content.events)
        : {};

    this.content.index.forEach((index, position) => {
      let point = find(this.content.deliverPoints, e => {
        let id = isPlainObject(e) ? e.deliveryPoint._id : e;
        return id === index.id;
      });

      if (!point) return;

      let classIcon = this.helpers.getDeliveryPointStatus(
        point,
        this.content.status,
        _event,
        position,
        this.currentPoint
      );

      index.className = classIcon.className;
      index.icon = classIcon.icon;
    });
  }

  /**
   * Calculate active point
   */
  calculateCurrentPoint() {
    if (!this.content.index) return;

    let cont = 0;

    for (let i = 0; i < this.content.index.length; i++) {
      let _item = this.findPoint(i);

      if (
        _item &&
        (_item.isDelivered ||
          _item.omittedAt ||
          _item.deliveredAt ||
          _item.documentedAt)
      )
        cont++;
      else break;
    }

    this.currentPoint = cont;
    return this.currentPoint;
  }

  /**
   * Initialize component data
   */
  private initialize() {
    this.completed =
      this.content.status === 'completed' ||
      this.content.status === 'cancelled';
    this.cancelled = this.content.status === 'cancelled';
    this.totalDuration = '---';

    this.calculateCurrentPoint();
    this.checkDeliveredPoints();
    this.selected = 1;

    this.startDateLabel = this.getInitAt();
    this.endDateLabel = this.getEstimatedFinishTime();
  }

  /**
   * Get route estimated end date label
   */
  private getEstimatedFinishTime() {
    let label = '---';

    if (!has(this.content, 'deliverPoints') && !has(this.content, 'index'))
      return label;

    if (!this.content.index.length) return label;

    let _last: any = last(this.content.index);

    if (!_last) return label;

    label = this.getDateText(moment(_last.eta.timeOut, 'x'));
    let _time =
      moment.utc(_last.eta.timeOut, 'x').local().format('hh:mm A') || '---';
    return `${label}, ${_time}`;
  }

  /**
   * Get route estimated start date label
   */
  private getInitAt() {
    let label = '---';

    if (!has(this.content, 'date')) return label;

    label = this.getDateText(this.content.date);
    let _time =
      moment.utc(this.content.date).local().format('hh:mm A') || '---';
    return `${label}, ${_time}`;
  }
}
