<mat-dialog-content
  class="text-center"
  style="padding-bottom: 0px; margin-top: 10px">
  <h3>{{ message | translate }}</h3>
  <p style="color: black">{{ submessage | translate }}</p>
  <span>{{ bodyMessage }}</span>
</mat-dialog-content>
<mat-dialog-actions align="center" style="margin-bottom: 10px">
  <button
    style="margin-right: 30px"
    mat-raised-button
    class="fleetflexButton"
    (click)="onConfirmClick()"
    tabindex="1">
    {{ confirmButtonText | translate }}
  </button>
  <button
    mat-raised-button
    mat-dialog-close
    tabindex="-1"
    class="fleetflexButton">
    {{ cancelButtonText | translate }}
  </button>
</mat-dialog-actions>
