import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'email-preview-modal',
  templateUrl: './email-preview.component.html',
  styleUrls: ['./email-preview.component.sass'],
})
export class EmailPreviewComponent implements OnInit {
  @ViewChild('emailModal') emailModal: ModalDirective;

  logo: string = './assets/img/logo_blue.png';
  defaultLogo: string = './assets/img/logo_blue.png';
  selectedTab: string;

  config = {
    backdrop: false,
    ignoreBackdropClick: false,
  };

  constructor() {}

  ngOnInit() {}

  /**
   * Select tab to be displayed
   *
   * @param tab
   */
  selectTab(tab: string) {
    this.selectedTab = tab;
  }

  /**
   * Show Onboarding Modal
   *
   * @param logo
   */
  show(logo?: string): void {
    this.emailModal.show();
    this.selectedTab = 'client';
    this.logo = logo ? logo : this.defaultLogo;
  }

  hide(): void {
    this.selectedTab = '';
    this.logo = this.defaultLogo;
    this.emailModal.hide();
  }
}
