import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { has, isEmpty } from 'lodash';

@Injectable()
export class LocationService {
  lastPointLocation: Subject<any> = new Subject<any>();

  constructor() {}

  /**
   * Ge
   * t last point location for certaim coords
   *
   * @param coords
   */
  setLastPointLocation(coords: any) {
    if (!coords) return;

    this.lastPointLocation.next(this.readCoords(coords));
  }

  /**
   * Get coords from LatLng location
   *
   * @param waypoint
   * @param location
   */
  private readCoords(location: any) {
    if (!location || isEmpty(location)) return null;

    let _coords = null;

    if (has(location, 'lat') && has(location, 'lng'))
      _coords = {
        latitude: location.lat(),
        longitude: location.lng(),
      };
    else return location;

    return _coords;
  }
}
