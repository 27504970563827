<div class="page-container">
  <div class="faq-container">
    <h1 class="header">{{ 'SIDE-MENU.FAQ' | translate }}</h1>
    <div class="themes-container">
      <mat-accordion>
        <mat-expansion-panel *ngFor="let theme of faq" #panel>
          <mat-expansion-panel-header class="panel-header">
            <mat-panel-title [ngClass]="{ 'expanded-title': panel.expanded }">
              {{ theme.theme }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div *ngFor="let question of theme.data">
            <h1 *ngIf="question.title !== undefined">{{ question.title }}</h1>
            <h2>{{ question.question }}</h2>
            <div
              style="text-align: center"
              *ngIf="question.image !== undefined">
              <img src="{{ question.image }}" alt="" />
            </div>
            <div
              *ngFor="let answer of question.answer"
              [ngClass]="answer.style">
              {{ answer.text }}
            </div>
            <ol *ngIf="question.items !== undefined">
              <li *ngFor="let answer of question.items" class="mb-3">
                <span *ngFor="let item of answer.item" [ngClass]="item.style">{{
                  item.text
                }}</span>
              </li>
            </ol>

            <div *ngIf="question.items2 !== undefined">
              <div *ngFor="let answer of question.items2">
                <p
                  *ngFor="let item of answer.item"
                  [ngClass]="item.style"
                  style="color: black">
                  {{ item.text }}
                </p>
              </div>
            </div>

            <div
              style="text-align: center; margin-bottom: 30px"
              *ngIf="question.image2 !== undefined">
              <img src="{{ question.image2 }}" alt="" />
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
