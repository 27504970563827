import { Injectable } from '@angular/core';
import { AuthRequests } from './auth/auth.requests';
import { DriverRequests } from './drivers/drivers.requests';
import { GroupRequests } from './groups/groups.requests';
import { VehiclesRequests } from './vehicles/vehicles.requests';
import { UploaderRequests } from './uploader/upload.requests';
import { DeliveryPointsRequests } from './deliveryPoints/deliveryPoints.requests';
import { WarehousesRequests } from './warehouses/warehouses.requests';
import { RoutesRequests } from './routes/routes.requests';
import { CompanyRequests } from './company/company.requests';
import { UserRequests } from './user/user.requests';
import { ReportsRequests } from './reports/reports.requests';
import { NotificationsRequests } from './notifications/notifications.requests';
import { TagsRequests } from './tags/tags.requests';

@Injectable()
export class ApiRequestsService {
  constructor(
    public user: UserRequests,
    public auth: AuthRequests,
    public drivers: DriverRequests,
    public groups: GroupRequests,
    public vechiles: VehiclesRequests,
    public uploader: UploaderRequests,
    public deliveryPoints: DeliveryPointsRequests,
    public warehouses: WarehousesRequests,
    public routes: RoutesRequests,
    public company: CompanyRequests,
    public reports: ReportsRequests,
    public notifications: NotificationsRequests,
    public tags: TagsRequests
  ) {}
}

/**
 *
 */
export function ApiDeclarations() {
  return [
    UserRequests,
    AuthRequests,
    DriverRequests,
    GroupRequests,
    VehiclesRequests,
    UploaderRequests,
    DeliveryPointsRequests,
    WarehousesRequests,
    RoutesRequests,
    CompanyRequests,
    ReportsRequests,
    NotificationsRequests,
    TagsRequests,
  ];
}
