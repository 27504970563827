import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, isUndefined, isEmpty, last, get } from 'lodash';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { HelpersService } from './helpers.service';
import { FirebaseService } from './firebase.service';
import { AnalyticsService } from './analytics.service';
import { ApiRequestsService } from '../../api';
import { INJECTION_TOKEN } from '../../app.config';

@Injectable()
export class RouteAssignmentService {
  private vehicleTypeId: string = '';
  // actives vechiles category name
  private vehicleCategoryActiveName: string = '';
  private _inMemoryRoutes: Array<any> = [];
  private currentPage: Number = 0;
  private itemsCount: Number = 0;
  private currentStatus: String;

  customTimeCheck: any;
  driversCount: Subject<Number> = new Subject<Number>();
  routesDriversList: any = {};
  routesVehiclesList: any = {};
  routesDriversCount: Subject<any> = new Subject<any>();
  vehiclesCount: Subject<Number> = new Subject<Number>();
  routesVehiclesCount: Subject<any> = new Subject<any>();
  routesCount: Subject<Number> = new Subject<Number>();
  canCheckItems: Subject<any> = new Subject<any>();

  errorAssignItem: Subject<any> = new Subject<any>();
  assignedRouteItem: Subject<any> = new Subject<any>();
  refreshRoute: Subject<any> = new Subject<any>();
  updateRouteItems: Subject<any> = new Subject<any>();

  assignedStatusCheck: Subject<any> = new Subject<any>();
  checkCounts: Subject<any> = new Subject<any>();

  set inMemoryRoutes(routes: Array<any>) {
    this._inMemoryRoutes = routes;
  }

  get inMemoryRoutes() {
    return this._inMemoryRoutes;
  }

  constructor(
    @Inject(INJECTION_TOKEN) private config: any,
    private api: ApiRequestsService,
    private helpers: HelpersService,
    private translate: TranslateService,
    private firebase: FirebaseService,
    private analytics: AnalyticsService
  ) {
    this.vehicleCategoryActiveName = this.config.vehicle_active_status;
  }

  /**
   * Init get counts by cards types
   *
   * @param type
   * @param date
   */
  getCountsByType(type: string = 'driver', date?: any) {
    if (type !== 'drivers') {
      this.getDriversCount(date);
    }

    if (type !== 'vehicles') {
      this.getVehiclesCounts(date);
    }

    if (type !== 'route') {
      this.getRoutesCount(date, type);
    }
  }

  /**
   * Assign vehicle to drivers
   *
   * @param itemId
   * @param routeId
   * @param options<AssignmentOptions>
   * @param vehicleId
   * @param targetId
   * @param options
   */
  assignVehicle(
    vehicleId: string,
    targetId: string,
    options?: AssignmentOptions
  ) {
    let payload = {
      routeId: targetId,
      vehicleId: vehicleId,
    };

    let optionsResponse = {
      type: 'vehicle',
      item: vehicleId,
      cardId: options.cardId,
      parentCard: options.parentId,
    };

    this.api.routes
      .assignVehicle(payload)
      .toPromise()
      .then(response => {
        this.assignedRouteItem.next(
          Object.assign({}, optionsResponse, { data: response.data })
        );
        let title = this.translate.instant('CARD-ASSIGNMENT.MESSAGES.TITLE');
        let message = this.translate.instant(
          options.isRoute
            ? 'CARD-ASSIGNMENT.MESSAGES.ROUTE-ASSIGNED'
            : 'CARD-ASSIGNMENT.MESSAGES.VEHICLE-ASSIGNED'
        );
        this.helpers.showSuccessMessage(message, title);

        this.analytics.sendAnalyticsEvent(
          this.handleVehicleAnalyticsType(options.parentType),
          { companyId: response.data.companyId },
          options.parentType || 'route'
        );
        this.updateInFirebase(targetId, { vehicle: response.data.vehicle });
      })
      .catch(error => {
        this.helpers.handleErrorMessages(error);
        this.errorAssignItem.next(optionsResponse);
      });
  }

  /**
   * Assign vehicle to drivers
   *
   * @param itemId
   * @param routeId
   * @param options<AssignmentOptions>
   * @param driverId
   * @param targetId
   * @param options
   */
  assignDriver(
    driverId: string,
    targetId: string,
    options?: AssignmentOptions
  ) {
    let payload = {
      routeId: targetId,
      driverId: driverId,
    };

    let optionsResponse = {
      type: 'driver',
      item: driverId,
      cardId: options.cardId,
      parentCard: options.parentId,
    };

    this.api.routes
      .assignDriver(payload)
      .toPromise()
      .then(response => {
        this.assignedRouteItem.next(
          Object.assign({}, optionsResponse, { data: response.data })
        );
        let title = this.translate.instant('CARD-ASSIGNMENT.MESSAGES.TITLE');
        let message = this.translate.instant(
          options.isRoute
            ? 'CARD-ASSIGNMENT.MESSAGES.ROUTE-ASSIGNED'
            : 'CARD-ASSIGNMENT.MESSAGES.DRIVER-ASSIGNED'
        );
        this.helpers.showSuccessMessage(message, title);

        this.analytics.sendAnalyticsEvent(
          this.handleDriverAnalyticsType(options.parentType),
          { companyId: response.data.companyId },
          options.parentType || 'route'
        );
        this.updateInFirebase(targetId, { driver: response.data.driver });
      })
      .catch(error => {
        this.helpers.handleErrorMessages(error);
        this.errorAssignItem.next(optionsResponse);
      });
  }

  /**
   * Get single route by id
   *
   * @param routeId
   */
  getSingleRoute(routeId: string): Promise<any> {
    return this.api.routes.getOne(routeId).toPromise();
  }

  /**
   * Get single route per item
   *
   * @param item could be Driver or Vehicle object
   * @param routeId desired routed it
   * @param type driver or vehicle
   */
  getItemRoute(item: any, routeId: string, type: string) {
    return new Promise((resolve, reject) => {
      if (!routeId) return resolve(item);

      this.api.routes
        .getOne(routeId)
        .toPromise()
        .then(response => {
          let data = response.data;

          if (type !== 'driver') item.driver = data.driver || null;

          if (type !== 'vehicle') item.vehicle = data.vehicle || null;

          item.route = data;

          return resolve(item);
        })
        .catch(error => reject(error));
    });
  }

  /**
   * Get drivers count
   *
   * @param date
   */
  public getDriversCount(date?) {
    let _date = date ? date : moment().format('YYYY-MM-DD Z');

    this.api.drivers
      .getAvailable(_date)
      .toPromise()
      .then(response => {
        this.driversCount.next(response.data.length || 0);
        this.routesDriversList[
          moment(_date, 'YYYY-MM-DD Z').format('DD/MM/YYYY')
        ] = response.data.length || 0;
        this.routesDriversCount.next(this.routesDriversList);
      })
      .catch(error => this.helpers.handleErrorMessages(error));
  }

  /**
   * Get drivers count
   *
   * @param date
   */
  public getVehiclesCounts(date?) {
    this.api.vechiles
      .getVehicleStatuses()
      .toPromise()
      .then(response => {
        let type = filter(response.data, item => item.type === 'vehicle').find(
          item => item.name === this.vehicleCategoryActiveName
        );

        if (isUndefined(type)) {
          this.routesVehiclesCount[
            moment(date, 'YYYY-MM-DD Z').format('DD/MM/YYYY')
          ] = 0;
          this.routesVehiclesCount.next(this.routesVehiclesCount);
          this.vehiclesCount.next(0);
          return false;
        }

        this.vehicleTypeId = type._id;
        let _date = date ? date : moment().format('YYYY-MM-DD Z');

        return this.api.vechiles
          .getNumberAvailable(_date, this.vehicleTypeId)
          .toPromise();
      })
      .then(response => {
        if (!response) return;

        this.vehiclesCount.next(response.data.count || 0);
        this.routesVehiclesList[
          moment(date, 'YYYY-MM-DD Z').format('DD/MM/YYYY')
        ] = response.data.count || 0;
        this.routesVehiclesCount.next(this.routesVehiclesList);
      })
      .catch(error => this.helpers.handleErrorMessages(error));
  }

  /**
   * Get routes count
   *
   * @param date
   * @param entity
   */
  public getRoutesCount(date?: string, entity: string = 'drivers') {
    this.api.routes
      .getAvailableByEntity(date, entity)
      .toPromise()
      .then(response =>
        this.routesCount.next(
          response.data[entity] ? response.data[entity].length : 0 || 0
        )
      )
      .catch(error => this.helpers.handleErrorMessages(error));
  }

  /**
   * Update route in firebase database
   *
   * @param routeId
   * @param data
   */
  updateInFirebase(routeId: string, data: any) {
    return this.firebase.update(`routes/${routeId}`, data);
  }

  /**
   * Get assignment status
   *
   * @param item
   * @param instanceType
   * @param events
   */
  checkIfIsAssigned(item: any, instanceType: string, events?: any) {
    let isAssigned = false;
    let isOnRoute = false;
    let driverOnline = false;
    let isCancelled = false;
    let isSetback = false;
    let isCompleted = false;
    let statusSpecial = false;
    let className = '';
    let style = {};

    let label = this.translate.instant('CARD-ASSIGNMENT.UNASSIGNED');
    let notRouteInstance =
      instanceType === 'driver' || instanceType === 'vehicle';
    let setbackValids = ['driver', 'route', 'routes', 'vehicle'];
    let setbackValidStatus = [undefined, null, 'pending'];

    if (!item)
      return {
        isAssigned,
        isOnRoute,
        driverOnline,
        isCancelled,
        isSetback,
        isCompleted,
        statusSpecial,
        label,
      };

    let _status = !notRouteInstance
      ? get(item, 'status')
      : get(item.route, 'status');

    let lastEvent = last(get(item, 'events')) || get(item, 'lastEvent') || {};

    isAssigned = !notRouteInstance
      ? !isEmpty(item.vehicle) && !isEmpty(item.driver)
      : instanceType === 'driver'
      ? !isEmpty(item.route) && !isEmpty(get(item, 'vehicle'))
      : instanceType === 'vehicle'
      ? !isEmpty(item.route) && !isEmpty(get(item, 'driver'))
      : false;

    isOnRoute = _status === 'in-progress';

    driverOnline = _status === 'in-progress';

    isCancelled = _status === 'cancelled';

    isSetback = !notRouteInstance
      ? _status === 'in-progress' && lastEvent.name === 'setback'
      : _status === 'in-progress' && lastEvent.name === 'setback';

    // when route has not been started and setback it's present
    if (
      setbackValids.indexOf(instanceType) !== -1 &&
      setbackValidStatus.indexOf(_status) !== -1
    ) {
      isSetback = lastEvent.name === 'setback';
    }

    isCompleted = _status === 'completed';

    if (isSetback) isOnRoute = false;

    statusSpecial = isOnRoute || isSetback ? true : false;

    if (isOnRoute || isSetback) isAssigned = false;

    if (isAssigned && !statusSpecial && !isCompleted)
      label = this.translate.instant('CARD-ASSIGNMENT.ASSIGNED');

    if (!isAssigned && !statusSpecial && !isCompleted && !isSetback)
      label = this.translate.instant('CARD-ASSIGNMENT.UNASSIGNED');

    if (isSetback) label = this.translate.instant('CARD-ASSIGNMENT.SETBACK');

    if (isOnRoute)
      label = this.translate.instant('CARD-ASSIGNMENT.IN-PROGRESS');

    if (isCompleted && !isCancelled)
      label = this.translate.instant('CARD-ASSIGNMENT.COMPLETED');

    if (isCancelled)
      label = this.translate.instant('CARD-ASSIGNMENT.CANCELLED');

    if (isAssigned && !isOnRoute) className = 'active';

    if (isOnRoute && !isSetback) className = 'online';

    if (isSetback || isCancelled) className = 'setback, warning';

    if (isCompleted) className = 'completed, online';

    return {
      isAssigned,
      isOnRoute,
      driverOnline,
      isCancelled,
      isSetback,
      isCompleted,
      statusSpecial,
      label,
      className,
      style,
    };
  }

  /**
   * Set analytics event name for drivers
   *
   * @param parent
   */
  private handleDriverAnalyticsType(parent: string) {
    let _eventName = 'Assign driver from routes';

    switch (parent) {
      case 'route':
        _eventName = 'Assign driver from routes';
        break;

      case 'driver':
        _eventName = 'Assign driver from drivers';
        break;

      case 'vechile':
        _eventName = 'Assign driver from vehicles';
        break;
    }

    return _eventName;
  }

  /**
   * Set analytics event name for vehicles
   *
   * @param parent
   */
  private handleVehicleAnalyticsType(parent: string) {
    let _eventName = 'Assign vehicle from routes';

    switch (parent) {
      case 'route':
        _eventName = 'Assign vehicle from routes';
        break;

      case 'driver':
        _eventName = 'Assign vehicle from drivers';
        break;

      case 'vechile':
        _eventName = 'Assign vehicle from vehicles';
        break;
    }

    return _eventName;
  }
}

export interface AssignmentOptions {
  cardId: string;
  parentId: string;
  parentType: string;
  isRoute: boolean;
}
