import { Injectable, Inject } from '@angular/core';
import { NewHttpClient } from '../interceptor.service';
//import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import { INJECTION_TOKEN } from '../../app.config';

@Injectable()
export class DriverRequests {
  constructor(
    @Inject(INJECTION_TOKEN) public config: any,
    private http: NewHttpClient
  ) {}

  /**
   * Get driver list
   *
   * @param data
   */
  get(data?: any): Observable<any> {
    let params = '';

    if (data) {
      let keys = Object.keys(data);
      keys.forEach(key => {
        if (!params.length) params = `?${key}=${data[key]}`;
        else params += `&${key}=${data[key]}`;
      });
    }

    let url = `${this.config.drivers_available}${params}`;
    console.log('ruta => ', url);
    return this.http.get(url);
  }

  /**
   * Get driver by id
   *
   * @param id
   */
  getOne(id: string): Observable<any> {
    let url = `${this.config.drivers_list}/${id}`;
    return this.http.get(url);
  }

  /**
   * Get available drivers
   *
   * @param date
   */
  getAvailable(date: string): Observable<any> {
    let url = `${this.config.drivers_available}/status/available/${date}`;
    return this.http.get(url);
  }

  /**
   * Get number of available drivers
   *
   * @param date
   */
  getNumberAvailable(date: string): Observable<any> {
    let url = `${this.config.number_drivers_available}/${date}`;
    return this.http.get(url);
  }

  /**
   * Get drivers count by state, ig: Assigned, not assigned, setback
   *
   * @param data
   */
  getDriversCountByState(data?: any): Observable<any> {
    let params = '';

    if (data) {
      let keys = Object.keys(data);
      keys.forEach(key => {
        if (!params.length) params = `?${key}=${data[key]}`;
        else params += `&${key}=${data[key]}`;
      });
    }
    let url = `${this.config.drivers_count_by_state}/${params}`;
    return this.http.get(url);
  }

  /**
   * Get drivers by state according to status sent from the backend, ig: Assigned, not assigned, setback
   *
   * @param q
   * @param data
   */
  getDriversListByState(q, data?: any): Observable<any> {
    let params = '';

    if (data) {
      let keys = Object.keys(data);
      keys.forEach(key => {
        if (!params.length) params = `?${key}=${data[key]}`;
        else params += `&${key}=${data[key]}`;
      });
    }

    let url = `${this.config.drivers_by_state}/${q}${params}`;
    return this.http.get(url);
  }

  /**
   * Create driver
   *
   * @param data
   */
  create(data: any): Observable<any> {
    let url = `${this.config.drivers_url}`;
    return this.http.post(url, data);
  }

  createManager(data: any): Observable<any> {
    let url = `${this.config.users_base}manager`;
    return this.http.post(url, data);
  }

  /**
   * Edit a driver
   *
   * @param id
   * @param data
   */
  edit(id: string, data: any): Observable<any> {
    let url = `${this.config.users_base}/${id}/driver`;
    return this.http.put(url, data);
  }

  /**
   * Delete one driver
   *
   * @param id
   */
  delete(id: string): Observable<any> {
    let url = `${this.config.drivers_url}/${id}`;
    return this.http.delete(url);
  }

  /**
   * Delete one driver
   *
   * @param id
   */
  deleteDeep(id: string): Observable<any> {
    let url = `${this.config.drivers2_url}/${id}`;
    return this.http.delete(url);
  }

  /**
   * Delete invitation
   *
   * @param id
   */
  deleteInvitation(id: string): Observable<any> {
    let url = `${this.config.users_base}/invitation-manager/${id}`;
    console.log(url);
    return this.http.delete(url);
  }

  /**
   * Get session history for a driver
   *
   * @param driverId
   * @param data
   */
  getSessionHistory(driverId: string, data: any): Observable<any> {
    let params = '';

    if (data) {
      let keys = Object.keys(data);
      keys.forEach(key => {
        if (!params.length) params = `?${key}=${data[key]}`;
        else params += `&${key}=${data[key]}`;
      });
    }

    let url = `${this.config.drivers_sessions}/${driverId}/${params}`;
    return this.http.get(url);
  }

  /**
   * Resend drivers invitation
   *
   * @param driverId
   */
  resendInvitation(driverId: string): Observable<any> {
    let payload = {
      driverId: driverId,
    };

    let url = `${this.config.drivers_available}/invitation/re-send`;
    return this.http.post(url, payload);
  }

  resendInvitationManager(managerId: string): Observable<any> {
    let payload = {
      managerId: managerId,
    };

    let url = `${this.config.users_base}resend-invitation`;
    console.log(url);
    return this.http.post(url, payload);
  }
  /**
   * Get one manager based on his id
   *
   * @param managerId
   */
  getOneManager(managerId: string): Observable<any> {
    const url = `${this.config.users_base}managers/${managerId}`;
    return this.http.get(url);
  }

  /**
   * Edit a manager
   *
   * @param id
   * @param data
   */
  editManager(id: string, data: any): Observable<any> {
    let url = `${this.config.users_base}/${id}/driver`;
    return this.http.put(url, data);
  }
}
