<div
  class=""
  style="background-color: white; max-width: 500px; max-height: 200px">
  <div class="row">
    <div
      class="col-6 borderBlue d-flex flex-column justify-content-between px-5">
      <p class="text-break mt-5 text-center message">
        {{ message }}
      </p>
      <div class="d-flex justify-content-between mb-3">
        <button mat-button (click)="pressBeforeButton()">
          <i class="material-icons fleetColor pointerCursor"
            >keyboard_arrow_left</i
          >
        </button>
        <button mat-button (click)="pressNextButton()">
          <i class="material-icons fleetColor pointerCursor"
            >keyboard_arrow_right</i
          >
        </button>
      </div>
    </div>

    <div class="col-6 borderRed fleetBackgroundColor text-center">
      <div>
        <img [src]="imageUrl" style="height: auto" />
      </div>
      <div *ngIf="messageUnderImage" style="color: white; font-weight: bold">
        {{ messageUnderImage }}
      </div>
    </div>
  </div>
</div>
