import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-no-content',
  templateUrl: './report-no-content.component.html',
  styleUrls: ['./report-no-content.component.sass'],
})
export class ReportNoContentComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
