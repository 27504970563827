import { Injectable } from '@angular/core';
import { ApiRequestsService } from '../../api';
import { CustomLoadingService } from './loading.service';
import { UserService } from './user.service';
import { AnalyticsService } from './analytics.service';
import * as moment from 'moment';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root',
})
export class ReportV2Service {
  constructor(
    private api: ApiRequestsService,
    private loading: CustomLoadingService,
    private userService: UserService,
    private analytics: AnalyticsService,
    private firebase: FirebaseService
  ) {}

  async getReport({ filters, data, method = 'download', type, emails = null }) {
    this.api.reports
      .getPdfReport({
        filters,
        data,
        type,
        method,
        emails,
      })
      .subscribe(
        pdfData => {
          if (method === 'download') {
            const file = new Blob([pdfData], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          }
        },
        error => {
          console.log('e', error);
        }
      );
  }

  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  isSameDate(date) {
    const init = moment(date.initDate, 'YYYY-MM-DD');
    const end = moment(date.endDate, 'YYYY-MM-DD');
    return init.isSame(end);
  }
  checkMail(mail) {
    const emailRegex: RegExp =
      /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

    if (!emailRegex.test(mail.email)) {
      mail.bgColor = '#efced5';
      mail.isValid = false;
    }
    return mail;
  }

  normalizeDate(date) {
    const initDate = moment(date.initDate, 'YYYY-MM-DD').toISOString();
    const endDate = moment(date.endDate, 'YYYY-MM-DD').toISOString();
    return {
      initDate: initDate,
      endDate: endDate,
    };
  }

  getFilterApplied(filter, type) {
    if (type === 'fast') {
      switch (filter) {
        case 1:
          return 'Todos';
        case 2:
          return 'Incompletos';
        case 3:
          return 'Todo Ok';
        case 4:
          return 'Rechazados';
      }
    }
    if (type === 'route') {
      switch (filter) {
        case 1:
          return 'Todas';
        case 3:
          return 'Completadas';
        case 4:
          return 'Canceladas';
        case 9:
          return 'Pendientes';
        case 10:
          return 'Incompletas';
      }
    }
  }
  async sendEmail({ content, pdf, type, emails }) {
    const pdfBase64 = await this.blobToBase64(pdf);
    let reportInfo = {};
    let analytic_text = '';
    switch (type) {
      case 'route-list':
        reportInfo = {
          driverName: content.driver,
          type: 'route-list',
          date: this.normalizeDate(content.date),
          name: null,
          status: this.getFilterApplied(content.filterApplied, 'route'),
        };
        analytic_text = 'Report Download PDF Route List';
        break;
      case 'route-individual':
        console.log(content.route);
        reportInfo = {
          driverName: null,
          type: 'route-individual',
          date: { initDate: content.route.date, endDate: content.route.date },
          name: content.route.label,
          status: null,
        };
        analytic_text = 'Report Download PDF Route Individual';
        break;
      case 'delivery':
        reportInfo = {
          driverName: null,
          type: 'delivery',
          date: {
            initDate: content.item.documentedAt || content.item.updatedAt,
            endDate: content.item.documentedAt || content.item.updatedAt,
          },
          name: content.item.deliveryPoint.deliveryName,
          status: null,
        };
        analytic_text = 'Report Download PDF Route Destination';
        break;
      case 'destination-delivery':
        reportInfo = {
          driverName: null,
          type: 'delivery',
          date: {
            initDate: content.item.documentedAt || content.item.updatedAt,
            endDate: content.item.documentedAt || content.item.updatedAt,
          },
          name: content.item.deliveryPoint.deliveryName,
          status: null,
        };
        analytic_text = 'Report Download PDF History Destination';
        break;
      case 'fast-list':
        const sortedArray = content.points.sort(
          (a, b) =>
            moment(b.documentedAt).valueOf() - moment(a.documentedAt).valueOf()
        );
        content.points = sortedArray;
        reportInfo = {
          driverName: content.driver,
          type: 'fast-list',
          date: this.normalizeDate(content.date),
          name: null,
          status: this.getFilterApplied(content.filterApplied, 'fast'),
        };
        analytic_text = 'Report Download PDF Fast Registry List';
        break;
      case 'fast-list-history':
        reportInfo = {
          driverName: content.driver,
          type: 'fast-list',
          date: this.normalizeDate(content.date),
          name: null,
          status: this.getFilterApplied(content.filterApplied, 'fast'),
        };
        analytic_text = 'Report Download PDF History Registry List';
        break;
      case 'fast-individual':
        reportInfo = {
          driverName: null,
          type: 'delivery',
          date: {
            initDate: content.item.documentedAt || content.item.updatedAt,
            endDate: content.item.documentedAt || content.item.updatedAt,
          },
          name: content.item.deliveryPoint.deliveryName,
          status: null,
        };
        analytic_text = 'Report Download PDF Fast Registry Individual';
        break;
      case 'destination-individual':
        reportInfo = {
          driverName: null,
          type: 'delivery',
          date: {
            initDate: content.item.documentedAt || content.item.updatedAt,
            endDate: content.item.documentedAt || content.item.updatedAt,
          },
          name: content.item.deliveryPoint.deliveryName,
          status: null,
        };
        analytic_text = 'Report Download PDF Destination';
        break;
    }

    const payload = {
      emails: emails,
      report64: pdfBase64,
      adminName:
        this.userService.user.firstName + ' ' + this.userService.user.lastName,
      reportInfo: reportInfo,
    };

    this.firebase.sendEmailReport(payload);
  }
}
