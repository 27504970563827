import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mini-loading',
  templateUrl: './mini-loading.component.html',
  styleUrls: ['./mini-loading.component.sass'],
})
export class MiniLoadingComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
