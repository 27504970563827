<mat-card-header class="detail-header">
  <div class="content-details">
    <h2 class="content-title" *ngIf="name">{{ name ? name : '' }}</h2>
    <div class="actions">
      <ng-template #popTemplate>
        <div class="overlay-message">
          <span *ngIf="type === 'driver'">
            {{
              (hasAssignation
                ? 'CARD-ASSIGNMENT.DELETE.UNASSING-DRIVER'
                : 'CARD-ASSIGNMENT.DELETE.MESSAGE-DRIVER'
              ) | translate
            }}
          </span>
          <span *ngIf="type === 'vehicle'">
            {{
              (hasAssignation
                ? 'CARD-ASSIGNMENT.DELETE.UNASSING-VEHICLE'
                : 'CARD-ASSIGNMENT.DELETE.MESSAGE-VEHICLE'
              ) | translate
            }}
          </span>
        </div>
        <div class="button-row flex-container">
          <button mat-button color="primary" (click)="pop.hide()">
            {{ 'COMMON.CANCEL' | translate }}
          </button>
          <button mat-button color="warn" (click)="deleteEmit()">
            {{
              (hasAssignation ? 'COMMON.UNASSIGN' : 'COMMON.DELETE') | translate
            }}
          </button>
        </div>
      </ng-template>

      <div
        *appRole="['company', 'manager_writer']"
        class="edit"
        (click)="editCall.emit()">
        <i class="material-icons">edit</i>
        <h4 class="content-action">{{ 'COMMON.EDIT' | translate }}</h4>
      </div>
      <!--      <div class="delete m-l-sm" #pop="bs-popover" [popover]="popTemplate" placement="bottom">-->
      <!--        <i class="material-icons"> delete</i>-->
      <!--        <h4 class="content-action"> -->
      <!--          {{ (hasAssignation ?  "COMMON.UNASSIGN" : "COMMON.DELETE") | translate }}-->
      <!--        </h4>-->
      <!--        <div #overlayTarget></div>-->
      <!--      </div>-->
    </div>
  </div>
  <div class="content-details" *ngIf="state.label">
    <div class="state" [ngClass]="state.className">
      <span>{{ state?.label }} </span>
    </div>
  </div>

  <div class="avatar-picture">
    <img [src]="imgSrc == null ? placeholder : imgSrc" />
  </div>
</mat-card-header>
