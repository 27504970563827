<div class="pagination-footer">
  <span class="footer-label">
    {{ 'CARD-ASSIGNMENT.FILES-PER-PAGE' | translate }}:
  </span>
  <mat-form-field class="pages-options">
    <mat-select
      [(value)]="rowsPerPage"
      (selectionChange)="onRowPerPageChanged.emit($event.value)">
      <mat-option *ngFor="let option of rowsCounts" [value]="option">
        {{ option }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <pagination-fleetflex
    [(paginationObject)]="paging"
    (backEvent)="backEvent.emit($event)"
    class="pagination-fleetflex"
    (nextEvent)="nextEvent.emit($event)">
  </pagination-fleetflex>
</div>
