import { Inject, Injectable } from '@angular/core';
import { INJECTION_TOKEN } from '../../app.config';
import { FirebaseService } from './firebase.service';
import { UserService } from './user.service';
import { ApiRequestsService } from '../../api';
import { CustomLoadingService } from './loading.service';
import { saveAs } from 'file-saver';
import { AnalyticsService } from './analytics.service';
@Injectable({
  providedIn: 'root',
})
export class ReportExcelService {
  constructor(
    @Inject(INJECTION_TOKEN) public config: any,
    private firebase: FirebaseService,
    private userService: UserService,
    private api: ApiRequestsService,
    private loading: CustomLoadingService,
    private analytics: AnalyticsService
  ) {}

  generateReport({
    type,
    data,
    driver = null,
    initDate = null,
    endDate = null,
    route = null,
    status = null,
    numerics = null,
    tags = null,
    deliveryStatus = null,
  }) {
    const payload = {};
    Object.assign(payload, {
      type,
      data,
      driver,
      initDate,
      endDate,
      route,
      status,
      numerics,
      tags,
      deliveryStatus,
    });

    switch (type) {
      case 'route-list':
        this.analytics.sendAnalyticsEvent(
          'Report Download Excel Route List',
          null,
          ''
        );
        break;
      case 'route-individual':
        this.analytics.sendAnalyticsEvent(
          'Report Download Excel Route Individual',
          null,
          ''
        );
        break;
      case 'delivery':
        this.analytics.sendAnalyticsEvent(
          'Report Download Excel Route Destination',
          null,
          ''
        );
        break;
      case 'fastRegistry-list':
        this.analytics.sendAnalyticsEvent(
          'Report Download Excel Fast Registry List',
          null,
          ''
        );
        break;
      case 'fastRegistry-individual':
        this.analytics.sendAnalyticsEvent(
          'Report Download Excel Fast Registry Individual',
          null,
          ''
        );
        break;
      case 'delivery_fastRegistry':
        this.analytics.sendAnalyticsEvent(
          'Report Download Excel Fast Registry Destination',
          null,
          ''
        );
        break;
    }

    this.api.reports
      .getExcelReport(payload)
      .toPromise()
      .then((response: Blob) => {
        saveAs(response, 'report.xlsx');
      })
      .catch(e => {});
  }
}
