<drag-scroll
  class="drag-container"
  [scrollbar-hidden]="true"
  [drag-scroll-y-disabled]="true"
  [scroll-x-wheel-enabled]="true">
  <div class="points-container">
    <div
      class="delivery-icon"
      *ngFor="let point of deliveryPoints; let index = index">
      <i
        class="material-icons"
        style="font-size: 30px"
        [ngClass]="{
          'deliver-icon': true,
          completed: point?.getClass() === 'completed',
          warning: point?.getClass() === 'warning',
          incomplete: point?.getClass() === 'incomplete'
        }">
        <span> {{ index + 1 }} </span>
      </i>
      <div class="stepper-bar-container">
        <span
          class="stepper-bar"
          [ngClass]="{
            'w-20': true,
            'completed-step': point?.getIcon()
          }"></span>
      </div>
    </div>
  </div>
</drag-scroll>
