import { Component, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { FirebaseService, SidePanelService } from '../../services';
import {
  isNull,
  isEmpty,
  has,
  isPlainObject,
  chain,
  forEach,
  delay,
} from 'lodash';
import * as moment from 'moment';
import { Howl } from 'howler';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'notification-badge',
  templateUrl: './notification-badge.component.html',
  styleUrls: ['./notification-badge.component.sass'],
})
export class NotificationBadgeComponent implements OnInit {
  count: number = 0;

  private owner: string = '';
  private subscriptions: any = {};
  private sound: any;
  private source: string = './assets/audio/notification-sound.mp3';

  @Output('open') badgeOpen: Subject<any> = new Subject<any>();

  constructor(
    private firebase: FirebaseService,
    private sidePanel: SidePanelService
  ) {
    this.sound = new Howl({
      src: [this.source],
      html5: true,
    });
  }

  ngOnInit() {
    this.subscriptions.notificationFetchLimit =
      this.sidePanel.notificationFetchLimit.subscribe(limit => {
        this.createSubscriptions(limit);
      });
  }

  ngAfterViewInit() {
    // wait until localstorage has current user data
    let interval = setInterval(() => {
      if (
        !isNull(localStorage.getItem('currentUserInfo')) &&
        !isEmpty(localStorage.getItem('currentUserInfo'))
      ) {
        let data = JSON.parse(localStorage.getItem('currentUserInfo'));
        this.owner = isPlainObject(data.data.companyId)
          ? data.data.companyId._id
          : data.data.companyId;
        delay(() => this.createSubscriptions(), 550);
        clearInterval(interval);
      }
    }, 500);
  }

  ngOnDestroy() {
    this.sidePanel.notificationsData.length = 0;
    forEach(this.subscriptions, value => value.unsubscribe());
  }

  /**
   * Create subscriptions for notifications of each user companyId
   *
   * @param limit
   */
  private createSubscriptions(limit = 50) {
    if (this.subscriptions.notifications)
      this.subscriptions.notifications.unsubscribe();

    this.subscriptions.notifications = this.firebase
      .subscribeToNode('notifications', this.owner, 'owner', limit)
      .pipe(debounceTime(100))
      .subscribe(val => {
        this.count = 0;
        let keys = val ? Object.keys(val) : [];

        let notifications = chain(keys)
          .map(key => {
            let item = val[key];
            item.notificationId = key;
            item.readed = true;
            item.createdAt = item.createdAt
              ? moment(item.createdAt).local(true).toDate()
              : moment().toDate();

            if (has(item, 'readedBy') && !item.readedBy[this.owner]) {
              item.readed = false;
              this.count++;
            }

            return item;
          })
          .filter((item, i) => !has(item, 'deletedAt'))
          .orderBy(e => moment(e.createdAt).format('x'), 'desc')
          .value();

        // if (this.count >= 1)
        //   this.sound.play();

        this.sidePanel.notificationsData = notifications;
        this.sidePanel.onNotifications.next(notifications);
      });
  }
}
