import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
//import 'rxjs/add/operator/map';
import * as moment from 'moment';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { INJECTION_TOKEN } from '../app.config';
import { Observable, throwError } from 'rxjs';
import { UserService } from '../shared/services/user.service';
import * as firebase from 'firebase';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class NewHttpClient {
  private pool: Array<any> = [];
  private unathorized: boolean = false;
  unauthorizedErrorCount = 0;

  private catchOptions: any = {
    maxRetryAttempts: 3,
    scalingDuration: 1000,
    excludedStatusCodes: [400, 500],
    includeStatusCodes: [401],
  };

  constructor(
    @Inject(INJECTION_TOKEN) public config: any,
    private http: HttpClient,
    private router: Router,
    private translate: TranslateService,
    private userService: UserService
  ) {}

  customHeaders(headers: HttpHeaders, contentType?: string) {
    let currentLang = sessionStorage.getItem('lang');
    let lang =
      currentLang === 'es' ? 'es-Es' : currentLang === 'en' ? 'en-En' : 'es-Es';
    let _contentType = contentType ? contentType : 'application/json';
    let _token = localStorage.getItem('currentUser');
    let sessionToken = `Bearer ${_token}`;
    let _timezone = moment().format('Z').replace(/\+/g, '').trim();

    // Set Authorization header if token exists
    if (_token) headers = headers.append('Authorization', sessionToken);

    // Set content type as JSON but if content type it's present, this one will be used instead
    headers = headers.append('Content-Type', _contentType);
    // Set lang
    headers = headers.append('Accept-Language', lang);
    // Set client timezone
    headers = headers.append('X-client-timezone', _timezone);

    return headers;
  }

  /**
   * GET Request
   *
   * @param url request url
   * @param showLoading show loading spinner until the request is finished;
   */
  get(url: string, showLoading: boolean = true) {
    let headers = new HttpHeaders();
    headers = this.customHeaders(headers);
    if (!showLoading) {
      headers = headers.append('showLoading', 'false');
    }
    return this.http
      .get(url, {
        headers: headers,
        withCredentials: true,
      })
      .pipe(
        map((response: HttpResponse<any>) => this.extractData(response)),
        catchError(error => this.handleError(error, { url, method: 'get' }))
      );
  }

  /**
   * POST Request
   *
   * @param url request url
   * @param data data object
   * @param [contentType] optional content type header
   * @param showLoading
   */
  post(
    url: string,
    data: any,
    contentType?: string,
    showLoading: boolean = true
  ) {
    let headers = new HttpHeaders();
    headers = this.customHeaders(headers, contentType);
    if (!showLoading) {
      headers = headers.append('showLoading', 'false');
    }
    return this.http
      .post(url, data, {
        headers: headers,
        withCredentials: true,
      })
      .pipe(
        map((response: HttpResponse<any>) => this.extractData(response)),
        catchError(error =>
          this.handleError(error, { url, data, method: 'post' })
        )
      );
  }

  /**
   * POST Request
   *
   * @param url request url
   * @param data data object
   * @param [contentType] optional content type header
   */
  post2(url: string, data: any, contentType?: string) {
    let headers = new HttpHeaders();
    headers = this.customHeaders(headers, contentType);
    return this.http.post(url, data, {
      headers: headers,
      withCredentials: true,
      responseType: 'blob',
    });
  }

  /**
   * POST Request custom error handlers
   *
   * @param url request url
   * @param data data object
   * @param [contentType] optional content type header
   */
  post3(url: string, data: any, contentType?: string) {
    let headers = new HttpHeaders();
    headers = this.customHeaders(headers, contentType);
    return this.http
      .post(url, data, {
        headers: headers,
        withCredentials: true,
      })
      .pipe(
        map((response: HttpResponse<any>) => this.extractData(response)),
        catchError(error => {
          return error;
        })
      );
  }

  /**
   * PUT Request
   *
   * @param url request url
   * @param data data object
   * @param [contentType] optional content type header
   */
  put(url: string, data: any, contentType?: string) {
    let headers = new HttpHeaders();
    headers = this.customHeaders(headers, contentType);
    return this.http
      .put(url, data, {
        headers: headers,
        withCredentials: true,
      })
      .pipe(
        map((response: HttpResponse<any>) => this.extractData(response)),
        catchError(error =>
          this.handleError(error, { url, data, method: 'put' })
        )
      );
  }

  /**
   * DELETE Request
   *
   * @param url request url
   */
  delete(url) {
    let headers = new HttpHeaders();
    headers = this.customHeaders(headers);
    return this.http
      .delete(url, {
        headers: headers,
        withCredentials: true,
      })
      .pipe(
        map((response: HttpResponse<any>) => this.extractData(response)),
        catchError(error => this.handleError(error, { url, method: 'delete' }))
      );
  }

  /**
   * Extract response from each request
   *
   * @param res Endpoint response
   */
  private extractData(res: HttpResponse<any>) {
    let body;

    if (res == null) return;

    // validate if response is not json
    if (res.body) {
      body = res.body || {};
    } else {
      body = res;
    }

    return body;
  }

  /**
   * Handle error if it's present on request
   *
   * @param error
   * @param from
   */
  private handleError(error: HttpResponse<any> | any, from?: any) {
    const message = this.errorMessage(this.getError(error), error.status);

    // TODO
    // improve way how to handle multiple requests whith 401 status at same time
    if (this.unathorized) {
      this.pool.push(from);
      const obs = new Observable(observer => observer.next({}));
      return obs;
    }

    // refresh token flow
    if (error.status === 401) {
      this.pool.push(from);
      return this.handleRefreshToken(message);
    }

    return throwError(message);
  }

  /**
   * Refresh user token call
   */
  private async refreshToken() {
    const payload = {
      refresh_token: localStorage.getItem('currentUserRT') || '',
    };

    const url = `${this.config.users_base}refresh-token`;
    const data = await this.post(url, payload).toPromise();
    return data;
  }

  /**
   * Clear auth from storage and redirect to login page
   */
  private clearAuth() {
    this.unathorized = false;
    this.pool.length = 0;
    this.userService.triggeredLogout();
    firebase.auth().signOut();
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  /**
   * Get message from HTTP error
   * or generate a custome one if status match an option
   *
   * @param error
   * @param status
   */
  private errorMessage(error: any, status?: any) {
    let message: any = {};

    switch (status) {
      case 404:
        message = {
          message: this.translate.instant('COMMON.NOT-FOUND'),
          ...error,
        };
        break;
      case 401:
        message = {
          message: this.translate.instant('COMMON.NOT-AUTORIZED'),
          ...error,
        };
        break;

      default:
        message = error;
        break;
    }

    return message;
  }

  /**
   * Get error from body
   *
   * @param error
   */
  private getError(error: any) {
    let body = error;
    try {
      // body = error.json() || {};
      body = _.isPlainObject(body.error.error)
        ? body.error.error
        : _.isPlainObject(body.error)
        ? body.error
        : body;
    } catch (e) {
      error = _.omit(error, [
        'headers',
        'toString',
        'json',
        'blob',
        'arrayBuffer',
        'text',
      ]);
      body = error;
    }

    return body;
  }

  /**
   * Call to refresh token method and do all pending
   * requests after token is refreshed
   *
   * @param message
   */
  private async handleRefreshToken(message) {
    if (this.unauthorizedErrorCount > 10) {
      this.clearAuth();
      this.unauthorizedErrorCount = 0;
      return Promise.reject(message);
    }
    this.unathorized = true;
    this.unauthorizedErrorCount++;

    try {
      const response = await this.refreshToken();

      if (response['error']) {
        this.clearAuth();
        return Promise.reject(message);
      }

      localStorage.setItem('currentUser', response['access_token']);
      localStorage.setItem('currentUserRT', response['refresh_token']);

      const promises = this.pool.map(req =>
        this[req.method](req.url, req.data || '').toPromise()
      );
      const allResponse = Promise.all(promises);

      this.unathorized = false;
      this.pool.length = 0;
      return allResponse;
    } catch (error) {
      this.clearAuth();
      return Promise.reject(message);
    }
  }
}
