<div class="account-verify-container">
  <div
    class="toast-backdrop"
    id="toaster-backdrop"
    *ngIf="displayBackDrop"></div>

  <button
    mat-raised-button
    class="alert-button"
    (click)="handleToasterDisplay()">
    <i class="material-icons"> error </i>
    <span class="activate-text">
      {{ 'SIGNUP-ACTIVATION.VERIFY-ACCOUNT' | translate }}
    </span>
  </button>

  <div
    class="toast-container"
    *ngIf="displayToaster"
    style="pointer-events: visible">
    <button
      id="toast-close"
      mat-icon-button
      class="toast-close"
      (click)="handleToasterDisplay()">
      <i class="material-icons">close</i>
    </button>

    <div class="toast-title">
      <h2 class="title">
        <i class="material-icons"> error </i>
        {{ 'SIGNUP-ACTIVATION.VERIFY-ACCOUNT' | translate }}
      </h2>
    </div>

    <div class="toast-body">
      <p>{{ 'SIGNUP-ACTIVATION.VERIFY-DISCLAIMER' | translate }}</p>
    </div>

    <div class="toast-footer">
      <button
        mat-raised-button
        color="primary"
        class="text-uppercase"
        (click)="displayActivationModal()">
        {{ 'SIGNUP-ACTIVATION.VERIFY-ACTION' | translate }}
      </button>
    </div>
  </div>
</div>

<activation-modal
  [user]="userInfo"
  (activated)="userActivated()"
  (hidden)="displayBackDrop = false"
  #modalActivation>
</activation-modal>
