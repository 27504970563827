import { Component, OnInit } from '@angular/core';
import { SubmenuService, HelpersService } from '../../../services';
import { ApiRequestsService } from '../../../../api';

@Component({
  selector: 'app-activation-code',
  templateUrl: './activation-code.component.html',
  styleUrls: ['./activation-code.component.sass'],
})
export class ActivationCodeComponent implements OnInit {
  activationCode: String = '000000';

  constructor(
    private submenuService: SubmenuService,
    private api: ApiRequestsService,
    private helper: HelpersService
  ) {}

  ngOnInit() {
    this.getCompany();
  }

  /**
   * Cancel customize
   */
  cancel() {
    this.submenuService.softBack.next(true);
  }

  /**
   * Get current company
   */
  private getCompany() {
    this.api.company
      .get()
      .toPromise()
      .then(response => {
        response = response.data;
        this.activationCode =
          response.driverDefaultPassword || this.activationCode;
      })
      .catch(error => this.helper.handleErrorMessages(error));
  }
}
