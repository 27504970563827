import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { forEach } from 'lodash';

@Component({
  selector: 'app-card-delete-confirmation',
  templateUrl: './card-delete-confirmation.component.html',
  styleUrls: ['./card-delete-confirmation.component.sass'],
})
export class CardDeleteConfirmationComponent implements OnInit {
  type: string = '';
  message: string = '';
  title: string = '';

  private subscriptions: any = {};

  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<CardDeleteConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.subscriptions.dialogOpen = this.dialogRef
      .afterOpened()
      .subscribe(() => {
        this.type = this.data.type ? this.data.type : 'driver';
        this.getMessageAndTitleByType(this.type, this.data.assigned);
      });
  }

  ngOnDestroy() {
    forEach(this.subscriptions, value => value.unsubscribe());
  }

  /**
   * Display delete message and title according to card type
   *
   * @param data
   * @param type
   * @param assigned
   */
  private getMessageAndTitleByType(type: any, assigned?: boolean) {
    let _message = this.translate.instant(
      'CARD-ASSIGNMENT.DELETE.MESSAGE-DRIVER'
    );
    let _title = this.translate.instant('CARD-ASSIGNMENT.DELETE.TITLE-DRIVER');

    if (type === 'driver' && assigned) {
      _message = this.translate.instant(
        'CARD-ASSIGNMENT.DELETE.UNASSING-DRIVER'
      );
      _title = this.translate.instant(
        'CARD-ASSIGNMENT.DELETE.UNASSING-TITLE-DRIVER'
      );
    } else if (type === 'driver') {
      _message = this.translate.instant(
        'CARD-ASSIGNMENT.DELETE.MESSAGE-DRIVER'
      );
      _title = this.translate.instant('CARD-ASSIGNMENT.DELETE.TITLE-DRIVER');
    }

    if (type === 'route' && assigned) {
      _message = this.translate.instant(
        'CARD-ASSIGNMENT.DELETE.UNASSING-ROUTE'
      );
      _title = this.translate.instant(
        'CARD-ASSIGNMENT.DELETE.UNASSING-TITLE-ROUTE'
      );
    } else if (type === 'route') {
      _message = this.translate.instant('CARD-ASSIGNMENT.DELETE.MESSAGE-ROUTE');
      _title = this.translate.instant('CARD-ASSIGNMENT.DELETE.TITLE-ROUTE');
    }

    if (type === 'vehicle' && assigned) {
      _message = this.translate.instant(
        'CARD-ASSIGNMENT.DELETE.UNASSING-VEHICLE'
      );
      _title = this.translate.instant(
        'CARD-ASSIGNMENT.DELETE.UNASSING-TITLE-VEHICLE'
      );
    } else if (type === 'vehicle') {
      _message = this.translate.instant(
        'CARD-ASSIGNMENT.DELETE.MESSAGE-VEHICLE'
      );
      _title = this.translate.instant('CARD-ASSIGNMENT.DELETE.TITLE-VEHICLE');
    }

    this.title = _title;
    this.message = _message;
  }
}
