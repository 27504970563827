<div class="child-container">
  <div class="child-header">
    <div class="wrapper">
      <button mat-icon-button (click)="closeChild()" class="child-close">
        <i class="material-icons">chevron_left</i>
      </button>

      <h4 class="title">
        {{ title | translate }}
      </h4>
    </div>
  </div>

  <div *ngIf="menuItems.length && !inChildView">
    <div *ngFor="let item of menuItems">
      <div
        class="item-row"
        *ngIf="item.display"
        [ngClass]="{ 'disabled-item': item.disabled }"
        (click)="childClick(item)">
        <button mat-icon-button class="item-button">
          <i class="m-r-sm" *ngIf="item.icon" [ngClass]="item?.icon"></i>

          {{ item?.label | translate }}
        </button>

        <button
          mat-icon-button
          class="icon-button"
          *ngIf="item?.hasItems || item['secondary-icon']">
          <i class="material-icons">
            {{
              item['secondary-icon'] ? item['secondary-icon'] : 'chevron_right'
            }}
          </i>
        </button>
      </div>
    </div>
  </div>

  <div class="component-holder" *ngIf="inChildView">
    <ng-template customRender></ng-template>
  </div>
</div>
