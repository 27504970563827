<div class="pagination-header">
  <p class="pagination-info m-r-sm">{{ showing() }}</p>
  <div class="pagination-controls">
    <i
      class="material-icons left"
      [ngClass]="{ disabled: !canGoPrevious() }"
      (click)="previous()"
      >chevron_left</i
    >
    <i
      class="material-icons right"
      [ngClass]="{ disabled: !canGoNext() }"
      (click)="next()"
      >chevron_right</i
    >
  </div>
</div>
