<mat-card-header class="card-header">
  <card-status-badge [item]="content"></card-status-badge>

  <div class="card-head-details" (click)="goToDetails()">
    <mat-card-title>
      {{ content.label ? content.label : '---' }}
    </mat-card-title>
  </div>

  <div class="card-form">
    <div class="form-item">
      <span class="control-label">
        {{ 'CARD-ASSIGNMENT.ROUTE-STARTS' | translate }}:
      </span>
      <span class="control-value capitalize-text"> {{ startDateLabel }} </span>
    </div>
    <div class="form-item">
      <span class="control-label">
        {{ 'CARD-ASSIGNMENT.ROUTE-ENDS' | translate }}:
      </span>
      <span class="control-value capitalize-text"> {{ endDateLabel }} </span>
    </div>
  </div>

  <div class="container-item">
    <div
      class="routes-items-container"
      [ngClass]="{
        'corousel-enabled':
          content?.deliverPoints?.length >= (viewType === 'list' ? 5 : 8)
      }"
      *ngIf="content?.deliverPoints?.length">
      <div
        class="arrow-container"
        *ngIf="content.deliverPoints?.length >= (viewType === 'list' ? 5 : 8)">
        <i class="arrow-left material-icons" (click)="scroll(0)">
          arrow_drop_down
        </i>
      </div>

      <div
        [id]="content._id"
        class="step-container"
        [ngClass]="{ 'w-full': viewType !== 'list' }">
        <points-stepper [content]="content"></points-stepper>
      </div>

      <div
        class="arrow-container"
        *ngIf="content?.deliverPoints?.length >= (viewType === 'list' ? 5 : 8)">
        <i class="arrow-right material-icons" (click)="scroll(1)">
          arrow_drop_down
        </i>
      </div>
    </div>
  </div>
</mat-card-header>
