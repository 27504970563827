<div class="">
  <div class="no_content_image">
    <img class="logo" src="/assets/img/no_content_1.png" />
  </div>
  <div class="">
    <h4 class="text-center" style="font-size: 28px">
      {{ 'No se han encontrado resultados' }}
    </h4>
    <h4 class="" style="font-weight: normal">{{ 'Puedes intentar:' }}</h4>
    <ul>
      <li>Revisar la ortografía</li>
      <li>Que esté en el rango de fechas elegido</li>
      <li>Seleccionar los filtros necesarios</li>
    </ul>
  </div>
</div>
