export const baseConfig = (baseUrl: string) => {
  return {
    transformImagesProvider: 'GoogleCloud',

    // default location
    location: {
      latitude: -0.161705,
      longitude: -78.484783,
    },

    //app enabled locations
    enabledLocations: ['ec', 'co', 'us', 'mx', 'bo', 'pe'],
    defaultCountries: [
      { name: 'Colombia', code: '+57' },
      { name: 'EEUU', code: '+1' },
      { name: 'Ecuador', code: '+593' },
      { name: 'Mexico', code: '+52' },
      { name: 'Peru', code: '+51' },
    ],

    // default times
    default_time_since: '06:00 AM',
    default_time_until: '08:00 PM',

    //langs

    langs: ['en', 'es'],
    defaultLang: 'es',

    // not activated account drivers invite count
    inactive_drivers_count: 2,

    //user
    users_base: `${baseUrl}/fleet/v1/users/`,
    me_url: `${baseUrl}/fleet/v1/users/me`,
    activation_code: `${baseUrl}/fleet/v1/users/activation-code`,

    // auth
    reset_password_url: `${baseUrl}/fleet/v1/users/reset-password`,
    oauth_url: `${baseUrl}/fleet/v1/users/login`,
    request_password_url: `${baseUrl}/fleet/v1/users/forgot-password`,

    // register
    register_url: `${baseUrl}/fleet/v1/users/company`,
    identity_url: `${baseUrl}/auth/v1/identity`,
    activate_account_url: `${baseUrl}/fleet/v1/users/activate`,
    activate_by_password: `${baseUrl}/auth/v1/activateByPassword`,

    //change password - temp
    change_password: `${baseUrl}/fleet/v1/users/change-password`,

    // vehicles
    vehicles_url: `${baseUrl}/fleet/v1/vehicles`,

    vehicles_plate_exists: `${baseUrl}/fleet/v1/vehicles/plate-exists`,
    vehicles_count_by_state: `${baseUrl}/fleet/v1/vehicles/count-vehicles-by-state`,
    vehicles_by_state: `${baseUrl}/fleet/v1/vehicles/vehicles-by-state`,

    // drivers
    drivers_url: `${baseUrl}/fleet/v1/users/driver`,
    drivers2_url: `${baseUrl}/fleet/v1/users/driver/v2`,
    drivers_list: `${baseUrl}/fleet/v1/users/drivers`,
    drivers_available: `${baseUrl}/fleet/v1/drivers`,
    number_drivers_available: `${baseUrl}/fleet/v1/drivers/number/available`,
    drivers_count_by_state: `${baseUrl}/fleet/v1/drivers/count-by-state`,
    drivers_by_state: `${baseUrl}/fleet/v1/drivers/drivers-by-state`,
    drivers_sessions: `${baseUrl}/fleet/v1/drivers/session-history`,

    // types
    vehicles_types_url: `${baseUrl}/fleet/v1/types`,
    vehicles_available: `${baseUrl}/fleet/v1/vehicles`,
    number_vehicles_available: `${baseUrl}/fleet/v1/vehicles/number/available`,

    // statuses
    vehicles_statuses_url: `${baseUrl}/fleet/v1/statuses`,
    //statuses's names
    activo: 'Activo',
    fds: 'Fuera de Servicio',

    // groups
    vehicles_groups_url: `${baseUrl}/fleet/v1/groups`,

    // addresses
    delivery_points_url: `${baseUrl}/fleet/v1/delivery-points`,
    delivery_points_export: `${baseUrl}/fleet/v1/delivery-points/export`,
    update_task: `${baseUrl}/fleet/v1/routes/updateAddress`,

    // tags
    tags_url: `${baseUrl}/fleet/v1/tags`,

    //routes
    routes_url: `${baseUrl}/fleet/v1/routes`,
    number_routes_active: `${baseUrl}/fleet/v1/routes/actives`,
    delivered: `${baseUrl}/fleet/v1/routes/progress`,
    routes_count_by_state: `${baseUrl}/fleet/v1/routes/count-by-state`,
    routes_by_state: `${baseUrl}/fleet/v1/routes/routes-by-state`,
    routes_templates: `${baseUrl}/fleet/v1/route-templates`,
    routes_templates_export: `${baseUrl}/fleet/v1/route-templates/export`,
    routes_import: `${baseUrl}/fleet/v1/routes/import`,
    points_import: `${baseUrl}/fleet/v1/delivery-points/import`,
    pre_import: `${baseUrl}/fleet/v1/routes/batch/file`,
    import_routes_templates: `${baseUrl}/fleet/v1/route-templates/import`,
    create_with_deliveryPoints: `${baseUrl}/fleet/v1/routes/create-from-template`,

    // warehouses
    warehouse_url: `${baseUrl}/fleet/v1/warehouses`,

    //upload
    upload_url: `${baseUrl}/files/v1/files`,
    upload_url_signed: `${baseUrl}/files/v1/files/signed-url`,

    // company
    company_url: `${baseUrl}/fleet/v1/companies`,
    configuration: `${baseUrl}/fleet/v1/companies/configuration`,
    unit_metrics: `${baseUrl}/fleet/v1/companies/metric_units`,
    company_custom_fields: `${baseUrl}/fleet/v1/companies/custom_fields`,

    //vehicles statuses
    vehicle_active_status: 'Activo',
    routes_pending_status: 'pending',

    //reports
    reports_url: `${baseUrl}/fleet/v1/delivery-points/reports`,
    reports_delivery_points: `${baseUrl}/fleet/v1/delivery-points/reports/deliveryPoints`,
    base_report_url: `${baseUrl}/fleet/v1/report`,

    //tracking
    tracking_url: `${baseUrl}/fleet/v1/delivery-points/tracking/`,

    // nofifications
    notifications_url: `${baseUrl}/fleet/v1/notifications`,
  };
};
