import { Injectable, Inject } from '@angular/core';
import { NewHttpClient } from '../interceptor.service';
//import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import { INJECTION_TOKEN } from '../../app.config';

@Injectable()
export class VehiclesRequests {
  constructor(
    @Inject(INJECTION_TOKEN) public config: any,
    private http: NewHttpClient
  ) {}

  /**
   * Get vehicles list
   *
   * @param data
   */
  get(data?: any): Observable<any> {
    let params = '';

    if (data) {
      let keys = Object.keys(data);
      keys.forEach(key => {
        if (!params.length) params = `?${key}=${data[key]}`;
        else params += `&${key}=${data[key]}`;
      });
    }

    let url = `${this.config.vehicles_url}${params}`;
    return this.http.get(url);
  }

  /**
   * Get vehicles by id
   *
   * @param id
   */
  getOne(id: string): Observable<any> {
    let url = `${this.config.vehicles_url}/${id}`;
    return this.http.get(url);
  }

  /**
   * Get available vehicles
   *
   * @param date
   * @param id
   */
  getAvailable(date: string, id: string): Observable<any> {
    let url = `${this.config.vehicles_available}/status/available/${date}/${id}`;
    return this.http.get(url);
  }

  /**
   * Get number of available vehicles
   *
   * @param date
   * @param status
   */
  getNumberAvailable(date: string, status: string): Observable<any> {
    let url = `${this.config.number_vehicles_available}?date=${date}&statusId=${status}`;
    return this.http.get(url);
  }

  /**
   * Create vehicles
   *
   * @param data
   */
  create(data: any): Observable<any> {
    let url = `${this.config.vehicles_url}`;
    return this.http.post(url, data);
  }

  /**
   * Edit a vehicles
   *
   * @param id
   * @param data
   */
  edit(id: string, data: any): Observable<any> {
    let url = `${this.config.vehicles_url}/${id}`;
    return this.http.put(url, data);
  }

  /**
   * Delete one vehicles
   *
   * @param id
   */
  delete(id: string): Observable<any> {
    let url = `${this.config.vehicles_url}/${id}`;
    return this.http.delete(url);
  }

  /**
   * Verify if license plate exists
   *
   * @param licensePlate raw license plate string
   */
  licensePlateCheck(licensePlate: string): Observable<any> {
    let url = this.config.vehicles_plate_exists;
    let data = {
      plate: licensePlate,
    };
    return this.http.post(url, data);
  }

  /**
   * Get vehicles types
   */
  getVehicleTypes(): Observable<any> {
    let url = this.config.vehicles_types_url;
    return this.http.get(url);
  }

  /**
   * Get vehicle statuses types
   *
   * @param data
   */
  getVehicleStatuses(data?: any): Observable<any> {
    let params = '';

    if (data) {
      let keys = Object.keys(data);
      keys.forEach(key => {
        if (!params.length) params = `?${key}=${data[key]}`;
        else params += `&${key}=${data[key]}`;
      });
    }

    let url = `${this.config.vehicles_statuses_url}${params}`;
    return this.http.get(url);
  }

  /**
   * Get vehicles count by state, ig: Assigned, not assigned, setback
   *
   * @param data
   */
  getVehicleCountByState(data?: any): Observable<any> {
    let params = '';

    if (data) {
      let keys = Object.keys(data);
      keys.forEach(key => {
        if (!params.length) params = `?${key}=${data[key]}`;
        else params += `&${key}=${data[key]}`;
      });
    }
    let url = `${this.config.vehicles_count_by_state}/${params}`;
    return this.http.get(url);
  }

  /**
   * Get vehicles by state according to status sent from the backend, ig: Assigned, not assigned, setback
   *
   * @param q
   * @param data
   */
  getVehiclesListByState(q, data?: any): Observable<any> {
    let params = '';

    if (data) {
      let keys = Object.keys(data);
      keys.forEach(key => {
        if (!params.length) params = `?${key}=${data[key]}`;
        else params += `&${key}=${data[key]}`;
      });
    }

    let url = `${this.config.vehicles_by_state}/${q}${params}`;
    return this.http.get(url);
  }

  /**
   * Get vehicles groups
   */
  getVehiclesGroups(): Observable<any> {
    let url = this.config.vehicles_groups_url;
    return this.http.get(url);
  }
}
