<div style="display: block; position: relative">
  <!-- Card menu actions -->
  <mat-menu style="z-index: 30" #cardMenu="matMenu" [xPosition]="'before'">
    <button
      class="visible-sm visible-xs"
      mat-menu-item
      (click)="goToDetails(content)"
      *ngIf="type === 'driver'">
      <mat-icon>phone</mat-icon>
      <span>
        {{ content['phone'] ? content['phone'] : '----' }}
      </span>
    </button>

    <button mat-menu-item (click)="goToDetails(content)" *ngIf="!deactivated">
      <mat-icon>event_note</mat-icon>
      <span>{{ 'COMMON.DETAILS' | translate }}</span>
    </button>

    <button
      mat-menu-item
      (click)="editComponent()"
      *ngIf="type === 'route' && !completed && !cancelled && !deactivated">
      <mat-icon>mode_edit</mat-icon>
      <span>{{ 'COMMON.EDIT' | translate }}</span>
    </button>

    <button
      mat-menu-item
      (click)="openCancelDialog()"
      *ngIf="type === 'route' && !completed && !cancelled && hasAssignation">
      <mat-icon>not_interested</mat-icon>
      <span>{{ 'CARD-ASSIGNMENT.CANCEL' | translate }}</span>
    </button>

    <button
      mat-menu-item
      (click)="editComponent()"
      *ngIf="
        type !== 'route' &&
        content?.route?.status !== 'in-progress' &&
        !deactivated
      ">
      <mat-icon>mode_edit</mat-icon>
      <span>{{ 'COMMON.EDIT' | translate }}</span>
    </button>

    <button
      mat-menu-item
      (click)="resendInvitation()"
      *ngIf="type === 'driver' && !content?.isActive">
      <mat-icon>forward</mat-icon>
      <span> {{ 'COMMON.RESEND-INVITATION' | translate }} </span>
    </button>

    <button
      mat-menu-item
      (click)="openConfirmationDialog()"
      *ngIf="
        hasAssignation &&
        !routeCompleted &&
        !isOnRoute &&
        !isSetback &&
        !cancelled
      ">
      <mat-icon>block</mat-icon>
      <span>
        {{ (hasAssignation ? 'COMMON.UNASSIGN' : 'COMMON.DELETE') | translate }}
      </span>
    </button>

    <button
      mat-menu-item
      (click)="deactivateDriver(true, driverId)"
      *ngIf="!deactivated">
      <mat-icon>delete</mat-icon>
      <span>{{ 'DELETE.TITLE-DRIVER' | translate }}</span>
    </button>

    <button
      mat-menu-item
      (click)="deactivateDriver(false, driverId)"
      *ngIf="deactivated">
      <mat-icon>restore_from_trash</mat-icon>
      <span>{{ 'COMMON.REACTIVATE-DRIVER' | translate }}</span>
    </button>
  </mat-menu>

  <div
    class="overlay"
    [ngStyle]="{ display: deactivated ? 'block' : 'none' }"></div>

  <mat-card appearance="outlined" class="card-assignment-row" role="group">
    <mat-card-content class="card-assigment-content-row">
      <!-- Col avatar -->
      <div
        class="row-avatar hidden-md hidden-sm hidden-xs m-r-xs"
        *ngIf="type === 'driver' || type === 'vehicle'"
        (click)="goToDetails(content)"
        style="cursor: pointer">
        <img
          mat-card-avatar
          *ngIf="type === 'driver'"
          [src]="content.image ? content.image : driverPicturePlaceholder" />
        <span
          class="status"
          *ngIf="type === 'driver'"
          [ngClass]="{ active: customDriver?.isOnline }">
        </span>
        <img
          mat-card-avatar
          *ngIf="type === 'vehicle'"
          [src]="
            content.vehicleImage
              ? content.vehicleImage
              : vehiclePicturePlaceholder
          " />
      </div>

      <!-- Col Plate -->
      <div
        class="row-column hidden-md hidden-sm hidden-xs"
        *ngIf="type === 'vehicle' || type === 'route'"
        [ngClass]="{ 'row-labels': type === 'route' }"
        style="cursor: pointer">
        <span
          class="row-field"
          *ngIf="type === 'vehicle'"
          (click)="goToDetails(content)">
          {{ content?.plate }}
        </span>
        <span
          class="row-field bold"
          *ngIf="type === 'route'"
          (click)="goToDetails(content)">
          {{ content.label ? content.label : '---' }}
        </span>
      </div>

      <!-- Name/Vehicle -->
      <div
        class="row-column row-name hidden-md hidden-sm hidden-xs"
        *ngIf="type === 'driver' || type === 'vehicle'"
        style="cursor: pointer">
        <!-- Driver -->
        <div class="card-item-details" *ngIf="type === 'driver'">
          <p class="card-item-title" (click)="goToDetails(content)">
            {{ driverName }}
          </p>
          <p class="card-item-subtitle" (click)="goToDetails(content)">
            {{ 'COMMON.IDENTIFICATION' | translate }}:
            {{ content['document-number'] ? content['document-number'] : '' }}
          </p>
        </div>

        <!-- Vehicle -->
        <div
          class="card-item-details"
          *ngIf="type === 'vehicle'"
          style="cursor: pointer">
          <p class="card-item-title" (click)="goToDetails(content)">
            {{ content?.brand }} {{ content?.model }}
          </p>
        </div>
      </div>

      <!-- Locations big -->

      <div
        class="row-column row-status hidden-md hidden-sm hidden-xs"
        *ngIf="type === 'route'">
        <div
          class="routes-items-container"
          [ngClass]="{ 'corousel-enabled': content.deliverPoints.length >= 6 }"
          *ngIf="content.deliverPoints.length">
          <div
            class="arrow-container"
            *ngIf="content.deliverPoints.length >= 6">
            <i class="arrow-left material-icons" (click)="scroll(0)">
              arrow_drop_down
            </i>
          </div>

          <div [id]="cardId" class="step-container">
            <div
              class="route-item"
              *ngFor="
                let point of content?.index;
                let index = index;
                let last = last
              "
              [ngClass]="point?.className">
              <span *ngIf="!point.icon"> {{ index + 1 }} </span>
              <mat-icon class="step-icon" *ngIf="point.icon">
                {{ point.icon }}
              </mat-icon>
            </div>
          </div>

          <div
            class="arrow-container"
            *ngIf="content.deliverPoints.length >= 6">
            <i class="arrow-right material-icons" (click)="scroll(1)">
              arrow_drop_down
            </i>
          </div>
        </div>
      </div>

      <!-- Status -->
      <card-status-badge
        class="row-column row-status hidden-md hidden-sm hidden-xs"
        [id]="isFirst && type === 'driver' ? 'driver-status' : ''"
        [ngClass]="{
          'm-r-sm': type === 'driver' || type === 'vehicle',
          'm-r-n': type === 'route'
        }"
        [oldStyle]="true"
        [cardId]="cardId"
        [instanceType]="type"
        [item]="content">
      </card-status-badge>

      <!-- Day -->
      <div
        class="row-column row-status hidden-md hidden-sm hidden-xs"
        *ngIf="type === 'route'">
        <span class="row-field"> {{ getDateText(content?.date) }}</span>
      </div>

      <!-- First item -->
      <div
        class="row-column row-card visible-md visible-sm visible-xs m-t-sm"
        *ngIf="type === 'driver'">
        <card-header-driver
          #driverRef
          [assigned]="isAssigned"
          [inProgress]="isOnRoute"
          [setback]="isSetback"
          [specialStatus]="statusSpecial"
          [parentCardId]="cardId"
          [content]="content">
        </card-header-driver>
      </div>

      <div
        class="row-column row-card visible-md visible-md visible-sm visible-xs m-t-sm"
        *ngIf="type === 'vehicle'">
        <card-header-vehicle
          [assigned]="isAssigned"
          [inProgress]="isOnRoute"
          [setback]="isSetback"
          [specialStatus]="statusSpecial"
          [parentCardId]="cardId"
          [content]="content">
        </card-header-vehicle>
      </div>

      <div
        class="row-column row-card visible-md visible-md visible-sm visible-xs m-t-sm"
        *ngIf="type === 'route'">
        <card-header-route
          [assigned]="isAssigned"
          [inProgress]="isOnRoute"
          [setback]="isSetback"
          [specialStatus]="statusSpecial"
          [parentCardId]="cardId"
          [viewType]="'list'"
          [content]="content">
        </card-header-route>
      </div>

      <!--     assign route -->
      <!--<div class="row-column row-card" *ngIf="type !== 'route'">
        <card-assign-item
          [viewType]="'list'"
          [available]="routesAvailable"
          [content]="content"
          [parentType]="type"
          [parentCardId]="cardId"
          [queryDate]="queryDate"
          [type]="'route'">
        </card-assign-item>
      </div>-->

      <!-- assign drive -->
      <div class="row-column row-card" *ngIf="type !== 'driver'">
        <card-assign-item
          [viewType]="'list'"
          [available]="driversAvailable"
          [content]="content"
          [parentType]="type"
          [parentCardId]="cardId"
          [queryDate]="queryDate"
          [type]="'driver'">
        </card-assign-item>
      </div>

      <!-- assign vehicle -->
      <div class="row-column row-card" *ngIf="type !== 'driver'">
        <card-assign-item
          [viewType]="'list'"
          [available]="vehiclesAvailable"
          [content]="content"
          [parentType]="type"
          [parentCardId]="cardId"
          [queryDate]="queryDate"
          [type]="'vehicle'">
        </card-assign-item>
      </div>

      <!-- Driver contact -->
      <!--    <div class="row-column row-card m-l-md hidden-sm hidden-xs" *ngIf="type === 'driver'">-->
      <!--      <div class="card-item-details">-->
      <!--        <div class="flex-center hidden">-->
      <!--          <mat-icon class="m-r-xs">email</mat-icon>-->
      <!--          <span class="card-item-subtitle ">-->
      <!--            {{ content['email'] ? content['email'] : '' }}-->
      <!--          </span>-->
      <!--        </div>-->
      <!--        <div class="flex-center m-t-sm">-->
      <!--          <mat-icon class="m-r-xs">phone</mat-icon>-->
      <!--          <span class="card-item-subtitle">-->
      <!--            {{ content['phone'] ? content['phone'] : '' }} {{ content['cellphone'] ? ' , ' + content['cellphone'] : '' }}-->
      <!--          </span>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--    </div>-->

      <div class="card-actions-head-toggle">
        <button mat-icon-button [matMenuTriggerFor]="cardMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
