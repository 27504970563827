import { Injectable, Inject } from '@angular/core';
import { INJECTION_TOKEN } from '../../app.config';
import { Angulartics2 } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { Angulartics2Mixpanel } from 'angulartics2/mixpanel';
import { merge } from 'lodash';

declare const mixpanel: any;
declare const ga: any;

@Injectable()
export class AnalyticsService {
  private emailRegex: RegExp =
    /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  mixpanelEnabled: boolean = false;

  constructor(
    @Inject(INJECTION_TOKEN) public config: any,
    public angulartics2: Angulartics2,
    public googleAnalytics: Angulartics2GoogleAnalytics,
    public mixpanelAnalytics: Angulartics2Mixpanel
  ) {
    mixpanelAnalytics.startTracking();
    googleAnalytics.startTracking();
  }

  /**
   * Initialize mixpanel
   */
  init() {
    // default use GA
    if (this.config['ga'] && this.config['ga']['ua'])
      ga('create', this.config.ga.ua, 'auto');

    if (this.mixpanelActive()) mixpanel.init(this.config.mixpanel_key);
  }

  /**
   * Register user in mixpanel at signup
   *
   * @param user
   */
  registerUser(user: any) {
    let _user = this.createUserData(user);

    this.googleAnalytics.setUsername(_user.id);

    if (this.mixpanelActive()) {
      mixpanel.people.set_once(_user);
      mixpanel.register_once(_user);
    }
  }

  /**
   * Update mixpanel user data
   *
   * @param user
   */
  updateUser(user: any) {
    let _user = this.createUserData(user);

    if (this.mixpanelActive()) {
      mixpanel.people.set(_user);
      mixpanel.register_once(_user);
    }
  }

  /**
   * Identify user in mixpanel
   *
   * @param user
   */
  identify(user: any) {
    if (this.mixpanelActive()) mixpanel.identify(user);
  }

  /**
   * Check if user it's idenfied by an email
   */
  identified() {
    if (this.mixpanelActive())
      return this.emailRegex.test(mixpanel.get_distinct_id());

    return false;
  }

  /**
   * reset mixpanel at logout
   */
  logout() {
    this.googleAnalytics.setUsername(null);
    if (this.mixpanelActive()) {
      mixpanel.reset();

      if (mixpanel.cookie) mixpanel.cookie.clear();
    }
  }

  /**
   * Send event to analytics dashboards
   *
   * @param action action name
   * @param properties  action payload
   * @param label action label for event
   */
  sendAnalyticsEvent(action: string, properties?: any, label?: string) {
    let payload: any = {
      action: action,
      properties: {
        category: label || 'Event',
        label: label || 'Event',
      },
    };

    if (properties) payload.properties = merge(payload.properties, properties);

    if (label) payload.label = label;

    // send event
    this.angulartics2.eventTrack.next(payload);
  }

  /**
   * Create user data to be stored on mixpanel
   *
   * @param user
   */
  private createUserData(user: any) {
    user['$email'] = user.email;
    user['id'] = user._id;

    if (user.firstName) user['$first_name'] = user.firstName;

    if (user.lastName) user['$last_name'] = user.lastName;

    if (user.createdAt) user['$created'] = user.createdAt;

    return user;
  }

  /**
   * Check if mixpanel it's active
   */
  private mixpanelActive(): Boolean {
    return mixpanel && this.mixpanelEnabled;
  }
}
