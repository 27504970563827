import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'customTime',
})
export class CustomTimePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) return null;

    let today = moment().startOf('day').format('YYYY-MM-DD');
    let format = args ? args : 'HH:mm';
    let time = moment(`${today} ${value}`, [
      'YYYY-MM-DD HH:mm',
      'YYYY-MM-DD hh:mm',
    ])
      .local()
      .format(format);

    return time;
  }
}
