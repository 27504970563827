<mat-card-header class="card-header">
  <card-status-badge [oldStyle]="true" [cardId]="parentCardId" [item]="content">
  </card-status-badge>

  <div class="card-head-details" (click)="goToDetails()">
    <mat-card-title>
      {{ content.label ? content.label : '---' }}
    </mat-card-title>
  </div>

  <div class="card-form">
    <div class="form-item">
      <span class="control-label">
        {{ 'CARD-ASSIGNMENT.ROUTE-START' | translate }}: &nbsp;</span
      >
      <span class="control-label"> {{ getDateText(content?.date) }} </span>
    </div>
  </div>
  <div class="container-item">
    <div
      class="routes-items-container"
      [ngClass]="{
        'corousel-enabled':
          content?.deliverPoints?.length >= (viewType === 'list' ? 5 : 8)
      }"
      *ngIf="content?.deliverPoints?.length">
      <div
        class="arrow-container"
        *ngIf="content.deliverPoints?.length >= (viewType === 'list' ? 5 : 8)">
        <i class="arrow-left material-icons" (click)="scroll(0)">
          arrow_drop_down
        </i>
      </div>

      <div
        [id]="content._id"
        class="step-container"
        [ngClass]="{ 'w-full': viewType !== 'list' }">
        <div
          class="route-item"
          *ngFor="
            let point of content?.index;
            let index = index;
            let last = last
          "
          [ngClass]="point?.className">
          <span *ngIf="!point.icon"> {{ index + 1 }} </span>
          <mat-icon class="step-icon" *ngIf="point.icon">
            {{ point.icon }}
          </mat-icon>
        </div>
      </div>

      <div
        class="arrow-container"
        *ngIf="content?.deliverPoints?.length >= (viewType === 'list' ? 5 : 8)">
        <i class="arrow-right material-icons" (click)="scroll(1)">
          arrow_drop_down
        </i>
      </div>
    </div>
  </div>
  <div class="card-form" *ngIf="displayPTimePicker">
    <div class="form-item">
      <label class="control-label">
        {{ 'CARD-ASSIGNMENT.ROUTE-STARTS' | translate }}:</label
      >
      <mat-form-field>
        <mat-select [(value)]="selected">
          <mat-option *ngFor="let option of dateOptions" [value]="option.value">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-item">
      <label class="control-label">
        {{ 'CARD-ASSIGNMENT.ETA' | translate }}:</label
      >
      <label class="control-value"> {{ totalDuration }} </label>
    </div>
  </div>
</mat-card-header>
