import {
  trigger,
  state,
  animate,
  transition,
  style,
} from '@angular/animations';

export const slideOutInAnimation = trigger('flyOutIn', [
  state('*', style({ transform: 'translateX(0)' })),
  transition('void => *', [
    style({ transform: 'translateX(-100%)' }),
    animate('350ms linear'),
  ]),
  transition('* => void', [
    animate('350ms linear', style({ transform: 'translateX(-100%)' })),
  ]),
]);
