import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ElementRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiRequestsService } from '../../../api';
import { HelpersService } from '../../services';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { UnitCreationModalComponent } from './unit-creation-modal/unit-creation-modal.component';

@Component({
  selector: 'app-units-modal',
  templateUrl: './units-modal.component.html',
  styleUrls: ['./units-modal.component.sass'],
})
export class UnitsModalComponent implements OnInit {
  units_metric = [];
  search_text = null;
  selected_unit = null;

  filteredOptions: Observable<any[]>;
  myControl = new UntypedFormControl('');
  private wasInside = true;
  private modalIsOpen = false;
  private isSearching = false;

  @Output() selected = new EventEmitter<any>();
  @Output() clickedOutEvent = new EventEmitter<boolean>();

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside && !this.modalIsOpen && !this.isSearching) {
      if (this.selected_unit) {
        console.log(this.selected_unit);
        this.selected.emit(this.selected_unit);
      } else {
        this.clickedOutEvent.emit(true);
      }
    }
    this.wasInside = false;
  }

  constructor(
    private apiServie: ApiRequestsService,
    private helperService: HelpersService,
    private dialog: MatDialog,
    private eRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.getUnitMetrics();

    this.filteredOptions = this.myControl.valueChanges.pipe(
      debounceTime(500),
      startWith(''),
      map(value => this._filter(value || ''))
    );
  }
  getUnitMetrics() {
    return this.apiServie.company
      .getUnitMetric()
      .toPromise()
      .then(response => {
        this.units_metric = response.data;
      })
      .catch(error => {
        this.helperService.handleErrorMessages(error);
      });
  }

  private _filter(value: string): any[] {
    if (value !== '') {
      this.isSearching = true;
      const filterValue = value.toLowerCase();
      return this.units_metric.filter(
        option =>
          option.unit?.toLowerCase().includes(filterValue) ||
          option.label?.toLowerCase().includes(filterValue)
      );
    }
    return [];
  }
  onSelectUnit() {
    this.selected.emit(this.selected_unit);
  }

  onSelectionChangeSearcher(unit) {
    this.selected_unit = unit;
    const temp = this.units_metric.filter(u => u._id !== unit._id);
    this.units_metric = [unit, ...temp];
    setTimeout(() => {
      this.isSearching = false;
    }, 1000);
  }

  openCreationUnitModal() {
    this.modalIsOpen = true;
    this.dialog
      .open(UnitCreationModalComponent)
      .afterClosed()
      .subscribe(response => {
        if (response) {
          console.log('RESPONSE UNIT', response);
          this.apiServie.company
            .createUnitMetric({
              unit: response.unit,
              label: response.label,
              category: 'others',
              unit_type: response.type,
              isVisible: true,
            })
            .subscribe(
              async responseCreation => {
                this.helperService.showSuccessMessage(
                  'Unidad creada con exito'
                );
                await this.getUnitMetrics();
                if (this.units_metric.length) {
                  this.onSelectionChangeSearcher(
                    this.units_metric[this.units_metric.length - 1]
                  );
                }
              },
              errorCreation => {
                this.helperService.handleErrorMessages(errorCreation);
              }
            );
        }
        this.modalIsOpen = false;
      });
  }
}
