import { Component, OnInit } from '@angular/core';
import {
  SidePanelService,
  FirebaseService,
  HelpersService,
} from '../../services';
import { forEach, chain } from 'lodash';
import { ApiRequestsService } from '../../../api';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.sass'],
})
export class NotificationsComponent implements OnInit {
  notifications: Array<any> = [];
  private subscriptions: any = {};

  constructor(
    private sidePanel: SidePanelService,
    private firebase: FirebaseService,
    private api: ApiRequestsService,
    private helper: HelpersService
  ) {}

  ngOnInit() {
    this.notifications = this.sidePanel.notificationsData;
    // subscribed to notifications when panel it's oppen
    this.subscriptions.onNotifications =
      this.sidePanel.onNotifications.subscribe(
        notifications => (this.notifications = notifications)
      );
    this.getCompanyConfiguration();
    setTimeout(() => this.markNotificationsAsRead(), 100);
  }

  // Set new notification limit to be fetched
  onReachedEnd(data) {
    const defaultLimit = 50;
    const actual_n = Math.floor(
      this.sidePanel.notificationsData.length / defaultLimit
    );
    if (this.sidePanel.notificationsData.length >= actual_n * defaultLimit) {
      this.sidePanel.notificationFetchLimit.next((actual_n + 1) * defaultLimit);
    }
  }

  ngOnDestroy() {
    forEach(this.subscriptions, value => value.unsubscribe());
  }
  async getCompanyConfiguration() {
    try {
      const response = await this.api.company
        .getFormConfiguration()
        .toPromise();
      this.helper.setCompanyConfigurations(response.data);
    } catch (e) {}
  }

  /**
   * Trigger panel close
   */
  close() {
    this.sidePanel.triggerClose.next(true);
  }

  /**
   * Mark all notification as read
   */
  private markNotificationsAsRead() {
    const notReaded = chain(this.notifications)
      .filter(notification => !notification.readedBy[notification.owner])
      .value();

    let query = {};
    notReaded.forEach((notification, i) => {
      query[
        `notifications/${notification.notificationId}/readedBy/${notification.owner}`
      ] = true;
    });

    this.firebase.db.ref().update(query);
  }
}
