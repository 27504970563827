import { Injectable, Inject } from '@angular/core';
import { LazyMapsAPILoaderConfigLiteral } from '@amin-karimi/agm-core';
import { INJECTION_TOKEN } from '../../app.config';

@Injectable()
export class MapsConfig implements LazyMapsAPILoaderConfigLiteral {
  public apiKey: string;
  public libraries: string[];
  public language: string;

  constructor(@Inject(INJECTION_TOKEN) public config: any) {
    this.apiKey = config.googleMapsApiKey;
    this.libraries = ['places', 'geometry'];
    this.language = 'es';
  }
}
