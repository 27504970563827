<div
  class="modal fade"
  bsModal
  #staticModal="bs-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
  [config]="config">
  <div class="modal-dialog modal-tutorial modal-lg m-t-xxl">
    <div class="modal-content">
      <div class="modal-body">
        <h1 class="title">{{ 'SIGNUP-SUCCESS.TITLE' | translate }}</h1>
        <p class="subtitle">{{ 'SIGNUP-SUCCESS.READY' | translate }}</p>
        <div class="profile-img-container">
          <img
            [src]="'assets/img/congrats.svg'"
            class="img-responsive"
            alt="Congrats" />
        </div>
      </div>
      <div class="modal-footer">
        <div class="profile-btn-container">
          <button
            class="text-uppercase"
            mat-raised-button
            color="primary"
            (click)="showTutorial()">
            {{ 'SIGNUP-SUCCESS.BEGIN' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
