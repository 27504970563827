import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  CustomLoadingService,
  HelpersService,
  MustMatch,
  UserService,
} from '../shared';
import * as _ from 'lodash';
import { ApiRequestsService } from '../api';
import { Router } from '@angular/router';
import { OnboardingService } from '../shared/services/onboarding.service';

@Component({
  selector: 'app-activate-by-password',
  templateUrl: './activate-by-password.component.html',
  styleUrls: ['./activate-by-password.component.sass'],
})
export class ActivateByPasswordComponent implements OnInit {
  activateByPasswordForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private api: ApiRequestsService,
    private loading: CustomLoadingService,
    private helper: HelpersService,
    private userService: UserService,
    private router: Router,
    private onBoardingService: OnboardingService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }
  private initForm() {
    this.activateByPasswordForm = this.formBuilder.group(
      {
        newPassword: ['', Validators.required],
        confirmPassword: ['', Validators.required],
      },
      {
        validator: MustMatch('newPassword', 'confirmPassword'),
      }
    );
  }
  updatePassword({ value, valid }: { value: any; valid: boolean }) {
    if (!valid) {
      _.forEach(this.activateByPasswordForm.controls, (val, key) => {
        if (!val.valid) {
          this.activateByPasswordForm.controls[key].markAsTouched();
          this.activateByPasswordForm.controls[key].setErrors({ valid: false });
        }
      });
      return;
    }

    this.api.auth
      .activateByPassword(value.newPassword)
      .toPromise()
      .finally(() => {
        // TODO: cahnge to then, cathc
        /*
            Assume that the response is correct. This is a bad practice used here because a cors problem.
            Please change it when cors not allowed credentials is fixed
           */
        this.userService.user.isActive = true;
        //this.userService.user.displayTutorial = true;

        localStorage.setItem(
          'currentUserInfo',
          JSON.stringify({ data: this.userService.user })
        );

        this.router.navigate(['/dashboard']);
        this.onBoardingService.startOnBoarding();
        this.userService.onProfileUpdate.next('');
        //this.userService.activated.next();
        this.api.user
          .me()
          .toPromise()
          .then(response => {
            localStorage.setItem('currentUserInfo', JSON.stringify(response));
            this.userService.user = response.data;
            this.userService.onProfileLoad.next(response.data);
          });
      });
  }
}
