import { Injectable, Inject } from '@angular/core';
import { NewHttpClient } from '../interceptor.service';
// import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import { INJECTION_TOKEN } from '../../app.config';
import { forEach } from 'lodash';

@Injectable()
export class CompanyRequests {
  constructor(
    @Inject(INJECTION_TOKEN) public config: any,
    private http: NewHttpClient
  ) {}

  /**
   * Get company
   *
   * @param data
   */
  get(data?: any): Observable<any> {
    let params = '';

    if (data) {
      const keys = Object.keys(data);
      keys.forEach(key => {
        if (!params.length) {
          params = `?${key}=${data[key]}`;
        } else {
          params += `&${key}=${data[key]}`;
        }
      });
    }

    const url = `${this.config.company_url}/${params}`;
    return this.http.get(url);
  }

  /**
   * Get company form configuration
   */
  getFormConfiguration(): Observable<any> {
    const url = `${this.config.configuration}`;
    return this.http.get(url);
  }

  /**
   * Edit company form configuration
   *
   * @param data
   */
  updateFormConfiguration(data: any): Observable<any> {
    const url = `${this.config.configuration}`;
    const form = {
      driver_form: data,
    };
    return this.http.put(url, form);
  }

  /**
   * Get driver by id
   *
   * @param id
   */
  getOne(id: string): Observable<any> {
    const url = `${this.config.company_url}/${id}`;
    return this.http.get(url);
  }

  /**
   * Update company profile
   *
   * @param data
   */
  update(data: any): Observable<any> {
    const url = `${this.config.company_url}`;
    return this.http.put(url, data);
  }

  /**
   * Upload company logo and do image transformation tasks
   *
   * @param file
   * @param fileName
   * @param tasks
   */
  uploadLogo(file: File, fileName: string, tasks?: any): Promise<any> {
    const payload = {
      provider: this.config.transformImagesProvider || 'GoogleCloud',
      bucket: this.config.upload_company_bucket,
      tasks: JSON.stringify(tasks),
    };

    const formData: FormData = new FormData();

    forEach(payload, (value, key) => {
      formData.append(key, value);
    });

    formData.append('file', file, fileName);

    const url = this.config.upload_url;
    const currentLang = sessionStorage.getItem('lang');
    const lang =
      currentLang === 'es' ? 'es-Es' : currentLang === 'en' ? 'en-En' : 'es-Es';

    return new Promise((resolve, reject) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.response));
          } else {
            reject(JSON.parse(xhr.response));
          }
        }
      };

      xhr.open('POST', url, true);
      xhr.setRequestHeader(
        'Authorization',
        `Bearer ${localStorage.getItem('currentUser')}`
      );
      xhr.setRequestHeader('Accept-Language', `${lang}`);
      xhr.send(formData);
    });
  }

  /**
   * Remove company logo
   */
  deleteLogo(): Observable<any> {
    const url = `${this.config.company_url}/logo`;
    return this.http.delete(url);
  }

  /**
   * Delete manager
   *
   * @param id
   */
  deleteManager(id: string): Observable<any> {
    console.log('en el delete', id);
    const url = `${this.config.users_base}manager/${id}`;
    return this.http.delete(url);
  }

  /*
   * Get company unit metrics
   */
  getUnitMetric(): Observable<any> {
    const url = `${this.config.unit_metrics}`;
    return this.http.get(url);
  }

  /*
   * Create company unit metrics
   */
  createUnitMetric({
    unit,
    label,
    category = 'others',
    unit_type = 'float',
    isVisible = true,
    isDeleted = false,
  }): Observable<any> {
    const url = `${this.config.unit_metrics}`;
    const payload = { unit, label, category, isVisible, isDeleted, unit_type };
    return this.http.post(url, payload);
  }

  /*
   * Create company's custom field
   */
  addCustomField({
    label,
    metric_unit,
    type,
    visible = true,
    isRequired = true,
    isDeleted = false,
  }): Observable<any> {
    const url = `${this.config.company_custom_fields}`;
    const payload = {
      label,
      metric_unit,
      type,
      isRequired,
      isDeleted,
      visible,
    };
    return this.http.post(url, payload);
  }

  /*
   * Update company's custom field
   */
  updateCustomField(id, data): Observable<any> {
    const url = `${this.config.company_custom_fields}/${id}`;
    return this.http.put(url, data);
  }
}
