import {
  Component,
  OnInit,
  Input,
  Output,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import {
  forEach,
  last,
  isEmpty,
  isNull,
  omit,
  chain,
  cloneDeep,
  find,
  isPlainObject,
} from 'lodash';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { CardDeleteConfirmationComponent } from './card-delete-confirmation/card-delete-confirmation.component';
import { CardCancelRouteComponent } from './card-cancel-route/card-cancel-route.component';
import {
  HelpersService,
  RouteAssignmentService,
  FirebaseService,
  DriverEventsService,
  CustomLoadingService,
} from '../../services';
import { filter, skip } from 'rxjs/operators';
import { ApiRequestsService } from '../../../api';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'card-assignment',
  templateUrl: './card-assignment.component.html',
  styleUrls: ['./card-assignment.component.sass'],
})
export class CardAssignmentComponent implements OnInit {
  @Input() type: string = 'driver';
  @Input() displayContent: boolean = true;

  @Input('driversCount') driversAvailable: Number = 0;
  @Input('vehiclesCount') vehiclesAvailable: Number = 0;
  @Input('routesCount') routesAvailable: Number = 0;
  @Input() queryDate: any = moment().format('YYYY-MM-DD');
  @Input() dataContent: any = {};
  @Input() isFirst: boolean = false;

  @Output() onEdition: Subject<any> = new Subject<any>();
  @Output() onDelete: Subject<any> = new Subject<any>();
  @Output() onCancel: Subject<any> = new Subject<any>();
  @Output() onResend: Subject<any> = new Subject<any>();

  routeCompleted: boolean = false;
  isAssigned: boolean = false;
  isOnRoute: boolean = false;
  isSetback: boolean = false;
  statusSpecial: boolean = false;
  driverOnline: boolean = false;
  isCancelled: boolean = false;
  cardId: string = '';
  hasAssignation: boolean = false;
  driverId: string = '';
  deactivated: boolean = false;

  subscriptions: any = {};
  private omittedStatus: Array<string> = ['completed', 'cancelled'];

  constructor(
    public dialog: MatDialog,
    public helpers: HelpersService,
    public router: Router,
    public routeAssign: RouteAssignmentService,
    public translate: TranslateService,
    public firebase: FirebaseService,
    public renderer: Renderer2,
    public componentRef: ElementRef,
    public statusService: DriverEventsService,
    public api: ApiRequestsService,
    public loading: CustomLoadingService,
    public helperService: HelpersService
  ) {
    this.cardId = this.helpers.randomItemId(5);
  }

  ngOnInit() {
    this.subscriptions.assignedStatusCheck =
      this.routeAssign.assignedStatusCheck
        .pipe(filter(item => item.cardId === this.cardId))
        .subscribe(item => {
          this.checkIfIsAssigned(item.route);

          if (item.checkCount) this.routeAssign.checkCounts.next(true);

          this.statusService.statusShouldUpdate.next(item);
        });

    this.driverId = this.dataContent._id;

    if (this.dataContent.deactivated !== null) {
      this.deactivated = this.dataContent.deactivated;
    }

    if (this.type === 'route') {
      this.checkIfIsAssigned(this.dataContent);
      this.createRouteSubscription(this.dataContent);
    }

    this.subscriptions.driverStatus = this.statusService.driverEvent
      .pipe(filter(item => item.cardId === this.cardId))
      .subscribe(statusEvent => {
        /*

        let _content = cloneDeep(this.dataContent);
        _content.lastEvent = pick(statusEvent, 'lastEvent') || {};
        _content.events = statusEvent.lastEvent ? [statusEvent.lastEvent] : [];

        let _payload = {..._content, ...pick(_content, ['driver', 'vehicle', 'events', 'lastEvent']) };

        _payload.cardId = this.cardId;
        this.statusService.statusShouldUpdate.next(_payload);
        this.componentRef.nativeElement.click();

         */
        this.statusService.statusShouldUpdate.next(statusEvent);
      });
  }

  ngOnDestroy() {
    forEach(this.subscriptions, value => value.unsubscribe());
  }

  /**
   * Redirect to edition view
   */
  editComponent() {
    return this.onEdition.next(this.dataContent._id);
  }

  /**
   * Resend driver invitation
   */
  resendInvitation() {
    return this.onResend.next(this.dataContent._id);
  }

  /**
   * Open delete confirmation dialog
   */
  openConfirmationDialog(): void {
    let dialogRef = this.dialog.open(CardDeleteConfirmationComponent, {
      width: '250px',
      data: { type: this.type, assigned: this.hasAssignation },
    });

    this.subscriptions.deleteDialog = dialogRef
      .afterClosed()
      .subscribe(result => {
        if (result) return this.onDelete.next(this.dataContent._id);

        return;
      });
  }

  /**
   * Open cancel confirmation dialog
   */
  openCancelDialog(): void {
    let dialogRef = this.dialog.open(CardCancelRouteComponent, {
      width: '430px',
      data: { route: this.dataContent },
    });

    this.subscriptions.deleteDialog = dialogRef
      .afterClosed()
      .subscribe(result => {
        if (result) return this.onCancel.next(this.dataContent);

        return;
      });
  }

  /**
   * Go to details page
   *
   * @param item
   */
  goToDetails(item) {
    if (this.type === 'driver')
      this.router.navigate(['drivers/details', item._id]);

    if (this.type === 'vehicle')
      this.router.navigate(['vehicles/details', item._id]);

    if (this.type === 'route') this.router.navigate(['routes', item._id]);
  }

  /**
   * Check if route item has driver and vehicle assigned
   *
   * @param item
   */
  checkIfIsAssigned(item: any) {
    this.hasAssignation = !isEmpty(item.vehicle) || !isEmpty(item.driver);
    this.isAssigned = !isEmpty(item.vehicle) && !isEmpty(item.driver);
    this.isOnRoute = item.status === 'in-progress';
    this.driverOnline = item.status === 'in-progress';

    this.isCancelled = item.status === 'cancelled';
    this.routeCompleted = item.status === 'completed';
    let events: any = item.events ? item.events : [];
    this.isSetback =
      item.status === 'in-progress' &&
      events.length &&
      last(events)['name'] === 'setback';

    if (this.isSetback) this.isOnRoute = false;

    this.statusSpecial = this.isOnRoute || this.isSetback ? true : false;

    if (this.isOnRoute || this.isSetback) this.isAssigned = false;
  }

  /**
   * Create firebase subcription for component route
   *
   * @param route
   */
  createRouteSubscription(route: any) {
    if (this.omittedStatus.indexOf(route.status) !== -1) return;

    let _node = `routes/${route._id}`;
    this.subscriptions[route._id] = this.firebase
      .subscribeToNode(_node, null, null)
      .pipe(
        skip(1),
        filter(item => !isNull(item))
      )
      .subscribe(item => {
        item = omit(item, ['adminPolyline', 'cursor']);
        item.parentCard = this.cardId;
        item.cardId = this.cardId;

        route.date = item.date || route.date;
        route.label = item.label || route.label;
        route.index = item.index || route.index;
        route.status = item.status || route.status;
        route.deliverPoints = this.generateDeliveryPoints(
          item.index,
          item.deliverPoints
        );

        item.unassign =
          (route.driver && !item.driver) ||
          (route.vehicle && !item.vehicle) ||
          false;

        route.driver = item.driver;
        route.vehicle = item.vehicle;

        item.events = item.lastEvent ? [item.lastEvent] : [];

        // refresh card at update
        this.routeAssign.refreshRoute.next(item);
        this.routeAssign.updateRouteItems.next(item);
        this.routeAssign.checkCounts.next(true);
        this.checkIfIsAssigned(item);
        this.statusService.statusShouldUpdate.next(item);

        this.componentRef.nativeElement.click();

        if (this.omittedStatus.indexOf(route.status) !== -1)
          this.subscriptions[route._id].unsubscribe();
      });
  }

  /**
   * Generate delivery points from firebase object
   *
   * @param index
   * @param indexes
   * @param points
   */
  private generateDeliveryPoints(indexes: Array<any>, points: any) {
    let deliveryPoins = chain(indexes)
      .map((index, position) => {
        index = cloneDeep(index);

        let point = find(points, e => {
          let id = isPlainObject(e) ? e.deliveryPoint._id : e;
          return id === index.id;
        });

        point = cloneDeep(point);

        return point ? point : null;
      })
      .filter(null)
      .value();

    return deliveryPoins;
  }

  deactivateDriver(activate: boolean, driverId: string) {
    if (activate) {
      const deleteConfirmation = this.dialog.open(ConfirmationModalComponent, {
        maxWidth: '40vw',
        data: {
          message: '¿Deseas eliminar al conductor de tu lista?',
          buttonText: {
            ok: 'Si',
            cancel: 'No',
          },
        },
      });
      deleteConfirmation.afterClosed().subscribe(result => {
        if (result) {
          let data = {
            deactivated: activate,
          };
          this.api.drivers.deleteDeep(driverId).subscribe(
            response => {
              this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              this.router.onSameUrlNavigation = 'reload';
              this.helperService.showSuccessMessage(
                'Se ha eliminado conductor'
              );
              return this.router.navigate(['/drivers']);
            },
            error => {
              this.helperService.handleErrorMessages(error);
            }
          );
        } else {
          //false
        }
      });
    } else {
      const deleteConfirmation = this.dialog.open(ConfirmationModalComponent, {
        maxWidth: '40vw',
        data: {
          message: '¿Deseas reactivar al conductor en tu lista',
          buttonText: {
            ok: 'Si',
            cancel: 'No',
          },
        },
      });
      deleteConfirmation.afterClosed().subscribe(result => {
        if (result) {
          let data = {
            deactivated: activate,
          };
          this.api.drivers.edit(driverId, data).subscribe(
            response => {
              this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              this.router.onSameUrlNavigation = 'reload';
              this.helperService.showSuccessMessage(
                'Se ha reactivado al conductor'
              );
              return this.router.navigate(['/drivers']);
            },
            error => {
              this.helperService.handleErrorMessages(error);
            }
          );
        } else {
          //false
        }
      });
    }
  }
}
