exports = {
  "data": [
    {
      "deliverPoints": [
        {
          "files": [],
          "referralGuides": [],
          "_id": "60d7d50b6c717f003d955cbf",
          "route": "60d7d50a954fc40026e747e2",
          "deliveryPoint": {
            "_id": "60d7d509954fc40026e747e1",
            "customerName": "Oko",
            "address": "N/A",
            "deliveryName": "Ss",
            "latitude": -2.1276248,
            "longitude": -79.9072064,
            "timeFrom": "06:00",
            "timeTo": "20:00",
            "phone": "12",
            "customerMail": "allanov1995@gmail.com",
            "companyId": "5f4fb96bff969b0042603e06",
            "details": "{\"warehouse\": \"\", \"comments\": [], \"referral_guides\": []}",
            "updatedAt": "2021-06-27T01:34:56.654Z",
            "createdAt": "2021-06-27T01:31:53.450Z",
            "cursor": 13883,
            "__v": 0,
            "buffer": 0,
            "type": "delivery",
            "deliveryPointRef": {
              "_id": "5fbd708cde7d51002d493a4d",
              "customerName": "Oko",
              "address": "N/A",
              "buffer": 0,
              "deliveryName": "Ss",
              "latitude": -2.1276248,
              "longitude": -79.9072064,
              "timeFrom": "06:00",
              "timeTo": "20:00",
              "phone": "12",
              "customerMail": "allanov1995@gmail.com",
              "details": "{\"warehouse\": \"Sector S\", \"comments\": [], \"referral_guides\": []}",
              "savedInDirectory": true,
              "companyId": "5f4fb96bff969b0042603e06",
              "updatedAt": "2021-07-02T21:22:08.757Z",
              "createdAt": "2020-11-24T20:43:56.283Z",
              "cursor": 12546,
              "__v": 0,
              "type": "delivery"
            }
          },
          "updatedAt": "2021-06-27T01:34:56.949Z",
          "createdAt": "2021-06-27T01:31:55.713Z",
          "cursor": 12467,
          "type": "delivery",
          "__v": 0,
          "documentedAt": "2021-06-27T01:34:16.916Z",
          "isDelivered": true,
          "latitude": 37.4219983,
          "longitude": -122.084,
          "receiverName": "ds",
          "status": "completed"
        },
        {
          "files": [],
          "referralGuides": [],
          "_id": "60d7d50c954fc40026e747e3",
          "route": "60d7d50a954fc40026e747e2",
          "deliveryPoint": {
            "_id": "60d7d5096c717f003d955cbd",
            "customerName": "Bxb",
            "address": "Bxb",
            "deliveryName": "Bxb",
            "latitude": -2.001960625905279,
            "longitude": -79.48487447574735,
            "timeFrom": "06:00",
            "timeTo": "20:00",
            "phone": "9988",
            "customerMail": "Xbbx",
            "companyId": "5f4fb96bff969b0042603e06",
            "details": "{\"warehouse\": \"\", \"comments\": [], \"referral_guides\": []}",
            "updatedAt": "2021-06-27T01:34:58.324Z",
            "createdAt": "2021-06-27T01:31:53.875Z",
            "cursor": 13884,
            "__v": 0,
            "buffer": 0,
            "type": "delivery",
            "deliveryPointRef": {
              "_id": "5fbd78f0c0b45700410d7cca",
              "customerName": "Bxb",
              "address": "Bxb",
              "buffer": 0,
              "deliveryName": "Bxb",
              "latitude": -2.001960625905279,
              "longitude": -79.48487447574735,
              "timeFrom": "06:00",
              "timeTo": "20:00",
              "phone": "9988",
              "customerMail": "Xbbx",
              "savedInDirectory": true,
              "companyId": "5f4fb96bff969b0042603e06",
              "updatedAt": "2021-07-22T17:48:45.166Z",
              "createdAt": "2020-11-24T21:19:44.866Z",
              "cursor": 12548,
              "__v": 0,
              "type": "delivery",
              "isDeleted": true
            }
          },
          "updatedAt": "2021-06-27T01:34:58.671Z",
          "createdAt": "2021-06-27T01:31:56.804Z",
          "cursor": 12468,
          "type": "delivery",
          "__v": 0,
          "documentedAt": "2021-06-27T01:34:24.912Z",
          "isDelivered": true,
          "latitude": 37.4219983,
          "longitude": -122.084,
          "receiverName": "fs",
          "status": "completed"
        },
        {
          "files": [],
          "referralGuides": [],
          "_id": "60d7d50d954fc40026e747e4",
          "route": "60d7d50a954fc40026e747e2",
          "deliveryPoint": {
            "_id": "60d7d50a6c717f003d955cbe",
            "customerName": "Ff",
            "address": "C. Nicaragua, Simón Bolívar, Ecuador",
            "deliveryName": "Vc",
            "latitude": -2.006276615689864,
            "longitude": -79.48341885717772,
            "timeFrom": "06:00",
            "timeTo": "20:00",
            "phone": "",
            "customerMail": "",
            "companyId": "5f4fb96bff969b0042603e06",
            "details": "{\"warehouse\": \"\", \"comments\": [], \"referral_guides\": []}",
            "updatedAt": "2021-06-27T01:34:59.367Z",
            "createdAt": "2021-06-27T01:31:54.280Z",
            "cursor": 13885,
            "__v": 0,
            "buffer": 0,
            "type": "delivery",
            "deliveryPointRef": {
              "_id": "5fbd9d204461f1004c263084",
              "customerName": "Ff",
              "address": "C. Nicaragua, Simón Bolívar, Ecuador",
              "buffer": 0,
              "deliveryName": "Vc",
              "latitude": -2.006276615689864,
              "longitude": -79.48341885717772,
              "timeFrom": "06:00",
              "timeTo": "20:00",
              "phone": "",
              "customerMail": "",
              "savedInDirectory": true,
              "companyId": "5f4fb96bff969b0042603e06",
              "updatedAt": "2021-06-26T23:48:24.425Z",
              "createdAt": "2020-11-24T23:54:08.472Z",
              "cursor": 12557,
              "__v": 0,
              "type": "delivery"
            }
          },
          "updatedAt": "2021-06-27T01:34:59.731Z",
          "createdAt": "2021-06-27T01:31:57.454Z",
          "cursor": 12469,
          "type": "delivery",
          "__v": 0,
          "documentedAt": "2021-06-27T01:34:31.743Z",
          "isDelivered": true,
          "latitude": 37.4219983,
          "longitude": -122.084,
          "receiverName": "czx",
          "status": "completed"
        }
      ],
      "index": [
        {
          "eta": {
            "text": "0.0 Sec",
            "timeIn": 1624759200000,
            "timeInText": "09:00",
            "timeOut": 1624759200000,
            "timeOutText": "21:0",
            "value": 0
          },
          "id": "60d7d509954fc40026e747e1",
          "index": 0
        },
        {
          "eta": {
            "text": "1.5 Hrs",
            "timeIn": 1624764599000,
            "timeInText": "10:29",
            "timeOut": 1624764599000,
            "timeOutText": "22:29",
            "value": 5399000
          },
          "id": "60d7d5096c717f003d955cbd",
          "index": 1
        },
        {
          "eta": {
            "text": "3.8 Min",
            "timeIn": 1624764827000,
            "timeInText": "10:33",
            "timeOut": 1624764827000,
            "timeOutText": "22:33",
            "value": 228000
          },
          "id": "60d7d50a6c717f003d955cbe",
          "index": 2
        }
      ],
      "bills": [],
      "events": [],
      "_id": "60d7d50a954fc40026e747e2",
      "companyId": "5f4fb96bff969b0042603e06",
      "userCreated": "5f4fb96bff969b0042603e07",
      "draft": false,
      "status": "completed",
      "updatedAt": "2021-06-27T01:35:03.340Z",
      "createdAt": "2021-06-27T01:31:54.846Z",
      "cursor": 3074,
      "__v": 3,
      "backToStart": false,
      "date": "2021-06-27T02:00:00.501Z",
      "driver": {
        "_id": "5fbd700ede7d51002d493a4c",
        "email": "loharo6778@tjuln.com",
        "companyId": "5f4fb96bff969b0042603e06",
        "image": "https://storage.googleapis.com/assets.weco.build/profile.png",
        "document-number": "98",
        "firstName": "Bx",
        "lastName": "Bx"
      },
      "label": "Esta si_1_1",
      "notes": "{\"template_id\":\"60493fd3a16ccc006d21ba40\",\"notes\":\"\"}",
      "startingPoint": "5fbd708cde7d51002d493a4d",
      "vehicle": {
        "_id": "60d7d5126c717f003d955cc0",
        "companyId": "5f4fb96bff969b0042603e06",
        "model": "Fleetflex",
        "plate": "8KOKJC"
      },
      "warehouse": null,
      "startedAt": "2021-06-27T01:33:01.811Z",
      "endedAT": "2021-06-27T01:34:35.103585Z"
    },
    {
      "deliverPoints": [
        {
          "files": [],
          "referralGuides": [],
          "_id": "60d7d3376c717f003d955cbb",
          "route": "60d7d3366c717f003d955cba",
          "deliveryPoint": {
            "_id": "60d7d334954fc40026e747dc",
            "customerName": "",
            "address": "Av. Francisco de Orellana 18, Guayaquil 090507, Ecuador",
            "buffer": 0,
            "deliveryName": "Das",
            "latitude": -2.12742263,
            "longitude": -79.9055072,
            "timeTo": "23:59",
            "timeFrom": "00:01",
            "details": "{\"warehouse\": \"\", \"comments\": \"ge\", \"referral_guides\": []}",
            "savedInDirectory": false,
            "companyId": "5f4fb96bff969b0042603e06",
            "updatedAt": "2021-06-27T01:25:54.487Z",
            "createdAt": "2021-06-27T01:24:04.758Z",
            "cursor": 13880,
            "__v": 0,
            "type": "delivery"
          },
          "updatedAt": "2021-06-27T01:25:54.928Z",
          "createdAt": "2021-06-27T01:24:07.476Z",
          "cursor": 12464,
          "type": "delivery",
          "__v": 0,
          "documentedAt": "2021-06-27T01:25:46.429Z",
          "isDelivered": true,
          "latitude": 37.4219983,
          "longitude": -122.084,
          "receiverName": "uo",
          "status": "completed"
        },
        {
          "files": [],
          "referralGuides": [],
          "_id": "60d7d3376c717f003d955cbc",
          "route": "60d7d3366c717f003d955cba",
          "deliveryPoint": {
            "_id": "60d7d335954fc40026e747dd",
            "customerName": "",
            "address": "Av. Fco. de Orellana Y 1° Herradura B NE, Guayaquil, Ecuador",
            "buffer": 0,
            "deliveryName": "Gwegwe",
            "latitude": -2.1275953899999998,
            "longitude": -79.9062813,
            "timeTo": "23:59",
            "timeFrom": "00:01",
            "details": "{\"warehouse\": \"\", \"comments\": \"wg\", \"referral_guides\": []}",
            "savedInDirectory": false,
            "companyId": "5f4fb96bff969b0042603e06",
            "updatedAt": "2021-06-27T01:25:55.635Z",
            "createdAt": "2021-06-27T01:24:05.248Z",
            "cursor": 13881,
            "__v": 0,
            "type": "delivery"
          },
          "updatedAt": "2021-06-27T01:25:56.054Z",
          "createdAt": "2021-06-27T01:24:07.843Z",
          "cursor": 12465,
          "type": "delivery",
          "__v": 0,
          "documentedAt": "2021-06-27T01:25:54.118Z",
          "isDelivered": true,
          "latitude": 37.4219983,
          "longitude": -122.084,
          "receiverName": "iy",
          "status": "incomplete"
        },
        {
          "files": [],
          "referralGuides": [],
          "_id": "60d7d338954fc40026e747df",
          "route": "60d7d3366c717f003d955cba",
          "deliveryPoint": {
            "_id": "60d7d335954fc40026e747de",
            "customerName": "",
            "address": "Av. Fco. de Orellana Y Pasaje 19b NE, Guayaquil 090507, Ecuador",
            "buffer": 0,
            "deliveryName": "Weg",
            "latitude": -2.1276222199999997,
            "longitude": -79.90570840000001,
            "timeTo": "23:59",
            "timeFrom": "00:01",
            "details": "{\"warehouse\": \"\", \"comments\": \"w\", \"referral_guides\": []}",
            "savedInDirectory": false,
            "companyId": "5f4fb96bff969b0042603e06",
            "updatedAt": "2021-06-27T01:26:05.247Z",
            "createdAt": "2021-06-27T01:24:05.581Z",
            "cursor": 13882,
            "__v": 0,
            "type": "delivery"
          },
          "updatedAt": "2021-06-27T01:26:05.527Z",
          "createdAt": "2021-06-27T01:24:08.196Z",
          "cursor": 12466,
          "type": "delivery",
          "__v": 0,
          "documentedAt": "2021-06-27T01:26:02.416Z",
          "isDelivered": true,
          "latitude": 37.4219983,
          "longitude": -122.084,
          "receiverName": "uo",
          "status": "rejected"
        }
      ],
      "index": [
        {
          "eta": {
            "text": "0.0 Sec",
            "timeIn": 1606488600000,
            "timeInText": "09:50",
            "timeOut": 1606488600000,
            "timeOutText": "9:50",
            "value": 1500
          },
          "id": "60d7d334954fc40026e747dc",
          "index": 0
        },
        {
          "eta": {
            "text": "0.0 Sec",
            "timeIn": 1606488600000,
            "timeInText": "09:50",
            "timeOut": 1606488600000,
            "timeOutText": "9:50",
            "value": 1500
          },
          "id": "60d7d335954fc40026e747dd",
          "index": 1
        },
        {
          "eta": {
            "text": "0.0 Sec",
            "timeIn": 1606488600000,
            "timeInText": "09:50",
            "timeOut": 1606488600000,
            "timeOutText": "9:50",
            "value": 1500
          },
          "id": "60d7d335954fc40026e747de",
          "index": 2
        }
      ],
      "bills": [],
      "events": [],
      "_id": "60d7d3366c717f003d955cba",
      "companyId": "5f4fb96bff969b0042603e06",
      "userCreated": "5f4fb96bff969b0042603e07",
      "draft": false,
      "status": "completed",
      "updatedAt": "2021-06-27T01:26:07.531Z",
      "createdAt": "2021-06-27T01:24:06.485Z",
      "cursor": 3073,
      "__v": 3,
      "backToStart": false,
      "date": "2021-06-27T01:55:00.878Z",
      "driver": {
        "_id": "5fbd700ede7d51002d493a4c",
        "email": "loharo6778@tjuln.com",
        "companyId": "5f4fb96bff969b0042603e06",
        "image": "https://storage.googleapis.com/assets.weco.build/profile.png",
        "document-number": "98",
        "firstName": "Bx",
        "lastName": "Bx"
      },
      "label": "Ruta rápida del 26/06/2021",
      "notes": "",
      "startingPoint": "60d7d334954fc40026e747dc",
      "vehicle": {
        "_id": "60d7d33a954fc40026e747e0",
        "companyId": "5f4fb96bff969b0042603e06",
        "model": "Fleetflex",
        "plate": "U6WA9D"
      },
      "warehouse": null,
      "type": "fast",
      "startedAt": "2021-06-27T01:25:22.662Z",
      "endedAT": "2021-06-27T01:26:05.746946Z"
    },
    {
      "deliverPoints": [
        {
          "files": [],
          "referralGuides": [],
          "_id": "60d7d278954fc40026e747d9",
          "route": "60d7d276954fc40026e747d8",
          "deliveryPoint": {
            "_id": "60d7d2756c717f003d955cb9",
            "customerName": "",
            "address": "Av. Fco. de Orellana Y 1° Herradura B NE, Guayaquil, Ecuador",
            "buffer": 0,
            "deliveryName": "Jk",
            "latitude": -2.12730809,
            "longitude": -79.90571596000001,
            "timeTo": "23:59",
            "timeFrom": "00:01",
            "details": "{\"warehouse\": \"\", \"comments\": \"\", \"referral_guides\": []}",
            "savedInDirectory": false,
            "companyId": "5f4fb96bff969b0042603e06",
            "updatedAt": "2021-06-27T01:22:18.711Z",
            "createdAt": "2021-06-27T01:20:53.669Z",
            "cursor": 13878,
            "__v": 0,
            "type": "delivery"
          },
          "updatedAt": "2021-06-27T01:22:19.017Z",
          "createdAt": "2021-06-27T01:20:56.228Z",
          "cursor": 12462,
          "type": "delivery",
          "__v": 0,
          "documentedAt": "2021-06-27T01:22:14.903Z",
          "isDelivered": true,
          "latitude": 37.4219983,
          "longitude": -122.084,
          "receiverName": "sad",
          "status": "completed"
        },
        {
          "files": [],
          "referralGuides": [],
          "_id": "60d7d29d954fc40026e747da",
          "route": "60d7d276954fc40026e747d8",
          "deliveryPoint": {
            "_id": "60d7d276954fc40026e747d7",
            "customerName": "",
            "address": "Av. Fco. de Orellana Y Pasaje 19b NE, Guayaquil 090507, Ecuador",
            "buffer": 0,
            "deliveryName": "K",
            "latitude": -2.1278282699999997,
            "longitude": -79.90543664,
            "timeTo": "23:59",
            "timeFrom": "00:01",
            "details": "{\"warehouse\": \"\", \"comments\": \"\", \"referral_guides\": []}",
            "savedInDirectory": false,
            "companyId": "5f4fb96bff969b0042603e06",
            "updatedAt": "2021-06-27T01:22:30.588Z",
            "createdAt": "2021-06-27T01:20:54.093Z",
            "cursor": 13879,
            "__v": 0,
            "type": "delivery"
          },
          "updatedAt": "2021-06-27T01:22:30.883Z",
          "createdAt": "2021-06-27T01:21:33.309Z",
          "cursor": 12463,
          "type": "delivery",
          "__v": 0,
          "documentedAt": "2021-06-27T01:22:22.649Z",
          "isDelivered": true,
          "latitude": 37.4219983,
          "longitude": -122.084,
          "receiverName": "das",
          "status": "incomplete"
        }
      ],
      "index": [
        {
          "eta": {
            "text": "0.0 Sec",
            "timeIn": 1606488600000,
            "timeInText": "09:50",
            "timeOut": 1606488600000,
            "timeOutText": "9:50",
            "value": 1000
          },
          "id": "60d7d2756c717f003d955cb9",
          "index": 0
        },
        {
          "eta": {
            "text": "0.0 Sec",
            "timeIn": 1606488600000,
            "timeInText": "09:50",
            "timeOut": 1606488600000,
            "timeOutText": "9:50",
            "value": 1000
          },
          "id": "60d7d276954fc40026e747d7",
          "index": 1
        }
      ],
      "bills": [],
      "events": [],
      "_id": "60d7d276954fc40026e747d8",
      "companyId": "5f4fb96bff969b0042603e06",
      "userCreated": "5f4fb96bff969b0042603e07",
      "draft": false,
      "status": "completed",
      "updatedAt": "2021-06-27T01:22:29.489Z",
      "createdAt": "2021-06-27T01:20:54.990Z",
      "cursor": 3072,
      "__v": 2,
      "backToStart": false,
      "date": "2021-06-27T01:50:00.509Z",
      "driver": {
        "_id": "5fbd700ede7d51002d493a4c",
        "email": "loharo6778@tjuln.com",
        "companyId": "5f4fb96bff969b0042603e06",
        "image": "https://storage.googleapis.com/assets.weco.build/profile.png",
        "document-number": "98",
        "firstName": "Bx",
        "lastName": "Bx"
      },
      "label": "Ruta rápida del 26/06/2021",
      "notes": "",
      "startingPoint": "60d7d2756c717f003d955cb9",
      "vehicle": {
        "_id": "60d7d2a1954fc40026e747db",
        "companyId": "5f4fb96bff969b0042603e06",
        "model": "Fleetflex",
        "plate": "V6HKF1"
      },
      "warehouse": null,
      "type": "fast",
      "startedAt": "2021-06-27T01:22:07.333Z",
      "endedAT": "2021-06-27T01:22:26.828982Z"
    },
    {
      "deliverPoints": [
        {
          "files": [],
          "referralGuides": [
            {
              "comment": "",
              "referralGuide": "dsa"
            }
          ],
          "_id": "60d7c6c7954fc40026e747d1",
          "route": "60d7c6c56c717f003d955cb5",
          "deliveryPoint": {
            "_id": "60d7c6c3954fc40026e747d0",
            "customerName": "",
            "address": "Av. Fco. de Orellana Y 1° Herradura B NE, Guayaquil, Ecuador",
            "buffer": 0,
            "deliveryName": "M",
            "latitude": -2.12768434,
            "longitude": -79.90602572,
            "timeTo": "23:59",
            "timeFrom": "00:01",
            "details": "{\"warehouse\": \"\", \"comments\": \"\", \"referral_guides\": []}",
            "savedInDirectory": false,
            "companyId": "5f4fb96bff969b0042603e06",
            "updatedAt": "2021-06-27T00:41:58.251Z",
            "createdAt": "2021-06-27T00:30:59.730Z",
            "cursor": 13874,
            "__v": 0,
            "type": "delivery"
          },
          "updatedAt": "2021-06-27T00:41:58.658Z",
          "createdAt": "2021-06-27T00:31:03.103Z",
          "cursor": 12458,
          "type": "delivery",
          "__v": 0,
          "documentedAt": "2021-06-27T00:41:37.785Z",
          "isDelivered": true,
          "latitude": 37.4219983,
          "longitude": -122.084,
          "receiverName": "das",
          "status": "completed"
        },
        {
          "files": [],
          "referralGuides": [],
          "_id": "60d7c6c8954fc40026e747d2",
          "route": "60d7c6c56c717f003d955cb5",
          "deliveryPoint": {
            "_id": "60d7c6c46c717f003d955cb4",
            "customerName": "",
            "address": "Av. Fco. de Orellana Y 1° Herradura B NE, Guayaquil, Ecuador",
            "buffer": 0,
            "deliveryName": "B",
            "latitude": -2.1275486299999997,
            "longitude": -79.90584574,
            "timeTo": "23:59",
            "timeFrom": "00:01",
            "details": "{\"warehouse\": \"\", \"comments\": \"\", \"referral_guides\": []}",
            "savedInDirectory": false,
            "companyId": "5f4fb96bff969b0042603e06",
            "updatedAt": "2021-06-27T00:42:05.678Z",
            "createdAt": "2021-06-27T00:31:00.210Z",
            "cursor": 13875,
            "__v": 0,
            "type": "delivery"
          },
          "updatedAt": "2021-06-27T00:42:05.958Z",
          "createdAt": "2021-06-27T00:31:04.005Z",
          "cursor": 12459,
          "type": "delivery",
          "__v": 0,
          "documentedAt": "2021-06-27T00:42:03.061Z",
          "isDelivered": true,
          "latitude": 37.4219983,
          "longitude": -122.084,
          "receiverName": "as",
          "status": "completed"
        }
      ],
      "index": [
        {
          "eta": {
            "text": "0.0 Sec",
            "timeIn": 1606488600000,
            "timeInText": "09:50",
            "timeOut": 1606488600000,
            "timeOutText": "9:50",
            "value": 1000
          },
          "id": "60d7c6c3954fc40026e747d0",
          "index": 0
        },
        {
          "eta": {
            "text": "0.0 Sec",
            "timeIn": 1606488600000,
            "timeInText": "09:50",
            "timeOut": 1606488600000,
            "timeOutText": "9:50",
            "value": 1000
          },
          "id": "60d7c6c46c717f003d955cb4",
          "index": 1
        }
      ],
      "bills": [],
      "events": [],
      "_id": "60d7c6c56c717f003d955cb5",
      "companyId": "5f4fb96bff969b0042603e06",
      "userCreated": "5f4fb96bff969b0042603e07",
      "draft": false,
      "status": "completed",
      "updatedAt": "2021-06-27T00:42:09.804Z",
      "createdAt": "2021-06-27T00:31:01.283Z",
      "cursor": 3070,
      "__v": 2,
      "backToStart": false,
      "date": "2021-06-27T01:00:00.705Z",
      "driver": {
        "_id": "5fbd700ede7d51002d493a4c",
        "email": "loharo6778@tjuln.com",
        "companyId": "5f4fb96bff969b0042603e06",
        "image": "https://storage.googleapis.com/assets.weco.build/profile.png",
        "document-number": "98",
        "firstName": "Bx",
        "lastName": "Bx"
      },
      "label": "Ruta rápida del 26/06/2021",
      "notes": "",
      "startingPoint": "60d7c6c3954fc40026e747d0",
      "vehicle": {
        "_id": "60d7c6ca954fc40026e747d3",
        "companyId": "5f4fb96bff969b0042603e06",
        "model": "Fleetflex",
        "plate": "XGIM2P"
      },
      "warehouse": null,
      "type": "fast",
      "startedAt": "2021-06-27T00:41:30.993Z",
      "endedAT": "2021-06-27T00:42:05.806532Z"
    },
    {
      "deliverPoints": [
        {
          "files": [],
          "referralGuides": [],
          "_id": "60d7c4ea954fc40026e747cd",
          "route": "60d7c4e9954fc40026e747cc",
          "deliveryPoint": {
            "_id": "60d7c4e7954fc40026e747cb",
            "customerName": "Oko",
            "address": "N/A",
            "deliveryName": "Ss",
            "latitude": -2.1276248,
            "longitude": -79.9072064,
            "timeFrom": "06:00",
            "timeTo": "20:00",
            "phone": "12",
            "customerMail": "allanov1995@gmail.com",
            "companyId": "5f4fb96bff969b0042603e06",
            "details": "{\"warehouse\": \"\", \"comments\": [], \"referral_guides\": []}",
            "updatedAt": "2021-06-27T00:40:44.307Z",
            "createdAt": "2021-06-27T00:23:03.499Z",
            "cursor": 13870,
            "__v": 0,
            "buffer": 0,
            "type": "delivery",
            "deliveryPointRef": {
              "_id": "5fbd708cde7d51002d493a4d",
              "customerName": "Oko",
              "address": "N/A",
              "buffer": 0,
              "deliveryName": "Ss",
              "latitude": -2.1276248,
              "longitude": -79.9072064,
              "timeFrom": "06:00",
              "timeTo": "20:00",
              "phone": "12",
              "customerMail": "allanov1995@gmail.com",
              "details": "{\"warehouse\": \"Sector S\", \"comments\": [], \"referral_guides\": []}",
              "savedInDirectory": true,
              "companyId": "5f4fb96bff969b0042603e06",
              "updatedAt": "2021-07-02T21:22:08.757Z",
              "createdAt": "2020-11-24T20:43:56.283Z",
              "cursor": 12546,
              "__v": 0,
              "type": "delivery"
            }
          },
          "updatedAt": "2021-06-27T00:40:44.714Z",
          "createdAt": "2021-06-27T00:23:06.168Z",
          "cursor": 12455,
          "type": "delivery",
          "__v": 0,
          "documentedAt": "2021-06-27T00:40:40.343Z",
          "isDelivered": true,
          "latitude": 37.4219983,
          "longitude": -122.084,
          "receiverName": "dsa",
          "status": "completed"
        },
        {
          "files": [],
          "referralGuides": [],
          "_id": "60d7c4eb6c717f003d955cb2",
          "route": "60d7c4e9954fc40026e747cc",
          "deliveryPoint": {
            "_id": "60d7c4e86c717f003d955cb0",
            "customerName": "Bxb",
            "address": "Bxb",
            "deliveryName": "Bxb",
            "latitude": -2.001960625905279,
            "longitude": -79.48487447574735,
            "timeFrom": "06:00",
            "timeTo": "20:00",
            "phone": "9988",
            "customerMail": "Xbbx",
            "companyId": "5f4fb96bff969b0042603e06",
            "details": "{\"warehouse\": \"\", \"comments\": [], \"referral_guides\": []}",
            "updatedAt": "2021-06-27T00:40:48.697Z",
            "createdAt": "2021-06-27T00:23:04.398Z",
            "cursor": 13871,
            "__v": 0,
            "buffer": 0,
            "type": "delivery",
            "deliveryPointRef": {
              "_id": "5fbd78f0c0b45700410d7cca",
              "customerName": "Bxb",
              "address": "Bxb",
              "buffer": 0,
              "deliveryName": "Bxb",
              "latitude": -2.001960625905279,
              "longitude": -79.48487447574735,
              "timeFrom": "06:00",
              "timeTo": "20:00",
              "phone": "9988",
              "customerMail": "Xbbx",
              "savedInDirectory": true,
              "companyId": "5f4fb96bff969b0042603e06",
              "updatedAt": "2021-07-22T17:48:45.166Z",
              "createdAt": "2020-11-24T21:19:44.866Z",
              "cursor": 12548,
              "__v": 0,
              "type": "delivery",
              "isDeleted": true
            }
          },
          "updatedAt": "2021-06-27T00:40:49.158Z",
          "createdAt": "2021-06-27T00:23:07.131Z",
          "cursor": 12456,
          "type": "delivery",
          "__v": 0,
          "documentedAt": "2021-06-27T00:40:47.405Z",
          "isDelivered": true,
          "latitude": 37.4219983,
          "longitude": -122.084,
          "receiverName": "da",
          "status": "completed"
        },
        {
          "files": [],
          "referralGuides": [
            {
              "comment": "",
              "referralGuide": "das"
            }
          ],
          "_id": "60d7c4eb6c717f003d955cb3",
          "route": "60d7c4e9954fc40026e747cc",
          "deliveryPoint": {
            "_id": "60d7c4e86c717f003d955cb1",
            "customerName": "Ff",
            "address": "C. Nicaragua, Simón Bolívar, Ecuador",
            "deliveryName": "Vc",
            "latitude": -2.006276615689864,
            "longitude": -79.48341885717772,
            "timeFrom": "06:00",
            "timeTo": "20:00",
            "phone": "",
            "customerMail": "",
            "companyId": "5f4fb96bff969b0042603e06",
            "details": "{\"warehouse\": \"\", \"comments\": [], \"referral_guides\": []}",
            "updatedAt": "2021-06-27T00:40:56.999Z",
            "createdAt": "2021-06-27T00:23:04.682Z",
            "cursor": 13872,
            "__v": 0,
            "buffer": 0,
            "type": "delivery",
            "deliveryPointRef": {
              "_id": "5fbd9d204461f1004c263084",
              "customerName": "Ff",
              "address": "C. Nicaragua, Simón Bolívar, Ecuador",
              "buffer": 0,
              "deliveryName": "Vc",
              "latitude": -2.006276615689864,
              "longitude": -79.48341885717772,
              "timeFrom": "06:00",
              "timeTo": "20:00",
              "phone": "",
              "customerMail": "",
              "savedInDirectory": true,
              "companyId": "5f4fb96bff969b0042603e06",
              "updatedAt": "2021-06-26T23:48:24.425Z",
              "createdAt": "2020-11-24T23:54:08.472Z",
              "cursor": 12557,
              "__v": 0,
              "type": "delivery"
            }
          },
          "updatedAt": "2021-06-27T00:40:57.331Z",
          "createdAt": "2021-06-27T00:23:07.800Z",
          "cursor": 12457,
          "type": "delivery",
          "__v": 0,
          "documentedAt": "2021-06-27T00:40:55.533Z",
          "isDelivered": true,
          "latitude": 37.4219983,
          "longitude": -122.084,
          "receiverName": "sd",
          "status": "completed"
        }
      ],
      "index": [
        {
          "eta": {
            "text": "0.0 Sec",
            "timeIn": 1624755300000,
            "timeInText": "07:55",
            "timeOut": 1624755300000,
            "timeOutText": "19:55",
            "value": 0
          },
          "id": "60d7c4e7954fc40026e747cb",
          "index": 0
        },
        {
          "eta": {
            "text": "1.5 Hrs",
            "timeIn": 1624760699000,
            "timeInText": "09:24",
            "timeOut": 1624760699000,
            "timeOutText": "21:24",
            "value": 5399000
          },
          "id": "60d7c4e86c717f003d955cb0",
          "index": 1
        },
        {
          "eta": {
            "text": "3.8 Min",
            "timeIn": 1624760927000,
            "timeInText": "09:28",
            "timeOut": 1624760927000,
            "timeOutText": "21:28",
            "value": 228000
          },
          "id": "60d7c4e86c717f003d955cb1",
          "index": 2
        }
      ],
      "bills": [],
      "events": [],
      "_id": "60d7c4e9954fc40026e747cc",
      "companyId": "5f4fb96bff969b0042603e06",
      "userCreated": "5f4fb96bff969b0042603e07",
      "draft": false,
      "status": "completed",
      "updatedAt": "2021-06-27T00:41:04.087Z",
      "createdAt": "2021-06-27T00:23:05.270Z",
      "cursor": 3069,
      "__v": 3,
      "backToStart": false,
      "date": "2021-06-27T00:55:00.015Z",
      "driver": {
        "_id": "5fbd700ede7d51002d493a4c",
        "email": "loharo6778@tjuln.com",
        "companyId": "5f4fb96bff969b0042603e06",
        "image": "https://storage.googleapis.com/assets.weco.build/profile.png",
        "document-number": "98",
        "firstName": "Bx",
        "lastName": "Bx"
      },
      "label": "Esta si_1_1",
      "notes": "{\"template_id\":\"60493fd3a16ccc006d21ba40\",\"notes\":\"\"}",
      "startingPoint": "5fbd708cde7d51002d493a4d",
      "vehicle": {
        "_id": "60d7c4ee954fc40026e747ce",
        "companyId": "5f4fb96bff969b0042603e06",
        "model": "Fleetflex",
        "plate": "QV2XED"
      },
      "warehouse": null,
      "startedAt": "2021-06-27T00:40:34.463Z",
      "endedAT": "2021-06-27T00:40:59.584554Z"
    },
    {
      "deliverPoints": [
        {
          "files": [],
          "referralGuides": [],
          "_id": "60d7be75954fc40026e747c9",
          "route": "60d7be72954fc40026e747c8",
          "deliveryPoint": {
            "_id": "60d7be6f6c717f003d955cae",
            "customerName": "Vvv",
            "address": "Bbbjb",
            "deliveryName": "Bbbjb",
            "latitude": -2.1913038790864183,
            "longitude": -79.89097198471427,
            "timeFrom": "06:00",
            "timeTo": "20:00",
            "phone": "008",
            "customerMail": " Vbbbb",
            "companyId": "5f4fb96bff969b0042603e06",
            "details": "{\"warehouse\": \"\", \"comments\": [], \"referral_guides\": []}",
            "updatedAt": "2021-06-26T23:57:53.338Z",
            "createdAt": "2021-06-26T23:55:27.935Z",
            "cursor": 13868,
            "__v": 0,
            "buffer": 0,
            "type": "delivery",
            "deliveryPointRef": {
              "_id": "5fbd8fe02b638a00734851be",
              "customerName": "Vvv",
              "address": "Bbbjb",
              "buffer": 0,
              "deliveryName": "Bbbjb",
              "latitude": -2.1913038790864183,
              "longitude": -79.89097198471427,
              "timeFrom": "06:00",
              "timeTo": "20:00",
              "phone": "008",
              "customerMail": " Vbbbb",
              "savedInDirectory": true,
              "companyId": "5f4fb96bff969b0042603e06",
              "updatedAt": "2021-06-26T23:48:41.589Z",
              "createdAt": "2020-11-24T22:57:36.723Z",
              "cursor": 12555,
              "__v": 0,
              "type": "delivery"
            }
          },
          "updatedAt": "2021-06-26T23:57:53.654Z",
          "createdAt": "2021-06-26T23:55:33.343Z",
          "cursor": 12453,
          "type": "delivery",
          "__v": 0,
          "documentedAt": "2021-06-26T23:57:50.358Z",
          "isDelivered": true,
          "latitude": 37.4219983,
          "longitude": -122.084,
          "receiverName": "jt",
          "status": "rejected"
        },
        {
          "files": [],
          "referralGuides": [],
          "_id": "60d7be76954fc40026e747ca",
          "route": "60d7be72954fc40026e747c8",
          "deliveryPoint": {
            "_id": "60d7be70954fc40026e747c7",
            "customerName": "Bxb",
            "address": "Bxb",
            "deliveryName": "Bxb",
            "latitude": -2.001960625905279,
            "longitude": -79.48487447574735,
            "timeFrom": "06:00",
            "timeTo": "20:00",
            "phone": "9988",
            "customerMail": "Xbbx",
            "companyId": "5f4fb96bff969b0042603e06",
            "details": "{\"warehouse\": \"\", \"comments\": [], \"referral_guides\": []}",
            "updatedAt": "2021-06-26T23:57:59.765Z",
            "createdAt": "2021-06-26T23:55:28.817Z",
            "cursor": 13869,
            "__v": 0,
            "buffer": 0,
            "type": "delivery",
            "deliveryPointRef": {
              "_id": "5fbd78f0c0b45700410d7cca",
              "customerName": "Bxb",
              "address": "Bxb",
              "buffer": 0,
              "deliveryName": "Bxb",
              "latitude": -2.001960625905279,
              "longitude": -79.48487447574735,
              "timeFrom": "06:00",
              "timeTo": "20:00",
              "phone": "9988",
              "customerMail": "Xbbx",
              "savedInDirectory": true,
              "companyId": "5f4fb96bff969b0042603e06",
              "updatedAt": "2021-07-22T17:48:45.166Z",
              "createdAt": "2020-11-24T21:19:44.866Z",
              "cursor": 12548,
              "__v": 0,
              "type": "delivery",
              "isDeleted": true
            }
          },
          "updatedAt": "2021-06-26T23:58:00.075Z",
          "createdAt": "2021-06-26T23:55:34.997Z",
          "cursor": 12454,
          "type": "delivery",
          "__v": 0,
          "documentedAt": "2021-06-26T23:57:58.413Z",
          "isDelivered": true,
          "latitude": 37.4219983,
          "longitude": -122.084,
          "receiverName": "jyj",
          "status": "rejected"
        }
      ],
      "index": [
        {
          "eta": {
            "text": "0.0 Sec",
            "timeIn": 1624753500000,
            "timeInText": "07:25",
            "timeOut": 1624753500000,
            "timeOutText": "19:25",
            "value": 0
          },
          "id": "60d7be6f6c717f003d955cae",
          "index": 0
        },
        {
          "eta": {
            "text": "1.4 Hrs",
            "timeIn": 1624758484000,
            "timeInText": "08:48",
            "timeOut": 1624758484000,
            "timeOutText": "20:48",
            "value": 4984000
          },
          "id": "60d7be70954fc40026e747c7",
          "index": 1
        }
      ],
      "bills": [],
      "events": [],
      "_id": "60d7be72954fc40026e747c8",
      "companyId": "5f4fb96bff969b0042603e06",
      "userCreated": "5f4fb96bff969b0042603e07",
      "draft": false,
      "status": "completed",
      "updatedAt": "2021-06-26T23:58:01.754Z",
      "createdAt": "2021-06-26T23:55:30.569Z",
      "cursor": 3068,
      "__v": 2,
      "backToStart": false,
      "date": "2021-06-27T00:25:00.577Z",
      "driver": {
        "_id": "5fbd700ede7d51002d493a4c",
        "email": "loharo6778@tjuln.com",
        "companyId": "5f4fb96bff969b0042603e06",
        "image": "https://storage.googleapis.com/assets.weco.build/profile.png",
        "document-number": "98",
        "firstName": "Bx",
        "lastName": "Bx"
      },
      "label": "Nueva Rutadwq",
      "notes": "{\"template_id\":\"6080632f8180b900856d1c11\",\"notes\":\"\"}",
      "startingPoint": "5fbd8fe02b638a00734851be",
      "vehicle": {
        "_id": "60d7be7b6c717f003d955caf",
        "companyId": "5f4fb96bff969b0042603e06",
        "model": "Fleetflex",
        "plate": "QGK2O4"
      },
      "warehouse": null,
      "startedAt": "2021-06-26T23:57:42.929Z",
      "endedAT": "2021-06-26T23:57:58.624855Z"
    },
    {
      "deliverPoints": [
        {
          "files": [],
          "referralGuides": [],
          "_id": "60d7bd0f954fc40026e747c5",
          "route": "60d7bd0e6c717f003d955cab",
          "deliveryPoint": {
            "_id": "60d7bd0d6c717f003d955ca9",
            "customerName": "Oko",
            "address": "N/A",
            "deliveryName": "Ss",
            "latitude": -2.1276248,
            "longitude": -79.9072064,
            "timeFrom": "06:00",
            "timeTo": "20:00",
            "phone": "12",
            "customerMail": "allanov1995@gmail.com",
            "companyId": "5f4fb96bff969b0042603e06",
            "details": "{\"warehouse\": \"\", \"comments\": [], \"referral_guides\": []}",
            "updatedAt": "2021-06-26T23:54:55.424Z",
            "createdAt": "2021-06-26T23:49:33.781Z",
            "cursor": 13865,
            "__v": 0,
            "buffer": 0,
            "type": "delivery",
            "deliveryPointRef": {
              "_id": "5fbd708cde7d51002d493a4d",
              "customerName": "Oko",
              "address": "N/A",
              "buffer": 0,
              "deliveryName": "Ss",
              "latitude": -2.1276248,
              "longitude": -79.9072064,
              "timeFrom": "06:00",
              "timeTo": "20:00",
              "phone": "12",
              "customerMail": "allanov1995@gmail.com",
              "details": "{\"warehouse\": \"Sector S\", \"comments\": [], \"referral_guides\": []}",
              "savedInDirectory": true,
              "companyId": "5f4fb96bff969b0042603e06",
              "updatedAt": "2021-07-02T21:22:08.757Z",
              "createdAt": "2020-11-24T20:43:56.283Z",
              "cursor": 12546,
              "__v": 0,
              "type": "delivery"
            }
          },
          "updatedAt": "2021-06-26T23:54:55.793Z",
          "createdAt": "2021-06-26T23:49:35.788Z",
          "cursor": 12450,
          "type": "delivery",
          "__v": 0,
          "documentedAt": "2021-06-26T23:54:50.395Z",
          "isDelivered": true,
          "latitude": 37.4219983,
          "longitude": -122.084,
          "receiverName": "uky",
          "status": "completed"
        },
        {
          "files": [],
          "referralGuides": [],
          "_id": "60d7bd10954fc40026e747c6",
          "route": "60d7bd0e6c717f003d955cab",
          "deliveryPoint": {
            "_id": "60d7bd0e6c717f003d955caa",
            "customerName": "Bxb",
            "address": "Bxb",
            "deliveryName": "Bxb",
            "latitude": -2.001960625905279,
            "longitude": -79.48487447574735,
            "timeFrom": "06:00",
            "timeTo": "20:00",
            "phone": "9988",
            "customerMail": "Xbbx",
            "companyId": "5f4fb96bff969b0042603e06",
            "details": "{\"warehouse\": \"\", \"comments\": [], \"referral_guides\": []}",
            "updatedAt": "2021-06-26T23:55:02.062Z",
            "createdAt": "2021-06-26T23:49:34.063Z",
            "cursor": 13866,
            "__v": 0,
            "buffer": 0,
            "type": "delivery",
            "deliveryPointRef": {
              "_id": "5fbd78f0c0b45700410d7cca",
              "customerName": "Bxb",
              "address": "Bxb",
              "buffer": 0,
              "deliveryName": "Bxb",
              "latitude": -2.001960625905279,
              "longitude": -79.48487447574735,
              "timeFrom": "06:00",
              "timeTo": "20:00",
              "phone": "9988",
              "customerMail": "Xbbx",
              "savedInDirectory": true,
              "companyId": "5f4fb96bff969b0042603e06",
              "updatedAt": "2021-07-22T17:48:45.166Z",
              "createdAt": "2020-11-24T21:19:44.866Z",
              "cursor": 12548,
              "__v": 0,
              "type": "delivery",
              "isDeleted": true
            }
          },
          "updatedAt": "2021-06-26T23:55:02.344Z",
          "createdAt": "2021-06-26T23:49:36.596Z",
          "cursor": 12451,
          "type": "delivery",
          "__v": 0,
          "documentedAt": "2021-06-26T23:54:59.721Z",
          "isDelivered": true,
          "latitude": 37.4219983,
          "longitude": -122.084,
          "receiverName": "tu",
          "status": "completed"
        },
        {
          "files": [],
          "referralGuides": [],
          "_id": "60d7bd116c717f003d955cac",
          "route": "60d7bd0e6c717f003d955cab",
          "deliveryPoint": {
            "_id": "60d7bd0e954fc40026e747c4",
            "customerName": "Ff",
            "address": "C. Nicaragua, Simón Bolívar, Ecuador",
            "deliveryName": "Vc",
            "latitude": -2.006276615689864,
            "longitude": -79.48341885717772,
            "timeFrom": "06:00",
            "timeTo": "20:00",
            "phone": "",
            "customerMail": "",
            "companyId": "5f4fb96bff969b0042603e06",
            "details": "{\"warehouse\": \"\", \"comments\": [], \"referral_guides\": []}",
            "updatedAt": "2021-06-26T23:55:23.311Z",
            "createdAt": "2021-06-26T23:49:34.342Z",
            "cursor": 13867,
            "__v": 0,
            "buffer": 0,
            "type": "delivery",
            "deliveryPointRef": {
              "_id": "5fbd9d204461f1004c263084",
              "customerName": "Ff",
              "address": "C. Nicaragua, Simón Bolívar, Ecuador",
              "buffer": 0,
              "deliveryName": "Vc",
              "latitude": -2.006276615689864,
              "longitude": -79.48341885717772,
              "timeFrom": "06:00",
              "timeTo": "20:00",
              "phone": "",
              "customerMail": "",
              "savedInDirectory": true,
              "companyId": "5f4fb96bff969b0042603e06",
              "updatedAt": "2021-06-26T23:48:24.425Z",
              "createdAt": "2020-11-24T23:54:08.472Z",
              "cursor": 12557,
              "__v": 0,
              "type": "delivery"
            }
          },
          "updatedAt": "2021-06-26T23:55:25.736Z",
          "createdAt": "2021-06-26T23:49:37.318Z",
          "cursor": 12452,
          "type": "delivery",
          "__v": 0,
          "documentedAt": "2021-06-26T23:55:08.889Z",
          "isDelivered": true,
          "latitude": 37.4219983,
          "longitude": -122.084,
          "receiverName": "kg",
          "status": "completed"
        }
      ],
      "index": [
        {
          "eta": {
            "text": "0.0 Sec",
            "timeIn": 1624753200000,
            "timeInText": "07:20",
            "timeOut": 1624753200000,
            "timeOutText": "19:20",
            "value": 0
          },
          "id": "60d7bd0d6c717f003d955ca9",
          "index": 0
        },
        {
          "eta": {
            "text": "1.5 Hrs",
            "timeIn": 1624758599000,
            "timeInText": "08:49",
            "timeOut": 1624758599000,
            "timeOutText": "20:49",
            "value": 5399000
          },
          "id": "60d7bd0e6c717f003d955caa",
          "index": 1
        },
        {
          "eta": {
            "text": "3.8 Min",
            "timeIn": 1624758827000,
            "timeInText": "08:53",
            "timeOut": 1624758827000,
            "timeOutText": "20:53",
            "value": 228000
          },
          "id": "60d7bd0e954fc40026e747c4",
          "index": 2
        }
      ],
      "bills": [],
      "events": [],
      "_id": "60d7bd0e6c717f003d955cab",
      "companyId": "5f4fb96bff969b0042603e06",
      "userCreated": "5f4fb96bff969b0042603e07",
      "draft": false,
      "status": "completed",
      "updatedAt": "2021-06-26T23:55:11.592Z",
      "createdAt": "2021-06-26T23:49:34.822Z",
      "cursor": 3067,
      "__v": 3,
      "backToStart": false,
      "date": "2021-06-27T00:20:00.314Z",
      "driver": {
        "_id": "5fbd700ede7d51002d493a4c",
        "email": "loharo6778@tjuln.com",
        "companyId": "5f4fb96bff969b0042603e06",
        "image": "https://storage.googleapis.com/assets.weco.build/profile.png",
        "document-number": "98",
        "firstName": "Bx",
        "lastName": "Bx"
      },
      "label": "Esta si_1_1",
      "notes": "{\"template_id\":\"60493fd3a16ccc006d21ba40\",\"notes\":\"\"}",
      "startingPoint": "5fbd708cde7d51002d493a4d",
      "vehicle": {
        "_id": "60d7bd146c717f003d955cad",
        "companyId": "5f4fb96bff969b0042603e06",
        "model": "Fleetflex",
        "plate": "01S0OR"
      },
      "warehouse": null,
      "startedAt": "2021-06-26T23:54:42.552Z",
      "endedAT": "2021-06-26T23:55:09.135887Z"
    },
    {
      "deliverPoints": [
        {
          "files": [],
          "referralGuides": [],
          "_id": "60d7b0dc954fc40026e747c2",
          "route": "60d7b0db6c717f003d955ca4",
          "deliveryPoint": {
            "_id": "5fbd708cde7d51002d493a4d",
            "customerName": "Oko",
            "address": "N/A",
            "buffer": 0,
            "deliveryName": "Ss",
            "latitude": -2.1276248,
            "longitude": -79.9072064,
            "timeFrom": "06:00",
            "timeTo": "20:00",
            "phone": "12",
            "customerMail": "allanov1995@gmail.com",
            "details": "{\"warehouse\": \"Sector S\", \"comments\": [], \"referral_guides\": []}",
            "savedInDirectory": true,
            "companyId": "5f4fb96bff969b0042603e06",
            "updatedAt": "2021-07-02T21:22:08.757Z",
            "createdAt": "2020-11-24T20:43:56.283Z",
            "cursor": 12546,
            "__v": 0,
            "type": "delivery"
          },
          "updatedAt": "2021-06-26T23:48:08.772Z",
          "createdAt": "2021-06-26T22:57:32.642Z",
          "cursor": 12445,
          "type": "delivery",
          "__v": 0,
          "documentedAt": "2021-06-26T23:48:04.332Z",
          "isDelivered": true,
          "latitude": 37.4219983,
          "longitude": -122.084,
          "receiverName": "gf",
          "status": "completed"
        },
        {
          "files": [],
          "referralGuides": [],
          "_id": "60d7b0dd954fc40026e747c3",
          "route": "60d7b0db6c717f003d955ca4",
          "deliveryPoint": {
            "_id": "5fbd78f0c0b45700410d7cca",
            "customerName": "Bxb",
            "address": "Bxb",
            "buffer": 0,
            "deliveryName": "Bxb",
            "latitude": -2.001960625905279,
            "longitude": -79.48487447574735,
            "timeFrom": "06:00",
            "timeTo": "20:00",
            "phone": "9988",
            "customerMail": "Xbbx",
            "savedInDirectory": true,
            "companyId": "5f4fb96bff969b0042603e06",
            "updatedAt": "2021-07-22T17:48:45.166Z",
            "createdAt": "2020-11-24T21:19:44.866Z",
            "cursor": 12548,
            "__v": 0,
            "type": "delivery",
            "isDeleted": true
          },
          "updatedAt": "2021-06-26T23:48:17.109Z",
          "createdAt": "2021-06-26T22:57:33.267Z",
          "cursor": 12446,
          "type": "delivery",
          "__v": 0,
          "documentedAt": "2021-06-26T23:48:12.589Z",
          "isDelivered": true,
          "latitude": 37.4219983,
          "longitude": -122.084,
          "receiverName": "gre",
          "status": "completed"
        },
        {
          "files": [],
          "referralGuides": [],
          "_id": "60d7b0dd6c717f003d955ca5",
          "route": "60d7b0db6c717f003d955ca4",
          "deliveryPoint": {
            "_id": "5fbd9d204461f1004c263084",
            "customerName": "Ff",
            "address": "C. Nicaragua, Simón Bolívar, Ecuador",
            "buffer": 0,
            "deliveryName": "Vc",
            "latitude": -2.006276615689864,
            "longitude": -79.48341885717772,
            "timeFrom": "06:00",
            "timeTo": "20:00",
            "phone": "",
            "customerMail": "",
            "savedInDirectory": true,
            "companyId": "5f4fb96bff969b0042603e06",
            "updatedAt": "2021-06-26T23:48:24.425Z",
            "createdAt": "2020-11-24T23:54:08.472Z",
            "cursor": 12557,
            "__v": 0,
            "type": "delivery"
          },
          "updatedAt": "2021-06-26T23:48:24.742Z",
          "createdAt": "2021-06-26T22:57:33.640Z",
          "cursor": 12447,
          "type": "delivery",
          "__v": 0,
          "documentedAt": "2021-06-26T23:48:19.997Z",
          "isDelivered": true,
          "latitude": 37.4219983,
          "longitude": -122.084,
          "receiverName": "qgr",
          "status": "completed"
        },
        {
          "files": [],
          "referralGuides": [],
          "_id": "60d7b0de6c717f003d955ca6",
          "route": "60d7b0db6c717f003d955ca4",
          "deliveryPoint": {
            "_id": "5fbd8f6d4461f1004c263082",
            "customerName": "As",
            "address": "1",
            "buffer": 0,
            "deliveryName": "1 Ps",
            "latitude": -2.004597636369343,
            "longitude": -79.48603151366115,
            "timeFrom": "03:00",
            "timeTo": "20:00",
            "phone": "",
            "customerMail": "",
            "details": "{\"warehouse\": \"N/A\", \"comments\": [], \"referral_guides\": []}",
            "savedInDirectory": true,
            "companyId": "5f4fb96bff969b0042603e06",
            "updatedAt": "2021-07-13T16:19:46.659Z",
            "createdAt": "2020-11-24T22:55:41.018Z",
            "cursor": 12554,
            "__v": 0,
            "type": "delivery",
            "isDeleted": true
          },
          "updatedAt": "2021-06-26T23:48:30.032Z",
          "createdAt": "2021-06-26T22:57:34.042Z",
          "cursor": 12448,
          "type": "delivery",
          "__v": 0,
          "documentedAt": "2021-06-26T23:48:27.437Z",
          "isDelivered": true,
          "latitude": 37.4219983,
          "longitude": -122.084,
          "receiverName": "qgre",
          "status": "incomplete"
        },
        {
          "files": [],
          "referralGuides": [],
          "_id": "60d7b0de6c717f003d955ca7",
          "route": "60d7b0db6c717f003d955ca4",
          "deliveryPoint": {
            "_id": "5fbd8fe02b638a00734851be",
            "customerName": "Vvv",
            "address": "Bbbjb",
            "buffer": 0,
            "deliveryName": "Bbbjb",
            "latitude": -2.1913038790864183,
            "longitude": -79.89097198471427,
            "timeFrom": "06:00",
            "timeTo": "20:00",
            "phone": "008",
            "customerMail": " Vbbbb",
            "savedInDirectory": true,
            "companyId": "5f4fb96bff969b0042603e06",
            "updatedAt": "2021-06-26T23:48:41.589Z",
            "createdAt": "2020-11-24T22:57:36.723Z",
            "cursor": 12555,
            "__v": 0,
            "type": "delivery"
          },
          "updatedAt": "2021-06-26T23:48:41.921Z",
          "createdAt": "2021-06-26T22:57:34.572Z",
          "cursor": 12449,
          "type": "delivery",
          "__v": 0,
          "documentedAt": "2021-06-26T23:48:38.608Z",
          "isDelivered": true,
          "latitude": 37.4219983,
          "longitude": -122.084,
          "receiverName": "rew",
          "status": "rejected"
        }
      ],
      "index": [
        {
          "eta": {
            "text": "0.0 Sec",
            "timeIn": 1624749900000,
            "timeInText": "06:25",
            "timeOut": 1624749900000,
            "timeOutText": "18:25",
            "value": 0
          },
          "id": "5fbd708cde7d51002d493a4d",
          "index": 0
        },
        {
          "eta": {
            "text": "1.5 Hrs",
            "timeIn": 1624755299000,
            "timeInText": "07:54",
            "timeOut": 1624755299000,
            "timeOutText": "19:54",
            "value": 5399000
          },
          "id": "5fbd78f0c0b45700410d7cca",
          "index": 1
        },
        {
          "eta": {
            "text": "3.8 Min",
            "timeIn": 1624755527000,
            "timeInText": "07:58",
            "timeOut": 1624755527000,
            "timeOutText": "19:58",
            "value": 228000
          },
          "id": "5fbd9d204461f1004c263084",
          "index": 2
        },
        {
          "eta": {
            "text": "1.5 Min",
            "timeIn": 1624755618000,
            "timeInText": "08:00",
            "timeOut": 1624755618000,
            "timeOutText": "20:0",
            "value": 91000
          },
          "id": "5fbd8f6d4461f1004c263082",
          "index": 3
        },
        {
          "eta": {
            "text": "1.3 Hrs",
            "timeIn": 1624760458000,
            "timeInText": "09:20",
            "timeOut": 1624760458000,
            "timeOutText": "21:20",
            "value": 4840000
          },
          "id": "5fbd8fe02b638a00734851be",
          "index": 4
        }
      ],
      "bills": [],
      "events": [],
      "_id": "60d7b0db6c717f003d955ca4",
      "companyId": "5f4fb96bff969b0042603e06",
      "userCreated": "5f4fb96bff969b0042603e07",
      "draft": false,
      "status": "completed",
      "updatedAt": "2021-06-26T23:48:42.995Z",
      "createdAt": "2021-06-26T22:57:31.722Z",
      "cursor": 3066,
      "__v": 5,
      "backToStart": false,
      "date": "2021-06-26T23:25:00.841Z",
      "driver": {
        "_id": "5fbd700ede7d51002d493a4c",
        "email": "loharo6778@tjuln.com",
        "companyId": "5f4fb96bff969b0042603e06",
        "image": "https://storage.googleapis.com/assets.weco.build/profile.png",
        "document-number": "98",
        "firstName": "Bx",
        "lastName": "Bx"
      },
      "label": "Con guias",
      "notes": "{\"template_id\":\"60493f9dc6f808003d8a6b5b\",\"notes\":\"\"}",
      "startingPoint": "5fbd708cde7d51002d493a4d",
      "vehicle": {
        "_id": "60d7b0e16c717f003d955ca8",
        "companyId": "5f4fb96bff969b0042603e06",
        "model": "Fleetflex",
        "plate": "LQ7XJE"
      },
      "warehouse": null,
      "startedAt": "2021-06-26T23:47:51.925Z",
      "endedAT": "2021-06-26T23:48:38.799719Z"
    },
    {
      "deliverPoints": [
        {
          "files": [],
          "referralGuides": [
            {
              "comment": "",
              "referralGuide": "fndn"
            }
          ],
          "_id": "60a531ac30d9cb006b36af98",
          "route": "60a531aaf58375009df15d75",
          "deliveryPoint": {
            "_id": "60a531a8f58375009df15d73",
            "customerName": "Oko",
            "address": "N/A",
            "deliveryName": "Ss",
            "latitude": -2.1276248,
            "longitude": -79.9072064,
            "timeFrom": "06:00",
            "timeTo": "20:00",
            "phone": "12",
            "customerMail": "allanov1995@gmail.com",
            "details": "\"\\\"{\\\\\\\"warehouse\\\\\\\":\\\\\\\"as\\\\\\\",\\\\\\\"comments\\\\\\\":[],\\\\\\\"referral_guides\\\\\\\":[]}\\\"\"",
            "companyId": "5f4fb96bff969b0042603e06",
            "updatedAt": "2021-05-19T16:07:22.301Z",
            "createdAt": "2021-05-19T15:41:28.645Z",
            "cursor": 13692,
            "__v": 0,
            "type": "delivery",
            "deliveryPointRef": {
              "_id": "5fbd708cde7d51002d493a4d",
              "customerName": "Oko",
              "address": "N/A",
              "buffer": 0,
              "deliveryName": "Ss",
              "latitude": -2.1276248,
              "longitude": -79.9072064,
              "timeFrom": "06:00",
              "timeTo": "20:00",
              "phone": "12",
              "customerMail": "allanov1995@gmail.com",
              "details": "{\"warehouse\": \"Sector S\", \"comments\": [], \"referral_guides\": []}",
              "savedInDirectory": true,
              "companyId": "5f4fb96bff969b0042603e06",
              "updatedAt": "2021-07-02T21:22:08.757Z",
              "createdAt": "2020-11-24T20:43:56.283Z",
              "cursor": 12546,
              "__v": 0,
              "type": "delivery"
            }
          },
          "updatedAt": "2021-05-19T16:07:23.025Z",
          "createdAt": "2021-05-19T15:41:32.207Z",
          "cursor": 12283,
          "type": "delivery",
          "__v": 0,
          "documentedAt": "2021-05-19T16:07:18.647Z",
          "isDelivered": true,
          "latitude": -2.0019392,
          "longitude": -79.4849057,
          "receiverName": "Xbxb",
          "status": "completed"
        },
        {
          "files": [],
          "referralGuides": [
            {
              "referralGuide": "das12",
              "comments": ""
            }
          ],
          "_id": "60a531adf58375009df15d76",
          "route": "60a531aaf58375009df15d75",
          "deliveryPoint": {
            "_id": "60a531a8f58375009df15d74",
            "customerName": "Bxb",
            "address": "Bxb",
            "deliveryName": "Bxb",
            "latitude": -2.001960625905279,
            "longitude": -79.48487447574735,
            "timeFrom": "06:00",
            "timeTo": "20:00",
            "phone": "9988",
            "customerMail": "Xbbx",
            "details": "\"\\\"{\\\\\\\"warehouse\\\\\\\":\\\\\\\"Bxbxb\\\\\\\"}\\\"\"",
            "companyId": "5f4fb96bff969b0042603e06",
            "updatedAt": "2021-05-19T16:11:40.425Z",
            "createdAt": "2021-05-19T15:41:28.960Z",
            "cursor": 13693,
            "__v": 0,
            "type": "delivery",
            "deliveryPointRef": {
              "_id": "5fbd78f0c0b45700410d7cca",
              "customerName": "Bxb",
              "address": "Bxb",
              "buffer": 0,
              "deliveryName": "Bxb",
              "latitude": -2.001960625905279,
              "longitude": -79.48487447574735,
              "timeFrom": "06:00",
              "timeTo": "20:00",
              "phone": "9988",
              "customerMail": "Xbbx",
              "savedInDirectory": true,
              "companyId": "5f4fb96bff969b0042603e06",
              "updatedAt": "2021-07-22T17:48:45.166Z",
              "createdAt": "2020-11-24T21:19:44.866Z",
              "cursor": 12548,
              "__v": 0,
              "type": "delivery",
              "isDeleted": true
            }
          },
          "updatedAt": "2021-05-19T16:11:40.718Z",
          "createdAt": "2021-05-19T15:41:33.519Z",
          "cursor": 12284,
          "type": "delivery",
          "__v": 0,
          "documentedAt": "2021-05-19T16:11:36.641Z",
          "isDelivered": true,
          "latitude": -2.0019465,
          "longitude": -79.4849151,
          "receiverName": "Xnxj",
          "status": "rejected"
        },
        {
          "files": [],
          "referralGuides": [
            {
              "referralGuide": "wqdqw",
              "comments": ""
            },
            {
              "referralGuide": "eqew",
              "comments": ""
            },
            {
              "referralGuide": "ewwq",
              "comments": ""
            },
            {
              "referralGuide": "ewq",
              "comments": ""
            },
            {
              "referralGuide": "ewqewq",
              "comments": ""
            }
          ],
          "_id": "60a531ae30d9cb006b36af99",
          "route": "60a531aaf58375009df15d75",
          "deliveryPoint": {
            "_id": "60a531a930d9cb006b36af97",
            "customerName": "Ff",
            "address": "C. Nicaragua, Simón Bolívar, Ecuador",
            "deliveryName": "Vc",
            "latitude": -2.006276615689864,
            "longitude": -79.48341885717772,
            "timeFrom": "06:00",
            "timeTo": "20:00",
            "phone": "",
            "customerMail": "",
            "details": "\"\\\"{\\\\\\\"warehouse\\\\\\\":\\\\\\\"ff\\\\\\\",\\\\\\\"comments\\\\\\\":[],\\\\\\\"referral_guides\\\\\\\":[]}\\\"\"",
            "companyId": "5f4fb96bff969b0042603e06",
            "updatedAt": "2021-05-19T16:07:39.266Z",
            "createdAt": "2021-05-19T15:41:29.205Z",
            "cursor": 13694,
            "__v": 0,
            "type": "delivery",
            "deliveryPointRef": {
              "_id": "5fbd9d204461f1004c263084",
              "customerName": "Ff",
              "address": "C. Nicaragua, Simón Bolívar, Ecuador",
              "buffer": 0,
              "deliveryName": "Vc",
              "latitude": -2.006276615689864,
              "longitude": -79.48341885717772,
              "timeFrom": "06:00",
              "timeTo": "20:00",
              "phone": "",
              "customerMail": "",
              "savedInDirectory": true,
              "companyId": "5f4fb96bff969b0042603e06",
              "updatedAt": "2021-06-26T23:48:24.425Z",
              "createdAt": "2020-11-24T23:54:08.472Z",
              "cursor": 12557,
              "__v": 0,
              "type": "delivery"
            }
          },
          "updatedAt": "2021-05-19T16:07:39.672Z",
          "createdAt": "2021-05-19T15:41:34.349Z",
          "cursor": 12285,
          "type": "delivery",
          "__v": 0,
          "documentedAt": "2021-05-19T16:07:36.731Z",
          "isDelivered": true,
          "latitude": -2.001941,
          "longitude": -79.4849063,
          "receiverName": "Fjjxxj",
          "status": "incomplete"
        }
      ],
      "index": [
        {
          "eta": {
            "text": "0.0 Sec",
            "timeIn": 1621440600000,
            "timeInText": "11:10",
            "timeOut": 1621440600000,
            "timeOutText": "11:10",
            "value": 0
          },
          "id": "60a531a8f58375009df15d73",
          "index": 0
        },
        {
          "eta": {
            "text": "0.0 Sec",
            "timeIn": 1621440600000,
            "timeInText": "11:10",
            "timeOut": 1621440600000,
            "timeOutText": "11:10",
            "value": 0
          },
          "id": "60a531a930d9cb006b36af97",
          "index": 1
        },
        {
          "eta": {
            "text": "0.0 Sec",
            "timeIn": 1621440600000,
            "timeInText": "11:10",
            "timeOut": 1621440600000,
            "timeOutText": "11:10",
            "value": 0
          },
          "id": "60a531a8f58375009df15d74",
          "index": 2
        }
      ],
      "bills": [],
      "events": [],
      "_id": "60a531aaf58375009df15d75",
      "companyId": "5f4fb96bff969b0042603e06",
      "userCreated": "5f4fb96bff969b0042603e07",
      "draft": false,
      "status": "completed",
      "updatedAt": "2021-05-19T16:11:42.488Z",
      "createdAt": "2021-05-19T15:41:30.605Z",
      "cursor": 3016,
      "__v": 3,
      "backToStart": false,
      "date": "2021-05-19T16:35:00.348Z",
      "driver": {
        "_id": "5fbd700ede7d51002d493a4c",
        "email": "loharo6778@tjuln.com",
        "companyId": "5f4fb96bff969b0042603e06",
        "image": "https://storage.googleapis.com/assets.weco.build/profile.png",
        "document-number": "98",
        "firstName": "Bx",
        "lastName": "Bx"
      },
      "label": "Esta si",
      "notes": "{\"template_id\":\"5fbd709ade7d51002d493a4e\",\"notes\":\"\"}",
      "startingPoint": "60a531a8f58375009df15d73",
      "vehicle": {
        "_id": "60a531b130d9cb006b36af9a",
        "companyId": "5f4fb96bff969b0042603e06",
        "model": "Fleetflex",
        "plate": "8E51WN"
      },
      "warehouse": null,
      "startedAt": "2021-05-19T16:07:03.911Z",
      "endedAT": "2021-05-19T16:11:38.530188Z"
    },
    {
      "deliverPoints": [
        {
          "files": [],
          "referralGuides": [],
          "_id": "609d8b29778b61006d534d07",
          "route": "609d8b279b3d7000538e7a31",
          "deliveryPoint": {
            "_id": "609d8b279b3d7000538e7a2f",
            "customerName": "Bxb",
            "address": "Bxb",
            "deliveryName": "Bxb",
            "latitude": -2.001960625905279,
            "longitude": -79.48487447574735,
            "timeFrom": "06:00",
            "timeTo": "20:00",
            "phone": "9988",
            "customerMail": "Xbbx",
            "details": "{\"warehouse\": \"Bxbxb\"}",
            "companyId": "5f4fb96bff969b0042603e06",
            "updatedAt": "2021-05-13T20:31:03.740Z",
            "createdAt": "2021-05-13T20:25:11.170Z",
            "cursor": 13628,
            "__v": 0,
            "buffer": 0,
            "type": "delivery",
            "deliveryPointRef": {
              "_id": "5fbd78f0c0b45700410d7cca",
              "customerName": "Bxb",
              "address": "Bxb",
              "buffer": 0,
              "deliveryName": "Bxb",
              "latitude": -2.001960625905279,
              "longitude": -79.48487447574735,
              "timeFrom": "06:00",
              "timeTo": "20:00",
              "phone": "9988",
              "customerMail": "Xbbx",
              "savedInDirectory": true,
              "companyId": "5f4fb96bff969b0042603e06",
              "updatedAt": "2021-07-22T17:48:45.166Z",
              "createdAt": "2020-11-24T21:19:44.866Z",
              "cursor": 12548,
              "__v": 0,
              "type": "delivery",
              "isDeleted": true
            }
          },
          "updatedAt": "2021-05-13T20:31:04.510Z",
          "createdAt": "2021-05-13T20:25:13.283Z",
          "cursor": 12240,
          "type": "delivery",
          "__v": 0,
          "documentedAt": "2021-05-13T20:30:59.407Z",
          "isDelivered": true,
          "latitude": -2.163464,
          "longitude": -79.9078989,
          "receiverName": "vkl",
          "status": "completed"
        },
        {
          "files": [],
          "referralGuides": [],
          "_id": "609d8b2a9b3d7000538e7a32",
          "route": "609d8b279b3d7000538e7a31",
          "deliveryPoint": {
            "_id": "609d8b279b3d7000538e7a30",
            "customerName": "Vvv",
            "address": "Bbbjb",
            "deliveryName": "Bbbjb",
            "latitude": -2.1913038790864183,
            "longitude": -79.89097198471427,
            "timeFrom": "06:00",
            "timeTo": "20:00",
            "phone": "008",
            "customerMail": " Vbbbb",
            "companyId": "5f4fb96bff969b0042603e06",
            "details": "{\"warehouse\": \"\", \"comments\": [], \"referral_guides\": []}",
            "updatedAt": "2021-05-13T20:31:18.000Z",
            "createdAt": "2021-05-13T20:25:11.388Z",
            "cursor": 13629,
            "__v": 0,
            "buffer": 0,
            "type": "delivery",
            "deliveryPointRef": {
              "_id": "5fbd8fe02b638a00734851be",
              "customerName": "Vvv",
              "address": "Bbbjb",
              "buffer": 0,
              "deliveryName": "Bbbjb",
              "latitude": -2.1913038790864183,
              "longitude": -79.89097198471427,
              "timeFrom": "06:00",
              "timeTo": "20:00",
              "phone": "008",
              "customerMail": " Vbbbb",
              "savedInDirectory": true,
              "companyId": "5f4fb96bff969b0042603e06",
              "updatedAt": "2021-06-26T23:48:41.589Z",
              "createdAt": "2020-11-24T22:57:36.723Z",
              "cursor": 12555,
              "__v": 0,
              "type": "delivery"
            }
          },
          "updatedAt": "2021-05-13T20:31:18.295Z",
          "createdAt": "2021-05-13T20:25:14.329Z",
          "cursor": 12241,
          "type": "delivery",
          "__v": 0,
          "documentedAt": "2021-05-13T20:31:13.357Z",
          "isDelivered": true,
          "latitude": -2.1633792,
          "longitude": -79.9078822,
          "receiverName": "kevin",
          "status": "completed"
        }
      ],
      "index": [
        {
          "eta": {
            "text": "0.0 Sec",
            "timeIn": 1620939300000,
            "timeInText": "03:55",
            "timeOut": 1620939300000,
            "timeOutText": "15:55",
            "value": 0
          },
          "id": "609d8b279b3d7000538e7a2f",
          "index": 0
        },
        {
          "eta": {
            "text": "1.4 Hrs",
            "timeIn": 1620944201000,
            "timeInText": "05:16",
            "timeOut": 1620944201000,
            "timeOutText": "17:16",
            "value": 4901000
          },
          "id": "609d8b279b3d7000538e7a30",
          "index": 1
        }
      ],
      "bills": [],
      "events": [],
      "_id": "609d8b279b3d7000538e7a31",
      "companyId": "5f4fb96bff969b0042603e06",
      "userCreated": "5f4fb96bff969b0042603e07",
      "draft": false,
      "status": "completed",
      "updatedAt": "2021-05-13T20:31:18.719Z",
      "createdAt": "2021-05-13T20:25:11.855Z",
      "cursor": 2997,
      "__v": 2,
      "backToStart": false,
      "date": "2021-05-13T20:55:00.061Z",
      "driver": {
        "_id": "609d8ab99b3d7000538e7a2e",
        "email": "",
        "companyId": "5f4fb96bff969b0042603e06",
        "image": "https://storage.googleapis.com/assets.weco.build/profile.png",
        "document-number": "1727327262",
        "firstName": "Kevin",
        "lastName": "Santacruz"
      },
      "label": "Nueva Rutaas121",
      "notes": "{\"template_id\":\"608065698180b900856d1c17\",\"notes\":\"\"}",
      "startingPoint": "5fbd78f0c0b45700410d7cca",
      "vehicle": {
        "_id": "609d8b2c9b3d7000538e7a33",
        "companyId": "5f4fb96bff969b0042603e06",
        "model": "Fleetflex",
        "plate": "G5Q04N"
      },
      "warehouse": null,
      "startedAt": "2021-05-13T20:30:50.229Z",
      "endedAT": "2021-05-13T20:31:14.351555Z"
    }
  ],
  "paging": {
    "totalItems": 13,
    "limit": 10,
    "page": 1,
    "totalPages": 2
  }
}
