<div class="modal-container">
  <div class="windowsBar d-flex justify-content-between align-items-center">
    <a
      class="d-flex align-items-center text-white"
      [href]="getGoogleMapsLink()"
      target="_blank">
      <i class="material-icons">link</i>
      <span class="link-label">Ver en google maps</span>
    </a>
    <div (click)="closeModal()" class="clickable">
      <i class="material-icons text-white">close</i>
    </div>
  </div>
  <agm-map
    #gm
    class="map position-relative"
    [latitude]="originalLatitude"
    [longitude]="originalLongitude"
    [zoom]="15">
    <agm-marker
      [latitude]="originalLatitude || 0"
      [longitude]="originalLongitude || 0"
      [iconUrl]="{ url: '../../assets/img/icon-pin-blue.svg' }"
      (mouseOver)="onMouseOver(infoWindowOriginal, gm)">
      <agm-info-window
        [disableAutoPan]="true"
        isOpen="true"
        #infoWindowOriginal>
        <div class="font-weight-bold">
          <div>{{ this.pointData.originalPoint.name }}</div>
          <div>
            {{ this.pointData.originalPoint.latitude }},{{
              this.pointData.originalPoint.longitude
            }}
          </div>
        </div>
      </agm-info-window>
    </agm-marker>
    <agm-marker
      *ngIf="realLatitude"
      [latitude]="realLatitude || 0"
      [longitude]="realLongitude || 0"
      [iconUrl]="{ url: '../../assets/img/icon-pin-yellow.svg' }"
      (mouseOver)="onMouseOver(infoWindowsReal, gm)">
      <agm-info-window [disableAutoPan]="false" isOpen="true" #infoWindowsReal>
        <div class="d-flex align-items-end" style="gap: 5px">
          <div class="font-weight-bold">
            <div class="">{{ this.pointData.realPointPoint.name }}</div>
            <div class="">
              {{ this.pointData.realPointPoint.latitude }},{{
                this.pointData.realPointPoint.longitude
              }}
            </div>
            <div class="">{{ this.pointData.distance.toFixed(2) }} m</div>
          </div>
          <div class="">
            <i class="material-icons warning-color">warning</i>
          </div>
        </div>
      </agm-info-window>
    </agm-marker>
    <agm-circle
      [latitude]="originalLatitude"
      [longitude]="originalLongitude"
      [radius]="200"
      [fillColor]="'#03A9F4'"></agm-circle>

    <agm-polyline *ngIf="realLatitude" strokeWeight="2" strokeColor="black">
      <agm-polyline-point
        [latitude]="originalLatitude"
        [longitude]="originalLongitude"></agm-polyline-point>

      <agm-polyline-point
        [latitude]="realLatitude"
        [longitude]="realLongitude"></agm-polyline-point>
    </agm-polyline>
  </agm-map>
  <div class="overlay-custom-map">
    <div class="legend-row">
      <div class="square blue"></div>
      <div class="font-weight-bold">
        {{ this.pointData.originalPoint.name }}
      </div>
    </div>

    <div *ngIf="realLatitude" class="legend-row">
      <div class="square yellow"></div>
      <div class="font-weight-bold">
        {{ this.pointData.realPointPoint.name }}
      </div>
    </div>
  </div>
</div>
