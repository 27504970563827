import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { forEach } from 'lodash';
import { Observable } from 'rxjs';
import { UserRequests } from '../../api/user/user.requests';
import { HelpersService } from './helpers.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class FirebaseService {
  db: any;
  messaging: any;
  private _device: any = {};

  constructor(
    private api: UserRequests,
    private helper: HelpersService,
    private translate: TranslateService,
    private device: DeviceDetectorService
  ) {
    this.db = firebase.database();
    this.messaging = firebase.messaging();
    this._device = this.device;
  }

  /**
   * Insert data on specified node
   *
   * @param  node  Node name where data will be stored
   * @param     data  Data to be pushed
   */
  insert(node: string, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const insert = this.db.ref(node).push();
      insert.set(data, error => {
        if (error) {
          return reject(error);
        }

        return resolve({ _id: insert.key });
      });
    });
  }

  /**
   * Update register of a given path, will update all fields of given object
   *
   * @param  node  Node name where data will be stored
   * @param     data  Data to be updated
   */
  update(node: string, data: any): Promise<any> {
    const item = {};
    forEach(data, (val, key) => {
      item[`/${node}/${key}`] = val;
    });

    return this.db.ref().update(item);
  }

  /**
   * Find data only one time per call
   *
   * @param     node    Node name where data will be retrieved
   * @param        query   Value to find
   * @param        order   Order value
   */
  findOnce(node: string, query: any, order: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let _method;
      if (order && query) {
        _method = this.db.ref(node).orderByChild(order).equalTo(query);
      } else if (order && !query) {
        _method = this.db.ref(node).orderByChild(order);
      } else {
        _method = this.db.ref(node);
      }

      _method.once('value', data => {
        if (!data || !data.val()) {
          return resolve({ data: {}, empty: true });
        }

        return resolve({ data: data.val(), empty: false });
      });
    });
  }

  /**
   * Subscribe to a given node / collection / item
   *
   * @param     node    Node name where data will be retrieved
   * @param        query   Value to find
   * @param        order   Order value
   * @param limit
   */
  subscribeToNode(
    node: string,
    query: any,
    order: any,
    limit: number = -1
  ): Observable<any> {
    let _method;
    if (order && query) {
      _method = this.db.ref(node).orderByChild(order).equalTo(query);
    } else if (order && !query) {
      _method = this.db.ref(node).orderByChild(order);
    } else {
      _method = this.db.ref(node);
    }
    if (limit > 0) {
      _method = _method.limitToLast(limit);
    }

    const subscriber = new Observable(observer => {
      _method.on('value', data => {
        observer.next(data.val());
      });
    });

    return subscriber;
  }

  /**
   * Remove child from a given node
   *
   * @param node
   * @param child
   */
  removeChild(node: string, child: string) {
    this.db.ref(`${node}/${child}`).remove();
  }

  /**
   * Check if user it's already logged or proceed with authentication
   */
  checkSession(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (firebase.auth() && firebase.auth().currentUser) {
        return resolve(true);
      }
      this.login()
        .then(logged => resolve(logged))
        .catch(error => reject(error));
    });
  }

  /**
   * Get permission to notifications
   */
  getPermission(): Promise<any> {
    const self = this;
    return new Promise((resolve, reject) => {
      if (this._device.browser.indexOf('safari') !== -1) {
        resolve(null);
      } else {
        this.messaging
          .requestPermission()
          .then(() => self.messaging.getToken())
          .then(token => self.api.addFirebaseToken({ token: token }))
          .catch(err => reject(this.handleNotificationsError(err)));
      }
    });
  }

  /**
   * Remove token firebase
   */
  removeToken(): Promise<any> {
    const self = this;
    return new Promise((resolve, reject) => {
      if (this._device.browser.indexOf('safari') !== -1) {
        resolve(null);
      } else {
        this.messaging
          .requestPermission()
          .then(() => this.messaging.getToken())
          .then(token => self.api.removeFirebaseToken({ token: token }))
          .then(() => resolve(null))
          .catch(err =>
            err && err.code.indexOf('messaging/notifications-blocked') !== -1
              ? resolve(null)
              : reject(err)
          );
      }
    });
  }

  /**
   * Login on firebase
   */
  private login() {
    return new Promise((resolve, reject) => {
      this.api
        .getFirebaseToken()
        .toPromise()
        .then(response =>
          firebase.auth().signInWithCustomToken(response.data.token)
        )
        .then(() => resolve(true))
        .catch(error => {
          this.helper.showWarningMessage(error);
          reject(false);
        });
    });
  }

  /**
   * handle error message for cloud notifications
   *
   * @param error
   */
  private handleNotificationsError(error: any) {
    const label = 'NOTIFICATIONS.ERROR.SUPPORTED';
    const response = {
      message:
        error.code && error.code.indexOf('messaging/unsupported-browser') !== -1
          ? this.translate.instant(label)
          : error.message,
    };
    return response;
  }

  sendEmailReport(payload: any) {
    const sender = firebase.app().functions().httpsCallable('sendReport');
    sender(payload)
      .then(result => {
        if (result) {
          this.showToast(0);
        } else {
          this.showToast(1);
        }
      })
      .catch(reason => {
        this.showToast(1);
      });
  }
  sendSyncDestinationNotification(deliveryPointList, type) {
    const payload = {
      companyId: 'das',
      notificationType: type,
      destinationsModified: deliveryPointList,
    };
    const sender = firebase
      .app()
      .functions()
      .httpsCallable('syncDestinationNotify');
    sender(payload)
      .then(result => {
        console.log('point notified');
      })
      .catch(reason => {
        console.log('error notifying point');
      });
  }

  showToast(code: number) {
    switch (code) {
      case 0:
        this.helper.showSuccessMessage('El e-mail ha sido enviado');
        break;
      case 1:
        this.helper.showErrorMessage('Existió un error al enviar el mensaje.');
        break;
    }
  }
}
