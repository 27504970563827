import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-tags',
  templateUrl: './info-tags.component.html',
  styleUrls: ['./info-tags.component.sass'],
})
export class InfoTagsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
