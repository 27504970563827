<div class="status-container" *ngIf="!oldStyle">
  <div class="status-item" [ngClass]="state?.className">
    <i class="icon material-icons" *ngIf="state?.icon">{{ state?.icon }}</i>
    <span class="status">
      {{ state?.label }}
    </span>
  </div>
</div>

<div class="status-old-fashion" [ngClass]="state?.className" *ngIf="oldStyle">
  <span class="status">
    {{ state?.label }}
  </span>
</div>
