import { Injectable, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as hopscotch from 'hopscotch';
import { clone, first, delay } from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject } from '../../../../node_modules/rxjs';
import { UserRequests } from '../../api/user/user.requests';
import { AnalyticsService } from './analytics.service';
import { HelpersService } from './helpers.service';
import { UserService } from './user.service';

@Injectable()
export class TourService {
  tourDisplayStatus: Subject<boolean> = new Subject<boolean>();
  tourHeaderIndex: Subject<any> = new Subject<any>();
  private tour: TourDefinition;

  constructor(
    private translate: TranslateService,
    private device: DeviceDetectorService,
    @Inject(UserRequests) private userApi: UserRequests,
    @Inject(AnalyticsService) private analytics: AnalyticsService,
    @Inject(HelpersService) private helper: HelpersService,
    @Inject(UserService) private userService: UserService
  ) {}

  showTour() {
    // this.createTour();
    // hopscotch.startTour(this.tour);
    this.finishTour();
  }

  /**
   * Create base tour configuration
   */
  private createTour() {
    this.tour = {
      id: 'fleetflex',
      showCloseButton: false,
      showPrevButton: false,
      smoothScroll: true,
      skipIfNoElement: false,
      i18n: {
        nextBtn: this.translate.instant('TOUR.OK'),
        doneBtn: this.translate.instant('TOUR.DONE'),
      },
      steps: this.createTourSteps(),
      onStart: () => this.tourDisplayStatus.next(false),
      onEnd: () => this.tourDisplayStatus.next(false),
    };
  }

  /**
   * Define all tour steps
   */
  private createTourSteps(): StepDefinition[] {
    let baseWidth: Number = window.innerWidth;

    let steps: StepDefinition[] = [
      {
        content: this.translate.instant('TOUR.DRIVER-MENU'),
        target: 'driver-menu',
        placement: 'bottom',
        arrowOffset: 'center',
        xOffset: baseWidth >= 700 ? -75 : -85,
        zIndex: -1,
        onShow: () => this.addChildBackdrop('driver-menu'),
        onNext: () => this.removeChildBackdrop('driver-menu'),
      },
      {
        content: this.translate.instant('TOUR.VEHICLES-MENU'),
        target: 'vehicles-menu',
        placement: 'bottom',
        arrowOffset: baseWidth >= 700 ? 'center' : 200,
        xOffset: baseWidth >= 700 ? -75 : -175,
        zIndex: -1,
        onShow: () => this.addChildBackdrop('vehicles-menu'),
        onNext: () => this.removeChildBackdrop('vehicles-menu'),
      },
      {
        content: this.translate.instant('TOUR.ROUTES-MENU'),
        target: 'routes-menu',
        placement: 'bottom',
        arrowOffset: baseWidth >= 700 ? 'center' : 60,
        xOffset: baseWidth >= 700 ? -95 : -50,
        zIndex: -1,
        onShow: () => this.addChildBackdrop('routes-menu'),
        onNext: () => this.removeChildBackdrop('routes-menu'),
      },
      {
        content: this.translate.instant('TOUR.DASHBOARD-MENU'),
        target: 'dashboard-menu',
        placement: 'bottom',
        arrowOffset: 20,
        xOffset: -10,
        zIndex: -1,
        onShow: () => this.addChildBackdrop('dashboard-menu', ['p-n']),
        onNext: () => this.removeChildBackdrop('dashboard-menu'),
      },
      {
        content: this.translate.instant('TOUR.ADD-DRIVER'),
        target: 'add-driver',
        placement: baseWidth >= 700 ? 'left' : 'bottom',
        arrowOffset: baseWidth >= 700 ? 'center' : 200,
        showNextButton: false,
        xOffset: baseWidth >= 700 ? -20 : -120,
        yOffset: baseWidth >= 700 ? -20 : 5,
        nextOnTargetClick: true,
        onShow: () => {
          this.tourHeaderIndex.next(true);

          if (this.isSafari()) delay(() => this.addSafariHeader(), 100);

          this.addChildBackdrop('add-driver', [
            'white-background',
            'p-r-sm',
            'p-l-sm',
          ]);
        },
        onNext: () => {
          this.tourHeaderIndex.next(false);
          sessionStorage.setItem('hopscotch.tour.state', 'fleetflex:5');
          this.removeChildBackdrop('add-driver');
          if (this.isSafari()) delay(() => this.removeSafariHeader(), 100);
          hopscotch.endTour(false);
        },
      },
      {
        content: this.translate.instant('TOUR.DRIVER-STATUS'),
        target: document.getElementById('driver-status'),
        placement: 'bottom',
        arrowOffset: 20,
        xOffset: -10,
        zIndex: -1,
        delay: 100,
        onShow: () => {
          this.tourHeaderIndex.next(true);
          this.addChildBackdrop('driver-status', ['white-background']);
        },
        onNext: () => {
          this.tourHeaderIndex.next(false);
          this.removeChildBackdrop('driver-status');
        },
      },
      {
        target: 'last-tour-step',
        placement: 'left',
        zIndex: -1,
        delay: 100,
        onShow: () => {
          this.finishTour();
          this.userService.displayActivationModal.next(true);
          this.removeChildBackdrop('driver-status');
          sessionStorage.removeItem('continueTutorial');
          setTimeout(() => {
            hopscotch.endTour(true);
          }, 200);
        },
      },
    ];

    return steps;
  }

  /**
   * Add child backdrop to selected step
   *
   * @param childName
   * @param customClass
   */
  private addChildBackdrop(childName: string, customClass?: any) {
    let item = document.getElementById(childName);
    item.insertAdjacentHTML(
      'afterend',
      '<div id="item-backdrop" class="item-backdrop"></div>'
    );
    item.classList.add('selected-tour-item');

    if (this.isSafari()) item.classList.add('safari-item');

    if (customClass)
      customClass.forEach(styleClass => item.classList.add(styleClass));
  }

  /**
   * remove child backdrop to previous step
   *
   * @param childName
   */
  private removeChildBackdrop(childName: string) {
    let item = document.getElementById(childName);
    item.classList.remove('selected-tour-item');

    if (this.isSafari()) item.classList.remove('safari-item');

    let backdrop = document.getElementById('item-backdrop');
    if (backdrop) backdrop.remove();
  }

  /**
   * Complete tour steps
   */
  private finishTour() {
    let data = JSON.parse(localStorage.getItem('currentUserInfo'));

    if (data) data = data.data;

    let _data = clone(data);

    let payload = {
      displayTutorial: false,
    };

    this.userApi
      .update(payload)
      .toPromise()
      .then(response => {
        localStorage.setItem('currentUserInfo', JSON.stringify(response));
        this.analytics.sendAnalyticsEvent(
          'Skip tutorial',
          {
            companyId: _data.companyId,
            email: _data.email,
          },
          'tutorial'
        );
      })
      .catch(error => {
        this.helper.handleErrorMessages(error);
      });
  }

  /**
   * Check if browser is safari
   */
  private isSafari() {
    return (
      this.device.browser.toLowerCase().indexOf('safari') !== -1 ||
      this.device.userAgent.toLowerCase().indexOf('safari') !== -1
    );
  }

  /**
   * Add styles to header for safari
   */
  private addSafariHeader() {
    let header = first(document.getElementsByTagName('app-header'));

    if (!header) return;

    header.setAttribute('style', 'z-index: -1 !important;');

    let bar = document.getElementById('custom-box-shadow');

    if (bar) bar.setAttribute('style', 'visibility: hidden;');
  }

  /**
   * remove styles to header for safari
   */
  private removeSafariHeader() {
    let header = first(document.getElementsByTagName('app-header'));

    if (!header) return;

    header.setAttribute('style', 'z-index: auto;');

    let bar = document.getElementById('custom-box-shadow');

    if (bar) bar.style.removeProperty('visibility');
  }
}
