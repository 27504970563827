<div
  class="modal fade"
  bsModal
  #emailModal="bs-modal"
  role="dialog"
  aria-hidden="true"
  [config]="config">
  <div class="modal-dialog modal-email">
    <div class="modal-content">
      <div class="modal-body">
        <button class="close" mat-icon-button (click)="hide()">
          <i class="material-icons">close</i>
        </button>

        <nav mat-tab-nav-bar class="custom-tabs" disableRipple="true">
          <a
            mat-tab-link
            (click)="selectTab('client')"
            [active]="selectedTab === 'client'"
            routerLinkActive>
            {{ 'EMAIL-TEMPLATE.CLIENTS' | translate }}
          </a>
          <a
            mat-tab-link
            (click)="selectTab('admin')"
            [active]="selectedTab === 'admin'"
            routerLinkActive>
            {{ 'EMAIL-TEMPLATE.ADMINISTRATOR' | translate }}
          </a>
          <a
            mat-tab-link
            (click)="selectTab('driver')"
            [active]="selectedTab === 'driver'"
            routerLinkActive>
            {{ 'EMAIL-TEMPLATE.DRIVERS' | translate }}
          </a>
        </nav>

        <div class="admin-body m-t-md" *ngIf="selectedTab === 'admin'">
          <div class="logo-container">
            <img [src]="logo" />
          </div>

          <div class="welcome-message">
            <h4 class="title">
              {{ 'EMAIL-TEMPLATE.WELCOME' | translate }}
            </h4>
          </div>

          <div class="email-admin">
            <h5 class="subtitle">
              {{ 'EMAIL-TEMPLATE.REGISTRATION-CODE' | translate }}:
            </h5>

            <div class="code-box">064247</div>
          </div>

          <div class="footer">
            <a class="label" href="https://fleetflex.io"> www.fleetflex.io </a>
            |
            <span class="label"> Fleetflex Copyright 2018 </span>
          </div>
        </div>

        <div class="driver-body m-t-md" *ngIf="selectedTab === 'driver'">
          <div class="logo-container">
            <img [src]="logo" />
          </div>

          <div class="welcome-message">
            <h4 class="title m-t-md">
              {{ 'EMAIL-TEMPLATE.WELCOME' | translate }}
            </h4>
          </div>

          <div class="email-driver">
            <div class="step-container">
              <div class="item">
                <div class="step">1</div>
                <span class="label m-t-xs">
                  {{ 'EMAIL-TEMPLATE.STEP-ONE' | translate }}:
                </span>
              </div>

              <img src="./assets/img/google_play.png" />
            </div>

            <div class="step-container">
              <div class="item">
                <div class="step">2</div>
                <span class="label m-t-xs">
                  {{ 'EMAIL-TEMPLATE.STEP-TWO' | translate }}:
                </span>
              </div>

              <div class="code-box">064247</div>
            </div>
          </div>

          <div class="footer">
            <a class="label" href="https://fleetflex.io"> www.fleetflex.io </a>
            |
            <span class="label"> Fleetflex Copyright 2018 </span>
          </div>
        </div>

        <div class="client-body m-t-md" *ngIf="selectedTab === 'client'">
          <div class="logo-container">
            <img [src]="logo" />
          </div>

          <div class="main-message">
            <h5 class="subtitle">
              {{ 'EMAIL-TEMPLATE.CLIENT-GREETINGS' | translate }}
            </h5>
            <h4>{{ 'EMAIL-TEMPLATE.CLIENT-TIME' | translate }}</h4>
          </div>

          <div class="email-client">
            <h5 class="subtitle">
              {{ 'EMAIL-TEMPLATE.CLIENT-DETAILS' | translate }}:
            </h5>

            <span class="link">
              https://admin.fleetflex.io/tracking/5af1eb7acb4e0800277f981d
            </span>
          </div>

          <div class="footer">
            <a class="label" href="https://fleetflex.io"> www.fleetflex.io </a>
            |
            <span class="label"> Fleetflex Copyright 2018 </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
