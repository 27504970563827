<div class="side-panel-container" [@.disabled]="safari">
  <div
    class="panel-backdrop"
    id="app-side-backdrop"
    *ngIf="showSidePanel"></div>

  <div class="side-panel" *ngIf="showSidePanel" [@flyInOut]>
    <ng-content> </ng-content>
  </div>
</div>
