import { Component } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';

import { Router } from '@angular/router';
import { ApiRequestsService } from '../api';
import { CustomLoadingService, HelpersService } from '../shared';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.sass'],
})
export class ForgotPasswordComponent {
  forgotPwdForm: UntypedFormGroup;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private loading: CustomLoadingService,
    private helpers: HelpersService,
    private api: ApiRequestsService
  ) {}

  ngOnInit() {
    this.initForm();
  }

  /**
   * Submit forgot password
   *
   * @param root0
   * @param root0.value
   * @param root0.valid
   */
  requestPwd({ value, valid }: { value: any; valid: boolean }): void {
    if (!valid) return;

    this.api.auth.forgotPwd(value.email).subscribe(
      response => {
        this.router.navigate(['/forgot-password/change-password']);
      },
      error => {
        this.helpers.handleErrorMessages(error);
      }
    );
  }

  /**
   * Init form fields
   */
  private initForm(): void {
    this.forgotPwdForm = this.fb.group({
      email: ['', Validators.required],
    });
  }
}
