<div mat-dialog-content class="position-relative mat-dialog-content">
  <div class="text-center mb-5">
    <button
      [autofocus]="false"
      mat-icon-button
      class="close_button align-content-center align-items-center"
      (click)="cancel()">
      <mat-icon>close</mat-icon>
    </button>
    {{ 'Nueva Unidad' }}
  </div>
  <mat-divider></mat-divider>
  <div class="my-3">
    <mat-form-field class="example-form-field" appearance="outline">
      <input
        matInput
        type="text"
        [(ngModel)]="unit_label"
        placeholder="(ej; viaticos, producto , total de km)" />
    </mat-form-field>
    <br />

    <mat-radio-group class="unit-radio-group" [(ngModel)]="unit_type_selected">
      <mat-radio-button
        class="unit-radio-button mx-auto d-block"
        *ngFor="let unit of unit_types"
        [value]="unit.type">
        {{ unit.label }}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div>
    <button
      mat-raised-button
      class="d-block mx-auto button-color"
      [disabled]="!allowSave()"
      (click)="save()">
      Guardar
    </button>
  </div>
</div>
