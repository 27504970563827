<div class="signup-activation-container">
  <mat-card appearance="outlined">
    <button [routerLink]="['/signup']" mat-icon-button>
      <mat-icon aria-label="icon">arrow_back</mat-icon>
    </button>
    <h1 class="title">{{ 'SIGNUP-ACTIVATION.TITLE' | translate }}</h1>
    <p class="subtitle">{{ 'SIGNUP-ACTIVATION.CODE-SEND' | translate }}</p>

    <form
      name="form"
      class="activation-form text-right"
      [formGroup]="activationForm"
      (ngSubmit)="activateAccount(activationForm)"
      no-validate>
      <!-- CODE -->
      <mat-form-field class="w-20">
        <input
          matInput
          type="email"
          placeholder="{{ 'SIGNUP-ACTIVATION.CODE' | translate }}"
          name="code"
          formControlName="code" />
        <mat-error
          *ngIf="
            activationForm.controls.code.invalid &&
            activationForm.controls.code.touched
          ">
          {{ 'SIGNUP-ACTIVATION.MESSAGES.NOT-CODE' | translate }}
        </mat-error>
      </mat-form-field>
      <button
        mat-button
        color="primary"
        class="uppercase"
        type="button"
        (click)="getActivationCode()">
        {{ 'SIGNUP-ACTIVATION.RE-SEND-CODE' | translate }}
      </button>
      <div class="activation-btn">
        <button
          [disabled]="activationForm.invalid"
          type="submit"
          mat-raised-button
          color="primary">
          {{ 'SIGNUP-ACTIVATION.ACTIVATE-ACCOUNT' | translate }}
        </button>
      </div>
    </form>
  </mat-card>
</div>
