import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'card-item-vehicle',
  templateUrl: './card-item-vehicle.component.html',
  styleUrls: ['./card-item-vehicle.component.sass'],
})
export class CardItemVehicleComponent implements OnInit {
  @Input() vehicleContent: any = {};
  @Input() type: string = 'list';

  picturePlaceholder: string = './assets/img/vehicle-pic-placeholder.png';

  constructor(private router: Router) {}

  ngOnInit() {}

  /**
   * Go to details page
   */
  goToDetails() {
    if (this.type !== 'list') return;

    this.router.navigate(['vehicles/details', this.vehicleContent._id]);
  }
}
