import { Component, OnInit, ViewChild, Output, Input } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ApiRequestsService } from '../../../api';
import {
  HelpersService,
  AnalyticsService,
  CustomLoadingService,
  UserService,
} from '../../services';
import { TranslateService } from '@ngx-translate/core';
import { clone } from 'lodash';
import { Subject } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'activation-modal',
  templateUrl: './activation-modal.component.html',
  styleUrls: ['./activation-modal.component.sass'],
})
export class ActivationModalComponent implements OnInit {
  @ViewChild('activationModal') activationModal: ModalDirective;
  @Input('user') userInfo: any = {};
  @Output('activated') accountActivated: Subject<any> = new Subject();
  @Output() hidden: Subject<any> = new Subject<any>();

  activationForm: UntypedFormGroup;
  isIOS: boolean = false;

  config = {
    backdrop: false,
    ignoreBackdropClick: true,
  };

  constructor(
    private router: Router,
    private api: ApiRequestsService,
    private loading: CustomLoadingService,
    private fb: UntypedFormBuilder,
    private helpers: HelpersService,
    private analytics: AnalyticsService,
    private userService: UserService,
    private device: DeviceDetectorService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.initForm();
    this.isIOS =
      this.device.browser.toLowerCase().indexOf('safari') !== -1 ||
      this.device.userAgent.toLowerCase().indexOf('safari') !== -1 ||
      false;
  }

  /**
   * Show activation Modal
   */
  show() {
    this.activationModal.show();
  }

  /**
   * hide activation modal
   */
  hide() {
    this.activationModal.hide();
    this.hidden.next(true);
  }

  /**
   * Submit activate account
   *
   * @param root0
   * @param root0.value
   * @param root0.valid
   */
  activateAccount({ value, valid }: { value: any; valid: boolean }): void {
    if (!valid) return;

    sessionStorage.setItem(
      'userEmail',
      this.userInfo ? this.userInfo.email : ''
    );

    this.api.auth
      .activateAccount(value.code)
      .toPromise()
      .then(response => {
        sessionStorage.removeItem('userEmail');
        localStorage.setItem(
          'currentUserInfo',
          JSON.stringify(clone(response))
        );
        response = response.data;
        this.userService.user = response;
        this.analytics.sendAnalyticsEvent(
          'Company Activated',
          {
            email: response.email,
            companyId: response.companyId,
            createdAt: response.updatedAt,
          },
          'registration'
        );

        this.accountActivated.next(true);
      })
      .catch(error => {
        this.helpers.handleErrorMessages(error);
      });
  }

  /**
   * re-send activation conde
   */
  getActivationCode() {
    this.api.user
      .getActivationCode()
      .toPromise()
      .then(response => {
        let message = this.translate.instant(
          'SIGNUP-ACTIVATION.MESSAGES.CODE-SENT'
        );
        this.helpers.showSuccessMessage(message);
      })
      .catch(error => {
        this.helpers.handleErrorMessages(error);
      });
  }

  /**
   * Init form fields
   */
  private initForm(): void {
    this.activationForm = this.fb.group({
      code: ['', Validators.required],
    });
  }
}
