<div class="notifications-container">
  <div class="notifications-header">
    <div class="wrapper">
      <h2 class="title">{{ 'NOTIFICATIONS.TITLE' | translate }}</h2>
      <button mat-icon-button (click)="close()" class="notifications-close">
        <i class="material-icons">clear</i>
      </button>
    </div>
  </div>
  <div class="notification-cards-container">
    <perfect-scrollbar
      class="scroll-container"
      (psYReachEnd)="onReachedEnd($event)">
      <notification-card
        *ngFor="let notification of notifications"
        [content]="notification"
        [type]="notification.type"
        [entity]="notification.entity">
      </notification-card>
    </perfect-scrollbar>
  </div>
</div>
