import {
  trigger,
  state,
  animate,
  transition,
  style,
} from '@angular/animations';

export const slideUpDown = trigger('slideUpDown', [
  state('in', style({ 'min-height': '0', transform: 'scaleY(0)' })),
  transition('void => *', [
    style({ 'min-height': '100%', transform: 'scaleY(0)' }),
    animate('300ms linear'),
  ]),
  transition('* => void', [
    style({ 'min-height': '100%', transform: 'scaleY(1)' }),
  ]),
]);
