<div [class.loginscreen]="!loggedIn" style="position: relative">
  <app-header
    [loggedIn]="loggedIn"
    safariHeader
    [class.lowerIndex]="withoutIndex"
    (location)="getLocation($event)"
    [ngClass]="{ 'header-responsive': headerClass === 'down' }">
  </app-header>
</div>

<div class="app-wrapper">
  <perfect-scrollbar
    class="content"
    [ngClass]="{
      'scroll-responsive': headerClass === 'down' && location !== 'dashboard',
      'responsive-dashboard': headerClass === 'down' && location === 'dashboard'
    }"
    [class.loginscreen]="!loggedIn"
    scrollDirective>
    <router-outlet></router-outlet>
  </perfect-scrollbar>
  <!-- Side panel -->
  <app-side-panel *ngIf="loggedIn">
    <app-side-profile *ngIf="useMenu"></app-side-profile>
    <app-notifications *ngIf="useNotify"> </app-notifications>
  </app-side-panel>
</div>

<activation-success #modalSuccess> </activation-success>
