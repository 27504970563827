import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { clone } from 'lodash';
import { ApiRequestsService } from './api';
import {
  CustomLoadingService,
  FirebaseService,
  UserService,
  AnalyticsService,
  HelpersService,
} from './shared/services';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private api: ApiRequestsService,
    private loading: CustomLoadingService,
    private firebase: FirebaseService,
    private userService: UserService,
    private analytics: AnalyticsService,
    private helper: HelpersService,
    private translate: TranslateService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let currentState = state.url;
    let currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));

    if (currentUser) currentUser = currentUser.data;
    else {
      this.notAuthorized();
      return false;
    }

    this.userService.user = currentUser;

    let obs = new Observable<boolean>(observer => {
      let canContinue = true;

      if (!currentUser.isActive)
        sessionStorage.setItem('userEmail', currentUser.email);

      if (currentUser.role !== 'company' && currentUser.role !== 'manager') {
        this.notAuthorized();
        this.helper.showWarningMessage(
          this.translate.instant('LOGIN.MESSAGES.NOT-AUTHORIZED-MESSAGE'),
          this.translate.instant('LOGIN.MESSAGES.NOT-AUTHORIZED')
        );
        canContinue = false;
        observer.next(false);
      }
      if (!currentUser.isActive && currentUser.role === 'manager') {
        if (currentState !== '/activateAccount') {
          canContinue = false;
          this.router.navigate(['/activateAccount']);
          observer.next(false);
        } else {
          canContinue = true;
          observer.next(true);
        }
      }
      /*
      if(currentUser.isActive && currentState === '/activateAccount'){
        canContinue = false;
        this.router.navigate(['/dashboard']);
        observer.next(false);
        this.loading.hide();
      }

       */

      // identify on analytics
      if (!this.analytics.identified()) {
        this.analytics.registerUser(clone(currentUser));
        this.analytics.identify(currentUser.email);
      }

      // if tutorial it's not completed, redirect to drivers page to start tutorial
      if (
        currentUser.displayTutorial &&
        currentState.indexOf('drivers') === -1 &&
        currentUser.role === 'company'
      ) {
        canContinue = false;
        this.router.navigate(['/drivers']);
        observer.next(false);
      }

      let greetings = localStorage.getItem('greetings');
      let alreadyCompleted = localStorage.getItem('profileCompleted');

      // flag for do not display profile completed modal
      if (
        !currentUser.displayTutorial &&
        currentUser.profileCompleted &&
        alreadyCompleted !== 'show-finish'
      )
        localStorage.setItem('profileCompleted', 'completed');

      if (!greetings) {
        let welcomeMessage = !currentUser.firstName
          ? this.translate.instant('COMMON.WELCOME')
          : this.translate.instant('COMMON.WELCOME-AGAIN', {
              value: currentUser.firstName,
            });
        this.helper.showSuccessMessage(welcomeMessage);
        localStorage.setItem('greetings', 'greetings');
      }

      if (canContinue) {
        this.firebase
          .checkSession()
          .then(status => {
            this.userService.onProfileLoad.next(currentUser);
            observer.next(true);
          })
          .catch(error => {
            this.notAuthorized();
            observer.next(false);
          });
      }
    });

    return obs;
  }

  notAuthorized() {
    this.analytics.logout();
    localStorage.clear();
    this.userService.isLogged.next(false);
    this.router.navigate(['/login']);
  }
}

@Injectable()
export class AuthGuardAdmin implements CanActivate {
  constructor(
    private router: Router,
    private api: ApiRequestsService,
    private loading: CustomLoadingService,
    private firebase: FirebaseService,
    private userService: UserService,
    private analytics: AnalyticsService,
    private helper: HelpersService,
    private translate: TranslateService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let currentState = state.url;
    let currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));

    if (currentUser) currentUser = currentUser.data;
    else {
      this.notAuthorized();
      return false;
    }

    this.userService.user = currentUser;

    let obs = new Observable<boolean>(observer => {
      let canContinue = true;

      if (!currentUser.isActive)
        sessionStorage.setItem('userEmail', currentUser.email);

      if (
        currentUser.role !== 'company' &&
        !(
          currentUser.role === 'manager' &&
          _.has(currentUser, 'read_only') &&
          !currentUser.read_only
        )
      ) {
        this.notAuthorized();
        this.helper.showWarningMessage(
          this.translate.instant('LOGIN.MESSAGES.NOT-AUTHORIZED-MESSAGE'),
          this.translate.instant('LOGIN.MESSAGES.NOT-AUTHORIZED')
        );
        canContinue = false;
        observer.next(false);
      }

      // identify on analytics
      if (!this.analytics.identified()) {
        this.analytics.registerUser(clone(currentUser));
        this.analytics.identify(currentUser.email);
      }

      // if tutorial it's not completed, redirect to drivers page to start tutorial
      if (
        currentUser.displayTutorial &&
        currentState.indexOf('drivers') === -1 &&
        currentUser.role !== 'manager'
      ) {
        canContinue = false;
        this.router.navigate(['/drivers']);
        observer.next(false);
      }

      let greetings = localStorage.getItem('greetings');
      let alreadyCompleted = localStorage.getItem('profileCompleted');

      // flag for do not display profile completed modal
      if (
        !currentUser.displayTutorial &&
        currentUser.profileCompleted &&
        alreadyCompleted !== 'show-finish'
      )
        localStorage.setItem('profileCompleted', 'completed');

      if (!greetings) {
        let welcomeMessage = !currentUser.firstName
          ? this.translate.instant('COMMON.WELCOME')
          : this.translate.instant('COMMON.WELCOME-AGAIN', {
              value: currentUser.firstName,
            });
        this.helper.showSuccessMessage(welcomeMessage);
        localStorage.setItem('greetings', 'greetings');
      }

      if (canContinue) {
        this.firebase
          .checkSession()
          .then(status => {
            this.userService.onProfileLoad.next(currentUser);
            observer.next(true);
          })
          .catch(error => {
            this.notAuthorized();
            observer.next(false);
          });
      }
    });

    return obs;
  }

  notAuthorized() {
    this.analytics.logout();
    localStorage.clear();
    this.userService.isLogged.next(false);
    this.router.navigate(['/login']);
  }
}

@Injectable()
export class AnonymousGuard implements CanActivate {
  constructor(private router: Router, private api: ApiRequestsService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let token = localStorage.getItem('currentUser');
    if (token) this.router.navigate(['/dashboard']);

    return true;
  }
}

@Injectable()
export class NotAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    // private auth: AuthService,
    private api: ApiRequestsService,
    private analytics: AnalyticsService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    localStorage.clear();
    this.analytics.logout();
    return true;
  }
}
