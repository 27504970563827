<div class="card-autocomplete-container">
  <form>
    <mat-form-field class="form-field">
      <input
        matInput
        #input
        [placeholder]="placeholder"
        [matAutocomplete]="auto"
        [formControl]="itemsCtrl" />
      <mat-icon matSuffix>search</mat-icon>

      <mat-autocomplete
        #auto="matAutocomplete"
        class="autocomplete-container"
        (optionSelected)="onOptionSelection($event)">
        <mat-option
          *ngFor="let item of filteredItems | async"
          [value]="item?._id"
          class="autocomplete-item">
          <!-- Items view -->
          <card-item-driver
            *ngIf="type === 'driver'"
            [type]="'autocomplete'"
            [driverContent]="item">
          </card-item-driver>

          <card-item-vehicle
            *ngIf="type === 'vehicle'"
            [type]="'autocomplete'"
            [vehicleContent]="item">
          </card-item-vehicle>

          <card-item-route
            *ngIf="type === 'route'"
            [viewType]="viewType"
            [isAutocomplete]="true"
            [type]="'autocomplete'"
            [content]="item">
          </card-item-route>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
</div>
