import { Component, OnInit } from '@angular/core';
import { ApiRequestsService } from '../api';
import { ComponentCanDeactivate } from '../shared/guards/pending-changes.guard';
import { HostListener } from '@angular/core';
import { Observable } from 'rxjs';

import * as _ from 'lodash';
import { FirebaseService, HelpersService } from '../shared';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { UnitsModalComponent } from 'app/shared/components/units-modal/units-modal.component';
import * as moment from 'moment';
import { ERRORS } from '../shared/errors/errors.component';

@Component({
  selector: 'app-form-config',
  templateUrl: './form-config.component.html',
  styleUrls: ['./form-config.component.sass'],
})
export class FormConfigComponent implements OnInit, ComponentCanDeactivate {
  formConfiguration: any;
  driverForm: any;
  _driverForm: any;
  editMode = false;
  changesMade = false;
  unitMetrics = [];
  showOptions = false;
  selectedUnit = null;
  selectedNumericFieldName = '';
  companyConfiguration: any;
  loading = true;

  constructor(
    private apiService: ApiRequestsService,
    private helperService: HelpersService,
    public dialog: MatDialog,
    private firebase: FirebaseService
  ) {}

  @HostListener('window:beforeunload')
  canDeactivate(): boolean | Observable<boolean> {
    return !(this.editMode && !_.isEqual(this.driverForm, this._driverForm));
  }
  toggleOptions() {
    this.showOptions = !this.showOptions;
  }
  computedNumericSelected() {
    return this.selectedUnit?.label || '';
  }

  async ngOnInit() {
    await this.apiService.company.getFormConfiguration().subscribe(response => {
      this.driverForm = response.data.driver_form;
      this._driverForm = _.cloneDeep(this.driverForm);
      this.companyConfiguration = response.data;
      this.helperService.setCompanyConfigurations(response.data);
      this.loading = false;
    });
    this.apiService.company.getUnitMetric().subscribe(
      response => {
        this.unitMetrics = response.data;
      },
      error => {
        this.helperService.handleErrorMessages(error);
      }
    );
  }

  logThis(event) {
    console.log(event);
  }

  resetForm() {
    this.editMode = false;
    this.driverForm = _.cloneDeep(this._driverForm);
  }

  deactivateRequired(event, field) {
    if (!event) {
      this.driverForm[field].isRequired = false;
    }
  }

  deactivateRequiredNumeric(event, index, numeric_obj) {
    if (!event.checked) {
      this.driverForm.numerics[index].isRequired = false;
    } else {
      if (
        this.driverForm.numerics
          .filter(n => !n.isDeleted)
          .filter(n => n.visible).length >
        (this.companyConfiguration?.max_numerics || 3)
      ) {
        this.showAddNumericLimitModal();
        this.driverForm.numerics[index].visible = false;
        event.source.checked = false;
      }
    }
  }

  showInfoPop(section) {
    Swal.fire({
      imageUrl: './../../assets/img/form-info-' + section + '.svg',
      width: 500,
      padding: '1em',
      showCloseButton: true,
      showConfirmButton: false,
    });
  }

  async saveForm() {
    if (!_.isEqual(this.driverForm, this._driverForm)) {
      this.loading = true;
      const db_form = _.cloneDeep(this.driverForm);

      // ignoring temp fields
      db_form.numerics = db_form.numerics?.filter(
        n => !(!n._id && n.isDeleted)
      );

      // Creating new numeric fields
      const numerics_id = [];
      const new_numerics = db_form.numerics?.filter(n => !n._id);
      for (let i = 0; i < new_numerics.length; i++) {
        const n = new_numerics[i];
        try {
          const new_numeric = await this.apiService.company
            .addCustomField({
              label: n.label,
              metric_unit: n.metric_unit._id,
              type: 'numeric',
              visible: n.visible,
            })
            .toPromise();
          numerics_id.push(new_numeric.data._id);
        } catch (e) {
          // Fix Do something
        }
      }
      // updating exiting numerics
      const numerics_existing = db_form.numerics?.filter(n => n._id);
      for (let i = 0; i < numerics_existing.length; i++) {
        const n = numerics_existing[i];
        n.metric_unit = n.metric_unit._id;
        try {
          await this.apiService.company.updateCustomField(n._id, n).toPromise();
        } catch (e) {
          // Fix Do something
        }
      }
      db_form.numerics = [
        ...db_form.numerics.filter(n => !n.isDeleted && n._id).map(n => n._id),
        ...numerics_id,
      ];

      this.apiService.company.updateFormConfiguration(db_form).subscribe(
        async response => {
          this.loading = false;
          this.driverForm = response.data.driver_form;
          this.companyConfiguration = response.data;
          this._driverForm = _.cloneDeep(this.driverForm);
          this.editMode = false;
          this.helperService.setCompanyConfigurations(response.data);
          this.helperService.showSuccessMessage('Se han guardado los cambios');
          // this.firebase.checkSession();
          const data = {
            date: moment().toISOString(),
          };
          await this.firebase.update(
            `formConfig/${this.companyConfiguration.companyId}`,
            data
          );
        },
        error => {
          this.helperService.handleErrorMessages(error, ERRORS.E017);
        }
      );
    } else {
      this.helperService.showWarningMessage('No existen cambios que guardar.');
    }
  }

  showCancelChangesModal() {
    Swal.fire({
      width: 500,
      title:
        '<br><p style="text-align: center;font-size: larger;color: black"><strong>¿Deseas cancelar los cambios?</strong></p>',
      html: '<br><p style="text-align: center;font-size: medium;color: black"><span>Al cancelar loca cambios, estos se perderán</span></p>',
      confirmButtonColor: '#1C98F8',
      confirmButtonText:
        '<div style="font-size: 20px; padding: 0 5px">Si</div>',
      denyButtonColor: '#1C98F8',
      denyButtonText: '<div style="font-size: 20px; padding: 0 5px">No</div>',
      showDenyButton: true,
    })
      .then(result => {
        if (result.isConfirmed) {
          // reseting form
          this.driverForm = _.cloneDeep(this._driverForm);
        }
      })
      .catch(e => {});
  }

  showRemoveNumericModal(position) {
    const numericField = this.driverForm.numerics[position];
    console.log('position:', position, 'numeric:', numericField);
    Swal.fire({
      width: 500,
      title: `<br><p style="text-align: center;font-size: larger;color: black"><strong>¿Desea eliminar el campo '${
        numericField?.label || ''
      }'?</strong></p>`,
      html: '<br><p style="text-align: center;font-size: medium;color: black"><span>Al cancelar no se guardaran los cambios que han hecho en la pagina</span></p>',
      confirmButtonColor: '#1C98F8',
      confirmButtonText:
        '<div style="font-size: 20px; padding: 0 5px">Si</div>',
      denyButtonColor: '#1C98F8',
      denyButtonText: '<div style="font-size: 20px; padding: 0 5px">No</div>',
      showDenyButton: true,
    })
      .then(result => {
        if (result.isConfirmed) {
          this.removeNumericField_inPosition(position);
        }
      })
      .catch(e => {});
  }

  async openSidePanel() {
    document.getElementById('sidepanelopen').click();
    setTimeout(() => {
      document.getElementById('tagIconMove').click();
    }, 500);
  }

  openMetricModal() {
    const ref = this.dialog.open(UnitsModalComponent);
  }

  onSelectedUnit(unit) {
    this.selectedUnit = unit;
    console.log('slected', unit);
    this.toggleOptions();
  }

  addNumericField(nameField, unit) {
    const numeric_obj = {
      visible: true,
      isRequired: false,
      label: nameField,
      metric_unit: unit,
    };
    if (!this.driverForm.numerics) {
      this.driverForm.numerics = [];
    }
    if (
      this.driverForm.numerics.filter(n => !n.isDeleted).filter(n => n.visible)
        .length >= (this.companyConfiguration?.max_numerics || 3)
    ) {
      numeric_obj.visible = false;
    }
    this.driverForm.numerics.push(numeric_obj);
    this.selectedUnit = null;
    this.selectedNumericFieldName = '';
  }

  removeNumericField_inPosition(position) {
    this.driverForm.numerics[position].isDeleted = true;
  }

  showAddNumericLimitModal() {
    Swal.fire({
      width: 500,
      icon: 'error',
      title: `<br><p style="text-align: center;font-size: larger;color: black"><strong>Error con campo numérico</strong></p>`,
      html: '<br><p style="text-align: center;font-size: medium;color: black"><span>Ha llegado a el numéro máximo de campos numéricos activados permitidos. Para crear este campo debes desactivar otra unidad de tu lista</span></p>',
      confirmButtonColor: '#1C98F8',
      confirmButtonText:
        '<div style="font-size: 20px; padding: 0 5px">Entiendo</div>',
      showDenyButton: false,
    })
      .then(result => {})
      .catch(e => {});
  }
}
