import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { UserService } from '../../services';

@Component({
  selector: 'app-onboarding-start-modal',
  templateUrl: './onboarding-start-modal.component.html',
  styleUrls: ['./onboarding-start-modal.component.sass'],
})
export class OnboardingStartModalComponent implements OnInit {
  user;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<OnboardingStartModalComponent>,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.user = this.userService.user;
  }
  close() {
    this.dialogRef.close();
  }
}
