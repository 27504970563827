import { Component } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { Router, ActivatedRoute } from '@angular/router';
import { ApiRequestsService } from '../../api';
import {
  CustomLoadingService,
  HelpersService,
  AnalyticsService,
} from '../../shared';
import { clone } from 'lodash';

@Component({
  selector: 'app-signup-activation',
  templateUrl: './signup-activation.component.html',
  styleUrls: ['./signup-activation.component.sass'],
})
export class SignupActivationComponent {
  error = false;
  activationForm: UntypedFormGroup;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private api: ApiRequestsService,
    private loading: CustomLoadingService,
    private fb: UntypedFormBuilder,
    private helpers: HelpersService,
    private analytics: AnalyticsService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.verifyIfCanAccess();
    this.initForm();
  }

  /**
   * Verify at init if token it's valid
   */
  verifyIfCanAccess() {
    this.api.user.me().subscribe(
      response => {
        localStorage.setItem('currentUserInfo', JSON.stringify(response));
        if (response.data.isActive) this.router.navigate(['/dashboard']);
      },
      error => {}
    );
  }

  /**
   * re-send activation conde
   */
  getActivationCode() {
    this.api.user.getActivationCode().subscribe(
      response => {
        let message = this.translate.instant(
          'SIGNUP-ACTIVATION.MESSAGES.CODE-SENT'
        );
        this.helpers.showSuccessMessage(message);
      },
      error => {
        this.helpers.handleErrorMessages(error);
      }
    );
  }

  /**
   * Submit activate account
   *
   * @param root0
   * @param root0.value
   * @param root0.valid
   */
  activateAccount({ value, valid }: { value: any; valid: boolean }): void {
    if (!valid) return;

    this.api.auth.activateAccount(value.code).subscribe(
      response => {
        localStorage.setItem(
          'currentUserInfo',
          JSON.stringify(clone(response))
        );
        response = response.data;

        this.router.navigate(['/signup/success']);

        this.analytics.sendAnalyticsEvent(
          'Company Activated',
          {
            email: response.email,
            companyId: response.companyId,
            createdAt: response.updatedAt,
          },
          'registration'
        );
      },
      error => {
        this.error = true;
        this.helpers.handleErrorMessages(error);
      }
    );
  }

  /**
   * Init form fields
   */
  private initForm(): void {
    this.activationForm = this.fb.group({
      code: ['', Validators.required],
    });
  }
}
