import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomLoadingService, HelpersService } from '../../../services';
import { ApiRequestsService } from '../../../../api';
import { RouteModel } from '../../../../models/route.model';

@Component({
  selector: 'routes-report-table',
  templateUrl: './routes-report-table.component.html',
  styleUrls: ['./routes-report-table.component.sass'],
})
export class RoutesReportTableComponent implements OnInit {
  @Input() route: RouteModel;
  @Input() statuses: Array<any> = [];
  @Output() previewOpenEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() previewChangeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() previewCloseEvent: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  galleryOptions: Array<any> = [];

  constructor(
    public helpers: HelpersService,
    private apiService: ApiRequestsService,
    private loading: CustomLoadingService
  ) {}

  ngOnInit() {
    this.galleryOptions = [
      {
        image: false,
        height: '120px',
        arrowPrevIcon: 'fa fa-chevron-left',
        arrowNextIcon: 'fa fa-chevron-right',
        closeIcon: 'fa fa-times',
        imageArrowsAutoHide: true,
        thumbnailsArrowsAutoHide: true,
      },
      {
        breakpoint: 500,
        width: '100%',
        thumbnailsColumns: 2,
      },
    ];
    this.reorderByIndex(this.route);
  }

  reorderByIndex(route: any) {
    let initDate = 3900245101;

    this.route.deliverPoints.sort((a, b) => {
      let date = initDate * 1000;
      let startDate = a.documentedAt ?? date;
      let endDate = b.documentedAt ?? date;
      initDate + 1;

      return new Date(startDate).getTime() - new Date(endDate).getTime();
    });
  }

  previewOpen($event) {
    this.previewOpenEvent.emit($event);
  }

  previewChange($event) {
    this.previewOpenEvent.emit($event);
  }

  previewClose($event) {
    this.previewCloseEvent.emit(false);
  }
}
