import { Injectable, Inject } from '@angular/core';
import { NewHttpClient } from '../interceptor.service';
//import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import { INJECTION_TOKEN } from '../../app.config';

@Injectable()
export class NotificationsRequests {
  constructor(
    @Inject(INJECTION_TOKEN) public config: any,
    private http: NewHttpClient
  ) {}

  /**
   * List company notifications
   */
  list(): Observable<any> {
    let url = `${this.config.notifications_url}/list/company`;
    return this.http.get(url);
  }

  /**
   * Store notifications settings to company
   *
   * @param type
   * @param payload
   */
  store(type: string, payload: any): Observable<any> {
    let url = `${this.config.notifications_url}/company/${type}`;
    return this.http.post(url, payload);
  }
}
