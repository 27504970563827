import {
  Component,
  OnInit,
  Input,
  Output,
  Inject,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { HelpersService } from '../../../services';
import { ApiRequestsService } from '../../../../api';
import { INJECTION_TOKEN } from '../../../../app.config';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'card-assign-autocomplete',
  templateUrl: './card-autocomplete.component.html',
  styleUrls: ['./card-autocomplete.component.sass'],
})
export class CardAutocompleteComponent implements OnInit {
  @ViewChild(MatAutocompleteTrigger) autoTrigger: MatAutocompleteTrigger;
  @ViewChild('input') input: ElementRef;

  @Input() type: string = 'driver';
  @Input() parentType: string = '';
  @Input() dateQuery: any;
  @Input() viewType;

  @Output() onSelection: Subject<any> = new Subject<any>();

  placeholder: string = '';
  itemsCtrl: UntypedFormControl;
  filteredItems: Observable<any[]>;

  items: Array<any> = [];

  private vehicleTypeId: string = '';
  // actives vechiles category name
  private vehicleCategoryActiveName: string = '';

  constructor(
    @Inject(INJECTION_TOKEN) private config: any,
    private translate: TranslateService,
    private api: ApiRequestsService,
    private helpers: HelpersService
  ) {
    this.itemsCtrl = new UntypedFormControl();
  }

  ngOnInit() {
    this.setPlaceholder();
    this.vehicleCategoryActiveName = this.config.vehicle_active_status;
    this.filteredItems = this.itemsCtrl.valueChanges.pipe(
      startWith(''),
      map(search => (search ? this.filterItems(search) : this.items.slice()))
    );
  }

  /**
   * Only load data when autocomplete it's shown
   */
  initLoad() {
    this.fillItemsByType();
  }

  /**
   * Get selected option of autocomplete
   *
   * @param selection
   */
  onOptionSelection(selection: any) {
    let selectedItem = this.items.find(e => e._id === selection.option.value);

    if (selectedItem) return this.onSelection.next(selectedItem);
  }

  /**
   * Open autocomplete panel options
   */
  private loadOptions() {
    this.itemsCtrl.setValue(' ');
    this.itemsCtrl.setValue('');
    this.autoTrigger.openPanel();
    this.input.nativeElement.focus();
  }

  /**
   * trigger item selection to default
   */
  resetSelection() {
    this.itemsCtrl.setValue(null);
  }

  /**
   * Filter search items
   *
   * @param search
   */
  private filterItems(search: any) {
    if (this.type === 'route') return this.filterRoutes(search);

    if (this.type === 'vehicle') return this.filterVehicles(search);

    if (this.type === 'driver') return this.filterDrivers(search);
  }

  /**
   * Set placeholder according to autocomplete type
   */
  private setPlaceholder() {
    let _placeholder =
      this.type === 'vehicle'
        ? this.translate.instant('CARD-ASSIGNMENT.SEARCH-VEHICLE')
        : this.type === 'route'
        ? this.translate.instant('CARD-ASSIGNMENT.SEARCH-ROUTE')
        : this.translate.instant('CARD-ASSIGNMENT.SEARCH-DRIVER');

    this.placeholder = _placeholder;
  }

  /**
   * Fill items array according to card type
   */
  private fillItemsByType() {
    if (this.type === 'vehicle') return this.getAvailableVehicles();

    if (this.type === 'driver') return this.getAvailableDrivers();

    if (this.type === 'route') return this.getAvailableRoutes();
  }

  /**
   * Get available vehicles list
   */
  private getAvailableVehicles() {
    this.api.vechiles
      .getVehicleStatuses({ limit: 50 })
      .toPromise()
      .then(response => {
        let type = _.filter(
          response.data,
          item => item.type === 'vehicle'
        ).find(item => item.name === this.vehicleCategoryActiveName);

        if (_.isUndefined(type))
          this.handleErrors({
            error: this.translate.instant(
              'CARD-ASSIGNMENT.ERRORS.INVALID-VEHICLES'
            ),
          });

        this.vehicleTypeId = type._id;
        let dateQuery = this.generateQueryDate();
        this.items = [];

        return this.api.vechiles
          .getAvailable(dateQuery, this.vehicleTypeId)
          .toPromise();
      })
      .then(response => {
        this.items = response.data;
        if (this.items.length > 0) this.loadOptions();
      })
      .catch(error => this.handleErrors(error));
  }

  /**
   * Get available drivers lists
   */
  private getAvailableDrivers() {
    let dateQuery = this.generateQueryDate();
    this.items = [];

    this.api.drivers
      .getAvailable(dateQuery)
      .toPromise()
      .then(response => {
        this.items = response.data;
        if (this.items.length > 0) this.loadOptions();
      })
      .catch(error => this.handleErrors(error));
  }

  /**
   * Get available routes lists
   */
  private getAvailableRoutes() {
    let query = { limit: 50, status: this.config.routes_pending_status };

    let entity = this.parentType === 'driver' ? 'drivers' : 'vehicles';
    let _date = this.generateQueryDate();
    this.api.routes
      .getAvailableByEntity(_date, entity)
      .toPromise()
      .then(response => {
        this.items = response.data[entity] || [];
        if (this.items.length > 0) this.loadOptions();
      })
      .catch(error => this.handleErrors(error));
  }

  /**
   * Display error message at error
   *
   * @param error
   */
  private handleErrors(error) {
    this.items = [];
    this.helpers.handleErrorMessages(error);
  }

  /**
   * Filter routes content
   *
   * @param term
   */
  private filterRoutes(term) {
    let labelSearch = _.filter(this.items, e => {
      let label = _.get(e, 'label');
      return label
        ? label.toLowerCase().indexOf(term.toLowerCase()) !== -1
          ? e
          : null
        : null;
    });

    // To filter by address or customer name
    /* let addressSearch = filter(this.items, e => {
      let address = e.deliverPoints.map(point => get(point, 'deliveryPoint.address'));
      return address ? address.find(e => e.toLowerCase().indexOf(term.toLowerCase()) !== -1 ) ? e : null : null
    });

    let customerName = filter(this.items, e => {
      let customerName = e.deliverPoints.map(point => get(point, 'deliveryPoint.customerName'));
      return customerName ? customerName.find(e => e.toLowerCase().indexOf(term.toLowerCase()) !== -1) ? e : null : null
    }); */

    let search = [].concat(labelSearch);

    return _.uniqBy(search, '_id');
  }

  /**
   * Filter vehicles content
   *
   * @param term
   */
  private filterVehicles(term) {
    let itemSearch = _.filter(this.items, e => {
      let label = e.brand + ' ' + e.model;
      return label
        ? label.toLowerCase().indexOf(term.toLowerCase()) !== -1
          ? e
          : null
        : null;
    });

    return itemSearch;
  }

  /**
   * Filter drivers content
   *
   * @param term
   */
  private filterDrivers(term) {
    let itemSearch = _.filter(this.items, e => {
      let label = '';
      if (e.firstName) {
        label = e.firstName + ' ' + e.lastName;
      } else {
        label = e.email;
      }
      return label
        ? label.toLowerCase().indexOf(term.toLowerCase()) !== -1
          ? e
          : null
        : null;
    });

    return itemSearch;
  }

  /**
   * Generate query date for requests
   */
  private generateQueryDate() {
    let _date = this.dateQuery
      ? moment(this.dateQuery, ['YYYY-MM-DD', 'YYYY-MM-DD Z']).format(
          'YYYY-MM-DD Z'
        )
      : moment().format('YYYY-MM-DD Z');
    return _date;
  }
}
