<div class="customize-container">
  <div class="customization-section">
    <h4 class="title">{{ 'SIDE-MENU.CUSTOMIZATION.UPLOAD' | translate }}:</h4>

    <div class="main-picture-holder" [ngClass]="{ 'p-b-n': cropingImage }">
      <div
        class="picture-holder"
        [ngClass]="{ selected: imageUrl, crop: cropingImage }"
        [ngStyle]="getContainerStyle()">
        <input
          type="file"
          name="picture"
          *ngIf="!imageUrl"
          #browseButton
          id="browseButton"
          class="input-file"
          (change)="loadPicture($event)"
          accept=".jpeg, .png, .jpg" />

        <angular-cropper
          #cropper
          [cropperOptions]="cropperConfig"
          [imageUrl]="imageUrl"
          *ngIf="imageUrl && cropingImage"></angular-cropper>

        <div class="description-holder" *ngIf="!imageUrl">
          <div class="w-full text-center">
            <i class="material-icons upload-icon">file_upload</i>
          </div>
          <div class="description">
            <span class="main">
              {{ 'SIDE-MENU.CUSTOMIZATION.IMPORT' | translate }}
            </span>
            <span class="child"> (.JPG, .PNG) </span>
          </div>
        </div>

        <div class="image-holder" *ngIf="imageUrl && !cropingImage">
          <img [src]="imageUrl" />
        </div>
      </div>

      <div class="holder-footer" *ngIf="imageUrl && !cropingImage">
        <button
          mat-button
          class="uppercase-text"
          *ngIf="!showEdition"
          color="primary"
          (click)="showEdition = !showEdition">
          <i class="material-icons">edit</i>
          {{ 'COMMON.EDIT' | translate }}
        </button>
        <button
          mat-button
          class="uppercase-text"
          *ngIf="showEdition"
          (click)="removeImage()">
          <i class="material-icons">delete</i>
          {{ 'COMMON.DELETE' | translate }}
        </button>
      </div>

      <div class="crop-controls" *ngIf="cropingImage">
        <button
          class="zoom"
          mat-icon-button
          color="primary"
          (click)="removeImage()">
          <i class="material-icons">delete</i>
        </button>
        <button
          class="zoom"
          mat-icon-button
          color="primary"
          (click)="zoom(0.1)">
          <i class="material-icons">zoom_in</i>
        </button>
        <button
          class="zoom"
          mat-icon-button
          color="primary"
          (click)="zoom(-0.1)">
          <i class="material-icons">zoom_out</i>
        </button>
      </div>
    </div>

    <div
      class="check-container"
      *ngIf="showEdition"
      [ngClass]="{ cropping: cropingImage, 'm-t-n': cropingImage }">
      <mat-checkbox [labelPosition]="'before'" [(ngModel)]="checked">
        {{ 'SIDE-MENU.CUSTOMIZATION.BACKGROUND' | translate }}
      </mat-checkbox>
    </div>

    <div class="email-template" *ngIf="imageUrl">
      <a (click)="showEmailPreview()">
        {{ 'SIDE-MENU.CUSTOMIZATION.EMAIL-TEMPLATE' | translate }}
      </a>
    </div>
  </div>

  <hr *ngIf="imageUrl && showEdition" />

  <div class="button-row pull-right" *ngIf="cropingImage">
    <button
      class="uppercase-text"
      mat-raised-button
      color="primary"
      (click)="cropImage()">
      {{ 'SIDE-MENU.CUSTOMIZATION.CROP' | translate }}
    </button>
  </div>

  <div
    class="button-row pull-right"
    *ngIf="imageUrl && !cropingImage && showEdition">
    <button
      class="m-r-sm uppercase-text"
      mat-button
      color="primary"
      (click)="cancel()">
      {{ 'COMMON.CANCEL' | translate }}
    </button>

    <button
      class="uppercase-text"
      mat-raised-button
      color="primary"
      (click)="storePicture()">
      {{ 'COMMON.STORED' | translate }}
    </button>
  </div>
</div>

<email-preview-modal></email-preview-modal>
