import { ApiRequestsService } from '../../../api';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { isPlainObject } from 'lodash';

class CountInfoContainer {
  count: Number;
  keyStatus: string;
  state: string;

  constructor(count: Number, keyStatus: string) {
    this.count = count;
    this.keyStatus = keyStatus;
    this.state = '';
  }
}

interface StateInterface {
  all: CountInfoContainer;
  assigned: CountInfoContainer;
  notAssigned: CountInfoContainer;
  OOS: CountInfoContainer;
  onRoute: CountInfoContainer;
  setback: CountInfoContainer;
  cancelled: CountInfoContainer;
  busy: Subscription;
  api: ApiRequestsService;
  filterApplied: Number;

  //signature of methods that bring the list requested
  getAll(perPage, page, sort, date): Observable<any>;
  getAssigned(perPage, page, sort, date): Observable<any>;
  getNotAssigned(perPage, page, sort, date): Observable<any>;
  getOOS(perPage, page, sort, date): Observable<any>;
  getOnRoute(perPage, page, sort, date): Observable<any>;
  getSetback(perPage, page, sort, date): Observable<any>;
  getCancelled(perPage, page, sort, date): Observable<any>;

  //signature of methods that collect all the counts
  getAllCount();
}

export class HeaderState implements StateInterface {
  all: CountInfoContainer;
  assigned: CountInfoContainer;
  notAssigned: CountInfoContainer;
  OOS: CountInfoContainer;
  onRoute: CountInfoContainer;
  setback: CountInfoContainer;
  cancelled: CountInfoContainer;
  invited: CountInfoContainer;
  busy: Subscription;
  api: ApiRequestsService;
  filterApplied: Number;

  constructor() {
    [
      this.all,
      this.assigned,
      this.notAssigned,
      this.onRoute,
      this.OOS,
      this.setback,
      this.cancelled,
      this.invited,
    ] = [
      new CountInfoContainer(0, ''),
      new CountInfoContainer(0, ''),
      new CountInfoContainer(0, ''),
      new CountInfoContainer(0, ''),
      new CountInfoContainer(0, ''),
      new CountInfoContainer(0, ''),
      new CountInfoContainer(0, ''),
      new CountInfoContainer(0, ''),
    ];
    this.filterApplied = 1;
  }

  //signature of methods that bring the list requested
  getAll(perPage, page, sort, date): Observable<any> {
    return null;
  }
  getAssigned(perPage, page, sort, date): Observable<any> {
    return null;
  }
  getNotAssigned(perPage, page, sort, date): Observable<any> {
    return null;
  }
  getOOS(perPage, page, sort, date): Observable<any> {
    return null;
  }
  getOnRoute(perPage, page, sort, date): Observable<any> {
    return null;
  }
  getSetback(perPage, page, sort, date): Observable<any> {
    return null;
  }
  getCancelled(perPage, page, sort, date): Observable<any> {
    return null;
  }
  getInvited(perPage, page, sort, date): Observable<any> {
    return null;
  }

  validateDate(date) {
    date = date ? date : moment().format('YYYY-MM-DD Z');
    return date;
  }

  //signature of methods that collect all the counts
  getAllCount(date?) {}

  protected assignValues(response) {
    this.all.count = response.data.all.count;
    this.all.keyStatus = response.data.all.status;
    this.all.state = response.data.all.q;
    if (response.data.fds != null) {
      this.OOS.count = response.data.fds.count;
      this.OOS.keyStatus = response.data.fds.status;
      this.OOS.state = response.data.fds.q;
    }
    this.onRoute.count = response.data.onRoute.count;
    this.onRoute.keyStatus = response.data.onRoute.status;
    this.onRoute.state = response.data.onRoute.q;
    this.notAssigned.count = response.data.withOut.count;
    this.notAssigned.keyStatus = response.data.withOut.status;
    this.notAssigned.state = response.data.withOut.q;
    this.assigned.count = response.data.pending.count;
    this.assigned.keyStatus = response.data.pending.status;
    this.assigned.state = response.data.pending.q;
    this.setback.count = response.data.others.count;
    this.setback.keyStatus = response.data.others.status;
    this.setback.state = response.data.others.q;
    if (response.data.cancelled != null) {
      this.cancelled.count = response.data.cancelled.count;
      this.cancelled.keyStatus = response.data.cancelled.status;
      this.cancelled.state = response.data.cancelled.q;
    }
    if (response.data.invited != null) {
      this.invited.count = response.data.invited.count;
      this.invited.keyStatus = response.data.invited.status;
      this.invited.state = response.data.invited.q;
    }
  }
}

export class DriverState extends HeaderState implements StateInterface {
  constructor(api: ApiRequestsService) {
    super();
    this.api = api;
    this.getAllCount();
  }

  getAll(perPage, page, sort, date): Observable<any> {
    date = this.validateDate(date);
    return this.api.drivers.getDriversListByState('all', {
      limit: perPage,
      page: page,
      sort: sort,
      date: date,
    });
    // return this.api.drivers.get({ limit: perPage, page: page, sort: sort, date: date });
  }

  //signature of methods that bring the list requested
  getAssigned(perPage, page, sort, date): Observable<any> {
    date = this.validateDate(date);
    return this.api.drivers.getDriversListByState(this.assigned.state, {
      limit: perPage,
      page: page,
      sort: sort,
      date: date,
    });
  }

  getNotAssigned(perPage, page, sort, date): Observable<any> {
    date = this.validateDate(date);
    return this.api.drivers.getDriversListByState(this.notAssigned.state, {
      limit: perPage,
      page: page,
      sort: sort,
      date: date,
    });
  }

  getOOS(perPage, page, sort, date): Observable<any> {
    date = this.validateDate(date);
    return this.api.drivers.getDriversListByState(this.OOS.state, {
      limit: perPage,
      page: page,
      sort: sort,
      date: date,
    });
  }

  getOnRoute(perPage, page, sort, date): Observable<any> {
    date = this.validateDate(date);
    return this.api.drivers.getDriversListByState(this.onRoute.state, {
      limit: perPage,
      page: page,
      sort: sort,
      date: date,
    });
  }

  getSetback(perPage, page, sort, date): Observable<any> {
    date = this.validateDate(date);
    return this.api.drivers.getDriversListByState(this.setback.state, {
      limit: perPage,
      page: page,
      sort: sort,
      date: date,
    });
  }

  getInvited(perPage, page, sort, date): Observable<any> {
    date = this.validateDate(date);
    return this.api.drivers.getDriversListByState(this.invited.state, {
      limit: perPage,
      page: page,
      sort: sort,
      date: date,
    });
  }

  getAllCount(date?: any) {
    date = this.validateDate(date);
    this.api.drivers.getDriversCountByState({ date: date }).subscribe(
      response => {
        this.assignValues(response);
      },
      error => {}
    );
  }
}

export class VehicleState extends HeaderState implements StateInterface {
  constructor(api: ApiRequestsService) {
    super();
    this.api = api;
    this.getAllCount();
  }

  getAll(perPage, page, sort, date): Observable<any> {
    date = this.validateDate(date);
    return this.api.vechiles.getVehiclesListByState('all', {
      limit: perPage,
      page: page,
      sort: sort,
      date: date,
    });
  }

  //signature of methods that bring the list requested
  getAssigned(perPage, page, sort, date): Observable<any> {
    date = this.validateDate(date);
    return this.api.vechiles.getVehiclesListByState(this.assigned.state, {
      status: this.assigned.keyStatus,
      limit: perPage,
      page: page,
      sort: sort,
      date: date,
    });
  }

  getNotAssigned(perPage, page, sort, date): Observable<any> {
    date = this.validateDate(date);
    return this.api.vechiles.getVehiclesListByState(this.notAssigned.state, {
      status: this.notAssigned.keyStatus,
      limit: perPage,
      page: page,
      sort: sort,
      date: date,
    });
  }
  getOOS(perPage, page, sort, date): Observable<any> {
    date = this.validateDate(date);
    return this.api.vechiles.getVehiclesListByState(this.OOS.state, {
      status: this.OOS.keyStatus,
      limit: perPage,
      page: page,
      sort: sort,
      date: date,
    });
  }
  getOnRoute(perPage, page, sort, date): Observable<any> {
    date = this.validateDate(date);
    return this.api.vechiles.getVehiclesListByState(this.onRoute.state, {
      status: this.onRoute.keyStatus,
      limit: perPage,
      page: page,
      sort: sort,
      date: date,
    });
  }
  getSetback(perPage, page, sort, date): Observable<any> {
    date = this.validateDate(date);
    return this.api.vechiles.getVehiclesListByState(this.setback.state, {
      status: this.setback.keyStatus,
      limit: perPage,
      page: page,
      sort: sort,
      date: date,
    });
  }

  getAllCount(date?: any) {
    date = this.validateDate(date);
    this.api.vechiles.getVehicleCountByState({ date: date }).subscribe(
      response => {
        this.assignValues(response);
      },
      error => {}
    );
  }
}

export class RouteState extends HeaderState implements StateInterface {
  constructor(api: ApiRequestsService) {
    super();
    this.api = api;
    this.getAllCount();
  }

  getAll(perPage, page, sort, date): Observable<any> {
    let payload = this.getPayload(perPage, page, sort, date);
    return this.api.routes.getRoutesListByState('all', payload);
  }

  //signature of methods that bring the list requested TODO
  getAssigned(perPage, page, sort, date): Observable<any> {
    let payload = this.getPayload(perPage, page, sort, date);
    return this.api.routes.getRoutesListByState(this.assigned.state, payload);
  }

  getNotAssigned(perPage, page, sort, date): Observable<any> {
    let payload = this.getPayload(perPage, page, sort, date);
    return this.api.routes.getRoutesListByState(
      this.notAssigned.state,
      payload
    );
  }

  getOOS(perPage, page, sort, date): Observable<any> {
    let payload = this.getPayload(perPage, page, sort, date);
    return this.api.routes.getRoutesListByState(this.OOS.state, payload);
  }

  getOnRoute(perPage, page, sort, date): Observable<any> {
    let payload = this.getPayload(perPage, page, sort, date);
    return this.api.routes.getRoutesListByState(this.onRoute.state, payload);
  }

  getSetback(perPage, page, sort, date): Observable<any> {
    let payload = this.getPayload(perPage, page, sort, date);
    return this.api.routes.getRoutesListByState(this.setback.state, payload);
  }

  getCancelled(perPage, page, sort, date): Observable<any> {
    let payload = this.getPayload(perPage, page, sort, date);
    return this.api.routes.getRoutesListByState(this.cancelled.state, payload);
  }

  getAllCount(date?) {
    if (isPlainObject(date)) date = this.validateDate(date.startDate);
    else date = this.validateDate(date);

    this.api.routes.getRoutesCountByState({ date: date }).subscribe(
      response => {
        this.assignValues(response);
      },
      error => {}
    );
  }

  getPayload(perPage, page, sort, date): any {
    let payload: any = { limit: perPage, page: page, sort: sort, draft: false };

    if (isPlainObject(date))
      payload.startDate = this.validateDate(date.startDate);
    else payload.date = this.validateDate(date);

    return payload;
  }
}
