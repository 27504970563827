import { Component, OnInit, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'table-list-and-assign',
  templateUrl: './table-list-and-assign.component.html',
  styleUrls: ['./table-list-and-assign.component.sass'],
})
export class TableListAndAssignComponent implements OnInit {
  @Input() dataSource: Array<any> = [];
  @Input() type: string = 'driver';

  @Input('driversCount') driversAvailable: Number = 0;
  @Input('vehiclesCount') vehiclesAvailable: Number = 0;
  @Input('routesCount') routesAvailable: Number = 0;
  @Input() dateQuery: any;

  @Output() onEdit: Subject<any> = new Subject<any>();
  @Output() onDelete: Subject<any> = new Subject<any>();
  @Output() onCancel: Subject<any> = new Subject<any>();
  @Output() onResend: Subject<any> = new Subject<any>();

  displayedColumns: any = {};

  scrollOutsideConfig: any = {
    scrollYMarginOffset: 20,
    scrollXMarginOffset: 25,
  };

  constructor() {}

  ngOnInit() {
    this.displayedColumns = {
      driver: [
        {
          label: 'CARD-ASSIGNMENT.LIST.NAME',
          class: 'item-name hidden-md hidden-sm hidden-xs',
        },
        {
          label: 'CARD-ASSIGNMENT.LIST.STATUS',
          class: 'item-status hidden-md hidden-sm hidden-xs m-l-n',
        },
        {
          label: 'CARD-ASSIGNMENT.LIST.DRIVER',
          class: 'item-card hidden-lg hidden-xl',
        },
        // {
        //   label: 'CARD-ASSIGNMENT.LIST.ASSIGNED-ROUTE',
        //   class: 'item-card'
        // },
        // {
        //   label: 'CARD-ASSIGNMENT.LIST.ASSIGNED-VEHICLE',
        //   class: 'item-card'
        // },
        // {
        //   label: 'CARD-ASSIGNMENT.LIST.CONTACT',
        //   class: 'item-card m-l-md hidden-sm hidden-xs'
        // },
      ],
      vehicle: [
        {
          label: 'CARD-ASSIGNMENT.LIST.PLATE',
          class: 'hidden-md hidden-sm hidden-xs m-r-n',
        },
        {
          label: 'CARD-ASSIGNMENT.LIST.VEHICLE',
          class: 'item-name',
        },
        {
          label: 'CARD-ASSIGNMENT.LIST.STATUS',
          class: 'item-status hidden-md hidden-sm hidden-xs m-l-n',
        },
        {
          label: 'CARD-ASSIGNMENT.LIST.ASSIGNED-ROUTE',
          class: 'item-card',
        },
        {
          label: 'CARD-ASSIGNMENT.LIST.ASSIGNED-DRIVER',
          class: 'item-card',
        },
      ],
      route: [
        {
          label: 'CARD-ASSIGNMENT.LIST.ROUTE',
          class: 'm-r-n item-labels item-special-route',
        },
        {
          label: 'CARD-ASSIGNMENT.LIST.LOCATIONS',
          class: 'item-status hidden-md hidden-sm hidden-xs ',
        },
        {
          label: 'CARD-ASSIGNMENT.LIST.STATUS',
          class: 'item-status hidden-md hidden-sm hidden-xs m-l-n',
        },

        {
          label: 'CARD-ASSIGNMENT.LIST.INIT',
          class: 'item-status hidden-md hidden-sm hidden-xs m-l-n',
        },
        {
          label: 'CARD-ASSIGNMENT.LIST.DRIVER',
          class: 'item-card',
        },
        {
          label: 'CARD-ASSIGNMENT.LIST.VEHICLE',
          class: 'item-card',
        },
      ],
    };
  }

  /**
   * Edition event
   *
   * @param data
   */
  edition(data: any) {
    this.onEdit.next(data._id);
  }

  /**
   * Edition event
   *
   * @param data
   */
  deleted(data: any) {
    this.onDelete.next(data._id);
  }

  /**
   * Cancel event
   *
   * @param data
   */
  cancelled(data: any) {
    this.onCancel.next(data);
  }

  /**
   * Resend invitation
   *
   * @param data
   */
  resend(data: any) {
    this.onResend.next(data._id);
  }

  /**
   * Get label for late time
   *
   * @param date
   */
  getDateText(date: any) {
    let _date = moment.utc(date).local().locale(sessionStorage.getItem('lang'));
    if (moment().diff(_date, 'days') >= 1) return _date.fromNow();

    return _date.calendar().split(' ')[0] !== 'Invalid'
      ? _date.calendar().split(' ')[0]
      : '---';
  }
}
