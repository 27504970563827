import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { AgmMap } from '@amin-karimi/agm-core';

export interface AppLocationMap {
  originalPoint: {
    latitude: number;
    longitude: number;
    name: string;
  };
  realPointPoint?: {
    latitude: number;
    longitude: number;
    name: string;
  };
  distance?: number;
}
@Component({
  selector: 'app-location-map',
  templateUrl: './location-map.component.html',
  styleUrls: ['./location-map.component.sass'],
})
export class LocationMapComponent implements OnInit, AfterViewInit {
  @ViewChild(AgmMap) agmMap: AgmMap;

  originalLatitude: number;
  originalLongitude: number;
  realLatitude: number;
  realLongitude: number;

  distance = 0;
  pointData: AppLocationMap;

  constructor(
    private dialogRef: MatDialogRef<LocationMapComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AppLocationMap
  ) {
    if (data) {
      this.pointData = data;
      this.originalLatitude = this.pointData.originalPoint.latitude;
      this.originalLongitude = this.pointData.originalPoint.longitude;
      this.realLatitude = this.pointData.realPointPoint?.latitude;
      this.realLongitude = this.pointData.realPointPoint?.longitude;
      this.distance = this.pointData.distance;
    }
  }

  ngOnInit(): void {}
  ngAfterViewInit(): void {
    if (this.realLatitude) {
      this.agmMap.mapReady.subscribe(map => {
        let bounds = map.LatLngBounds();
        let coords = map.LatLng({
          lat: this.originalLatitude,
          lng: this.originalLongitude,
        });
        bounds.extend(coords);
        coords = map.LatLng({
          lat: this.realLatitude,
          lng: this.realLongitude,
        });
        bounds.extend(coords);
        map.fitBounds(bounds);
      });
    }
  }

  /**
   * Open market tooltip
   *
   * @param infoWindow
   * @param gm
   */
  onMouseOver(infoWindow, gm) {
    if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }

    gm.lastOpen = infoWindow;

    infoWindow.open();
  }

  getGoogleMapsLink() {
    let url = `https://www.google.com/maps/dir/${this.originalLatitude},${this.originalLongitude}`;
    if (this.realLatitude) {
      url += `/${this.realLatitude},${this.realLongitude}`;
    }
    return url;
  }
  closeModal() {
    this.dialogRef.close();
  }
}
