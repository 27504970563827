import { EventEmitter, Injectable, Output, Directive } from '@angular/core';
import { Router } from '@angular/router';

@Directive()
@Injectable({
  providedIn: 'root',
})
export class DirectoryNavigationService {
  @Output() changeToNewTemplateViewEvent: EventEmitter<any> =
    new EventEmitter();
  @Output() changeToTemplateSummaryViewEvent: EventEmitter<any> =
    new EventEmitter();
  @Output() changeToDirectoryPointsViewEvent: EventEmitter<boolean> =
    new EventEmitter();
  @Output() newTemplateEvent: EventEmitter<any> = new EventEmitter();
  @Output() changeToAssignedRoutesSummaryEvent: EventEmitter<any> =
    new EventEmitter();

  cachedRoute: any = {};

  fastRegistryCached: any;

  cachedDestination: string = '';

  constructor(private router: Router) {}

  navigateToDestinations() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/directory/destinations']);
  }
  navigateToRoutes() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/directory/routes']);
  }
  navigateToAssignedRoutes() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/directory/assigned']);
  }
  navigateToCreateRoute() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/directory/routes/create']);
  }

  navigateToEditAssignedRoute() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/directory/assigned/edit']);
  }

  navigateToEditFastRoute() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/directory/fast/edit']);
  }

  fireNewTemplate(routeToEdit, isAssigned) {
    this.newTemplateEvent.emit({
      routeToEdit: routeToEdit,
      isAssigned: isAssigned,
    });
  }
  changeToNewTemplateView(
    isRouteRendered: boolean,
    routeToEdit: any,
    isAssigned: boolean
  ) {
    this.changeToNewTemplateViewEvent.emit({
      isRouteRendered: isRouteRendered,
      routeToEdit: routeToEdit,
      isAssigned: isAssigned,
    });
  }
  changeToTemplateSummaryView(data) {
    this.changeToTemplateSummaryViewEvent.emit(data);
  }
  changeToDirectoryPointsView() {
    this.changeToDirectoryPointsViewEvent.emit(true);
  }
  changeToAssignedRoutesView(data) {
    this.changeToAssignedRoutesSummaryEvent.emit(data);
  }
}
