import { Injectable, Inject } from '@angular/core';
import { NewHttpClient } from '../interceptor.service';
import { Observable } from 'rxjs';
import { INJECTION_TOKEN } from '../../app.config';
import * as firebase from 'firebase';
import { UserService } from '../../shared/services/user.service';
import { FirebaseService } from '../../shared/services/firebase.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import * as appUtils from '../../../app/utils';
import { HelpersService } from '../../shared/services';

@Injectable()
export class AuthRequests {
  groups: {};

  constructor(
    @Inject(INJECTION_TOKEN) public config: any,
    private http: NewHttpClient,
    private userService: UserService,
    private firebaseService: FirebaseService,
    private translate: TranslateService,
    private router: Router,
    private helpers: HelpersService
  ) {}

  /**
   * Login user in app
   *
   * @param data user credentials
   */
  login(data: any): Observable<any> {
    const roleToCheck = appUtils.getRoleToCheck();

    let url = this.config.oauth_url;
    //encoded query
    let query = {
      email: data.username,
      password: data.password,
      role: roleToCheck, //this.config.defaultRole
    };

    let observer = new Observable(_observer => {
      this.http
        .post(url, query, 'application/json')
        .toPromise()
        .then((response: any) => {
          if (response.error) {
            let error = {
              error: this.translate.instant('LOGIN.MESSAGES.LOGIN-ERROR'),
              response: response,
            };
            return _observer.error(error);
          }

          localStorage.setItem('currentUser', response.access_token);
          localStorage.setItem('currentUserRT', response.refresh_token);

          return this.getIdentity().toPromise();
        })
        .then((identity: any) => {
          localStorage.setItem('currentUserInfo', JSON.stringify(identity));
          _observer.next(identity);
        })
        .catch(error => _observer.error(error));
    });

    return observer;
  }

  /**
   * Sign up
   *
   * @param data
   */
  signup(data: any): Observable<any> {
    let url = this.config.register_url;
    //encoded query
    let query = {
      password: data.password,
      companyName: data.companyName,
      email: data.email,
    };

    let observer = new Observable<any>(_observer => {
      this.http
        .post(url, query, 'application/json')
        .toPromise()
        .then((response: any) => {
          sessionStorage.setItem('userEmail', data.email);
          localStorage.setItem('currentUser', response.access_token);
          this.helpers.showSuccessMessage('', 'Cuenta creada con éxito');
          this.router.navigate(['/login']);
        })
        .catch(error => _observer.error(error));
    });

    return observer;
  }

  /**
   * Activate account
   *
   * @param code
   */
  activateAccount(code: any): Observable<any> {
    let url = this.config.activate_account_url;
    let query = {
      code: code,
      email: sessionStorage.getItem('userEmail'),
    };

    return this.http.post(url, query, 'application/json');
  }

  activateByPassword(newPassword: any): Observable<any> {
    let url = this.config.activate_by_password;
    let query = {
      newPassword,
    };

    return this.http.post(url, query, 'application/json');
  }

  forgotPwd(email: string): Observable<any> {
    let query = {
      email: email,
    };

    let url = this.config.request_password_url;

    return this.http.post(url, query, 'application/json');
  }

  resetPwd(code: string, newPassword: string): Observable<any> {
    let query = {
      code: code,
      newPassword: newPassword,
    };

    let url = this.config.reset_password_url;

    return this.http.post(url, query);
  }

  // this should be used to verify current user in Auth Guard
  /**
   * verify token
   */
  getIdentity(): Observable<any> {
    let url = this.config.me_url;
    return this.http.get(url);
  }

  /**
   * Clear localstorage for loggout
   */
  logout(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.firebaseService
        .removeToken()
        .then(response => {
          this.userService.triggeredLogout();
          firebase.auth().signOut();
          resolve(null);
        })
        .catch(err => reject(err));
    });
  }
}
