<div class="custom-toastr">
  <div class="toastr-content">
    <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
      {{ title }}
    </div>
    <div
      *ngIf="message && options.enableHtml"
      role="alert"
      aria-live="polite"
      [class]="options.messageClass"
      [innerHTML]="message"></div>
    <div
      *ngIf="message && !options.enableHtml"
      role="alert"
      aria-live="polite"
      [class]="options.messageClass"
      [attr.aria-label]="message">
      {{ message }}
    </div>
  </div>
  <div class="toastr-controls text-right">
    <a *ngIf="options.closeButton" (click)="remove()" class="toastr-close">
      <i class="material-icons">clear</i>
    </a>
  </div>
</div>
