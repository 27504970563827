<div class="signup-container">
  <mat-card appearance="outlined">
    <button [routerLink]="['/login']" type="button" mat-icon-button>
      <mat-icon aria-label="icon">arrow_back</mat-icon>
    </button>
    <h1 class="title">{{ 'SIGNUP.TITLE' | translate }}</h1>
    <p class="subtitle">{{ 'SIGNUP.SUBTITLE' | translate }}</p>
    <form
      name="signupForm"
      class="signup-form"
      [formGroup]="signupForm"
      (ngSubmit)="register(signupForm)"
      no-validate>
      <!-- COMPANY NAME -->
      <mat-form-field class="w-20">
        <input
          matInput
          type="text"
          placeholder="{{ 'SIGNUP.NAME' | translate }}"
          name="companyName"
          formControlName="companyName" />
        <mat-error
          *ngIf="
            signupForm.controls.companyName.invalid &&
            signupForm.controls.companyName.touched
          ">
          {{ 'SIGNUP.MESSAGES.NAME' | translate }}
        </mat-error>
      </mat-form-field>
      <!-- EMAIL -->
      <mat-form-field class="w-20">
        <input
          matInput
          type="email"
          placeholder="{{ 'COMMON.EMAIL' | translate }}"
          name="email"
          formControlName="email" />
        <mat-error
          *ngIf="
            signupForm.controls.email.invalid &&
            signupForm.controls.email.touched
          ">
          {{ 'SIGNUP.MESSAGES.EMAIL' | translate }}
        </mat-error>
      </mat-form-field>
      <!--PASSWORD -->
      <mat-form-field class="w-20">
        <input
          matInput
          type="password"
          placeholder="{{ 'COMMON.PASSWORD' | translate }}"
          name="password"
          formControlName="password" />
        <mat-error
          *ngIf="
            signupForm.controls.password.invalid &&
            signupForm.controls.password.touched
          ">
          {{ 'SIGNUP.MESSAGES.PASSWORD' | translate }}
        </mat-error>
      </mat-form-field>
      <!-- CONFIRM PASSWORD -->
      <mat-form-field class="w-20">
        <input
          matInput
          type="password"
          placeholder="{{ 'COMMON.CONFIR_PASSWORD' | translate }}"
          name="confirmPassword"
          formControlName="confirmPassword" />
        <mat-error
          *ngIf="
            signupForm.controls.password.invalid &&
            signupForm.controls.password.touched
          ">
          {{ 'SIGNUP.MESSAGES.CONFIRM_PASSWORD' | translate }}
        </mat-error>
        <mat-error
          *ngIf="
            signupForm.controls.confirmPassword.invalid &&
            signupForm.controls.confirmPassword.touched &&
            !signupForm.controls.confirmPassword.errors?.MatchFields
          ">
          {{ 'SIGNUP.MESSAGES.MATCH_PASSWORD' | translate }}
        </mat-error>
      </mat-form-field>

      <div class="signup-btn">
        <button mat-button type="button" [routerLink]="['/login']">
          {{ 'COMMON.CANCEL' | translate }}
        </button>
        <button
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="signupForm.invalid">
          {{ 'COMMON.CREATE' | translate }}
        </button>
      </div>
    </form>
  </mat-card>
</div>
