import { Component, OnInit, ViewChild, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'welcome-modal',
  templateUrl: './welcome-modal.component.html',
  styleUrls: ['./welcome-modal.component.sass'],
})
export class WelcomeModalComponent implements OnInit {
  @ViewChild('staticModal') staticModal: ModalDirective;
  @Output() displayOnboarding: Subject<any> = new Subject<any>();

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
  };

  constructor() {}

  ngOnInit() {}

  /**
   * Show welcome modal
   */
  show() {
    this.staticModal.show();
  }

  /**
   * Hide welcome modal
   */
  hide() {
    this.staticModal.hide();
  }

  /**
   * Show tutorial
   */
  showTutorial(): void {
    this.hide();
    this.displayOnboarding.next(null);
  }
}
