<div class="message-placeholder" *ngIf="disabled && viewType === 'list'">
  <span *ngIf="parentType === 'driver' && driverDisabled">
    {{ 'CARD-ASSIGNMENT.ERRORS.NOT-ACTIVE' | translate }}
  </span>
  <span *ngIf="!driverDisabled && !completed && !isBefore">
    {{ 'CARD-ASSIGNMENT.ERRORS.ROUTE-FIRST' | translate }}
  </span>
  <span *ngIf="!driverDisabled && completed && !isBefore">
    {{ 'CARD-ASSIGNMENT.ERRORS.ROUTE-COMPLETED' | translate }}
  </span>
  <span *ngIf="!driverDisabled && isBefore">
    {{ 'CARD-ASSIGNMENT.ERRORS.INVALID-DATE' | translate }}
  </span>
</div>

<mat-accordion
  class="card-item-container"
  [ngClass]="{ 'night-mode': nightMode }"
  [hidden]="disabled">
  <mat-expansion-panel
    [ngClass]="{
      'disabled-item': disabled,
      'with-selection': selectionExists && !opened
    }"
    (opened)="onExpanded()"
    (closed)="opened = false"
    [hideToggle]="opened"
    #expansionPanel>
    <mat-expansion-panel-header
      [expandedHeight]="'0px'"
      [collapsedHeight]="selectionExists ? '60px' : '48px'"
      [ngClass]="{
        'selected-item': selectionExists && !opened,
        'with-selection': selectionExists && !opened
      }">
      <mat-panel-title
        class="card-assign-title"
        *ngIf="!opened && !selectionExists && available == 0; else notShow">
        No disponible
      </mat-panel-title>
      <ng-template #notShow>
        <mat-panel-title
          class="card-assign-title"
          *ngIf="!opened && !selectionExists">
          {{ title }} ({{ available }})
        </mat-panel-title>
      </ng-template>

      <!-- Selected item view -->
      <div
        class="card-selected-item-content"
        *ngIf="selectionExists && !opened">
        <div class="close-selection" *ngIf="canRemove">
          <button mat-icon-button (click)="remove()">
            <i class="material-icons">highlight_off</i>
          </button>
        </div>

        <card-item-driver
          *ngIf="type === 'driver'"
          [driverContent]="selection"
          [cardId]="parentCardId">
        </card-item-driver>

        <card-item-vehicle
          *ngIf="type === 'vehicle'"
          [vehicleContent]="selection">
        </card-item-vehicle>

        <card-item-route
          *ngIf="type === 'route'"
          [content]="selection"
          [isAutocomplete]="notValidateOnSelection"
          [viewType]="viewType">
        </card-item-route>
      </div>
    </mat-expansion-panel-header>

    <!-- Autocomplete -->
    <div class="flex-container" *ngIf="available != 0">
      <card-assign-autocomplete
        #autocompletePanel
        (onSelection)="onAutocompleteSelection($event)"
        [dateQuery]="queryDate"
        [viewType]="viewType"
        [parentType]="parentType"
        [type]="type">
      </card-assign-autocomplete>

      <button mat-icon-button (click)="closeExpaned()" class="expansion-close">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </mat-expansion-panel>
</mat-accordion>
