import {
  Component,
  OnInit,
  Input,
  Inject,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { GoogleMapsAPIWrapper, MapsAPILoader } from '@amin-karimi/agm-core';
import * as _ from 'lodash';
import { FirebaseService } from '../../services/firebase.service';
import { RoadsApi } from '../../classes/Roads/Roads';
import { INJECTION_TOKEN } from '../../../app.config';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { LocationService } from '../../services/location.service';
import { DriverEventsService } from '../../services';
import { debounceTime, filter } from 'rxjs/operators';
import { from } from 'rxjs';
import { forkJoin } from 'rxjs';

declare let google: any;

@Component({
  selector: 'route-polyline',
  templateUrl: './route-polyline.component.html',
  styleUrls: ['./route-polyline.component.sass'],
})
export class RoutePolylineComponent implements OnInit {
  @Input() routePolyline: any = {};
  @Input() event: any = {};
  @Input() points: Array<any> = [];
  @Input() center: boolean = true;
  @Input() status: string = 'pending';
  @Input() mapType: string = '';
  @Input() driverPathId: string = '';
  @Input() currentIndex: any = 0;
  @Input() startingPoint: any = {};
  @Input() backToStart: boolean = false;

  @ViewChild('snackContainer') snackContainer: ElementRef;

  private map: any;
  private directionsDisplay: any;
  private polylines: Array<any> = [];
  private borderPolyline: Array<any> = [];
  private mapTypes: Array<String> = ['satellite', 'hybrid'];
  private mainPolylineColorized: any = {};
  private subscriptions: any = {};
  private roadsApi: any;

  private driverPolyline: Array<any> = [];
  private pathIndex: number = 0;
  private driverPathRendered: boolean = false;
  private segmentType: string = 'locations';

  constructor(
    private gmapsApi: GoogleMapsAPIWrapper,
    private mapsAPILoader: MapsAPILoader,
    private firebase: FirebaseService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private locationService: LocationService,
    private driverEvents: DriverEventsService,
    @Inject(INJECTION_TOKEN) private config: any
  ) {}

  ngOnInit() {
    this.mapsAPILoader
      .load()
      .then(() => this.gmapsApi.getNativeMap())
      .then(map => {
        this.map = map;
        this.directionsDisplay = new google.maps.DirectionsRenderer();

        if (this.mapTypes.indexOf(this.mapType) !== -1) this.renderBorder();

        // this.renderRouteDirections(this.routePolyline, this.center);
        this.renderSimplePolyline(this.points, this.center);

        if (this.driverPathId) this.subscribeToDriverRoute();

        // listen driver events
        this.subscriptions['driverEvent'] = this.driverEvents.driverEvent
          .pipe(debounceTime(10))
          .subscribe(driverEvent => {
            this.event = driverEvent;
            // this.removeColorizedSteps();
            // this.colorizeMainRoute();
          });
      });
  }

  ngOnChanges(changes) {
    if (changes.mapType) {
      if (this.mapTypes.indexOf(this.mapType) !== -1) this.renderBorder();
      else this.cleanBorders();
    }

    if (changes.points || changes.event) {
      // this.colorizeMainRoute();
    }

    if (changes.driverPathId) this.subscribeToDriverRoute();

    if (
      changes.routePolyline &&
      changes.routePolyline.previousValue !== undefined
    ) {
      this.removeColorizedSteps();
      this.renderSimplePolyline(this.points, this.center);
      // this.renderRouteDirections(this.routePolyline, this.center);
    }
  }

  ngOnDestroy() {
    _.forEach(this.subscriptions, value => value.unsubscribe());
    this.removeRoutePolyline();
    this.cleanBorders();
    this.removeColorizedSteps();

    if (this.snackBar) this.snackBar.dismiss();
  }

  /**
   * Create subscription to driver route
   */
  private subscribeToDriverRoute() {
    if (this.subscriptions.driverPath)
      this.subscriptions.driverPath.unsubscribe();

    if (!this.driverPathId.length) return;

    if (!this.roadsApi) this.roadsApi = new RoadsApi(this.config);

    this.subscriptions.driverPath = this.firebase
      .subscribeToNode(`driverPaths/${this.driverPathId}`, null, null)
      .pipe(
        filter(path => !_.isNull(path) || !_.isEmpty(path)),
        filter(path => {
          let _content = Object.keys(path);
          let content = path[_.last(_content)];
          this.segmentType = _.isPlainObject(content) ? 'location' : 'encoded';
          return path;
        }),
        debounceTime(150)
      )
      .subscribe(path => {
        let mainKeys = Object.keys(path);

        if (!mainKeys.length) return;
        let _paths = [];

        if (this.segmentType === 'location')
          _paths = this.generateDriverPathSegment(path);

        if (this.segmentType === 'encoded')
          _paths = this.generateDriverPathSegmentFromEncoded(path);

        if (!this.driverPathRendered) {
          let message = this.translate.instant('DRIVER-PATH.LOADING');
          this.openSnackBar(message);
        }

        if (this.subscriptions.driverPathSegments)
          this.subscriptions.driverPathSegments.unsubscribe();

        let _observers = _paths.map(e => from(e));

        this.subscriptions.driverPathSegments = forkJoin(_observers).subscribe(
          response => {
            if (!this.driverPathRendered) {
              this.driverPathRendered = true;
              this.closeSnackBar();
            }

            let coords = _.flatten(response);
            this.renderDriverPath(coords);
            this.locationService.setLastPointLocation(_.last(coords));
          },
          error => {
            let message = this.translate.instant('DRIVER-PATH.ERROR');
            this.openSnackBar(message);
            this.closeSnackBar();
          }
        );
      });
  }

  /**
   * Render a straight polyline between markers
   *
   * @param deliveryPoints
   * @param center
   */
  private renderSimplePolyline(deliveryPoints: Array<any>, center?: boolean) {
    let bounds = new google.maps.LatLngBounds();

    let coords = _.chain(deliveryPoints)
      .map((point, i) => {
        let style = this.getDeliveryPointStatus(
          point,
          this.status,
          this.event,
          i,
          this.currentIndex
        );
        return this.getCoords(point.deliveryPoint);
      })
      .filter(null)
      .value();

    if (this.backToStart) {
      const home = _.find(deliveryPoints, { type: 'startingPoint' as any });

      if (home && home.location) coords.push(this.getCoords(home.location));
    }

    if (this.startingPoint && !_.isEmpty(this.startingPoint))
      coords = [this.getCoords(this.startingPoint), ...coords];

    let polylineShadow = new google.maps.Polyline({
      strokeColor: 'black',
      path: coords,
      strokeOpacity: 0.2,
      strokeWeight: 6,
      // zIndex: 1,
      map: this.map,
    });

    let polyline = new google.maps.Polyline({
      strokeColor: '#4a4a4a',
      path: coords,
      strokeWeight: 4,
      strokeOpacity: 1,
      zIndex: 2,
      map: this.map,
    });

    this.polylines.push(polylineShadow);
    this.polylines.push(polyline);

    coords.forEach(coord => bounds.extend(coord));
    // Extend bounds a little bit to get less zoom
    let extendPoint = new google.maps.LatLng(
      bounds.getNorthEast().lat() + 0.01,
      bounds.getNorthEast().lng() + 0.01
    );
    bounds.extend(extendPoint);

    if (center) this.map.fitBounds(bounds);
  }

  /**
   * Get response from google maps and trace a new polygon line
   * based on each route point
   *
   * @param response
   * @param center
   */
  private renderRouteDirections(response: any, center?: boolean) {
    let bounds = new google.maps.LatLngBounds();
    let legs = response;

    // route end and and points on map
    legs.forEach((leg, i) => {
      let item = leg;
      let steps = leg.steps || [];

      let _point = this.points[i] ? this.points[i] : {};
      let style = this.getDeliveryPointStatus(
        _point,
        this.status,
        this.event,
        i,
        this.currentIndex
      );

      steps.forEach((step, j) => {
        let segment = new google.maps.geometry.encoding.decodePath(
          step.polyline.points
        );
        let polyline = new google.maps.Polyline({
          strokeColor: '#4a4a4a',
          path: segment,
          strokeWeight: 8,
          strokeOpacity: 1,
          zIndex: 2,
          map: this.map,
        });

        bounds.extend(_.last(segment));
        this.polylines.push(polyline);
      });
    });

    // this.colorizeMainRoute();

    if (center) this.map.fitBounds(bounds);
  }

  /**
   * Set color of each polyline segment
   */
  private colorizeMainRoute() {
    _.forEach(this.routePolyline, (leg, i: any) => {
      let steps = leg.steps || [];
      let _point = this.points[i] ? this.points[i] : {};
      let style = this.getDeliveryPointStatus(
        _point,
        this.status,
        this.event,
        i,
        this.currentIndex
      );

      if (this.mainPolylineColorized[_point._id]) {
        _.forEach(this.mainPolylineColorized[_point._id].polyline, polygon =>
          polygon.setMap(null)
        );
      }

      this.mainPolylineColorized[_point._id] = {
        polyline: [],
      };

      steps.forEach((step, j) => {
        let segment = new google.maps.geometry.encoding.decodePath(
          step.polyline.points
        );
        let polyline = new google.maps.Polyline({
          strokeColor: style.backgroundColor || '#4a4a4a',
          path: segment,
          strokeWeight: 8,
          strokeOpacity: 1,
          zIndex: 3,
          map: this.map,
        });

        this.mainPolylineColorized[_point._id].polyline.push(polyline);
      });
    });
  }

  /**
   * Render route border
   */
  private renderBorder() {
    if (this.borderPolyline.length) return;

    let coords = _.chain(this.points)
      .map((point, i) => this.getCoords(point.deliveryPoint))
      .filter(null)
      .value();

    if (this.startingPoint && !_.isEmpty(this.startingPoint))
      coords = [this.getCoords(this.startingPoint), ...coords];

    if (this.backToStart) {
      const home = _.find(this.points, { type: 'startingPoint' as any });

      if (home && home.location) coords.push(this.getCoords(home.location));
    }

    let polyline = new google.maps.Polyline({
      strokeColor: '#FFFFFF',
      path: coords,
      strokeWeight: 7,
      strokeOpacity: 1,
      zIndex: 1,
      map: this.map,
      geodesic: true,
    });

    this.borderPolyline.push(polyline);
  }

  /**
   * Remove all polyline borders from view
   */
  private cleanBorders() {
    this.borderPolyline.forEach(polyline => {
      polyline.setMap(null);
    });

    this.borderPolyline.length = 0;
  }

  /**
   * Remove main route polyline
   */
  private removeRoutePolyline() {
    this.polylines.forEach(polyline => polyline.setMap(null));
    this.driverPolyline.forEach(polyline => polyline.setMap(null));

    this.driverPolyline.length = 0;
    this.polylines.length = 0;
  }

  /**
   * Removed colorized steps
   */
  private removeColorizedSteps() {
    _.forEach(this.mainPolylineColorized, e => {
      if (e && e.polyline) {
        _.forEach(e.polyline, polygon => polygon.setMap(null));
      }
    });
  }

  /**
   * Get delivery point segment color
   *
   * @param point point information
   * @param status current route status
   * @param events current route events
   * @param currentIndex point position
   * @param currentActiveIndex current active position
   * @returns icon and className
   */
  private getDeliveryPointStatus(
    point: any,
    status: string,
    events: any,
    currentIndex: Number,
    currentActiveIndex: Number
  ) {
    let backgroundColor = '#4a4a4a';

    if (!point || _.isEmpty(point)) return { backgroundColor: backgroundColor };

    let _completed = status === 'completed';
    let _inProgress = status === 'in-progress';

    let ommited =
      point.omittedAt ||
      (_completed && !point.delivered) ||
      (point.deliveredAt && !point.isDelivered);

    // let delivered = (_completed || _inProgress) && (point.deliveredAt && point.isDelivered);
    let delivered =
      (_completed || _inProgress) &&
      ((point.documentedAt && point.isDelivered) ||
        (point.deliveryAt && point.isDelivered));

    let setback = !_.isEmpty(events) && (events.name || events.message);

    let samePoint = currentActiveIndex === currentIndex;
    let canDraw = samePoint && _inProgress;

    backgroundColor =
      delivered && canDraw
        ? '#03a9f4'
        : ommited && canDraw
        ? '#03a9f4'
        : setback && canDraw
        ? '#e8bebe'
        : _inProgress && canDraw && (!delivered || !ommited || !setback)
        ? '#b6f3c7'
        : '#4a5f69';

    // if (!_.isEmpty(events) && events.name !== 'setback' && (!delivered || !ommited) && canDraw)
    //   backgroundColor = events.backgroundColor;

    return { backgroundColor: backgroundColor };
  }

  /**
   * Genereate google maps LatLng from raw location
   *
   * @param location
   * @param waypoint
   */
  private getCoords(waypoint: any) {
    if (!waypoint || _.isEmpty(waypoint)) return null;

    if (!waypoint.latitude || !waypoint.longitude) return null;

    return new google.maps.LatLng(waypoint.latitude, waypoint.longitude);
  }

  /**
   * Get coords from LatLng location
   *
   * @param waypoint
   */
  private reversedCoords(waypoint: any) {
    if (!waypoint || _.isEmpty(waypoint)) return null;

    let _coords = null;

    if (_.has(waypoint, 'lat') && _.has(waypoint, 'lng'))
      _coords = {
        latitude: waypoint.lat(),
        longitude: waypoint.lng(),
      };

    return _coords;
  }

  /**
   * Draw driver polyline from driver path
   *
   * @param waypoints
   */
  private renderDriverPath(waypoints: Array<any>) {
    if (!waypoints.length) return;

    let polyline = new google.maps.Polyline({
      strokeColor: '#03a9f4',
      path: waypoints,
      strokeWeight: 8,
      strokeOpacity: 1,
      zIndex: 5,
      map: this.map,
    });

    // this.googleRoadsApi(waypoints)
    this.driverPolyline.push(polyline);
  }

  /**
   * Divide polyline steps from firebase into segments
   * so in this way we could fetch the Google Roads API
   * and get the correct Polyline locations
   *
   * @param path
   * @param segments
   * @returns
   */
  private generateDriverPathSegment(
    path: Array<any>,
    segments: Array<any> = []
  ) {
    let mainKeys = Object.keys(path);
    let sliceStart =
      this.pathIndex === 0 ? 0 : this.pathIndex >= 1 ? this.pathIndex - 1 : 0;
    let sliceEnd = sliceStart + 50;
    let lastKey = '';
    let _paths = _.chain(mainKeys)
      .slice(sliceStart, sliceEnd)
      .tap(keys => {
        lastKey = _.last(keys);
        this.pathIndex = _.findIndex(mainKeys, e => e === lastKey) || 0;
      })
      .map(key => path[key])
      .filter(null)
      .value();

    let _promises = this.getRoadsPath(_paths, lastKey);

    segments = _.concat(segments, _promises);

    if (mainKeys.length - 1 === this.pathIndex) return segments;
    else return this.generateDriverPathSegment(path, segments);
  }

  /**
   * Divide polyline steps from firebase into segments from an encoded polyline
   * so in this way we could fetch the Google Roads API
   * and get the correct Polyline locations
   *
   * @param path
   * @param segments
   * @returns
   */
  private generateDriverPathSegmentFromEncoded(
    path: Array<any>,
    segments: Array<any> = []
  ) {
    let mainKeys = Object.keys(path);
    let _segment = mainKeys.map(
      key => new google.maps.geometry.encoding.decodePath(path[key])
    );
    _segment = !_.last(_segment).length
      ? _.clone(_segment[_segment.length - 1 - 1])
      : _.last(_segment);

    let sliceStart =
      this.pathIndex === 0 ? 0 : this.pathIndex >= 1 ? this.pathIndex - 1 : 0;
    let sliceEnd = sliceStart + 50;
    let lastKey: any = '';

    let _paths = _.chain(_segment)
      .slice(sliceStart, sliceEnd)
      .tap(keys => {
        let lastValue = _.last(keys) || {};
        if (lastValue && !_.isEmpty(lastValue)) {
          this.pathIndex =
            _.findIndex(_segment, e => {
              return e.lat() === lastValue.lat() && e.lng() === lastValue.lng();
            }) || 0;
        }

        lastKey = _.clone(this.pathIndex);
      })
      .map(key => this.reversedCoords(key))
      .filter(null)
      .value();

    let _promises = this.getRoadsPath(_paths, lastKey);

    segments = _.concat(segments, _promises);

    if (_segment.length - 1 === this.pathIndex) return segments;
    else return this.generateDriverPathSegmentFromEncoded(path, segments);
  }

  /**
   * Fetch Google Roads API to get
   *
   * @param waypoints
   * @param index
   */
  private getRoadsPath(waypoints, index) {
    return new Promise((resolve, reject) => {
      this.roadsApi
        .getRoad(waypoints)
        .then(res => {
          if (
            _.isEmpty(res) ||
            _.isEmpty(res['snappedPoints']) ||
            !res['snappedPoints']
          )
            return resolve([]);

          // clean response to only get formated coordinates
          let _locations = _.chain(res['snappedPoints'])
            .map(point => this.getCoords(point.location || {}))
            .filter(null)
            .value();

          resolve(_locations);
        })
        .catch(error => reject(error));
    });
  }

  /**
   * Open snackbar
   *
   * @param message
   */
  private openSnackBar(message: string) {
    this.snackBar.open(message, '', {
      horizontalPosition: 'center',
    });
  }

  /**
   * Close snackbar
   */
  private closeSnackBar() {
    setTimeout(() => {
      this.snackBar.dismiss();
    }, 1000);
  }
}
