import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { filter, map, timeInterval, combineLatest } from 'rxjs/operators';
import {
  chain,
  merge,
  isEmpty,
  has,
  inRange,
  isPlainObject,
  omit,
} from 'lodash';
import * as moment from 'moment';
import { FirebaseService } from './firebase.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class DriversService {
  private subscriptions: any = {};

  constructor(
    private firebase: FirebaseService,
    private translate: TranslateService
  ) {}

  /**
   * Check if driver it's online
   *
   * @param driver
   */
  checkIfOnline(driver: any): Observable<any> {
    // if subscription already exists use same subscription as value
    if (this.subscriptions[driver]) return this.subscriptions[driver];

    let data = JSON.parse(localStorage.getItem('currentUserInfo'));
    let company = '';
    if (data) {
      data = data.data;
      company = isPlainObject(data.companyId)
        ? data.companyId._id
        : data.companyId;
    }

    let subscribers = [
      this.firebase.subscribeToNode(
        `drivers_session/${company}/${driver}`,
        null,
        null
      ),
      this.firebase.subscribeToNode(
        `drivers_location/${company}/${driver}`,
        null,
        null
      ),
    ];

    let interval = 1000 * 60 * 5; // 5 minutes
    // subscriptions instance
    this.subscriptions[driver] = this.watcher(subscribers, interval);

    return this.subscriptions[driver];
  }

  /**
   * Handle subscription to all collections and check if it's online every N milliseconds
   *
   * @param subscribers
   * @param interval
   */
  private watcher(subscribers: Array<Observable<any>>, interval) {
    return new Observable<any>(observer => {
      timer(0, interval)
        .pipe(
          timeInterval(),
          combineLatest(subscribers),
          map(item => {
            let filtered = chain(item).filter(null).value();

            let _item = {};

            filtered.forEach(e => {
              merge(_item, e);
            });

            return omit(_item, ['value', 'interval']);
          }),
          filter(item => !isEmpty(item))
        )
        .subscribe((item: any) => {
          let _date = moment.utc(item.dateTime).local();
          let _elapsed: any = moment.duration(moment().diff(_date));
          // verify if date it's not more than one day, no more than 1 hour and less than 10 minutes
          let _validTime =
            inRange(_elapsed.days(), 0, 1) &&
            inRange(_elapsed.hours(), 0, 1) &&
            inRange(_elapsed.minutes(), 0, 10);
          let hasSessionInfo = item.sessionInfo && item.sessionInfo.lastStatus;
          let status = item.lastStatus;
          if (hasSessionInfo) {
            status = moment(item.lastStatusAt).isAfter(
              item.sessionInfo.lastStatusAt
            )
              ? item.lastStatus
              : item.sessionInfo.lastStatus;
          }
          let _online =
            status === 'online' && _validTime && has(item, 'dateTime');
          observer.next(_online);
        });
    });
  }

  getStatusFixtures(driver) {
    let fixture;
    if (driver)
      fixture = driver.lastEvent
        ? {
            label: driver.lastEvent.name || 'offline',
            className: driver.lastEvent.name || 'offline',
          }
        : {
            label: driver.status || 'offline',
            className: driver.status || 'offline',
          };
    else fixture = { label: 'offline', className: 'offline' };
    switch (fixture.label) {
      case 'offline':
        fixture.label = this.translate.instant('CARD-ASSIGNMENT.OFFLINE');
        break;
      case 'online':
        fixture.label = this.translate.instant('CARD-ASSIGNMENT.ONLINE');
        break;
      case 'setback':
        fixture.label = this.translate.instant('CARD-ASSIGNMENT.SETBACK');
        break;
      case 'road-issue':
        fixture.label = this.translate.instant('CARD-ASSIGNMENT.ROAD-ISSUE');
        break;
    }
    return fixture;
  }
  getStatusFixturesByBoolean(isOnline) {
    if (isOnline) {
      return {
        label: this.translate.instant('CARD-ASSIGNMENT.ONLINE'),
        className: 'online',
      };
    } else {
      return {
        label: this.translate.instant('CARD-ASSIGNMENT.OFFLINE'),
        className: 'offline',
      };
    }
  }
}
