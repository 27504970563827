<div
  id="capture"
  style="
    background: #03a9f4;
    width: 500px;
    text-align: center;
    position: absolute;
    top: -1500px;
    left: -1500px;
  ">
  <img
    [src]="actualImage"
    #imageDownload
    style="max-width: 100%; width: 500px; text-align: center" />
  <!-- <h4 style="color: #000; ">Hello world!</h4> -->
  <p style="color: white; text-align: center">
    <span class="ml-2 mr-2"> Entregado en: {{ photoEntregadoEn }} </span>
    <span class="ml-2 mr-2"> Entregado por: {{ photoEntregadoPor }} </span>
    <span class="ml-2 mr-2">
      {{ photoFechaEntrega }}
    </span>
    <span class="ml-2 mr-2">
      {{ photoStatus }}
    </span>
  </p>
</div>

<a [href]="capturedImage" download #anchorDownload style="display: none"
  >download item</a
>

<div
  class="container-btn-donwload d-flex justify-content-center align-items-center"
  *ngIf="isImageOpen">
  <button
    *ngIf="!showSessionLoad"
    mat-icon-button
    (click)="descargarImagen()"
    class="d-flex justify-content-center align-items-center">
    <i class="material-icons" style="font-size: 15px">download</i>
    <span style="font-size: 14px">
      {{ 'COMMON.DOWNLOAD-IMAGE' | translate }}
    </span>
  </button>
  <mini-loading
    style="background-color: transparent"
    *ngIf="showSessionLoad"
    class="mt-5"></mini-loading>
</div>

<mat-accordion>
  <mat-expansion-panel
    *ngFor="let item of points; index as i"
    (opened)="item.panelOpenState = true; sendAnalytics()"
    [ngClass]="{ 'odd-row': i % 2 === 0 }"
    (closed)="item.panelOpenState = false">
    <mat-expansion-panel-header>
      <div class="grid-layout">
        <div class="">
          <div class=" ">
            <span>{{ i + 1 }}</span>
          </div>
        </div>

        <div class="">
          <div class="">
            <mat-checkbox
              [(ngModel)]="item.checked"
              (change)="setCheckedValue(item)"
              (click)="$event.stopPropagation()"></mat-checkbox>
          </div>
        </div>

        <div class="">
          <div class="image-card">
            <!--          <img [src]="item?.userId?.image ? item?.userId?.image : driverPicturePlaceholder">-->
            <div>
              <p class="name wrap-title">
                {{ item.driver?.getFullName() }}
              </p>

              <p
                class="id"
                *ngIf="item?.userId && item?.userId['document-number']">
                <span>CI: </span>
                <span>{{ item?.driver['document-number'] }} </span>
              </p>
            </div>
          </div>
        </div>

        <div class="">
          <div
            class="d-flex align-items-center"
            (click)="openMapModal(item)"
            [ngClass]="{
              'warning-color': item.isOutOfFence(),
              'item-link-title': !item.isOutOfFence()
            }">
            <u
              class="text-wrap"
              data-toggle="tooltip"
              data-placement="top"
              [title]="item?.deliveryPoint?.deliveryName">
              {{ item?.deliveryPoint?.deliveryName }}
            </u>

            <span>
              <i
                *ngIf="!item.isOutOfFence()"
                class="material-icons"
                style="transform: rotate(135deg)"
                >link</i
              >
              <i
                *ngIf="item.isOutOfFence()"
                class="material-icons warning-color"
                >warning</i
              >
            </span>
          </div>
        </div>

        <div class="">
          <div>
            <a
              *ngIf="item?.latitude && item?.longitude; else withoutLocation"
              class="d-flex item-link-title"
              [href]="item.getMapUrl()"
              target="_blank">
              <div class="">
                {{ item.getCoordinatesStr() }}
              </div>
              <i class="material-icons" style="transform: rotate(135deg)"
                >link</i
              >
            </a>

            <ng-template #withoutLocation>
              {{ 'REPORTS.WITHOUT-LOCATION' | translate }}
            </ng-template>
          </div>
        </div>

        <div class="">
          <div>
            <p class="item-title" style="text-transform: capitalize">
              {{ item?.deliveryPoint?.warehouse || 'N/A' }}
            </p>
          </div>
        </div>

        <div class="">
          <div *ngIf="item?.timeLabel">
            <p class="item-title wrap-title">
              {{ item?.timeLabel + ', ' }} {{ item?.time }}
            </p>
          </div>
          <div *ngIf="!item?.timeLabel">
            <p class="item-title">
              {{ this.giveTimeFormat(item?.documentedAt) }}
            </p>
          </div>
        </div>

        <div class="">
          <i
            *ngIf="item.isDocumented"
            class="material-icons"
            [style]="item.getIconStyle()"
            >{{ item.getIcon(false) }}</i
          >
          <div [style]="item.getIconStyle()">
            {{ helpers.mapStatutes({ status: item.status }) | translate }}
          </div>
        </div>

        <div class="">
          <div class="tags-wrapper">
            <drag-scroll
              [scrollbar-hidden]="true"
              [drag-scroll-y-disabled]="true"
              [scroll-x-wheel-enabled]="true"
              class="drag-container">
              <div class="d-flex align-items-center chip-list-style">
                <mat-chip-listbox>
                  <div class="tag-row" *ngFor="let tag of item.tags">
                    <mat-chip [ngStyle]="{ 'background-color': tag.color }">
                      {{ tag.label }}
                    </mat-chip>
                  </div>
                </mat-chip-listbox>
              </div>
            </drag-scroll>
            <i
              class="material-icons item-icon"
              (click)="openTagsSelectorModal(item); sendEvent()">
              sell
            </i>
          </div>
        </div>

        <div class="pl-2">
          <div class="end-container">
            <div class="btn-details">
              <span class="uppercase-text">
                {{ 'REPORTS.MORE-DETAILS' | translate }}
              </span>
              <i *ngIf="!item.panelOpenState" class="material-icons"
                >expand_more</i
              >
              <i *ngIf="item.panelOpenState" class="material-icons"
                >expand_less</i
              >
            </div>
          </div>
        </div>

        <div>
          <mat-icon (click)="downloadReport(item)">get_app</mat-icon>
        </div>
      </div>
    </mat-expansion-panel-header>

    <div class="d-flex justify-content-between">
      <div class="w-75 pl-4 pr-4">
        <div class="table-body-collapse-content">
          <div class="collapse-item-5">
            <span class="table-collapse-title-black">
              {{ 'Información del destino' | translate }}
            </span>
          </div>

          <div class="collapse-item-5">
            <span class="table-collapse-title-slate">
              {{ 'Nombre del contacto' | translate }}:
            </span>
            <div class="d-flex align-items-center">
              <mat-menu
                class="guide"
                #matCustomerNameMenu
                style="background: #385b70">
                <app-popover-clipboard
                  [guides]="
                    item?.deliveryPoint?.customerName
                  "></app-popover-clipboard>
              </mat-menu>

              <p style="text-transform: capitalize">
                {{
                  (item?.deliveryPoint?.customerName || []
                    | joinArryas : ',') || 'N/A'
                }}
              </p>

              <mat-icon
                *ngIf="itemt?.deliveryPoint?.customerName?.length"
                class="view-popover"
                mat-icon-button
                [matMenuTriggerFor]="matCustomerNameMenu"
                >add</mat-icon
              >
            </div>
          </div>

          <div class="collapse-item-5">
            <span class="table-collapse-title-slate">
              {{ 'Coordenadas de gestión' | translate }}:
            </span>
            <div>
              <a
                class="d-flex align-items-center"
                [href]="getMapUrl(item?.latitude, item?.longitude)"
                target="_blank">
                <span class="item-link-title wrap-title">
                  <u>
                    {{ getDouble(item?.latitude).toFixed(5) }},{{
                      getDouble(item?.longitude).toFixed(5)
                    }}
                  </u>
                </span>
                <span
                  ><i class="material-icons" style="transform: rotate(135deg)"
                    >link</i
                  ></span
                >
              </a>
            </div>
          </div>

          <div class="collapse-item-5">
            <span class="table-collapse-title-slate">
              {{ 'Email' | translate }}:
            </span>
            <div class="d-flex align-items-center">
              <mat-menu class="guide" #matEmailMenu style="background: #385b70">
                <app-popover-clipboard
                  [guides]="
                    item?.deliveryPoint?.customerMail
                  "></app-popover-clipboard>
              </mat-menu>

              <p style="text-transform: capitalize">
                {{
                  (item?.deliveryPoint?.customerMail || []
                    | joinArryas : ',') || 'N/A'
                }}
              </p>

              <mat-icon
                *ngIf="item?.deliveryPoint?.phone?.length"
                class="view-popover"
                mat-icon-button
                [matMenuTriggerFor]="matEmailMenu"
                >add</mat-icon
              >
            </div>
          </div>

          <div class="collapse-item-5">
            <span class="table-collapse-title-slate">
              {{ 'Teléfono' | translate }}
            </span>
            <div class="d-flex align-items-center">
              <mat-menu class="guide" #matPhoneMenu style="background: #385b70">
                <app-popover-clipboard
                  [guides]="
                    itemt?.deliveryPoint?.phone
                  "></app-popover-clipboard>
              </mat-menu>

              <p style="text-transform: capitalize">
                {{
                  (item?.deliveryPoint?.phone || [] | joinArryas : ',') || 'N/A'
                }}
              </p>

              <mat-icon
                *ngIf="item?.deliveryPoint?.phone?.length"
                class="view-popover"
                mat-icon-button
                [matMenuTriggerFor]="matPhoneMenu"
                >add</mat-icon
              >
            </div>
          </div>
        </div>

        <div class="table-body-collapse-content">
          <div class="collapse-item-5">
            <span class="table-collapse-title-black">
              {{ 'Detalle de gestión' | translate }}
            </span>
          </div>

          <div
            *ngIf="helpers.renderCustomField(item, 0)"
            class="collapse-item-5">
            <span class="table-collapse-title-slate">
              {{ helpers.getCustomFields(item, 0).label || 'N/A' | translate }}:
            </span>
            <div>
              <p style="text-transform: capitalize">
                {{
                  helpers.getCustomFieldValue(
                    helpers.getCustomFields(item, 0)
                  ) || 'N/A' | translate
                }}
              </p>
            </div>
          </div>

          <div
            *ngIf="helpers.renderCustomField(item, 1)"
            class="collapse-item-5">
            <span class="table-collapse-title-slate">
              {{ helpers.getCustomFields(item, 1).label || ' ' | translate }}:
            </span>
            <div>
              <p style="text-transform: capitalize">
                {{
                  helpers.getCustomFieldValue(
                    helpers.getCustomFields(item, 1)
                  ) || 'N/A' | translate
                }}
              </p>
            </div>
          </div>

          <div
            *ngIf="helpers.renderCustomField(item, 2)"
            class="collapse-item-5">
            <span class="table-collapse-title-slate">
              {{ helpers.getCustomFields(item, 2).label || ' ' | translate }}:
            </span>
            <div>
              <p style="text-transform: capitalize">
                {{
                  helpers.getCustomFieldValue(
                    helpers.getCustomFields(item, 2)
                  ) || 'N/A' | translate
                }}
              </p>
            </div>
          </div>

          <div class="collapse-item-5">
            <span class="table-collapse-title-slate">
              {{ 'Fotos' | translate }}:
            </span>
            <div>
              <p style="text-transform: capitalize">
                {{ item.files.length || 'N/A' | translate }}
              </p>
            </div>
          </div>
        </div>

        <div class="table-body-collapse-content">
          <div class="collapse-item-3">
            <mat-menu class="guide" #menu="matMenu" style="background: #385b70">
              <app-popover-clipboard
                [guides]="getReferralGuidesValue(item)"></app-popover-clipboard>
            </mat-menu>
            <span class="table-collapse-title-slate">
              {{ 'Guías de remisión:' | translate }}
            </span>

            <div class="wrapper-guide">
              <drag-scroll
                *ngIf="item.referralGuides.length > 0"
                [scrollbar-hidden]="true"
                [drag-scroll-y-disabled]="true"
                style="height: auto; display: flex; align-items: center">
                <div class="d-flex align-items-center chip-list-style">
                  <mat-chip-listbox style="max-width: 320px">
                    <mat-chip-option
                      *ngFor="let guide of item.referralGuides"
                      >{{ guide.referralGuide }}</mat-chip-option
                    >
                  </mat-chip-listbox>
                </div>
              </drag-scroll>
              <mat-icon
                class="view-popover"
                mat-icon-button
                [matMenuTriggerFor]="menu"
                >add</mat-icon
              >
            </div>
          </div>

          <div class="collapse-item-5">
            <span class="table-collapse-title-slate">
              {{ 'Detalle de gestión' | translate }}:
            </span>
            <perfect-scrollbar
              class="comment"
              style="
                white-space: pre-line;
                word-wrap: break-word;
                max-height: 110px;
              ">
              <p>
                {{
                  getComments(item) || 'No hay Detalle de gestión' | translate
                }}
              </p>
            </perfect-scrollbar>
          </div>
        </div>

        <div class="table-body-collapse-content">
          <div class="collapse-item-5">
            <span class="table-collapse-title-black">
              {{ 'Información del receptor' | translate }}
            </span>
          </div>

          <div class="collapse-item-5">
            <span class="table-collapse-title-slate">
              {{ 'Nombre del receptor' | translate }}:
            </span>
            <div>
              <p style="text-transform: capitalize">
                {{ item.receiverName || 'N/A' | translate }}
              </p>
            </div>
          </div>

          <div class="collapse-item-5">
            <span class="table-collapse-title-slate">
              {{ 'Cédula' | translate }}:
            </span>
            <div>
              <p>{{ item.receiverId || 'N/A' | translate }}</p>
            </div>
          </div>

          <div class="collapse-item-5">
            <span class="table-collapse-title-slate">
              {{ 'Firma' | translate }}:
            </span>
            <div>
              <p>
                {{
                  helpers.hasSignatureFastRegistry(item) || 'N/A' | translate
                }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-column w-25 pl-4 pr-4">
        <div class="collapse-item">
          <div>
            <ngx-gallery
              class="gallery"
              [options]="galleryOptions"
              [images]="getFiles(item.files)"
              (previewOpen)="previewOpen($event, item)"
              (previewClose)="previewClose()"
              (previewChange)="previewChange($event, item)">
            </ngx-gallery>
          </div>
        </div>
        <div class="collapse-item">
          <div class="sign-container pt-4">
            <div class="sign-content">
              <img
                [ngStyle]="{ height: '110px' }"
                *ngIf="item.signature"
                [src]="item.signature" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<swal
  #emailSwal
  [title]="'Enviar PDF'"
  [confirmButtonText]="'Enviar'"
  [cancelButtonText]="'Cancelar'"
  [showCancelButton]="true"
  [focusCancel]="true"
  [showConfirmButton]="true"
  [width]="600"
  [confirmButtonColor]="'#385b70'"
  [cancelButtonColor]="'#385b70'">
  <div style="text-align: left; color: red" *swalPortal>
    <mat-form-field class="w-100">
      <mat-chip-grid #chipList>
        <mat-chip-row
          *ngFor="let email of emails"
          [selectable]="selectable"
          [ngStyle]="{ backgroundColor: email.bgColor }"
          [removable]="removable"
          (removed)="remove(email)">
          {{ email.email }}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip-row>
        <input
          placeholder="Nuevo correo..."
          type="email"
          style="color: black"
          [matChipInputFor]="chipList"
          (paste)="addEmailsOnPaste($event, splitRegex)"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add($event)" />
      </mat-chip-grid>
    </mat-form-field>
  </div>
</swal>
