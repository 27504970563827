import { baseConfig } from './base-config';

const base_url: string = 'https://api-qa.fleetflex.io';
const _environment = {
  production: false,
  qa: true,
  defaultRole: 'company',

  // client
  client_id: 'fleetflex',
  client_secret: 'ed1fbd1eb41a11e7abc4cec278b6b50a',
  grant_type: 'password',
  googleMapsApiKey: 'AIzaSyBIcGUApRZZTBdIm3AXJiXHCcrcKiA3R8s',

  // firebase
  firebase: {
    apiKey: 'AIzaSyCU3HWQdeCmd1NsU8BuBeXARoKsWPVU0N8',
    authDomain: 'fleetflex-qa.firebaseapp.com',
    databaseURL: 'https://fleetflex-qa.firebaseio.com',
    projectId: 'fleetflex-qa',
    storageBucket: 'fleetflex-qa.appspot.com',
    messagingSenderId: '211112040165',
    appId: '1:211112040165:android:1b0cfa3c17cb4bf6',
  },
  // file uploads
  upload_provider: 'GoogleCloudSigned',
  upload_drivers_bucket: 'fleetflex-drivers',
  upload_vehicles_bucket: 'fleetflex-vehicles',
  upload_company_bucket: 'fleetflex-drivers',
  upload_bucket_url: 'https://fleetflex-drivers.storage.googleapis.com',

  // mixpanel
  mixpanel_key: '7a8fa71b75115d9f3fd9eaa4764de9f8',

  // sentry
  sentry_key: 'https://e21e1b5ed13a4f1cbd3b61ab068b2762@sentry.io/1186087',

  // google tag manager
  ga: {
    url: 'https://www.googletagmanager.com/gtag/js?id=UA-118019885-7',
    ua: 'UA-118019885-7',
  },
};

export const environment = Object.assign(_environment, baseConfig(base_url));
