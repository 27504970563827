import { Component, OnInit } from '@angular/core';
import { ApiRequestsService } from '../../../../api';
import { MatDialog } from '@angular/material/dialog';
import {
  AnalyticsService,
  HelpersService,
  UserService,
} from '../../../services';
import { ConfirmationModalComponent } from '../../confirmation-modal/confirmation-modal.component';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { ColorArrayPickerComponent } from '../../color-array-picker/color-array-picker.component';

@Component({
  selector: 'app-tag-creator',
  templateUrl: './tag-creator.component.html',
  styleUrls: ['./tag-creator.component.sass'],
})
export class TagCreatorComponent implements OnInit {
  selectedColor = '';
  selectedName = '';
  displayCreator = false;
  displayNameError = false;
  displayEditor = false;
  displayInfo = false;
  displayDeleted = false;
  displaySpinner = false;
  displayInfoStart = false;
  tagsList = [];
  deletedList = [];
  selectedTagForEdit: any;
  selectedTagForEdit_;
  disabledColors = [];
  searchText = '';
  editInitialColor = '';

  constructor(
    private analytics: AnalyticsService,
    private api: ApiRequestsService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private helper: HelpersService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.showSpinner();
    this.getAllTags();
  }

  logColor(tag) {
    console.log(tag);
  }

  displayInfoSection(initial = false) {
    this.displayCreator = false;
    this.displaySpinner = false;
    this.displayDeleted = false;
    this.displayEditor = false;
    this.displayInfo = true;
    this.displayInfoStart = initial;
  }

  displayEditorSection(tag) {
    this.selectedTagForEdit = tag;
    this.selectedTagForEdit_ = this.selectedTagForEdit;
    this.displayCreator = false;
    this.displaySpinner = false;
    this.displayDeleted = false;
    this.displayEditor = true;
    this.displayInfo = false;
    this.editInitialColor = tag.color;
  }

  displayCreatorSection(repeatedError = false) {
    this.displayCreator = true;
    this.displaySpinner = false;
    this.displayDeleted = false;
    this.displayInfo = false;
    this.displayEditor = false;
    this.displayNameError = repeatedError;
    this.editInitialColor = '';
  }

  onSelectColor(color) {
    if (
      !this.disabledColors
        .filter(c => c.toLowerCase() !== this.editInitialColor.toLowerCase())
        .includes(color.toLowerCase())
    ) {
      if (this.displayEditor) {
        this.selectedTagForEdit.color = color;
        return;
      }
      this.selectedColor = color.toLowerCase();
    }
  }

  displayDeletedSection() {
    this.showSpinner();
    this.api.tags.getDeleted().subscribe(response => {
      this.deletedList = response.data;
      this.displayCreator = false;
      this.displaySpinner = false;
      this.displayEditor = false;
      this.displayDeleted = true;
      this.displayInfo = false;
    });
  }

  showSpinner() {
    this.displayCreator = false;
    this.displaySpinner = true;
    this.displayEditor = false;
    this.displayInfo = false;
    this.displayDeleted = false;
  }

  getAllTags(repeatedError = false) {
    this.showSpinner();
    this.tagsList = [];
    this.api.tags.get().subscribe(response => {
      this.tagsList = response.data;
      this.disabledColors = this.getUsedColors();
      if (this.tagsList.length === 0) {
        this.displayInfoSection(true);
      } else {
        this.displayCreatorSection(repeatedError);
      }
    });
  }

  createTag(tagName, color) {
    this.showSpinner();
    const newTag = {
      label: tagName,
      color: color,
    };
    this.api.tags.create(newTag).subscribe(
      response => {
        this.getAllTags();
        this.selectedColor = '';
        this.analytics.sendAnalyticsEvent(
          'create tag button clicked',
          '',
          'tags'
        );
      },
      error => {
        this.errorHandler(error);
        console.log(error);
      }
    );
  }

  deleteTag(tag) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        message: this.translate.instant(
          '¿Estas seguro de eliminar la etiqueta ' + tag.label + ' ?'
        ),
        buttonText: {
          ok: this.translate.instant('SI'),
          cancel: this.translate.instant('NO'),
        },
      },
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.showSpinner();
        this.api.tags.deleteTag(tag._id).subscribe(response => {
          this.getAllTags();
        });
      }
    });
  }

  enableTag(_id) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        message: this.translate.instant('SIDE-MENU.TAGS.ENABLE-TAG'),
        buttonText: {
          ok: this.translate.instant('SI'),
          cancel: this.translate.instant('NO'),
        },
      },
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.api.tags.enableTag(_id).subscribe(
          response => {
            this.getAllTags();
            this.analytics.sendAnalyticsEvent(
              'Reactivar etiqueta',
              null,
              'Tag from the deleted list added to the active tags list'
            );
          },
          error => {
            this.errorHandler(error);
          }
        );
      }
    });
  }

  editTag(name, tag) {
    tag.label = name;
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        message:
          '¿Deseas editar la etiqueta "' +
          this.selectedTagForEdit_.label +
          '"? ' +
          this.translate.instant('SIDE-MENU.TAGS.EDIT-TAG'),
        buttonText: {
          ok: this.translate.instant('SI'),
          cancel: this.translate.instant('NO'),
        },
      },
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.api.tags.updateTag(tag).subscribe(
          response => {
            this.getAllTags();
          },
          error => {
            this.errorHandler(error);
          }
        );
      }
    });
  }

  errorHandler(error) {
    if (error.code === 0) {
      Swal.fire({
        width: 500,
        icon: 'error',
        title: 'Error con etiquetas',
        text:
          'Este nombre ya se encuentra entre una etiqueta eliminada\n' +
          'Puedes reactivarla desde la seccion de etiquetas eliminadas.',
        confirmButtonColor: '#03A9F4',
        confirmButtonText: '  Entendido   ',
      }).then(result => {
        this.getAllTags(false);
      });
    }
    if (error.code === 1) {
      this.getAllTags(true);
    }
    if (error.code === 2) {
      Swal.fire({
        width: 500,
        icon: 'error',
        title: 'Error con etiquetas',
        text:
          'Has llegado a número máximo de etiquetas permitidas.\n' +
          'Para reactivar esta etiqueta, debes eliminar otra etiqueta de tu lista',
        confirmButtonColor: '#03A9F4',
        confirmButtonText: '  Entendido   ',
      }).then(result => {
        this.getAllTags(false);
      });
    }
  }

  getUsedColors() {
    return this.tagsList.map(tag => tag.color.toLowerCase());
  }
  openColorArrayPicker() {
    this.dialog
      .open(ColorArrayPickerComponent, {
        data: {
          disabledColors: this.getUsedColors().filter(
            c => c.toLowerCase() !== this.editInitialColor
          ),
        },
      })
      .afterClosed()
      .subscribe(color => {
        console.log('esyte es el color', color);
        if (color) {
          if (this.displayEditor) {
            this.selectedTagForEdit.color = color;
            return;
          }
          this.selectedColor = color;
        }
      });
  }
  getFilterTagList() {
    return this.tagsList
      .filter(tag =>
        tag.label.toLowerCase().includes(this.searchText.toLowerCase())
      )
      .sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      );
  }
}
