<div class="header-for-lists-container">
  <div class="title-and-add" *ngIf="listName !== 'route'">
    <div class="title">
      <h1>
        {{
          listName === 'driver'
            ? ('DRIVERS.TITLE' | translate)
            : ('VEHICLES.TITLE' | translate)
        }}
      </h1>
    </div>
    <div
      class="add-action"
      (click)="add()"
      [id]="listName === 'driver' ? 'add-driver' : 'add-vehicle'">
      <i class="material-icons m-r-sm">add_circle</i>
      <p class="add-action-text">
        {{
          listName === 'driver'
            ? ('DRIVERS.NEW-DRIVER' | translate)
            : ('VEHICLES.NEW-VEHICLE' | translate)
        }}
      </p>
    </div>
  </div>
  <div class="controls">
    <ul class="filters hidden-xs">
      <li
        class="filter first-filter"
        [ngClass]="{ active: state.filterApplied == 1 }"
        (click)="filter(1)">
        {{ 'FILTERS.ALL' | translate }} ({{ state.all.count }})
      </li>
      <li
        class="filter"
        [ngClass]="{ active: state.filterApplied == 2 }"
        (click)="filter(2)">
        {{ 'FILTERS.NOT-ASSIGNED' | translate }} ({{ state.notAssigned.count }})
      </li>
      <li
        class="filter"
        [ngClass]="{ active: state.filterApplied == 3 }"
        (click)="filter(3)">
        {{ 'FILTERS.ASSIGNED' | translate }} ({{ state.assigned.count }})
      </li>
      <li
        class="filter"
        *ngIf="listName !== 'route'"
        [ngClass]="{ active: state.filterApplied == 4 }"
        (click)="filter(4)"
        [hidden]="listName === 'driver' || listName === 'routes'">
        {{ 'FILTERS.OOS' | translate }} ({{ state.OOS.count }})
      </li>
      <li
        class="filter"
        [ngClass]="{ active: state.filterApplied == 5 }"
        (click)="filter(5)">
        {{ 'FILTERS.ON-ROUTE' | translate }} ({{ state.onRoute.count }})
      </li>
      <!-- <li class="filter" [ngClass]="{'active': state.filterApplied == 6}" (click)="filter(6)">
          {{ "FILTERS.SETBACKS" | translate }} ({{state.setback.count}})
        </li> -->
      <li
        class="filter"
        *ngIf="listName !== 'route'"
        [ngClass]="{ active: state.filterApplied == 7 }"
        (click)="filter(7)"
        [hidden]="listName === 'vehicle' || listName === 'routes'">
        {{ 'FILTERS.INVITED' | translate }} ({{ state.invited?.count }})
      </li>
      <li
        class="filter"
        *ngIf="listName === 'route'"
        [ngClass]="{ active: state.filterApplied == 8 }"
        (click)="filter(8)">
        {{ 'FILTERS.CANCELLED' | translate }} ({{ state.cancelled.count }})
      </li>
    </ul>
    <mat-form-field
      class="select-dashboard select-filter hidden-sm hidden-md hidden-lg"
      *ngIf="listName === 'vehicle'">
      <mat-select
        [(ngModel)]="selected"
        (change)="filter($event.value)"
        name="filter"
        placeholder="{{ !selected ? ('COMMON.FILTER' | translate) : '' }}">
        <mat-option [value]="1">
          {{ 'FILTERS.ALL' | translate }} ({{ state.all.count }})
        </mat-option>
        <mat-option [value]="2">
          {{ 'FILTERS.NOT-ASSIGNED' | translate }} ({{
            state.notAssigned.count
          }})
        </mat-option>
        <mat-option [value]="3">
          {{ 'FILTERS.ASSIGNED' | translate }} ({{ state.assigned.count }})
        </mat-option>
        <mat-option [value]="4">
          {{ 'FILTERS.OOS' | translate }} ({{ state.OOS.count }})
        </mat-option>
        <mat-option [value]="5">
          {{ 'FILTERS.ON-ROUTE' | translate }} ({{ state.onRoute.count }})
        </mat-option>
        <mat-option [value]="6">
          {{ 'FILTERS.SETBACKS' | translate }} ({{ state.setback.count }})
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field
      class="select-dashboard select-filter hidden-sm hidden-md hidden-lg"
      *ngIf="listName === 'driver'">
      <mat-select
        [(ngModel)]="selected"
        (change)="filter($event.value)"
        name="filter"
        placeholder="{{ !selected ? ('COMMON.FILTER' | translate) : '' }}">
        <mat-option [value]="1">
          {{ 'FILTERS.ALL' | translate }} ({{ state.all.count }})
        </mat-option>
        <mat-option [value]="2">
          {{ 'FILTERS.NOT-ASSIGNED' | translate }} ({{
            state.notAssigned.count
          }})
        </mat-option>
        <mat-option [value]="3">
          {{ 'FILTERS.ASSIGNED' | translate }} ({{ state.assigned.count }})
        </mat-option>
        <mat-option [value]="5">
          {{ 'FILTERS.ON-ROUTE' | translate }} ({{ state.onRoute.count }})
        </mat-option>
        <mat-option [value]="6">
          {{ 'FILTERS.SETBACKS' | translate }} ({{ state.setback.count }})
        </mat-option>
        <mat-option [value]="7">
          {{ 'FILTERS.INVITED' | translate }} ({{ state.invited?.count }})
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field
      class="select-dashboard select-filter hidden-sm hidden-md hidden-lg"
      *ngIf="listName === 'route'">
      <mat-select
        [(ngModel)]="selected"
        (change)="filter($event.value)"
        name="filter"
        placeholder="{{ !selected ? ('COMMON.FILTER' | translate) : '' }}">
        <mat-option [value]="1">
          {{ 'FILTERS.ALL' | translate }} ({{ state.all.count }})
        </mat-option>
        <mat-option [value]="2">
          {{ 'FILTERS.NOT-ASSIGNED' | translate }} ({{
            state.notAssigned.count
          }})
        </mat-option>
        <mat-option [value]="3">
          {{ 'FILTERS.ASSIGNED' | translate }} ({{ state.assigned.count }})
        </mat-option>
        <mat-option [value]="5">
          {{ 'FILTERS.ON-ROUTE' | translate }} ({{ state.onRoute.count }})
        </mat-option>
        <mat-option [value]="6">
          {{ 'FILTERS.SETBACKS' | translate }} ({{ state.setback.count }})
        </mat-option>
        <mat-option [value]="8">
          {{ 'FILTERS.CANCELLED' | translate }} ({{ state.cancelled.count }})
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="date-container hidden-xs" *ngIf="listName !== 'route'">
      <div class="container-widget">
        <span class="display-text-date" [ngClass]="{ dissapear: !notOpened }">{{
          'COMMON.TODAY' | translate
        }}</span>
        <mat-form-field
          [ngClass]="{ 'space-xs': notOpened, 'form-field-date': !notOpened }">
          <input
            matInput
            [matDatepicker]="pickerx"
            (dateChange)="dateChange($event)" />
          <mat-datepicker #pickerx></mat-datepicker>
        </mat-form-field>
        <i class="material-icons pointer" (click)="pickerx.open()"
          >arrow_drop_down</i
        >
      </div>
    </div>
    <div class="date-container hidden-xs" *ngIf="listName === 'route'">
      <div class="container-widget">
        <app-custom-datepicker
          (dateChange)="dateChangeRoute($event)"></app-custom-datepicker>
      </div>
    </div>
    <div class="sort-container">
      <div class="sort-select">
        <span class="sort-select-span"
          >{{ 'COMMON.SORT-BY' | translate }}:
        </span>
        <mat-form-field>
          <mat-select
            [(ngModel)]="sortType"
            (valueChange)="updateSort($event)"
            name="sort">
            <mat-option
              [value]="'date asc'"
              *ngIf="listName === 'route' && activeRoutes"
              >{{ 'COMMON.DATE' | translate }}</mat-option
            >
            <mat-option [value]="'updatedAt desc'">{{
              'COMMON.RECENT-ACTIVITY' | translate
            }}</mat-option>
            <mat-option [value]="'createdAt desc'">{{
              'COMMON.CREATION-DATE' | translate
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="display-div hidden-xs">
      <span> {{ 'COMMON.VIEW' | translate }}: </span>
      <i
        class="material-icons display-icons"
        [ngClass]="{ disabled: !listActive }"
        (click)="listView()"
        >view_list</i
      >
      <i
        class="material-icons display-icons"
        [ngClass]="{ disabled: listActive }"
        (click)="moduleView()"
        >view_module</i
      >
    </div>
  </div>
</div>
