import { Subject } from 'rxjs';
import { forEach, isEmpty } from 'lodash';

declare let google: any;

/**
 *
 * @param latlng
 * @param map
 * @param layout
 * @param args
 */
function Marker(latlng, map, layout, args) {
  this.latlng = latlng;
  this.args = args;
  this.layout = layout;
  this.setMap(map);
}

export class CustomMarker {
  constructor(coords: any, map: any, layout: any, options?: any) {
    Marker.prototype = new google.maps.OverlayView();

    Marker.prototype.draw = this.draw;

    Marker.prototype.onAdd = this.onAdd;

    Marker.prototype.onRemove = this.removeMarker;

    Marker.prototype.getPosition = this.getPosition;

    Marker.prototype.getOffset = this.getOffset;

    Marker.prototype.refreshMarker = this.refreshMarker;

    Marker.prototype.drawDiv = this.drawDiv;

    Marker.prototype.emmiter = new Subject<any>();

    return new Marker(coords, map, layout, options || {});
  }

  /**
   * Draw custom marker
   */
  private draw() {
    let self: any = this;
    self.data = self.args.data;

    let div = self.div;

    let point = self.getProjection().fromLatLngToDivPixel(self.latlng);
    let offset = self.getOffset();

    div.style.left = point.x + offset.width + 'px';
    div.style.top = point.y + offset.height + 'px';
  }

  /**
   * onAdd is called when the map's panes are ready and the overlay has been
   * added to the map.
   */
  private onAdd() {
    this.drawDiv();
  }

  /**
   * Draw dif if do not exits
   */
  private drawDiv() {
    let self: any = this;

    // add layout to main div
    let div = (self.div = self.layout);

    // Added click event when it's necessary
    if (self.args.clickEvent) {
      google.maps.event.addDomListener(div, 'click', function (event) {
        event.preventDefault();
        self.args.clickEvent();
      });
    }

    let panes = self.getPanes();

    if (panes) panes.overlayImage.appendChild(div);

    return div;
  }

  /**
   * Update marker location and styles
   *
   * @param coords
   * @param styles
   * @param options
   * @param map
   */
  private refreshMarker(coords, options, map) {
    let self: any = this;
    let div = self.div;

    if (!self.map) self.setMap(map);

    if (!div) div = self.drawDiv();

    let style = self.args.style || {};

    if (options.style) style = options.style || {};

    self.latlng = coords;

    if (options.className) div.className = options.className || '';

    if (!isEmpty(style) && div) {
      forEach(style, (val, key) => {
        div.style[key] = val;
      });
    }

    let projection = self.getProjection();

    if (!projection) return;

    let point = projection.fromLatLngToDivPixel(self.latlng);
    let offset = self.getOffset();

    div.style.left = point.x + offset.width + 'px';
    div.style.top = point.y + offset.height + 'px';
  }

  /**
   * Remove custom marker
   */
  private removeMarker() {
    let self: any = this;
    if (self.div) {
      self.div.parentNode.removeChild(self.div);
      self.div = null;
    }
  }

  /**
   * Get marker position
   */
  private getPosition() {
    let self: any = this;
    return self.latlng;
  }

  /**
   * Get point offset
   */
  private getOffset() {
    let self: any = this;

    let offset = new google.maps.Size(0, 0);

    if (!self.div) return offset;

    let width = self.div.offsetWidth;
    let height = self.div.offsetHeight;

    offset.width = -(width / 2);
    offset.height = -(height / 2);

    return offset;
  }
}
