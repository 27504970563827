import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  ElementRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  AnalyticsService,
  FirebaseService,
  HelpersService,
} from '../../../shared/services';
import { MatChipInputEvent } from '@angular/material/chips';
import * as moment from 'moment';
import { ReportExcelService } from '../../../shared/services/report-excel.service';
import { ReportShareModalComponent } from '../../../shared/components/report-share-modal/report-share-modal.component';
import { ApiRequestsService } from '../../../api';
import { NgxTippyService } from 'ngx-tippy-wrapper';
import * as _ from 'lodash';
import { TagSelectorModalComponent } from '../../../shared/components/tag-selector-modal/tag-selector-modal.component';
import {
  AppLocationMap,
  LocationMapComponent,
} from '../../location-map/location-map.component';
import { ReportV2Service } from '../../../shared/services/report-v2.service';
import html2canvas from 'html2canvas';
import { RouteDeliveryPointModel } from '../../../models/routeDeliveryPoint.model';

@Component({
  selector: 'app-task-report-row',
  templateUrl: './task-report-row.component.html',
  styleUrls: ['./task-report-row.component.sass'],
})
export class TaskReportRowComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private analytics: AnalyticsService,
    public reportService: ReportV2Service,
    public reportExcelService: ReportExcelService,
    private api: ApiRequestsService,
    private tagsPopOver: NgxTippyService,
    public helpers: HelpersService,
    private firebase: FirebaseService
  ) {}
  @ViewChild('emailSwal') private emailSwal: SwalComponent;
  @Input() points: Array<RouteDeliveryPointModel> = [];
  @Input() statuses: Array<any> = [];
  @Output() emitCheck: EventEmitter<any> = new EventEmitter();

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  emails: Array<any> = [];
  galleryOptions = [
    {
      image: false,
      height: '120px',
      arrowPrevIcon: 'fa fa-chevron-left',
      arrowNextIcon: 'fa fa-chevron-right',
      closeIcon: 'fa fa-times',
      imageArrowsAutoHide: true,
      thumbnailsArrowsAutoHide: true,
    },
    {
      breakpoint: 500,
      width: '100%',
      thumbnailsColumns: 2,
    },
  ];
  tagLoading = true;
  tagListShow = false;
  tagList = [];
  splitRegex: RegExp = /[\n,]/;

  @ViewChild('anchorDownload') public anchorDownload: ElementRef;
  capturedImage;
  isImageReady = false;
  showSessionLoad = false;
  isReadyToDownload = false;
  isImageOpen = false;
  actualImage = '';
  photoStatus = '';
  photoEntregadoEn = '';
  photoEntregadoPor = '';
  photoFechaEntrega = '';

  logThis() {}

  setCheckedValue(item) {
    this.emitCheck.emit(item._id);
  }

  openTagsSelectorModal(routeDeliveryPoint) {
    this.dialog
      .open(TagSelectorModalComponent, {
        data: { selectedTags: routeDeliveryPoint.tags },
        width: '500px',
      })
      .afterClosed()
      .subscribe(response => {
        routeDeliveryPoint.tags = response;
        const payload = {
          id: routeDeliveryPoint._id,
          referralGuides: routeDeliveryPoint.referralGuides,
          tags: response.map(x => x._id) || [],
        };
        this.api.routes
          .updateAddress(payload)
          .toPromise()
          .catch(error => {
            this.helpers.handleErrorMessages(error);
          });
      });
  }

  getAllTags() {
    this.tagLoading = true;
    this.tagList = [];
    this.api.tags.get().subscribe(response => {
      this.tagList = response.data;
      if (this.tagList.length === 0) {
        /* empty */
      } else {
        this.tagLoading = false;
        this.tagListShow = true;
      }
    });
  }
  sendEvent() {
    this.analytics.sendAnalyticsEvent(
      'Tag submenu icon clicked',
      null,
      'sends event when the tag submenu icon is clicked'
    );
  }

  ngOnInit() {
    this.tagsPopOver.setDefaultProps({
      trigger: 'click',
      placement: 'bottom',
      appendTo: () => document.body,
      hideOnClick: true,
      interactive: true,
    });
  }

  hideTagsPopOver() {
    this.tagsPopOver.hideAll();
  }
  getDouble(data): number {
    if (data) {
      return parseFloat(data);
    }
    return 0;
  }

  checkValueTag(event, deliverPoint, tag) {
    const add = event.checked;
    if (!deliverPoint) {
      return;
    }
    if (add) {
      deliverPoint.tags = _.union(deliverPoint.tags, [tag]);
    } else {
      deliverPoint.tags = _.filter(
        deliverPoint.tags,
        function (currentDP: any) {
          return currentDP._id !== tag._id;
        }
      );
    }
    let mappedTags;

    if (deliverPoint.tags) {
      mappedTags = deliverPoint.tags.map(x => x._id);
    }
    const payload = {
      id: deliverPoint._id,
      referralGuides: deliverPoint.referralGuides,
      tags: mappedTags || [],
    };
    this.api.routes
      .updateAddress(payload)
      .toPromise()
      .catch(error => {
        this.helpers.handleErrorMessages(error);
      });
  }

  confirmCheckedValues(tag, item) {
    if (item.tags) {
      return item.tags.some(t => t._id === tag._id);
    }
    return false;
  }

  getComments(task) {
    try {
      const parsed = JSON.parse(task.comments);
      return parsed.comments;
    } catch (e) {
      return task.comments;
    }
  }
  getWarehouse(deliveryPoint) {
    try {
      const parsed = JSON.parse(deliveryPoint.details);
      return parsed.warehouse || '';
    } catch (e) {
      return deliveryPoint.warehouse || '';
    }
  }

  getClientCi(task) {
    if (task.receiverId) {
      return task.receiverId;
    } else {
      try {
        const parsed = JSON.parse(task.comments);
        if (parsed.client_CI !== null) {
          return parsed.client_CI;
        } else {
          return 'N/A';
        }
      } catch (e) {
        return 'N/A';
      }
    }
  }

  getClientName(task) {
    if (task.deliveryPoint.deliveryName === 'some') {
      return 'N/A';
    }
    return task.deliveryPoint.deliveryName;
  }

  showExportModal(message: string, item: any) {
    const dialogRef = this.dialog.open(ReportShareModalComponent, {
      data: {
        message: message,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const method = {
          method: 'download',
        };
        // eslint-disable-next-line unused-imports/no-unused-vars
        const reportContent = {
          item: item,
        };
        switch (result) {
          case 'PDF':
            method.method = 'download';
            this.reportService.getReport({
              type: 'fast-individual',
              data: item,
              method: 'download',
              filters: {},
            });
            break;
          case 'EXCEL':
            method.method = 'download-excel';
            this.reportExcelService.generateReport({
              data: item,
              driver: item.userId,
              initDate: moment(item.documentedAt).valueOf(),
              endDate: moment(item.documentedAt).valueOf(),
              type: 'fastRegistry-individual',
              route: null,
              status: null,
            });
            break;
          case 'EMAIL':
            this.emailSwal.fire().then(_result => {
              if (_result.isConfirmed) {
                let validMails = this.emails.filter(x => x.isValid === true);
                if (validMails.length > 0) {
                  validMails = validMails.map(x => x.email);
                  // eslint-disable-next-line unused-imports/no-unused-vars
                  const method = {
                    method: 'sendEmail',
                    contacts: validMails,
                  };
                  this.reportService.getReport({
                    type: 'fast-individual',
                    data: item,
                    method: 'email',
                    filters: {},
                    emails: validMails,
                  });
                } else {
                  this.helpers.showErrorMessage(
                    'No existen destinatarios válidos para enviar reporte'
                  );
                }
              }
              if (_result.isDismissed) {
                this.emails = [];
              }
            });
            break;
        }
      }
    });
  }

  downloadReport(item: any) {
    const companyName = item.deliveryPoint.deliveryName;
    const message = `Desea generar reporte sobre el registro rápido en "${companyName}"`;
    this.showExportModal(message, item);
  }

  /**
   * Open preview image of gallery
   *
   * @param $event event
   * @param item item
   */
  previewOpen($event, item) {
    this.createImageDiv($event, item);
  }

  /**
   * Close preview image
   */
  previewClose() {
    this.isImageOpen = false;
  }

  /**
   *  Change preview image
   *
   * @param $event  event
   * @param item item
   */
  previewChange($event, item) {
    this.createImageDiv($event, item);
  }

  /**
   * Get managment status to add in image footer
   *
   * @param status status
   * @returns value of status
   */
  getStatus(status: string): string {
    switch (status) {
      case 'completed':
        return 'Todo ok';
      case 'incomplete':
        return 'Incompleto';
      case 'rejected':
        return 'Rechazado';
      default:
        return '-';
    }
  }

  /**
   * Download image
   */
  descargarImagen() {
    this.isImageReady = true;
    this.showSessionLoad = true;
    html2canvas(document.querySelector('#capture'), {
      useCORS: true,
      width: 500,
    }).then(canvas => {
      this.capturedImage = canvas.toDataURL();

      const _this = this;
      canvas.toBlob(function (blob) {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          // eslint-disable-next-line unused-imports/no-unused-vars
          const base64data = reader.result;

          setTimeout(function () {
            _this.anchorDownload.nativeElement.href = _this.capturedImage;
            _this.isReadyToDownload = true;
            _this.showSessionLoad = false;
            _this.anchorDownload.nativeElement.click();
          }, 500);
        };
      });
    });
  }

  /**
   * Create image with footer
   *
   * @param $event event
   * @param item item
   */
  createImageDiv($event, item) {
    this.isImageOpen = true;

    this.actualImage = $event.image.small;

    const currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
    if (currentUser) {
      this.photoEntregadoEn = currentUser.data.companyName;
    }
    this.photoEntregadoPor = item.driver.firstName + ' ' + item.driver.lastName;
    this.photoFechaEntrega = new Date(item.documentedAt).toLocaleString();
    this.photoStatus = this.getStatus(item.status);
  }

  getFiles(points) {
    if (points.length && typeof points[0] !== 'string') {
      return points;
    }
    let images = [];
    let files =
      points.length && typeof points[0] !== 'string'
        ? points[0] || []
        : points || [];
    _.forEach(files, val => {
      images.push({
        small: val,
        medium: val,
        big: val,
      });
    });
    return images;
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (value.trim() !== '') {
      if (!this.emails.find(x => x.email === value.trim())) {
        const mail = {
          email: value.trim(),
          bgColor: '#b7b7b7',
          isValid: true,
        };
        const toSend = this.reportService.checkMail(mail);
        if (toSend) {
          this.emails.push(toSend);
        }
      } else {
        this.showRepeatedEmail();
      }
    }

    if (input) {
      input.value = '';
    }
  }

  remove(email: string): void {
    const index = this.emails.indexOf(email);
    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }
  addEmailsOnPaste(event, separator) {
    let stringList = event.clipboardData.getData('text/plain');
    stringList = stringList.trim();
    const emailList = stringList.split(separator);
    for (let i = 0; i < emailList.length; i++) {
      const value = emailList[i];
      if (value.trim() !== '') {
        if (!this.emails.find(x => x.email === value.trim())) {
          const mail = {
            email: value.trim(),
            bgColor: '#b7b7b7',
            isValid: true,
          };
          const toSend = this.reportService.checkMail(mail);
          if (toSend) {
            this.emails.push(toSend);
          }
        }
      }
    }
    event.target.setValue('');
  }

  showRepeatedEmail() {
    this.helpers.showWarningMessage('Dirección de correo ingresada repetida');
  }

  giveTimeFormat(time) {
    return moment(time).format('DD-MM-YYYY hh:mm ');
  }

  sendAnalytics() {
    this.analytics.sendAnalyticsEvent('View more details (TASK)', null, '');
  }
  sendAnalyticsPhoto() {
    this.analytics.sendAnalyticsEvent('Open Photo in report', null, '');
  }
  getMapUrl(latitude, longitude) {
    if (!latitude || !longitude) {
      return null;
    }
    return `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
  }

  outOfFence(item) {
    return (
      this.helpers.calculateDistance({
        latitudeX: item.deliveryPoint.latitude,
        longitudeX: item.deliveryPoint.longitude,
        latitudeY: item.latitude,
        longitudeY: item.longitude,
      }) > 200
    );
  }

  openMapModal(item) {
    const distance = this.helpers.calculateDistance({
      latitudeX: item.deliveryPoint.latitude,
      longitudeX: item.deliveryPoint.longitude,
      latitudeY: item.latitude,
      longitudeY: item.longitude,
    });
    const data: AppLocationMap = {
      originalPoint: {
        latitude: item.deliveryPoint.latitude,
        longitude: item.deliveryPoint.longitude,
        name: item.deliveryPoint.deliveryName,
      },
    };

    if (distance > 200) {
      data.realPointPoint = {
        latitude: item.latitude,
        longitude: item.longitude,
        name: 'Coordenada de gestión',
      };
      data.distance = distance;
    }
    this.dialog.open(LocationMapComponent, {
      data: data,
    });
  }

  /**
   * Get referral guides value
   *
   * @param routeDeliveryPoint Route delivery point
   * @returns guide
   */
  getReferralGuidesValue(routeDeliveryPoint: any) {
    return (
      routeDeliveryPoint.referralGuides?.map(element => {
        return element.referralGuide;
      }) || []
    );
  }
}
