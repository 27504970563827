/**
 * Utils Fleetflex
 */


/**
 * Base on subdomain, a role should be used to compare validations
 */
function getRoleToCheck() {
    const host = window.location.host;
    const subdomain = host.split('.')[0];
    let rolToCheck;
    switch (subdomain) {
        case 'equipo':
        case 'equipo-qa':
        case 'equipo-dev':
            rolToCheck = 'manager';
            break;
        case 'localhost:4200':
            rolToCheck = 'company'; //Change for development
            break;
        case 'admin':
        case 'admin-qa':
        case 'admin-dev':
            rolToCheck = 'company';
            break;
        default:
            rolToCheck = 'company';
    }
    return rolToCheck;
}
function isCompanyDomain(){
    return getRoleToCheck() === 'company'
}
function isManagerDomain(){
    return getRoleToCheck() === 'manager'
}

module.exports ={
    getRoleToCheck,
    isCompanyDomain,
    isManagerDomain
}
