<div class="login-container">
  <mat-card appearance="outlined">
    <h1 class="title">{{ 'PROFILE.CHANGE-PASSWORD' | translate }}</h1>

    <form name="form" class="login-form" [formGroup]="activateByPasswordForm">
      <!--NEW PASSWORD -->
      <mat-form-field class="w-20 m-t-xs">
        <input
          matInput
          type="password"
          placeholder="{{ 'RESET-PASSWORD.CONFIRM-PASSWORD' | translate }}"
          name="newPassword"
          formControlName="newPassword" />
        <mat-error
          *ngIf="
            !activateByPasswordForm.controls.newPassword.valid &&
            activateByPasswordForm.controls.newPassword.touched
          ">
          {{ 'LOGIN.MESSAGES.REQUIRED-PASSWORD' | translate }}
        </mat-error>
      </mat-form-field>

      <!--REPEAT NEW PASSWORD -->
      <mat-form-field class="w-20 m-t-xs">
        <input
          matInput
          type="password"
          placeholder="{{ 'RESET-PASSWORD.CONFIRM-NEW-PASSWORD' | translate }}"
          name="confirmPassword"
          formControlName="confirmPassword" />
        <!-- <mat-error *ngIf="(!activateByPasswordForm.controls.confirmPassword.valid && activateByPasswordForm.controls.confirmPassword.touched)" >
                  {{"LOGIN.MESSAGES.REQUIRED-PASSWORD" | translate }}
                </mat-error> -->
      </mat-form-field>

      <div class="text-center">
        <button
          mat-raised-button
          color="primary"
          class="btn-save-changes"
          [disabled]="activateByPasswordForm.invalid"
          (click)="updatePassword(activateByPasswordForm)">
          {{ 'RESET-PASSWORD.SAVE-CHANGES' | translate }}
        </button>
      </div>
    </form>
  </mat-card>
</div>
