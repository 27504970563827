<div class="datepicket-label" (click)="opened = !opened">
  <span class="display-text-date" *ngIf="allDates">{{
    'ROUTES.ACTIVE_ROUTES' | translate
  }}</span>
  <span class="display-text-date" *ngIf="!allDates && isToday()">{{
    'COMMON.TODAY' | translate
  }}</span>
  <span class="display-text-date" *ngIf="!allDates && !isToday()">{{
    date | date : 'dd/MM/yyyy'
  }}</span>
  <i class="material-icons pointer">arrow_drop_down</i>
</div>

<div class="datepicker-container" *ngIf="opened">
  <div>
    <datepicker
      [(ngModel)]="date"
      [showWeeks]="false"
      [formatDayTitle]="'MMM YYYY'"
      [formatDayHeader]="'dd'"
      (selectionDone)="OnValueChange($event)"></datepicker>
  </div>
  <button mat-button (click)="selectedDateValue(false, true)">
    {{ 'ROUTES.SHOW_ALL_ROUTES' | translate }}
  </button>
</div>
