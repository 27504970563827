import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  OnChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { HelpersService, DriverEventsService } from '../../services';
import * as moment from 'moment';
import { IRouteIndex, RouteModel } from '../../../models/route.model';
import { RouteDeliveryPointModel } from '../../../models/routeDeliveryPoint.model';
import * as _ from 'lodash';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'points-stepper',
  templateUrl: './points-stepper.component.html',
  styleUrls: ['./points-stepper.component.sass'],
})
export class PointsStepperComponent implements OnInit, OnDestroy, OnChanges {
  @Input() content: RouteModel;
  @Input() set points_data(val: RouteDeliveryPointModel[]) {
    this.points = val;
  }

  @Input() import: boolean = false;
  @Input() childType: string = 'route';

  points: RouteDeliveryPointModel[];
  deliveryPoints: RouteDeliveryPointModel[] = [];
  currentPoint = 0;

  private subscriptions: any = {};

  constructor(
    private helpers: HelpersService,
    private driverEvents: DriverEventsService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnChanges() {
    if (this.points) {
      this.deliveryPoints = this.points;
    }
  }

  ngOnInit() {
    if (!this.points) {
      this.calculateCurrentPoint();
      this.checkDeliveredPoints();

      this.subscriptions['driverEvent'] = this.driverEvents
        .subscribeToEventPool(this.getDriverId())
        .pipe(filter(driverEvent => driverEvent.owner === this.getDriverId()))
        .subscribe(driverEvent => {
          this.checkDeliveredPoints();
        });
    } else {
      this.deliveryPoints = this.points;
    }
  }

  ngOnDestroy() {
    _.forEach(this.subscriptions, value => value.unsubscribe());
  }

  /**
   * Check delivery points statuses
   */
  checkDeliveredPoints() {
    if (!this.content.deliverPoints && !this.content.index) return;

    this.deliveryPoints.length = 0;

    const unDocumented = this.content.deliverPoints.filter(
      e => !e.documentedAt
    );
    const documented = this.content.deliverPoints
      .filter(e => e.documentedAt)
      .sort((a, b) => moment(a.documentedAt).diff(b.documentedAt));

    const unDocumentedSorted = _.filter(
      _.sortBy(this.content.index, ['index']).map((index, position) => {
        index = index as IRouteIndex;
        index.id = index['id'];
        let point = _.find(unDocumented, e => {
          let id = e.deliveryPoint._id;
          return id === index.id;
        });

        point = _.cloneDeep(point);

        if (!point) return null;
        return point;
      }),
      null
    );

    this.deliveryPoints = [...documented, ...unDocumentedSorted];
  }

  /**
   * Calculate active point
   */
  calculateCurrentPoint() {
    this.currentPoint = 0;

    for (let i = 0; i < this.content.index.length; i++) {
      let _item = this.findPoint(i);
      if (!_item) break;

      if (_item.isDocumented) this.currentPoint += 1;
      else break;
    }

    return this.currentPoint === 0 ? 0 : this.currentPoint - 1;
  }

  /**
   * Find a point by index position
   *
   * @param position
   */
  findPoint(position: any): RouteDeliveryPointModel | null {
    const point = this.content.index[position];

    // console.log(position)
    if (!point) return null;

    const _item = _.find(this.content.deliverPoints, e => {
      const id = _.isPlainObject(e.deliveryPoint) ? e.deliveryPoint._id : e;
      return id === this.content.index[position].id;
    });

    return _item;
  }

  /**
   * Get current delivery point
   */
  private getCurrentPoint() {
    return this.content.deliverPoints[this.calculateCurrentPoint()] || {};
  }

  /**
   * Get driver id to be used as filter
   */
  private getDriverId() {
    const _id = _.get(this.content, 'driver._id');
    return _id;
  }
}
