import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent } from 'rxjs';
import {
  combineLatest,
  debounceTime,
  filter,
  map,
  merge,
} from 'rxjs/operators';
import {
  HelpersService,
  FirebaseService,
  AnalyticsService,
} from '../../services';
import { Router } from '@angular/router';
import { SidePanelService } from '../../services';
import { slideOutInAnimation } from '../../animations';
import { forEach } from 'lodash';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ApiRequestsService } from '../../../api';
import * as _ from 'lodash';
import { DirectoryNavigationService } from '../../../directory/services/directory-navigation.service';
import { RouteDeliveryPointModel } from '../../../models/routeDeliveryPoint.model';
import { RouteModel } from '../../../models/route.model';

@Component({
  selector: 'notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.sass'],
  animations: [slideOutInAnimation],
})
export class NotificationCardComponent implements OnInit {
  @Input() content: any = {};
  @Input() entity: string = 'driver';
  @Input() type: string;
  @ViewChild('previewSwal') private previewSwal: SwalComponent;
  picturePlaceholder: string = './assets/img/profile-placeholder.jpg';
  notification: any = {};
  displayMenu: boolean = false;
  responsiveActive: boolean = false;
  warningStatus: boolean = false;
  importantStatus: boolean = false;
  withoutTitle: boolean = false;

  modalTitle = '';

  theObject: any = {};
  routeDeliveryPoints: Array<RouteDeliveryPointModel> = [];
  routes: Array<RouteModel> = [];

  private subscriptions: any = {};
  private queryParams: any = {};

  constructor(
    private router: Router,
    private helpers: HelpersService,
    private translate: TranslateService,
    private sidePanel: SidePanelService,
    private elementRef: ElementRef,
    private firebase: FirebaseService,
    private analytics: AnalyticsService,
    private apiService: ApiRequestsService,
    private directoryNavigationService: DirectoryNavigationService
  ) {}

  ngOnInit() {
    this.generateContentByEntityType();
    this.initItemMenu();
  }

  ngOnDestroy() {
    forEach(this.subscriptions, value => value.unsubscribe());
  }

  /**
   * Translate time to human readable text
   *
   * @param time
   */
  private getTimeLabel(time: any) {
    return `${this.helpers.getDateText(time)} ${moment(time).format(
      'hh:mm A'
    )}`;
  }

  /**
   * Trigget link content
   *
   * @param link
   */
  triggerAction(link: string) {
    if (this.type === 'task-added') {
      this.theObject.date = this.content.entityObject.payload.documentedAt;
      this.theObject.id = this.content.entityId;
      this.theObject.data = [];
      let data: any = {
        limit: 1000,
        initDate: moment(this.theObject.date).format('YYYY-MM-DD Z'),
        endDate: moment(this.theObject.date).format('YYYY-MM-DD Z'),
      };
      this.apiService.reports
        .getTasksReport(data)
        .toPromise()
        .then(result => {
          let element;
          try {
            element = result.data.filter(x => x._id === this.theObject.id)[0];
            element.files = this.displayPointAttachments(element.files);
            if (element) {
              this.theObject.data.push(element);
            }
            this.fireTaskRegistry();
          } catch (e) {
            console.log('Error at fetch time');
          }
        });
    }

    if (this.type === 'route-completed') {
      this.theObject.type = 'route';
      this.theObject.id = this.content.entityId;
      this.theObject.data = [];
      let temporalDeliver = this.content.entityObject.payload.deliverPoints;
      this.content.entityObject.payload.deliverPoints = [];
      try {
        for (const [key, value] of Object.entries(temporalDeliver)) {
          this.content.entityObject.payload.deliverPoints.push(value);
        }
      } catch (e) {
        console.log('Error parsing points');
      }
      this.theObject.data.push(this.content.entityObject.payload);
      this.fireRouteRegistry();
    }

    if (this.type === 'destination-added') {
      let searchword =
        this.content.entityObject.payload.data.deliveryName || null;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['/directory/destinations'], {
        queryParams: { searchword: searchword },
      });
      this.sidePanel.triggerClose.next(true);
    }

    if (this.type === 'route-has-not-started') {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['/dashboard']);
      this.sidePanel.triggerClose.next(true);
    }

    // if (isEmpty(this.queryParams)){
    //   this.router.navigate([link]);
    //   if (link ==="library"){
    //     let sub = this.notification.subtitle;
    //     let sp = sub.split("'")[1]
    //     this.analytics.sendAnalyticsEvent('Notification new destination', null,'');
    //     this.router.navigate([link, { dest: sp}]);
    //   }
    // }
    // else{
    //   this.router.navigate([link], { queryParams: this.queryParams });
    // }
  }

  /**
   * Remove notification from list
   */
  remove() {
    let payload = {
      deletedAt: moment().toISOString(),
    };

    this.firebase
      .update(`notifications/${this.content.notificationId}`, payload)
      .catch(error => this.helpers.handleErrorMessages(error));
  }

  /**
   * Create data content by their entity
   */
  private generateContentByEntityType() {
    if (this.entity === 'driver') return this.generateDriverContent();
    if (this.entity === 'route') return this.generateRouteContent();

    if (this.entity === 'deliveryPoint' && this.type === 'status-reported')
      return this.generateDeliveryStatus();

    if (this.entity === 'deliveryPoint') return this.generateTaskContent();

    if (this.entity === 'destination') return this.generateDestinationContent();

    if (this.entity === 'driver-status')
      return this.generateDriverStatusContent();
  }

  /**
   * Generate notification content for entitty type equals to status-reported
   */
  private generateDeliveryStatus() {
    let content = this.content.entityObject;

    if (content.subtitle.includes('rejected')) {
      this.warningStatus = true;
      content.subtitle1 = content.subtitle.split('rejected')[0];
      content.subtitle2 = this.helpers.mapStatutes({ status: 'rejected' });
      content.subtitle3 = content.subtitle.split('rejected')[1];
    }

    if (content.subtitle.includes('incomplete')) {
      this.importantStatus = true;
      content.subtitle1 = content.subtitle.split('incomplete')[0];
      content.subtitle2 = this.helpers.mapStatutes({ status: 'incomplete' });
      content.subtitle3 = content.subtitle.split('incomplete')[1];
    }

    this.notification = {
      title: '',
      subtitle: content.title,
      details1: content.subtitle1,
      details2: content.subtitle2,
      details3: content.subtitle3,
      readed: this.content.readed,
      createdAt: this.getTimeLabel(this.content.createdAt),
    };

    //this.notification.link = this.getLink(this.type);
    //this.notification.button = this.getButton(this.type);

    if (this.content.attachments)
      this.notification.image = this.content.attachments.image;
  }

  willShow() {
    if (this.entity === 'deliveryPoint') {
      if (this.type === 'status-reported') return false;
    }
    return true;
  }

  /**
   * Generate notification content for entitty type equals to driver-status
   */
  private generateDriverStatusContent() {
    let content = this.content.entityObject;

    if (content.subtitle.includes('setback')) {
      this.warningStatus = true;
      content.subtitle = content.subtitle.replace('setback', 'EMERGENCIA');
    }

    if (content.subtitle.includes('road-issue')) {
      this.importantStatus = true;
      content.subtitle = content.subtitle.replace(
        'road-issue',
        'PROBLEMA VIAL'
      );
    }

    this.notification = {
      title: '',
      subtitle: content.title,
      details: content.subtitle,
      readed: this.content.readed,
      createdAt: this.getTimeLabel(this.content.createdAt),
    };

    //this.notification.link = this.getLink(this.type);
    //this.notification.button = this.getButton(this.type);

    if (this.content.attachments)
      this.notification.image = this.content.attachments.image;
  }

  /**
   * Generate notification content for entitty type equals to driver
   */
  private generateDriverContent() {
    let content = this.content.entityObject;

    this.notification = {
      title: this.translate.instant(this.content.entityObject.title, {
        value: '',
      }),
      readed: this.content.readed,
      createdAt: this.getTimeLabel(this.content.createdAt),
    };

    this.notification.link = this.getLink(this.type);
    this.notification.button = this.getButton(this.type);

    if (content.details)
      this.notification.details =
        this.type === 'invitation'
          ? `${this.translate.instant('COMMON.IDENTIFICATION')}: ${
              content.details
            }`
          : '';

    if (content.subtitle) this.notification.subtitle = content.subtitle;

    if (this.content.attachments)
      this.notification.image = this.content.attachments.image;

    if (this.type === 'network') {
      this.notification.details = this.translate.instant(
        this.content.entityObject.title,
        { value: content.details || '' }
      );
      this.notification.title = '';
      this.warningStatus = true;
    }
  }

  /**
   * Generate notification content for entity type equals to destination
   */
  private generateDestinationContent() {
    let content = this.content.entityObject;

    this.notification = {
      title: this.translate.instant(this.content.entityObject.title, {
        value: '',
      }),
      readed: this.content.readed,
      createdAt: this.getTimeLabel(this.content.createdAt),
    };

    this.notification.link = this.getLink(this.type);
    this.notification.button = this.getButton(this.type);

    if (content.details)
      this.notification.details =
        this.type === 'invitation'
          ? `${this.translate.instant('COMMON.IDENTIFICATION')}: ${
              content.details
            }`
          : '';

    if (content.subtitle) this.notification.subtitle = content.subtitle;

    if (this.content.attachments)
      this.notification.image = this.content.attachments.image;
  }

  /**
   * Generate notification content for entity type equals to deliveryPoint
   */
  private generateTaskContent() {
    let content = this.content.entityObject;

    this.notification = {
      title: this.translate.instant(this.content.entityObject.title, {
        value: '',
      }),
      readed: this.content.readed,
      createdAt: this.getTimeLabel(this.content.createdAt),
    };

    this.notification.link = this.getLink(this.type);
    this.notification.button = this.getButton(this.type);

    if (content.details)
      this.notification.details =
        this.type === 'invitation'
          ? `${this.translate.instant('COMMON.IDENTIFICATION')}: ${
              content?.payload?.deliveryPoint?.deliveryName ?? content.details
            }`
          : '';

    if (content.subtitle) this.notification.subtitle = content.subtitle;

    if (this.content.attachments)
      this.notification.image = this.content.attachments.image;

    this.notification.details = this.translate.instant(
      this.content.entityObject.title,
      {
        value:
          content?.payload?.deliveryPoint?.deliveryName ??
          (content.details || ''),
      }
    );
    this.notification.title = '';
    this.withoutTitle = true;
  }

  /**
   * Generate notification content for entity type equals to route
   */
  private generateRouteContent() {
    let content = this.content.entityObject;
    this.notification = {
      title: this.translate.instant(this.content.entityObject.title, {
        value: content.details || '',
      }),
      readed: this.content.readed,
      createdAt: this.getTimeLabel(this.content.createdAt),
    };

    this.notification.link = this.getLink(this.type);
    this.notification.button = this.getButton(this.type);

    if (content.subtitle) this.notification.subtitle = content.subtitle;

    if (this.content.attachments)
      this.notification.image = this.content.attachments.image;

    if (this.type === 'route-has-not-started') {
      this.notification.details = this.translate.instant(
        this.content.entityObject.title,
        { value: content.details || '' }
      );
      this.notification.title = '';
      this.withoutTitle = true;
    }
  }

  /**
   * Enable hover actions for desktop sites
   */
  private enableHoverItem() {
    this.responsiveActive = false;

    if (this.subscriptions.mouseMove)
      this.subscriptions.mouseMove.unsubscribe();
    if (this.subscriptions.mouseLeave)
      this.subscriptions.mouseLeave.unsubscribe();

    let enter = fromEvent(this.elementRef.nativeElement, 'mouseenter');
    let leave = fromEvent(this.elementRef.nativeElement, 'mouseleave');
    let move = fromEvent(this.elementRef.nativeElement, 'mousemove');
    let entered = enter.pipe(
      map(e => true),
      merge(leave.pipe(map(e => false)))
    );
    // display menu only when mouse it's not moving around
    this.subscriptions.mouseMove = move
      .pipe(
        combineLatest(entered),
        debounceTime(100),
        filter(([e, b]) => {
          e['preventDefault']();
          return b;
        })
      )
      .subscribe(e => {
        this.displayMenu = true;
      });

    // remove menu panel on mouse leave
    this.subscriptions.mouseLeave = leave
      .pipe(debounceTime(50))
      .subscribe(e => (this.displayMenu = false));
  }

  /**
   * Enable touch actions for mobile devices
   */
  private enableTouchItem() {
    this.responsiveActive = true;

    if (this.subscriptions.mouseMove)
      this.subscriptions.mouseMove.unsubscribe();
    if (this.subscriptions.mouseLeave)
      this.subscriptions.mouseLeave.unsubscribe();
  }

  /**
   * Init item menu hover or touch action
   */
  private initItemMenu() {
    let width = window.innerWidth;

    if (width > 600) this.enableHoverItem();
    else this.enableTouchItem();

    this.subscriptions.resizedWindow = fromEvent(window, 'resize')
      .pipe(debounceTime(1500))
      .subscribe((e: any) => {
        let width = e.target.innerWidth;
        if (width > 600) this.enableHoverItem();
        else this.enableTouchItem();
      });
  }

  /**
   * Get notification button by their type
   *
   * @param type
   */
  private getButton(type) {
    let button = '';
    switch (type) {
      case 'invitation':
        button = this.translate.instant('NOTIFICATIONS.DRIVER.PROFILE-LOOKUP');
        break;
      case 'route-has-not-started':
        let today = moment().startOf('day');
        button =
          this.content.entityId &&
          moment(this.content.createdAt).startOf('day').local().isSame(today)
            ? this.translate.instant('NOTIFICATIONS.ROUTE.LOOKUP-DASHBOARD')
            : '';
        this.warningStatus = true;
        break;
      case 'task-added':
        button = this.translate.instant('NOTIFICATIONS.TASKS.LOOKUP-TASK');
        break;
      case 'destination-added':
        button = this.translate.instant(
          'NOTIFICATIONS.DESTINATIONS.LOOKUP-DESTINATION'
        );
        break;
      case 'route-completed':
        button = this.translate.instant('NOTIFICATIONS.COMPLETED-ROUTE');
        break;
    }

    return button;
  }

  /**
   * Get notification link by their type
   *
   * @param type
   */
  private getLink(type) {
    let link = '';
    switch (type) {
      case 'invitation':
        link = `drivers/details/${this.content.entityId}`;
        break;
      case 'route-has-not-started':
        link = `dashboard`;
        this.queryParams = { route: this.content.entityId };
        break;
      case 'task-added':
        link = `reports`;
        this.modalTitle = 'Tarea añadida';
        this.queryParams = { type: 'tasks' };
        break;
      case 'destination-added':
        link = 'library';
        break;
      case 'route-completed':
        link = 'reports';
        this.modalTitle = 'Ruta completada';
        break;
    }

    return link;
  }

  fireTaskRegistry() {
    this.routeDeliveryPoints = RouteDeliveryPointModel.fromListMap(
      this.theObject.data
    );
    this.previewSwal.fire().then(_result => {
      if (_result.isConfirmed) {
      }
      if (_result.isDismissed) {
      }
    });
  }

  fireRouteRegistry() {
    this.routes = RouteModel.fromListMap(this.theObject.data);
    this.previewSwal.fire().then(_result => {
      if (_result.isConfirmed) {
      }
      if (_result.isDismissed) {
      }
    });
  }

  /**
   * Display attachments when these are selected
   *
   * @param point
   * @param points
   */
  private displayPointAttachments(points: any) {
    let images = [];
    let files =
      points.length && typeof points[0] !== 'string'
        ? points[0] || []
        : points || [];

    _.forEach(files, val => {
      images.push({
        small: val,
        medium: val,
        big: val,
      });
    });

    return images;
  }
}
