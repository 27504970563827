import {
  Component,
  OnInit,
  Output,
  Input,
  ViewChild,
  ComponentFactoryResolver,
  OnDestroy,
} from '@angular/core';
import { Subject } from 'rxjs';
import {
  clone,
  values,
  first,
  get,
  isPlainObject,
  forEach,
  delay,
} from 'lodash';
import * as submenu from './submenu-items.json';
import { SubmenuService } from '../../../services/submenu.service';
import { CustomRenderDirective } from '../../../directives/custom-render.directive';
import { CustomizeCompanyComponent } from '../customize-company/customize-company.component';
import { ActivationCodeComponent } from '../activation-code/activation-code.component';
import { NotificationsSettingsComponent } from '../notifications-settings/notifications-settings.component';

import { TranslateService } from '@ngx-translate/core';
import { TagCreatorComponent } from '../tag-creator/tag-creator.component';
import { InfoTagsComponent } from '../info-tags/info-tags.component';

@Component({
  selector: 'app-side-child-view',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.sass'],
})
export class ItemChildViewComponent implements OnInit, OnDestroy {
  @ViewChild(CustomRenderDirective) componentHost: CustomRenderDirective;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close: Subject<boolean> = new Subject<boolean>();
  @Input() item: any = {};

  title: string = '';
  inChildView: boolean = false;
  menuItems: Array<any> = [];

  private titleClone: string = '';
  private subscriptions: any = {};
  private mainChilds: Array<any> = [];
  private validComponents: Array<string> = [
    'customize-company',
    'activation-code',
    'notifications-settings',
    'tag-creator',
    'tag-deleted',
  ];

  constructor(
    private _componentFactoryResolver: ComponentFactoryResolver,
    private submenuService: SubmenuService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    if (this.item.inChildView) {
      this.title = this.item.title;
      this.inChildView = true;
      return delay(
        () =>
          this.componentRenderer(this.item.toRender, this.item.options || {}),
        200
      );
    } else {
      this.initialize();

      this.subscriptions.softBack = this.submenuService.softBack.subscribe(
        () => {
          this.inChildView = false;
          this.title = this.titleClone;
        }
      );
    }
  }

  ngOnDestroy() {
    forEach(this.subscriptions, value => value.unsubscribe());
  }

  /**
   * Close sub menu view
   *
   * @returns child
   */
  closeChild() {
    if (this.item.inChildView) {
      return this.close.next(true);
    } else {
      const type = this.item.childsType;

      const _child = first(this.menuItems) || {};

      if (this.inChildView) {
        this.title = this.titleClone;
        return (this.inChildView = false);
      }

      if (_child.parentType === type) {
        return this.close.next(true);
      }

      this.title = this.item.title || '';
      this.menuItems = this.getItems(_child.parentType);
      this.inChildView = false;
    }
  }

  /**
   * Trigger actions per child selection
   *
   * @param child childclik
   * @returns child
   */
  childClick(child: any) {
    if (child.disabled) return;

    this.titleClone = clone(this.title);
    this.title = child.subtitle
      ? child.subtitle
      : child.title
      ? child.title
      : this.title;

    if (child.items && child.items.length) {
      this.mainChilds = clone(this.menuItems);
      this.menuItems = child.items || [];
      return;
    }

    if (child.actionType === 'render') {
      this.title = child.label ? child.label : this.title;
      this.inChildView = true;

      if (this.validComponents.indexOf(child.action) !== -1)
        return delay(
          () => this.componentRenderer(child.action, child.options || {}),
          200
        );
    }

    if (child.actionType === 'link')
      return this.downloadURI(
        child.action,
        this.translate.instant(child.label)
      );
  }

  /**
   * Render custom components on custom host template
   *
   * @param componentName componentName
   * @param options options
   * @returns component
   */
  private componentRenderer(componentName: String, options?: any) {
    let componentFactory =
      this._componentFactoryResolver.resolveComponentFactory(
        this.getComponentByName(componentName)
      );
    let viewContainerRef = this.componentHost.viewContainerRef;
    viewContainerRef.clear();
    let componentRef = viewContainerRef.createComponent(componentFactory);

    if (options) {
      forEach(options, (val, key) => {
        componentRef.instance[key] = val;
      });
    }

    return componentRef;
  }

  /**
   * Get component by name
   *
   * @param componentName componentName
   * @returns component
   */
  private getComponentByName(componentName: String) {
    let componentRef: any = {};

    switch (componentName) {
      case 'customize-company':
        componentRef = CustomizeCompanyComponent;
        break;

      case 'activation-code':
        componentRef = ActivationCodeComponent;
        break;

      case 'tag-creator':
        componentRef = TagCreatorComponent;
        break;

      case 'info-tags':
        componentRef = InfoTagsComponent;
        break;

      case 'notifications-settings':
        componentRef = NotificationsSettingsComponent;
        break;
    }

    return componentRef;
  }

  /**
   * initialize child menu
   */
  private initialize() {
    this.menuItems = this.getItems(this.item.childsType);

    this.mainChilds = clone(this.menuItems);
    this.title = this.item.title || '';
  }

  /**
   * Get items for each menu level
   *
   * @param type type
   * @returns object
   */
  private getItems(type: string) {
    const submenu2: any = submenu;
    let _values = get(submenu2.default, type) || {};
    return isPlainObject(_values) && !_values.items
      ? values(_values) || []
      : !_values.parentType
      ? _values.items
      : this.getItems(_values.parentType);
  }

  /**
   * Get parent menu item
   *
   * @param type type
   * @returns submenu
   */
  private getParent(type: string) {
    return get(submenu, type) || {};
  }

  /**
   * Download items from external links
   *
   * @param uri uri
   * @param name name
   */
  private downloadURI(uri, name) {
    let link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
