import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { isEmpty, forEach, isPlainObject, has } from 'lodash';
import { HelpersService, RouteAssignmentService } from '../../../services';
import { CardAutocompleteComponent } from '../card-autocomplete/card-autocomplete.component';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'card-assign-item',
  templateUrl: './card-item.component.html',
  styleUrls: ['./card-item.component.sass'],
})
export class CardItemComponent implements OnInit, AfterViewInit {
  @ViewChild('expansionPanel') expansionPanel: MatExpansionPanel;
  @ViewChild('autocompletePanel') autocompletePanel: CardAutocompleteComponent;

  @Input() type: string = 'driver';
  @Input() parentType: string = '';
  @Input() parentCardId: string = '';
  @Input() content: any = {};
  @Input() viewType: string = 'card';

  @Input() canRemove: boolean = false;
  @Input() isOnline: boolean = false;
  @Input() nightMode: boolean = false;
  @Input() available: number = 0;
  @Input() disabled: boolean = true;
  @Input() emitSave: boolean = false;
  @Input() queryDate: any = moment().format('YYYY-MM-DD Z');
  @Input() notValidateOnSelection: boolean = false;
  @Output() onSelection: Subject<any> = new Subject<any>();
  @Output() onRemove: Subject<any> = new Subject<any>();

  opened: boolean = false;
  selectionExists: boolean = false;
  driverDisabled: boolean = false;
  completed: boolean = false;
  isBefore: boolean = false;

  title: string = '';
  selection: any = {};

  private cardId: string = '';
  private subscriptions: any = {};
  private currentRoute: any = {};
  private subContent: any = {};
  private reassing: boolean = false;

  // TEMP control value
  private tempItemsCheck: any = ['vehicle', 'driver'];

  constructor(
    private translate: TranslateService,
    private routeAssign: RouteAssignmentService,
    private helpers: HelpersService
  ) {
    this.cardId = this.helpers.randomItemId(5);
  }

  ngOnInit() {
    this.title = this.setTitle();
    this.isBefore = moment(this.queryDate, ['YYYY-MM-DD', 'YYYY-MM-DD-Z'])
      .startOf('day')
      .isBefore(moment().startOf('day'));

    if (this.type === 'route') this.disabled = false;

    if (this.parentType === 'driver' && !this.content.isActive) {
      this.disabled = true;
      this.driverDisabled = true;
    }
  }

  ngAfterViewInit() {
    if (this.parentType !== 'route') {
      this.initalizeRouteType();
    } else {
      this.disabled = false;
      this.fillExistingItem(this.content[this.type]);

      if (!this.content[this.type] && this.isBefore) this.disabled = true;
    }

    this.subscriptions.errorAssigning = this.routeAssign.errorAssignItem
      .pipe(filter(item => item.cardId === this.cardId))
      .subscribe(item => {
        this.selectionExists = false;
        this.selection = {};
        this.expansionPanel.disabled = false;
        this.autocompletePanel.resetSelection();
      });

    this.subscriptions.assignedRoute = this.routeAssign.assignedRouteItem
      .pipe(filter(item => item.parentCard === this.parentCardId))
      .subscribe(item => {
        this.reassing = true;
        if (this.parentType !== 'route') this.getCurrentRoute(true, item.data);
        else this.getSingleRoute();
      });

    this.subscriptions.refreshRoute = this.routeAssign.refreshRoute
      .pipe(filter(item => item.parentCard === this.parentCardId))
      .subscribe(item => {
        this.initalizeRouteType();

        if (item.unassign) this.remove();
      });
  }

  ngOnDestroy() {
    forEach(this.subscriptions, value => value.unsubscribe());
  }

  /**
   * Trigger data load for autocomplete
   */
  onExpanded() {
    if (this.available != 0) {
      this.opened = true;
      this.autocompletePanel.initLoad();
    }
  }

  /**
   * Close already expaned item
   */
  closeExpaned() {
    this.expansionPanel.close();
  }

  /**
   * Got fired when an option of autocomplete it's selected
   * and display selected value
   *
   * @param selection
   */
  onAutocompleteSelection(selection: any) {
    this.fillExistingItem(selection);

    if (this.emitSave) {
      this.onSelection.next({ type: this.type, data: selection });
      return;
    }

    let cardOptions = {
      cardId: this.cardId,
      parentId: this.parentCardId,
      parentType: this.parentType,
      isRoute: this.type === 'route',
    };

    let _type = this.type === 'driver' ? 'assignDriver' : 'assignVehicle';

    if (this.type === 'route') {
      this.notValidateOnSelection = true;
      _type = this.parentType === 'driver' ? 'assignDriver' : 'assignVehicle';
      return this.routeAssign[_type](
        this.content._id,
        selection._id,
        cardOptions
      );
    }

    if (this.parentType === 'route') {
      _type = this.type === 'driver' ? 'assignDriver' : 'assignVehicle';
      return this.routeAssign[_type](
        selection._id,
        this.content._id,
        cardOptions
      );
    }

    return this.routeAssign[_type](
      selection._id,
      this.currentRoute._id,
      cardOptions
    );
  }

  /*
   * Remove selection
   */
  remove() {
    this.closeExpaned();
    this.selectionExists = false;
    this.selection = {};
    this.expansionPanel.disabled = false;
    this.autocompletePanel.resetSelection();

    if (this.canRemove) this.onRemove.next({});
  }

  /**
   * Fill selection data
   *
   * @param selection
   */
  private fillExistingItem(selection: any) {
    if (
      (this.content.status === 'completed' ||
        this.content.status === 'cancelled') &&
      !selection
    ) {
      this.disabled = true;
      this.completed = true;
    }

    if (!selection) return;

    if (isEmpty(this.subContent) && this.type !== 'route')
      this.subContent = selection;

    if (!isPlainObject(selection)) return;

    this.disabled = false;
    this.closeExpaned();
    this.selectionExists = true;
    this.selection = selection;
    this.expansionPanel.disabled = true;
    this.autocompletePanel.resetSelection();
  }

  /**
   * Set card title according to card type
   */
  private setTitle() {
    let title =
      this.type === 'vehicle'
        ? this.translate.instant('CARD-ASSIGNMENT.ASSIGN-VEHICLE')
        : this.type === 'route'
        ? this.translate.instant('CARD-ASSIGNMENT.ASSIGN-ROUTE')
        : this.translate.instant('CARD-ASSIGNMENT.ASSIGN-DRIVER');

    return title;
  }

  /**
   * Get single route
   */
  private getSingleRoute() {
    this.routeAssign
      .getSingleRoute(this.content._id)
      .then(item =>
        this.routeAssign.assignedStatusCheck.next({
          route: item.data,
          cardId: this.parentCardId,
          checkCount: true,
        })
      )
      .catch(error => this.helpers.handleErrorMessages(error));
  }

  /**
   * Get currrent route
   *
   * @param checked
   * @param data
   */
  private getCurrentRoute(checked: boolean, data: any) {
    this.routeAssign
      .getSingleRoute(data._id)
      .then(item => {
        if (this.parentType === 'driver' && !this.content.isActive) {
          this.disabled = true;
          this.driverDisabled = true;
          return;
        }

        item = item.data;

        this.currentRoute = item;
        this.disabled =
          this.type === 'route'
            ? false
            : this.tempItemsCheck.indexOf(this.type) !== -1 &&
              isEmpty(this.currentRoute);

        if (!isEmpty(item)) {
          if (
            this.reassing &&
            !isEmpty(this.selection) &&
            this.type !== 'route'
          ) {
            this.selection = {};
            this.subContent = {};
            this.selectionExists = false;
            this.reassing = false;
          }
          this.fillExistingItem(this.type === 'route' ? item : item[this.type]);
          this.routeAssign.assignedStatusCheck.next({
            route: item,
            cardId: this.parentCardId,
            checkCount: true,
          });
        }

        if (this.type === 'route') this.notValidateOnSelection = false;

        if (checked) this.disabled = false;
      })
      .catch(error => this.helpers.handleErrorMessages(error));
  }

  isAssigned() {
    return this.selection._id ? true : false;
  }

  private initalizeRouteType() {
    this.fillExistingItem(this.content[this.type]);
    if (has(this.content, 'route')) {
      // when components init and detect that already have a route assigned,
      // use this route as current, so in this way we could assign content without reassign all data over again
      this.currentRoute = this.content.route;
      setTimeout(() => {
        this.routeAssign.assignedStatusCheck.next({
          route: this.content.route,
          cardId: this.parentCardId,
          checkCount: false,
        });
      }, 10);
      this.disabled = false;
    }

    if (!has(this.content, 'route') && this.isBefore) this.disabled = true;
  }
}
