<div class="no_content_general">
  <div class="no_content_image">
    <img class="logo" [src]="img" />
  </div>
  <div class="no_content_text">
    <p class="" style="font-weight: normal; margin-left: 20px; color: black">
      {{ title | translate }}
    </p>
    <br />
    <ul *ngFor="let item of messages">
      <li>{{ item | translate }}</li>
    </ul>
  </div>
</div>
