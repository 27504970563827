<div>
  <div class="text-center font-weight-bold">Tipo de unidad</div>
  <div>
    <mat-form-field class="w-100" appearance="outline">
      <input
        type="text"
        placeholder="Pick one"
        aria-label="Number"
        matInput
        [formControl]="myControl"
        [matAutocomplete]="auto" />
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option
          *ngFor="let option of filteredOptions | async"
          [value]="option.unit + ' ' + option.label"
          (onSelectionChange)="onSelectionChangeSearcher(option)">
          {{ option.unit }} {{ option.label }}
        </mat-option>
      </mat-autocomplete>
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>
  <perfect-scrollbar id="list_options" scrollDirective>
    <div>
      <mat-radio-group
        aria-labelledby="example-radio-group-label"
        class="unit-radio-group"
        (change)="onSelectUnit()"
        [(ngModel)]="selected_unit">
        <div
          class="d-flex justify-content-between w-100 border-bottom my-3"
          *ngFor="let element of units_metric">
          <div class="left-radio-button">
            {{ element.unit }} {{ element.label }}
          </div>
          <div class="right-radio-button">
            <mat-radio-button class="radio-button" [value]="element">
            </mat-radio-button>
          </div>
        </div>
      </mat-radio-group>
    </div>
  </perfect-scrollbar>

  <div class="my-3">
    <div class="mx-auto text-center font-weight-bold">Otro</div>
    <button
      mat-raised-button
      class="mx-auto d-block button-color"
      (click)="openCreationUnitModal()">
      + Agregar otra unidad
    </button>
  </div>
</div>
