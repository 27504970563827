import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';

@Component({
  selector: 'app-color-array-picker',
  templateUrl: './color-array-picker.component.html',
  styleUrls: ['./color-array-picker.component.sass'],
})
export class ColorArrayPickerComponent implements OnInit {
  @Input() disabledColors = [];

  colors: Array<Array<string>> = [
    ['#3B188E', '#714DCA', '#B67DFF', '#B9A9F3', '#EDA5EC'],
    ['#A13A50', '#EA3888', '#D939A3', '#F275C8', '#CB79AD'],
    ['#C3281B', '#F26155', '#EE7D79', '#F97316', '#F59E0B'],
    ['#6EAA52', '#8ECB30', '#B6C655', '#DCC037', '#F7CD45'],
    ['#377D50', '#00956A', '#10B981', '#5AC57C', '#6EC9B8'],
    ['#2E4F8C', '#2D57CC', '#4498DF', '#50C5F8', '#4DABC8'],
    ['#2A4E70', '#3A84BB', '#7B99C6', '#64748B', '#333333'],
    ['#786B59', '#A9977D', '#B48371', '#785549', '#523F3E'],
  ];

  colorsT: Array<Array<string>> = [];
  selectedColor = null;

  constructor(
    private dialogRef: MatDialogRef<ColorArrayPickerComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    if (data && data['disabledColors']) {
      this.disabledColors = data['disabledColors'].map(color =>
        color.toLowerCase()
      );
    }
  }

  ngOnInit(): void {
    this.colorsT = this.colors[0].map((_, colIndex) =>
      this.colors.map(row => row[colIndex])
    );
    this.colorsT = [].concat.apply([], this.colorsT);
    this.dialogRef.backdropClick().subscribe(response => {
      this.closeModal();
    });
  }

  onSelectColor(color) {
    if (!this.disabledColors.includes(color.toLowerCase())) {
      this.selectedColor = color.toLowerCase();
    }
  }

  closeModal() {
    this.dialogRef.close(this.selectedColor);
  }
}
