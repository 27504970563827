<div class="reset-container">
  <mat-card appearance="outlined">
    <h1 class="title">{{ 'RESET-PASSWORD.TITLE' | translate }}</h1>
    <p class="subtitle">{{ 'RESET-PASSWORD.SUBTITLE' | translate }}</p>
    <form
      name="resetPwdForm"
      class="reset-form"
      autocomplete="off"
      [formGroup]="resetPwdForm"
      (ngSubmit)="resetPwd(resetPwdForm)"
      no-validate>
      <!-- CODE -->
      <mat-form-field class="w-20">
        <input
          matInput
          type="text"
          placeholder="{{ 'RESET-PASSWORD.CODE' | translate }}"
          name="code"
          formControlName="code"
          autocomplete="off" />
        <mat-error
          *ngIf="
            resetPwdForm.controls.code.invalid &&
            resetPwdForm.controls.code.touched
          ">
          {{ 'RESET-PASSWORD.MESSAGES.REQUIRED-CODE' | translate }}
        </mat-error>
      </mat-form-field>

      <!-- New pass -->
      <mat-form-field class="w-20">
        <input
          matInput
          type="password"
          placeholder="{{ 'RESET-PASSWORD.NEW-PASSWORD' | translate }}"
          name="newPassword"
          formControlName="newPassword"
          autocomplete="off" />
        <mat-error
          *ngIf="
            resetPwdForm.controls.newPassword.invalid &&
            resetPwdForm.controls.newPassword.touched
          ">
          {{ 'RESET-PASSWORD.NEW-PASSWORD-MISMATCH' | translate }}
        </mat-error>
      </mat-form-field>

      <!-- confirm pass -->
      <mat-form-field class="w-20">
        <input
          matInput
          type="password"
          placeholder="{{ 'RESET-PASSWORD.NEW-PASSWORD-CONFIRM' | translate }}"
          name="newPassword"
          formControlName="confirmPassword"
          autocomplete="off" />
        <mat-error
          *ngIf="
            resetPwdForm.controls.confirmPassword.invalid &&
            resetPwdForm.controls.confirmPassword.errors.mustMatch &&
            resetPwdForm.controls.confirmPassword.touched
          ">
          {{ 'RESET-PASSWORD.NEW-PASSWORD-MISMATCH' | translate }}
        </mat-error>
      </mat-form-field>

      <div class="reset-btn">
        <button
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="resetPwdForm.invalid">
          {{ 'RESET-PASSWORD.CHANGE-PASSWORD' | translate }}
        </button>
      </div>
    </form>
  </mat-card>
</div>
