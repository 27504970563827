import { Component, OnInit, Input } from '@angular/core';
import { ApiRequestsService } from '../../../../api';
import {
  HelpersService,
  UserService,
  CustomLoadingService,
} from '../../../services';

@Component({
  selector: 'app-notifications-settings',
  templateUrl: './notifications-settings.component.html',
  styleUrls: ['./notifications-settings.component.sass'],
})
export class NotificationsSettingsComponent implements OnInit {
  @Input() type: string = 'admin';
  items: Array<any> = [];
  title: String = '';

  constructor(
    private api: ApiRequestsService,
    private helper: HelpersService,
    private userService: UserService,
    private loading: CustomLoadingService
  ) {}

  ngOnInit() {
    this.listNotifications();
  }

  /**
   * Get checkbox selection value
   *
   * @param param0
   * @param param0.checked
   * @param item
   */
  checkboxUpdated({ checked }: any, item: any) {
    item.active = checked;
    let payload = {
      active: checked,
      action: item.action,
    };

    if (item.type === 'schedule') {
      let value = item.selected;
      return this.selectionUpdated(value, item);
    }

    this.storeSetting('event', payload);
  }

  /**
   * Get selection item value
   *
   * @param param0
   * @param param0.value
   * @param item
   */
  selectionUpdated({ value }: any, item) {
    let index = item.optionsValue.indexOf(value);

    if (index === -1) return;

    let payload = {
      active: item.active,
      value: item.optionsValue[index],
      action: item.action,
      type: item.optionsLabel,
    };

    this.storeSetting('schedule', payload);
  }

  /**
   * List all user notificactions
   */
  private listNotifications() {
    this.api.notifications
      .list()
      .toPromise()
      .then(response => {
        let data = response.data || {};
        this.title = data[this.type]['label'];
        this.items = data[this.type]['items'];
      })
      .catch(error => this.helper.handleErrorMessages(error));
  }

  /**
   * Store Settings value for company
   *
   * @param type
   * @param payload
   */
  private storeSetting(type: string, payload: any) {
    this.api.notifications
      .store(type, payload)
      .toPromise()
      .catch(error => this.helper.handleErrorMessages(error));
  }
}
