<div class="dialogContainer">
  <div class="text-center">
    <h3 class="" style="font-size: 18px">Descargar Reporte</h3>
    <p style="color: black">{{ message }}</p>
  </div>

  <hr />
  <button
    mat-flat-button
    class="buttonBlue py-2 w-100 text-center font-weight-bold"
    (click)="onConfirmClick('PDF')">
    Descargar
  </button>
  <hr />
  <button
    mat-flat-button
    class="buttonBlue py-2 w-100 text-center font-weight-bold"
    (click)="onConfirmClick('EXCEL')">
    Descargar hoja de cálculo
  </button>
  <hr />
  <button
    mat-flat-button
    class="buttonBlue py-2 w-100 text-center font-weight-bold"
    (click)="onConfirmClick('EMAIL')">
    Enviar e-mail
  </button>
  <hr />
  <button
    mat-flat-button
    mat-dialog-close
    class="buttonCancel py-2 w-100 text-center">
    Cancelar
  </button>
</div>
