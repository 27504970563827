import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SidePanelService {
  triggerSidePanel: Subject<any> = new Subject<any>();
  triggerClose: Subject<any> = new Subject<any>();
  onClose: Subject<any> = new Subject<any>();
  onNotifications: Subject<any> = new Subject<any>();
  notificationsData: Array<any> = [];

  notificationFetchLimit: Subject<any> = new Subject<any>();

  constructor() {}

  openPanel() {
    this.triggerSidePanel.next('menu');
  }
}
