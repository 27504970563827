<div class="signup-success-container">
  <mat-card appearance="outlined">
    <h1 class="title">{{ 'SIGNUP-SUCCESS.TITLE' | translate }}</h1>
    <p class="subtitle">{{ 'SIGNUP-SUCCESS.SUBSTITLE' | translate }}</p>
    <div class="signup-containter">
      <img class="signup-icon" [src]="'assets/img/congrats.svg'" />
    </div>
    <div class="divider"></div>
    <p class="label-signup-success">{{ 'SIGNUP-SUCCESS.LABEL' | translate }}</p>
    <div class="continue-btn">
      <button mat-raised-button color="primary" (click)="goToProfile()">
        {{ 'COMMON.CONTINUE' | translate }}
      </button>
    </div>
  </mat-card>
</div>
