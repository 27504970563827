import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { forEach } from 'lodash';

@Component({
  selector: 'app-card-cancel-route',
  templateUrl: './card-cancel-route.component.html',
  styleUrls: ['./card-cancel-route.component.sass'],
})
export class CardCancelRouteComponent implements OnInit {
  type: string = '';
  message: string = '';
  title: string = '';

  private subscriptions: any = {};

  constructor(
    public dialogRef: MatDialogRef<CardCancelRouteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.subscriptions.dialogOpen = this.dialogRef
      .afterOpened()
      .subscribe(() => {
        this.type = this.data.type ? this.data.type : 'route';
      });
  }

  ngOnDestroy() {
    forEach(this.subscriptions, value => value.unsubscribe());
  }
}
