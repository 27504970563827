import * as _ from 'lodash';

declare let google: any;

export class RoadsApi {
  private _mapsKey: string = '';
  private _baseUrl: string = 'https://roads.googleapis.com/v1/snapToRoads?key=';

  constructor(config) {
    if (!config.googleMapsApiKey) throw 'No google maps credentials found';

    this._mapsKey = config.googleMapsApiKey;
    this._baseUrl += this._mapsKey;
  }

  /**
   * Do Google Roads request
   *
   * @param waypoints
   * @param interpolate
   * @returns
   */
  getRoad(waypoints, interpolate = true) {
    return new Promise((resolve, reject) => {
      if (!waypoints.length) return resolve({});

      let xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) resolve(this.extractResponse(xhr.response));
          else reject(this.extractResponse(xhr.response));
        }
      };

      let _paths = '';
      let lastIndex: any = _.findLastIndex(waypoints);

      _.forEach(waypoints, (point, i) => {
        _paths += `${this.generatePath(point)}${i === lastIndex ? '' : '|'}`;
      });

      let url = `${this._baseUrl}&path=${_paths}&interpolate=${interpolate}`;

      xhr.open('POST', url, true);
      xhr.send();
    });
  }

  /**
   * Generate string path for each waypoint
   *
   * @param waypoint
   * @returns path
   */
  generatePath(waypoint) {
    if (!waypoint || _.isEmpty(waypoint)) return '';

    let _path = '';

    if (_.has(waypoint, 'lat') && _.has(waypoint, 'lng'))
      _path = `${waypoint.lat()},${waypoint.lng()}`;

    if (waypoint.latitude && waypoint.longitude)
      _path = `${waypoint.latitude},${waypoint.longitude}`;

    return _path;
  }

  /**
   * Get response from XMLHttpRequest request
   *
   * @param response
   * @returns
   */
  extractResponse(response) {
    let body = {};
    try {
      body = JSON.parse(response);
    } catch (error) {
      body = {};
    }

    return body;
  }
}
