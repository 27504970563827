<div class="table-report hidden-sm hidden-xs">
  <div class="table-body">
    <app-routes-report-table-row
      [route]="route"
      [driver]="route.driver"
      (previewOpenEvent)="previewOpen($event)"
      (previewCloseEvent)="previewClose($event)"
      [statuses]="statuses"
      (previewChangeEvent)="previewChange($event)">
    </app-routes-report-table-row>
  </div>
</div>
